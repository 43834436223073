<template>
  <v-col v-bind="$attrs">
    <v-sheet color="secondary" rounded style="min-height: 100%;">
      <v-responsive :aspect-ratio="320 / 180">
        <v-skeleton-loader
          height="100%"
          type="image"
          boilerplate
        ></v-skeleton-loader>
      </v-responsive>
      <v-skeleton-loader
        type="list-item-two-line, list-item-avatar"
        boilerplate
      ></v-skeleton-loader>
    </v-sheet>
  </v-col>
</template>

<script>
export default {
  name: 'VideoItemLoader',
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-list-item:hover::before {
    opacity: 0 !important;
  }

  .v-skeleton-loader__image {
    height: 100%;
  }

  .v-skeleton-loader__list-item-avatar {
    height: 40px;

    .v-skeleton-loader__avatar {
      height: 30px !important;
      width: 30px !important;
    }
  }
}
</style>
