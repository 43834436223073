import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
// @ts-ignore
import Vuelidate from 'vuelidate'
import Default from './layouts/DefaultLayout.vue'
import Empty from './layouts/EmptyLayout.vue'
import Console from './layouts/ConsoleLayout.vue'
import VueClipboard from 'vue-clipboard2'
import VueMeta from 'vue-meta'
import i18n from './plugins/i18n'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import '@vime/core/themes/default.css'
// import '@/assets/js/firebase-init.js'
Vue.config.productionTip = false
Vue.component('default-layout', Default)
Vue.component('empty-layout', Empty)
Vue.component('console-layout', Console)
// @ts-ignore
import VueCountdownTimer from 'vuejs-countdown-timer'
Vue.use(VueCountdownTimer)

Vue.prototype.$pause = async (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

Vue.filter('capitalize', function(value: string) {
  if (!value) return ''
  value = value.toString()
  // return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('capitalize_first', function(value: string) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.use(Vuelidate as any)
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(VueMeta)

/*Sentry.init({
  Vue,
  dsn:
    'https://e27383de425b470bb020b2f4d2c75ca2@o252825.ingest.sentry.io/5951543',
  integrations: [
    new BrowserTracing({
      traceXHR: true,
      traceFetch: true,
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      // logErrors: true,
      tracingOrigins: [
        'localhost',
        'crowdbunker.com',
        /.+-mclean-cb1295\.netlify\.app/,
        // /.+\.divulg\.org/,
        /.+\.crowdbunker\.com/,
        '192.168.1.16:7070',
      ],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.VUE_APP_ENV === 'production' ? 0.2 : 1,
  environment: process.env.VUE_APP_ENV,
  release: `front-web@${process.env.npm_package_version}`,
})*/

new Vue({
  router,
  store,
  // @ts-ignore
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
