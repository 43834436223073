<template>
  <div>
    <v-card
      v-if="
        roleCurrentUser === 'owner' ||
          roleCurrentUser === 'administrator' ||
          modal
      "
      flat
    >
      <v-card-title v-if="modal" class="headline secondary lighten-2"
        >{{ $tc('organization.new-organization') | capitalize }}
      </v-card-title>
      <v-divider v-if="modal"></v-divider>
      <v-card-text>
        <v-btn
          v-if="embed"
          @click="$emit('input', false)"
          :disabled="submitLoading"
          text
          small
        >
          <v-icon left small>mdi-chevron-left</v-icon>
          {{ $tc('button.return') }}
        </v-btn>
        <v-form>
          <text-field
            :label="$tc('organization.name-of-organization') | capitalize"
            :field="form.name"
            :counter="form.name.value.length > 40 ? 50 : undefined"
            @keyup="nameEdited"
          >
            <template v-if="subscriptionLevel" v-slot:append>
              <v-icon :color="getColor(subscriptionLevel)">mdi-seal</v-icon>
            </template>
          </text-field>
          <text-field
            :label="($tc('login.username') + ' (UID)') | capitalize_first"
            prefix="@"
            :field="form.uid"
            counter="25"
            @input="checkUidAvailability"
            @keyup="UIDEdited = true"
          >
            <template #append>
              <v-progress-circular
                indeterminate
                size="20"
                width="2"
                color="white"
                v-if="UIDCheckLoading"
              ></v-progress-circular>
              <v-icon
                v-else
                v-text="UIDAvailable ? 'mdi-check-bold' : 'mdi-close-thick'"
                :color="UIDAvailable ? 'success' : 'error'"
              ></v-icon>
            </template>
          </text-field>
          <div class="text-caption">
            {{ $tc('channel.profile-image') | capitalize }}
          </div>
          <v-hover v-slot="{ hover }">
            <channel-avatar
              :thumbnails="organizationThumbnails"
              :size="80"
              :channel-name="form.name.value"
              :bg-color="organizationAvatarColor || 'secondary lighten-1'"
              class="my-2 hand-cursor d-inline-block"
              @click.native="uppyAvatar.getPlugin('Dashboard').openModal()"
            >
              <v-overlay
                absolute
                :value="hover"
                color="success darken-1"
                opacity="0.8"
              >
                <v-icon>mdi-cloud-upload-outline</v-icon>
              </v-overlay>
            </channel-avatar>
          </v-hover>
          <div
            v-if="getErrors(form.thumbnailKey.errors).length"
            class="error text-caption"
          >
            {{ getErrors(form.thumbnailKey.errors)[0] }}
          </div>

          <div class="text-caption">
            {{ $tc('channel.cover-image') | capitalize }}
          </div>
          <v-hover v-slot="{ hover }">
            <v-img
              :src="bannerPreviewUrl"
              width="100%"
              :aspect-ratio="1903 / 315"
              class="my-2 hand-cursor"
              @click="uppyBanner.getPlugin('Dashboard').openModal()"
            >
              <v-overlay
                absolute
                :value="hover"
                color="success darken-1"
                opacity="0.8"
              >
                <div class="d-flex text-button align-center">
                  <v-icon left>mdi-cloud-upload-outline</v-icon>
                  {{ $tc('button.import-an-image') }}
                </div>
              </v-overlay>
              <div
                v-if="bannerPreviewUrl === null"
                class="d-flex secondary lighten-1 fill-height justify-center align-center"
                style="width: 100%"
              >
                <span v-show="!hover">{{
                  ($tc('label.recommended') + ' : 1900 x 320 px') | capitalize
                }}</span>
              </div>
            </v-img>
          </v-hover>
          <div
            v-if="getErrors(form.bannerKey.errors).length"
            class="error text-caption"
          >
            {{ getErrors(form.bannerKey.errors)[0] }}
          </div>

          <textarea-field
            :label="$tc('label.optional-description') | capitalize"
            :field="form.description"
            :counter="form.description.value.length > 900 ? 1000 : undefined"
            class="mt-4"
            rows="2"
          ></textarea-field>
          <template v-if="unhandledErrors.error">
            <div
              v-for="error in unhandledErrors.error"
              :key="error"
              class="error--text"
            >
              {{ error }}
            </div>
          </template>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="modal"
          @click="$emit('input', false)"
          :disabled="submitLoading"
          text
          >{{ $tc('button.cancel') }}
        </v-btn>
        <v-btn
          color="success"
          @click="submit"
          :loading="submitLoading"
          depressed
          >{{ $tc('button.validate') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-alert type="info" border="left" colored-border>
        {{ $t('organization.only-admins-can-access-this-feature') }}
      </v-alert>
    </v-card>
    <template v-if="!modal && roleCurrentUser === 'owner'">
      <div class="text-h5 mb-2 mt-5">
        {{ $tc('organization.other-parameters') | capitalize }}
      </div>
      <v-card flat>
        <v-card-text>
          <div class="text-h6">
            {{ $tc('organization.deletion') | capitalize }}
          </div>
          <div class="text-caption">
            {{ $tc('organization.organization-deleted-content-hidden') }}
          </div>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title class="headline">
                {{ $tc('organization.confirm-deletion') }}
              </v-card-title>
              <br />
              <v-card-subtitle>
                {{ $tc('organization.organization-deleted-content-hidden') }}
              </v-card-subtitle>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="mt-1" text @click="dialog = false">
                  {{ $tc('button.cancel') }}
                </v-btn>
                <v-btn
                  class="mt-1"
                  color="error"
                  text
                  @click="updateStatus('deleted')"
                >
                  {{ $tc('button.confirm') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn class="mt-1" color="error" @click="dialog = true" depressed>
            {{ $tc('button.delete-organization') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>

<script>
import { requestService } from '@/services/request.service.js'
import ChannelAvatar from '../ChannelAvatar'
import Validation from '../mixins/Validation'
import TextField from '../form/TextField'
import TextareaField from '../form/TextareaField'
import { uppyService } from '@/services/uppy.service'
import { subscriptionService } from '@/services/subscription.service'

// And their styles (for UI plugins)
// With webpack and `style-loader`, you can require them like this:
require('@uppy/core/dist/style.min.css')
require('@uppy/dashboard/dist/style.min.css')
require('@uppy/image-editor/dist/style.min.css')

export default {
  name: 'EditOrganization',
  components: { TextareaField, TextField, ChannelAvatar },
  mixins: [Validation],
  props: {
    value: { type: Boolean, default: false },
    modal: { type: Boolean, default: false },
    embed: { type: Boolean, default: false },
    organization: { type: Object, default: null },
    roleCurrentUser: { type: String, default: null },
  },
  data() {
    return {
      uppyAvatar: {},
      uppyBanner: {},
      storageBaseUrl: '',
      picturePath: null,
      form: {
        name: {
          value: '',
          required: true,
          minLength: 3,
          maxLength: 50,
          regex: [/^((?!Crowd.?Bunker|admin([^a-z]|$)).)*$/i, /^[^@].+$/i],
          regexMessage: [
            this.$options.filters.capitalize(
              this.$tc('organization.must-not-contain-words'),
            ),
            this.$options.filters.capitalize(
              this.$tc('organization.must-not-start-with-at'),
            ),
          ],
        },
        uid: {
          value: '',
          required: true,
          minLength: 3,
          maxLength: 25,
          regex: [
            /^[a-z0-9_-]+$/i,
            /^((?!Crowd.?Bunker|admin([^a-z]|$)).)*$/i,
            /^[a-z0-9]+.*[a-z0-9]+$/i,
            /^((?![_-]{2,}).)*$/i,
          ],
          regexMessage: [
            this.$options.filters.capitalize(
              this.$tc('organization.must-not-contain-letters'),
            ),
            this.$options.filters.capitalize(
              this.$tc('organization.must-not-contain-words'),
            ),
            this.$options.filters.capitalize(
              this.$tc('organization.must-not-start-or-end-with'),
            ),
            this.$options.filters.capitalize(
              this.$tc('organization.must-not-contain-more-than'),
            ),
          ],
        },
        description: {
          value: '',
          maxLength: 1000,
        },
        thumbnailKey: {
          value: '',
        },
        bannerKey: {
          value: '',
        },
      },
      picturePreviewUrl: null,
      bannerPreviewUrl: null,
      UIDAvailable: false,
      UIDCheckTimeout: null,
      UIDCheckLoading: false,
      UIDEdited: false,
      submitLoading: false,
      dialog: false,
    }
  },
  computed: {
    organizationThumbnails() {
      if (this.picturePreviewUrl) {
        return [
          {
            // url: `${this.storageBaseUrl}/${this.picturePath}`,
            url: this.picturePreviewUrl,
            name: 'medium',
          },
        ]
      } else if (this.organization && this.organization.thumbnails) {
        return this.organization.thumbnails
      }
      return []
    },
    organizationAvatarColor() {
      return this.organization?.avatarColor
    },
    editing() {
      return this.organization !== null && this.organization.id
    },
    plateFormSubscription() {
      return this.$store.state.account.plateFormSubscription
    },
    subscriptionLevel() {
      return (
        this.organization?.ownerMembershipLevel ??
        this.plateFormSubscription?.planLevel ??
        0
      )
    },
  },
  methods: {
    getColor(level) {
      return subscriptionService.subscriptionLevelColor(level)
    },
    checkUidAvailability() {
      this.formValidation(this.form)
      if (
        this.form.uid.errors ||
        this.form.uid.value.length < 3 ||
        this.form.uid.value.length > 25
      ) {
        this.UIDAvailable = false
        return
      }
      if (this.editing && this.organization.uid === this.form.uid.value) {
        this.UIDAvailable = true
        return
      }
      this.UIDCheckLoading = true
      clearTimeout(this.UIDCheckTimeout)
      this.UIDCheckTimeout = setTimeout(() => {
        requestService
          .get(`/organization/check-uid-availability/${this.form.uid.value}`)
          .then(response => {
            this.UIDAvailable = response.available
          })
          .finally(() => {
            this.UIDCheckLoading = false
          })
      }, 300)
    },
    generateUID() {
      return this.form.name.value
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^a-z0-9_-]/gi, ' ')
        .replace(/ +/g, ' ')
        .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
          if (+match === 0) return '' // or if (/\s+/.test(match)) for white spaces
          return index === 0 ? match.toLowerCase() : match.toUpperCase()
        })
    },
    submit() {
      this.formValidation(this.form, true)
      if (this.errorsNbr > 0) {
        return
      }
      this.submitLoading = true
      const url =
        '/organization/manage' +
        (this.editing ? `/${this.organization.id}` : '') +
        (this.$route.query.force !== undefined ? '?force' : '')
      requestService
        .post(url, this.formatDataToSend(this.form))
        .then(response => {
          if (response.success) {
            const message = this.editing
              ? this.$options.filters.capitalize(
                  this.$tc('channel.saved-modification'),
                )
              : this.$options.filters.capitalize(
                  this.$tc('organization.organization-successfully-created'),
                )
            this.$store.dispatch('alert/success', message)
            this.$emit('input', false)
            this.$emit('edited', response.organizationId)
            if (!this.editing) {
              this.$root.$emit('organizationCreated')
            }
          }
        })
        .catch(response => {
          this.submitLoading = false
          this.handleServerErrors(response, this)
        })
        .finally(() => (this.submitLoading = false))
    },
    loadOrganization() {
      if (this.organization !== null) {
        this.form.name.value = this.organization.name || ''
        this.form.uid.value = this.organization.uid || this.generateUID()
        this.form.description.value = this.organization.description || ''
        // if (this.organization.thumbnails && this.organization.thumbnails[0]) {
        //   this.picturePreviewUrl = this.organization.thumbnails[0].url
        // }
        this.bannerPreviewUrl = this.organization.banner
          ? this.organization.banner.url
          : null
        this.UIDAvailable = this.editing
        this.checkUidAvailability()
      }
    },
    instantiateUppyAvatar() {
      this.uppyAvatar = uppyService.instantiate({
        id: 'avatar',
        note: this.$tc('organization.jpg-png-or-gif') + ' : 800 x 800 px',
        minCanvasWidth: 200,
        aspectRatio: 1,
      })
      this.uppyAvatar.on('upload-success', file => {
        this.picturePath = file.meta['key']
        this.form.thumbnailKey.value = file.meta['key']
        this.picturePreviewUrl = file.preview
      })
    },
    instantiateUppyBanner() {
      this.uppyBanner = uppyService.instantiate({
        id: 'banner',
        note: this.$tc('organization.jpg-png-or-gif') + ' : 1900 x 320 px',
        minCanvasWidth: 200,
        aspectRatio: 1900 / 315,
        thumbnailWidth: 575,
      })
      this.uppyBanner.on('upload-success', file => {
        this.form.bannerKey.value = file.meta['key']
        this.bannerPreviewUrl = file.preview
      })
    },
    nameEdited() {
      if (this.UIDEdited || this.editing) {
        return
      }
      this.form.uid.value = this.generateUID()
      this.$nextTick(() => this.checkUidAvailability())
    },
    confirmDelete() {
      if (confirm(this.$tc('organization.confirm-deletion'))) {
        this.updateStatus('deleted')
      }
    },
    async updateStatus(status) {
      try {
        await requestService
          .post(`/organization/manage/${this.organization.uid}/update-status`, {
            status: status,
          })
          .then(response => {
            this.organization.status = response.status
            this.$store.dispatch(
              'alert/success',
              this.$options.filters.capitalize(
                this.$tc(
                  'organization.the-organization-has-been-successfully-deleted',
                ),
              ),
            )
            this.$router.push({ name: 'MyOrganizations' })
          })
      } catch (error) {
        this.$store.dispatch(
          'alert/error',
          this.$options.filters.capitalize(
            this.$tc('organization.error-could-not-delete-this-organization'),
          ),
        )
      }
    },
  },
  watch: {
    organization() {
      this.loadOrganization()
    },
  },
  mounted() {
    this.instantiateUppyAvatar()
    this.instantiateUppyBanner()
    this.loadOrganization()
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-counter {
    color: var(--v-success-base);
  }
}
.theme--dark.v-btn.v-btn--text.error--text:hover::before {
  opacity: 0.3;
}

.hand-cursor {
  cursor: pointer;
}
</style>
