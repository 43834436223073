import i18n from '@/plugins/i18n'

const state = {
  type: null,
  message: null,
  active: false,
}

const actions = {
  success({ commit }, message) {
    commit('success', message)
  },
  error({ commit }, message) {
    commit('error', message)
  },
  clear({ commit }) {
    commit('clear')
  },
  updateActive({ commit }, active) {
    commit('updateActive', active)
  },
}

const mutations = {
  success(state, message) {
    state.type = 'success'
    state.message = message
    state.active = true
  },
  error(state, message) {
    state.type = 'error'
    state.active = true

    // Check if message is an object with a "message" field
    if (message && typeof message === 'object' && message.message) {
      state.message = message.message
    }
    // if 404
    else if (message && typeof message === 'object' && message.status === 404) {
      state.message = i18n.t('error.content-not-found')
    }
    // Check if message is an object in the specified format
    else if (message && typeof message === 'object' && message.detail) {
      state.message = `${message.status}: ${message.detail}`
    }
    // Fallback to treating message as a string
    else {
      state.message = message
    }
  },
  clear(state) {
    state.type = null
    state.message = null
    state.active = false
  },
  updateActive(state, active) {
    state.active = active
  },
}

export const alert = {
  namespaced: true,
  state,
  actions,
  mutations,
}
