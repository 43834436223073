<template>
  <v-text-field
    v-model="field.value"
    :error-messages="getErrors(field.errors)"
    @change="onFieldChange(field)"
    @keydown="onKeyDown($event)"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-text-field>
</template>

<script>
import Validation from '../mixins/Validation'

export default {
  name: 'TextField',
  mixins: [Validation],
  props: {
    field: {
      type: Object,
    },
  },
  methods: {
    onKeyDown(e) {
      if (this.$attrs.type === 'decimal') {
        this.onDecimal(e)
      } else if (this.$attrs.type === 'number') {
        this.onNumber(e)
      }
    },
    onNumber(e) {
      const pos = e.target.selectionStart
      const oldVal = e.target.value
      const newVal = oldVal.slice(0, pos) + e.key + oldVal.slice(pos)
      if (e.key.length === 1 && e.ctrlKey === false) {
        if (newVal.match(/^\d*$/g) === null) {
          e.preventDefault()
        } else if (newVal.match(/,/g)) {
          const corrected = newVal.replace(/,/g, '.')
          this.$emit('input', corrected)
          e.target.value = corrected
          e.target.setSelectionRange(pos + 1, pos + 1)
          e.preventDefault()
        }
      }
    },
    onDecimal(e) {
      const pos = e.target.selectionStart
      const oldVal = e.target.value
      const newVal = oldVal.slice(0, pos) + e.key + oldVal.slice(pos)
      if (e.key.length === 1 && e.ctrlKey === false) {
        if (newVal.match(/^(\d{1,7}([.,]\d{0,2})?)?$/g) === null) {
          e.preventDefault()
        } else if (newVal.match(/,/g)) {
          const corrected = newVal.replace(/,/g, '.')
          this.$emit('input', corrected)
          e.target.value = corrected
          e.target.setSelectionRange(pos + 1, pos + 1)
          e.preventDefault()
        }
      }
    },
  },
}
</script>

<style scoped></style>
