<template>
  <div class="d-flex align-center" :class="{ 'mx-2': !small }" v-bind="$attrs">
    <span
      v-if="!text && likesCount > 0"
      v-text="likesCount ? likesCount : ''"
      class="likes-count d-inline-flex text-no-wrap"
      :style="{ order: textRight ? 2 : 1 }"
      style="min-width: 10px"
    ></span>
    <v-tooltip bottom color="grey">
      <template v-slot:activator="{ on }">
        <v-btn
          class="d-inline-flex"
          :class="{ 'btn-vertical': text }"
          icon
          :small="small"
          :color="liked ? 'success' : 'grey'"
          :style="{ order: textRight ? 1 : 2 }"
          @click.prevent.stop="liked ? unlike() : like()"
        >
          <v-icon :small="small" v-on="on">
            mdi-thumb-up
          </v-icon>
          <span
            v-if="text"
            class="font-weight-bold text-caption text-sm-overline"
            v-text="likesCount"
          ></span>
        </v-btn>
      </template>
      <span>{{ liked ? unlikeText : likeText }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'LikeButton',
  props: {
    post: { type: Object },
    small: { type: Boolean },
    textRight: { type: Boolean },
    text: { type: Boolean },
  },
  data() {
    return {
      liked: false,
      likesCount: 0,
    }
  },
  computed: {
    likeText() {
      return this.$t('label.i-like')
    },
    unlikeText() {
      return this.$t('label.i-dont-like')
    },
  },
  methods: {
    like() {
      this.$set(this.post, 'liked', true)
      this.liked = true
      const oldLikesCount = this.post.likesCount
      this.$store.dispatch('like/like', this.post.uid).then(response => {
        if (response.success && response.likesCount !== undefined) {
          this.liked = true
          this.likesCount = response.likesCount
          this.$set(this.post, 'liked', true)
          this.$set(this.post, 'likesCount', response.likesCount)
        } else {
          this.$set(this.post, 'liked', false)
          this.liked = false
          this.likesCount = oldLikesCount
          this.$set(this.post, 'likesCount', oldLikesCount)
          this.$store.dispatch('alert/error', response.message)
        }
      })
    },
    unlike() {
      this.$set(this.post, 'liked', false)
      this.liked = false
      this.$store.dispatch('like/unlike', this.post.uid).then(response => {
        if (response.success && response.likesCount !== undefined) {
          this.$set(this.post, 'likesCount', response.likesCount)
          this.likesCount = response.likesCount
          this.post.liked = false
          this.liked = false
        } else {
          this.$set(this.post, 'liked', true)
          this.liked = true
        }
      })
    },
  },
  watch: {
    'post.likesCount'(value) {
      this.likesCount = value
      this.liked = !!this.post.liked
    },
  },
  mounted() {
    this.liked = !!this.post.liked
    this.likesCount = this.post.likesCount
  },
}
</script>

<style scoped lang="scss">
.likes-count {
  padding-top: 3px;
}
</style>
