import { requestService } from '@/services/request.service.js'
import store from '@/store/index'
import router from '../router/index.ts'

const actions = {
  vote(action, { postUid, vote }) {
    return new Promise((resolve, reject) => {
      if (!store.state.account.status.loggedIn) {
        router.push({ name: 'Login' })
        return
      }
      requestService
        .post(`/post/${postUid}/vote`, {
          vote,
        })
        .then(response => resolve(response))
        .catch(response => reject(response))
    })
  },
}

export const vote = {
  namespaced: true,
  actions,
}
