<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn
        class="chat-message-button"
        icon
        small
        color="grey"
        v-bind="$attrs"
        v-on="on"
        @click.stop.prevent
      >
        <v-icon small>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list outlined elevation="0" dense class="pa-0" @mouseover.stop.prevent>
      <v-list-item
        class="post-menu-item"
        @click="deletePost"
        v-if="
          message.isOwner || post.isAMemberOfOrganization || post.canModerate
        "
      >
        <v-list-item-icon>
          <v-icon>mdi-delete</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-uppercase"
            >{{ $tc('label.delete') | capitalize }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        class="post-menu-item"
        @click="banPost"
        v-if="video.isOwner || post.isAMemberOfOrganization || post.canModerate"
      >
        <v-list-item-icon>
          <v-icon>mdi-cancel</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-uppercase"
            >{{ $tc('label.ban') | capitalize }}...
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="$store.state.account.status.loggedIn"
        class="post-menu-item"
        @click="report"
      >
        <v-list-item-icon>
          <v-icon>mdi-alert-octagon</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-uppercase"
            >{{ $tc('label.report') | capitalize }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { requestService } from '@/services/request.service'

export default {
  name: 'ChatMessageMenu',
  props: {
    message: { type: Object, required: true },
    video: { type: Object, required: true },
    post: {
      type: Object,
      required: true,
    },
  },
  methods: {
    deletePost() {
      this.$emit('loading', true)
      requestService
        .delete(`/chat/${this.message.id}`)
        .then(() => {
          this.$store.dispatch(
            'alert/success',
            this.$tc('error.message-deleted'),
          )
          this.deleted = true
          this.$emit('deleted')
        })
        .catch(response => {
          if (response.status === 404) {
            this.$store.dispatch(
              'alert/error',
              this.$tc('error.message-doesnt-exist'),
            )
          } else {
            this.$store.dispatch(
              'alert/error',
              this.$tc('error.impossible-suppress-message'),
            )
          }
        })
        .finally(() => {
          this.$emit('loading', false)
        })
    },
    report() {
      this.$root.$emit('report', this.message)
    },
    banPost() {
      this.$emit('ban', this.message)
    },
  },
}
</script>

<style scoped lang="scss">
.chat-message-button {
  margin-right: 3px;
}
</style>
