
import { defineComponent } from 'vue'
import { requestService } from '@/services/request.service'
import PostMenu from '@/components/PostMenu.vue'
import PostPreview from '@/components/post/PostPreview.vue'
import { utilsService } from '@/services/utils.service'
import DensePostItem from '@/components/post/DensePostItem.vue'

export default defineComponent({
  name: 'RelatedCard',
  components: { DensePostItem, PostPreview, PostMenu },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      relatedPosts: [],
    }
  },
  methods: {
    fetchRelated() {
      if (!this.post?.uid) return
      requestService
        .get(`/post/${this.post.uid}/related`)
        .then(res => {
          this.relatedPosts = this.formatPosts(res.posts)
        })
        .catch(err => {
          console.log(err)
        })
    },
    formatPosts(posts) {
      return posts.map(post => {
        return {
          ...post,
          video: {
            title: post.title,
            thumbnails: [
              {
                url: post.thumbnailUrl,
              },
            ],
            hlsManifest: {
              url: post.hlsUrl,
            },
            id: post.uid,
          },
        }
      })
    },
  },
  watch: {
    'post.uid': {
      immediate: true,
      handler() {
        this.fetchRelated()
      },
    },
  },
})
