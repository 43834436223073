<template>
  <div v-if="showAlert">
    <v-alert
      type="info"
      outlined
      dismissible
      dense
      rounded
      prominent
      class="mb-0"
      @input="dismissAlert"
    >
      {{ $t('organization.monetization-intro-part1') }}
      <router-link
        :to="{
          name: 'Monetization',
          params: { id: organization.id },
        }"
        style="text-decoration: none; font-weight: bolder"
      >
        {{ $t('organization.access-console') }}
      </router-link>
      {{ $t('organization.monetization-intro-part2') }}
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    organization: Object,
  },
  data() {
    return {
      showAlert: false,
    }
  },
  methods: {
    dismissAlert() {
      const now = new Date()
      const expiryDate = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000) // 30 days from now
      localStorage.setItem(
        'alertMonetizationDismissed',
        expiryDate.toISOString(),
      )
      this.showAlert = false
    },
    checkAlertStatus() {
      const dismissedDate = localStorage.getItem('alertMonetizationDismissed')
      if (dismissedDate) {
        const now = new Date()
        const expiryDate = new Date(dismissedDate)
        if (now < expiryDate) {
          return
        }
      }
      this.showAlert = true
    },
  },
  mounted() {
    this.checkAlertStatus()
  },
}
</script>
