import Uppy from '@uppy/core'
import French from '@uppy/locales/lib/fr_FR'
import De_DE from '@uppy/locales/lib/de_DE'
import En_US from '@uppy/locales/lib/en_US'
import Es_ES from '@uppy/locales/lib/es_ES'
import It_IT from '@uppy/locales/lib/it_IT'
import Dashboard from '@uppy/dashboard'
import DragDrop from '@uppy/drag-drop'
import ImageEditor from '@uppy/image-editor'
import AwsS3 from '@uppy/aws-s3'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import ThumbnailGenerator from '@uppy/thumbnail-generator'
import { requestService } from '@/services/request.service.js'
import i18n from '../plugins/i18n'
import Vue from 'vue'

function getLocale() {
  switch (i18n.locale) {
    case 'fr':
      return French
    case 'de':
      return De_DE
    case 'en':
      return En_US
    case 'es':
      return Es_ES
    case 'it':
      return It_IT
  }
}

function instantiate(options) {
  const defaultOptions = {
    id: 'avatar',
    maxFileSize: 15000000, // max 15 MO
    minFileSize: 1000,
    maxNumberOfFiles: 1,
    allowedFileTypes: ['image/jpeg', 'image/png', 'image/gif'],
    allowMultipleUploads: false,
    note: i18n.t('upload.image-recommendation', { height: 800, width: 800 }),
    minCanvasWidth: 200,
    aspectRatio: 1,
    thumbnailWidth: 280,
    useDashboard: true,
    useImageEditor: true,
    useDragDrop: false,
    autoProceed: false,
    width: '100%',
    height: '100%',
    onBeforeFileAdded: () => true,
    autoOpenFileEditor: true,
  }
  Object.assign(defaultOptions, options)

  const locale = getLocale()

  // French.strings.done = 'Annuler'
  locale.strings.save = Vue.filter('capitalize')(i18n.t('organization.save'))
  locale.strings.rotate = Vue.filter('capitalize')(
    i18n.t('organization.rotation'),
  )
  locale.strings.flipHorizontal = Vue.filter('capitalize')(
    i18n.t('organization.horizontal-mirror'),
  )
  locale.strings.zoomIn = Vue.filter('capitalize')(
    i18n.t('organization.zoom') + ' +',
  )
  locale.strings.zoomOut = Vue.filter('capitalize')(
    i18n.t('organization.zoom') + ' -',
  )
  locale.strings.browse = i18n.t('label.browse')
  locale.strings.done = Vue.filter('capitalize')(i18n.t('label.ended'))
  locale.strings.dropHereOr = i18n.tc(
    'upload.drop-here-or',
    defaultOptions.maxNumberOfFiles,
    { browse: '%{browse}' },
  )
  locale.strings.dropPasteFiles = i18n.tc(
    'upload.drop-paste-both',
    defaultOptions.maxNumberOfFiles,
    { browse: '%{browse}' },
  )
  let uppy = new Uppy({
    id: defaultOptions.id,
    allowMultipleUploadBatches: defaultOptions.allowMultipleUploads,
    restrictions: {
      maxFileSize: defaultOptions.maxFileSize,
      minFileSize: defaultOptions.minFileSize,
      maxTotalFileSize: null,
      maxNumberOfFiles: defaultOptions.maxNumberOfFiles,
      minNumberOfFiles: 1,
      allowedFileTypes: defaultOptions.allowedFileTypes,
    },
    locale: locale,
    autoProceed: defaultOptions.autoProceed,
    onBeforeFileAdded: defaultOptions.onBeforeFileAdded,
  })
  if (defaultOptions.useDashboard) {
    uppy = uppy.use(Dashboard, {
      theme: 'dark',
      note: defaultOptions.note,
      autoOpenFileEditor: defaultOptions.autoOpenFileEditor,
      hideCancelButton: true,
      thumbnailWidth: defaultOptions.thumbnailWidth,
      thumbnailHeight: defaultOptions.thumbnailHeight,
      proudlyDisplayPoweredByUppy: false,
    })
  } else {
    uppy.use(ThumbnailGenerator, {
      thumbnailWidth: 200,
      // thumbnailHeight: 200 // optional, use either width or height,
      waitForThumbnailsBeforeUpload: true,
    })
  }

  if (defaultOptions.useDragDrop) {
    uppy = uppy.use(DragDrop, {
      target: defaultOptions.target,
      width: defaultOptions.width,
      height: defaultOptions.height,
      note: defaultOptions.note,
      locale: {},
    })
  }

  if (defaultOptions.useImageEditor) {
    uppy = uppy.use(ImageEditor, {
      id: 'ImageEditor',
      target: Dashboard,
      // trigger: '#select-files',
      quality: 0.8,
      cropperOptions: {
        viewMode: 1,
        background: false,
        autoCrop: true,
        autoCropArea: 1,
        responsive: true,
        aspectRatio: defaultOptions.aspectRatio,
        minCanvasWidth: defaultOptions.minCanvasWidth,
        minCropBoxWidth: defaultOptions.minCanvasWidth,
      },
      actions: {
        revert: false,
        rotate: true,
        flip: true,
        zoomIn: true,
        zoomOut: true,
        cropSquare: false,
        cropWidescreen: false,
        cropWidescreenVertical: false,
      },
    })
  }
  if (defaultOptions.maxFileSize < 20 * 1024 * 1024) {
    uppy = uppy.use(AwsS3, {
      timeout: 300 * 1000,
      getUploadParameters(file) {
        return requestService
          .post('/file/presign', {
            name: file.name,
            contentType: file.type,
            size: file.size,
          })
          .then(response => {
            this.storageBaseUrl = response.url
            file.meta.key = response.key
            return {
              method: response.method,
              url: response.url,
              fields: response.fields,
              headers: {},
            }
          })
      },
    })
  } else {
    uppy = uppy.use(AwsS3Multipart, {
      limit: 5,
      createMultipartUpload(file) {
        return requestService
          .post('/file/create-multipart-upload', {
            fileName: file.name,
            contentType: file.type,
          })
          .then(response => {
            return {
              uploadId: response.uploadId,
              key: response.key,
            }
          })
      },
      listParts(file, { uploadId, key }) {
        return requestService
          .post('/file/list-parts', {
            uploadId: uploadId,
            key: key,
          })
          .then(response => {
            return response
          })
      },
      signPart(file, partData) {
        return requestService
          .post('/file/prepare-upload-part', {
            uploadId: partData.uploadId,
            key: partData.key,
            number: partData.partNumber,
          })
          .then(response => {
            return {
              url: response.url,
            }
          })
      },
      abortMultipartUpload(file, { uploadId, key }) {
        return requestService.post('/file/abort-multipart-upload', {
          uploadId: uploadId,
          key: key,
        })
      },
      completeMultipartUpload(file, { uploadId, key, parts }) {
        return requestService
          .post('/file/complete-multipart-upload', {
            uploadId: uploadId,
            key: key,
            parts: parts,
          })
          .then(response => {
            return {
              location: response.url,
            }
          })
      },
      getChunkSize(file) {
        if (file.size > 50 * 1024 * 1024) {
          return 50 * 1024 * 1024
        }
        return 5 * 1024 * 1024
      },
    })
  }
  return uppy
}

export const uppyService = { instantiate }
