import Vue from 'vue'
import VueI18n, { LocaleMessage } from 'vue-i18n'
import { createI18n } from 'vue-i18n-composable'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context(
    '../locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i,
  )
  const messages: { [key: string]: Record<string, LocaleMessage> } = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default createI18n({
  locale:
    localStorage.getItem('locale') || navigator.language.split('-')[0] || 'fr',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'fr',
  messages: loadLocaleMessages(),
})
