var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-bottom-navigation',{staticClass:"elevation-0 d-flex d-md-none",staticStyle:{"z-index":"10"},attrs:{"fixed":"","background-color":"secondary","color":"primary","grow":"","input-value":!['PostView', 'VideoPost'].includes(_vm.$route.name)}},[_c('v-btn',{attrs:{"to":{ name: 'Home' },"exact":""},on:{"click":_vm.maybeRefresh}},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('label.home'))))]),_vm._v(" "),_c('v-icon',{domProps:{"textContent":_vm._s(_vm.$route.name === 'Home' ? 'mdi-home' : 'mdi-home-outline')}})],1),_vm._v(" "),_c('v-btn',{attrs:{"to":{ name: 'Subscribed' }}},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('subscribed.subscriptions'))))]),_vm._v(" "),_c('v-icon',{domProps:{"textContent":_vm._s(
        _vm.$route.name === 'Subscribed'
          ? 'mdi-heart-multiple'
          : 'mdi-heart-multiple-outline'
      )}})],1),_vm._v(" "),_c('v-btn',{staticClass:"add-post-button align-self-center",attrs:{"color":"primary","fab":"","small":"","depressed":""},on:{"click":_vm.addPost}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_vm._v(" "),_c('v-btn',{attrs:{"to":{ name: 'UserLists' }}},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('list.list', 2))))]),_vm._v(" "),_c('v-icon',{domProps:{"textContent":_vm._s(
        _vm.$route.name === 'UserLists'
          ? 'mdi-play-box-multiple'
          : 'mdi-play-box-multiple-outline'
      )}})],1),_vm._v(" "),_c('v-btn',{attrs:{"to":{ name: 'Donation' }}},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('donation.donate'))))]),_vm._v(" "),_c('v-icon',{domProps:{"textContent":_vm._s(
        _vm.$route.name === 'Donation' ? 'mdi-hand-coin' : 'mdi-hand-coin-outline'
      )}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }