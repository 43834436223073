<template>
  <div class="mx-n3">
    <div class="primary pa-md-4 pa-3 overflow-hidden">
      <v-row class="align-center">
        <v-col cols="12" md="6">
          <div class="text-center text-h3 text-sm-h2 app-title">
            Et si on reprenait le contrôle ?
          </div>
          <div class="text-center text-h5">
            Finissons-en définitivement avec la censure !
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="justify-center d-flex align-center flex-column my-md-5"
          style="position: relative"
        >
          <v-img
            lazy-src="/images/fund/liquid-header-light.png"
            src="/images/fund/liquid-header-light-rocket.png"
            style="position:absolute;right: 0;opacity: 0.1"
            max-height="450px"
            height="450px"
            eager
          ></v-img>
          <div class="font-weight-bold mb-2">Lancement dans :</div>
          <vue-countdown-timer
            @start_callback="startCallBack('event started')"
            @end_callback="endCallBack('event ended')"
            :start-time="$store.state.realtime.campaignStartDate"
            :end-time="$store.state.realtime.campaignStartDate"
            :interval="1000"
            :start-label="''"
            :end-label="'Lancement imminent !'"
            label-position="begin"
            :end-text="'Lancement imminent !'"
            :day-txt="'jours'"
            :hour-txt="'heures'"
            :minutes-txt="'min.'"
            :seconds-txt="'sec.'"
          >
            <template slot="countdown" slot-scope="scope">
              <div class="text-center d-flex">
                <div>
                  <div class="countdown-number">
                    {{ scope.props.days }}
                  </div>
                  <div class="text-overline">
                    {{ scope.props.dayTxt }}
                  </div>
                </div>
                <div class="countdown-number countdown-separator">:</div>
                <div>
                  <div class="countdown-number">
                    {{ scope.props.hours }}
                  </div>
                  <div class="text-overline">
                    {{ scope.props.hourTxt }}
                  </div>
                </div>
                <div class="countdown-number countdown-separator">:</div>
                <div>
                  <div class="countdown-number">
                    {{ scope.props.minutes }}
                  </div>
                  <div class="text-overline">
                    {{ scope.props.minutesTxt }}
                  </div>
                </div>
                <div class="countdown-number countdown-separator">:</div>
                <div>
                  <div class="countdown-number">
                    {{ scope.props.seconds }}
                  </div>
                  <div class="text-overline">
                    {{ scope.props.secondsTxt }}
                  </div>
                </div>
              </div>
            </template>

            <template slot="end-text" slot-scope="scope">
              <span>{{ scope.props.endText }}</span>
            </template>
          </vue-countdown-timer>
        </v-col>
      </v-row>
    </div>
    <div class="pt-8 px-3 d-flex justify-center main-body">
      <div style="max-width: 800px;width: 100%;overflow-x: hidden;">
        <div>
          <p>
            Le <strong>24 octobre 2022 à 21h</strong> (heure de Paris), nous
            vous dévoilerons quelles seront les prochaines évolutions MAJEURES
            de CrowdBunker !
          </p>
          <p>
            Après de longs mois de préparation,
            <strong>nous sommes enfin prêts</strong> à lancer la campagne de
            financement qui va nous permettre de
            <strong>nous libérer définitivement de la censure.</strong>
          </p>
          <p>
            En mettant à la première place les utilisateurs et en faisant
            confiance à l'intelligence collective, la prochaine version de la
            plateforme libèrera à tout jamais votre façon de vous exprimer ou de
            vous informer sur internet.
          </p>
          <v-card>
            <v-card-text>
              <p class="text-h6">
                Souhaitez-vous aider CrowdBunker à devenir
                <strong>
                  la véritable plateforme de libre-expression dont nous avons
                  tous besoin
                </strong>
                en diffusant cette campagne ?
              </p>
              <p>
                Recevez toutes les informations nécessaires avant tout le monde,
                juste avant le lancement !
              </p>
              <v-row>
                <v-col
                  cols="12"
                  sm="8"
                  class="align-center justify-center d-flex flex-column"
                >
                  <div style="width: 100%">
                    <v-text-field
                      v-model="email"
                      label="Votre adresse email"
                      type="email"
                      :error-messages="formError"
                      outlined
                    ></v-text-field>
                  </div>
                  <div>
                    <v-btn @click="registerParticipent" :loading="formLoading" color="success" large
                      >Je veux participer</v-btn
                    >
                  </div>
                  <div v-if="formSuccess" class="success--text mt-2">Votre participation a été enregistrée.</div>
                </v-col>
                <v-col cols="12" sm="4" class="d-flex justify-center">
                  <v-img
                    src="/images/fund/stop-censorship.png"
                    contain
                    width="90%"
                    max-width="90%"
                  ></v-img>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <p></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import router from "@/router";
import Vue from "vue";

export default Vue.extend({
  name: 'FundCountdown',
  data() {
    return {
      email: '',
      formError: '',
      formLoading: false,
      formSuccess: false,
    }
  },
  metaInfo() {
    return {
      title: 'LIBÉRONS-NOUS - CrowdBunker',
      meta: [
        {
          name: 'description',
          vmid: 'description',
          content:
            'Finançons la plateforme indépendante de libre-expression dont nous avons tous besoin.',
        },
        {
          property: 'og:description',
          vmid: 'og:description',
          content:
            'Finançons la plateforme indépendante de libre-expression dont nous avons tous besoin.',
        },
        {
          property: 'og:title',
          vmid: 'og:title',
          content: 'LIBÉRONS-NOUS - CrowdBunker',
        },
        {
          property: 'twitter:title',
          vmid: 'twitter:title',
          content: 'LIBÉRONS-NOUS - CrowdBunker',
        },
        {
          property: 'og:image',
          vmid: 'og:image',
          content: 'https://crowdbunker.com/images/fund/liberons-nous.png',
        },
        {
          property: 'twitter:image',
          vmid: 'twitter:image',
          content: 'https://crowdbunker.com/images/fund/liberons-nous.png',
        },
        {
          property: 'og:site_name',
          vmid: 'og:site_name',
          content: 'CrowdBunker',
        },
        { property: 'og:type', vmid: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    }
  },
  methods: {
    endCallBack() {
      setTimeout(() => window.location.reload(), 1000)
    },
    startCallBack() {
      setTimeout(() => window.location.reload(), 1000)
    },
    registerParticipent() {
      this.formError = ''
      const email = this.email.trim()
      if (email === '') {
        this.formError = 'Veuillez saisir votre adresse email'
        return
      }
      this.formLoading = true
      axios
        .post(
          'https://auto.cbkr.cloud/webhook/9d477d2f-3bed-4480-8319-0e2cd97b70f1',
          {
            email: this.email,
          },
        )
        .then(() => {
          this.formSuccess = true
          this.email = ''
          this.formError = ''
        })
        .catch(() => {
          this.formError = 'Une erreur est survenue.'
        })
        .finally(() => (this.formLoading = false))
    },
  },
})
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

.app-title {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 900;
}

.countdown-number {
  font-size: 52px;
}

.countdown-separator {
  width: 20px;
}

.text-h4,
.text-h5,
.text-h6,
.caption {
  font-family: 'Montserrat', sans-serif !important;
}

.main-body {
  //font-weight: 300;
  font-family: 'Montserrat', sans-serif;

  b {
    font-weight: 500;
  }

  strong {
    color: var(--v-primary-base);
  }

  .text-h5 {
    margin-top: 3rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
  }

  .text-h4 {
    margin-top: 3.5rem;
    font-weight: 700;
  }

  blockquote {
    border-left: 2px white solid;
    padding-left: 15px;
  }
}
</style>
