
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BottomBar',
  data() {
    return {
      lastRefresh: Date.now(),
      lastPageChange: Date.now(),
    }
  },
  methods: {
    addPost() {
      if (this.$store.state.account.status.loggedIn) {
        this.$root.$emit('createPost')
      } else {
        this.$router.push({ name: 'Login' })
      }
    },
    maybeRefresh() {
      if (Date.now() - this.lastRefresh > 5000 && window.scrollY < 300) {
        this.$root.$emit('reload')
        this.lastRefresh = Date.now()
      } else if (Date.now() - this.lastPageChange > 500) {
        this.$vuetify.goTo(0)
      }
    },
  },
  mounted() {
    this.$router.beforeEach((to, from, next) => {
      next()
      this.lastPageChange = Date.now()
    })
  },
})
