var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"min-width":"0","position":"relative"}},[(_vm.commentsLoading)?_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"position":"absolute","width":"100%"}},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_vm._e(),_vm._v(" "),_vm._l((_vm.commentsFormatted),function(comment,index){return _c('div',{key:comment.uid,staticClass:"d-flex my-4 comment-line",class:{ 'is-pinned': comment.pinnedAt !== null }},[_c('div',[_c('channel-tooltip',{attrs:{"uid":comment.sender.uid || comment.sender.username,"name":comment.sender.name,"thumbnails":comment.sender.thumbnails,"is-user":!comment.sender.uid},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(comment.link ? 'router-link' : 'span',_vm._g({tag:"component",staticClass:"text-decoration-none grey--text text--lighten-1",attrs:{"to":comment.link || undefined},nativeOn:{"mouseenter":function($event){return on.mouseenter.apply(null, arguments)},"mouseleave":function($event){return on.mouseleave.apply(null, arguments)}}},on),[_c('channel-avatar',{staticClass:"mr-3",attrs:{"thumbnails":comment.sender.thumbnails ? comment.sender.thumbnails : [],"size":_vm.sub ? 30 : 45,"channel-name":comment.sender.name,"bg-color":comment.sender.avatarColor
                  ? comment.sender.avatarColor
                  : 'primary'}})],1)]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"flex-grow-1",staticStyle:{"min-width":"0"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-grow-1",staticStyle:{"min-width":"0"}},[_c('div',{staticClass:"comment-headline d-flex"},[_c('div',{staticClass:"flex-grow-1",staticStyle:{"min-width":"0"}},[(comment.pinnedAt !== null)?_c('div',{staticStyle:{"cursor":"text"}},[_c('v-icon',{attrs:{"size":"12"}},[_vm._v("\n                  mdi-pin\n                ")]),_vm._v("\n                "+_vm._s(_vm.$t('label.pinned-by') + ' ' + comment.originalSender.name)+"\n              ")],1):_vm._e(),_vm._v(" "),_c('channel-tooltip',{attrs:{"uid":comment.sender.uid || comment.sender.username,"name":comment.sender.name,"thumbnails":comment.sender.thumbnails,"is-user":!comment.sender.uid},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(comment.link ? 'router-link' : 'span',_vm._g({tag:"component",staticClass:"text-decoration-none grey--text text--lighten-1 d-inline-flex align-center",attrs:{"to":comment.link || undefined},nativeOn:{"mouseenter":function($event){return on.mouseenter.apply(null, arguments)},"mouseleave":function($event){return on.mouseleave.apply(null, arguments)}}},on),[_c('span',{staticClass:"font-weight-bold",class:{
                        'original-sender': comment.isOriginalSender,
                        author: comment.isAuthor,
                      }},[_vm._v(_vm._s(comment.sender.name))]),_vm._v(" "),(comment.sender.uid)?_c('span',{staticClass:"ml-1"},[_vm._v("@"+_vm._s(comment.sender.uid))]):_vm._e(),_vm._v("\n                     \n                    "),(!comment.sender.uid)?[(comment.platformSubscription)?_c('v-icon',{attrs:{"color":_vm.getColor(comment.platformSubscription.planLevel),"small":""}},[_vm._v("mdi-seal")]):_vm._e(),_vm._v(" "),(
                          comment.memberships.find(
                            e =>
                              e.organizationUid ===
                              comment.originalSender.uid,
                          )
                        )?_c('v-icon',{attrs:{"color":"grey","small":""}},[_vm._v("mdi-account-group-outline")]):_vm._e()]:[(!!comment.ownerMembershipLevel)?_c('v-icon',{attrs:{"color":_vm.getColor(comment.ownerMembershipLevel),"small":""}},[_vm._v("mdi-seal")]):_vm._e()]],2)]}}],null,true)}),_vm._v(" "),_c('span',{staticClass:"ml-1"},[_vm._v("•\n                "),_c('router-link',{staticClass:"grey--text",attrs:{"to":{
                    name: 'Post',
                    params: { slug: comment.uid, post: comment.original },
                  }}},[_vm._v(_vm._s(_vm._f("capitalize")(comment.createdAt)))])],1),_vm._v(" "),(comment.isArchived)?_c('v-chip',{staticClass:"transparent-white-background ml-2",attrs:{"small":"","color":"white","text-color":"#e64a19"}},[_vm._v("\n                "+_vm._s(_vm.$tc('label.comment-archived'))+"\n              ")]):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"comment-content text-pre-wrap flex-grow-1 overflow-hidden text-break"},[_c('div',{staticClass:"d-flex align-center mb-1"},[(comment.isDonation)?_c('v-chip',{staticClass:"mr-1",attrs:{"color":"success","small":""}},[_c('v-icon',{staticClass:"text-overline",attrs:{"left":""}},[_vm._v("\n                  mdi-hand-coin\n                ")]),_vm._v(" "),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(comment.donation)+"€")])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"my-auto",domProps:{"innerHTML":_vm._s(comment.textContent)}})],1),_vm._v(" "),(comment.tooLong)?_c('div',[_c('v-btn',{attrs:{"text":"","small":"","color":"grey lighten-1"},on:{"click":function($event){_vm.commentExpended =
                    _vm.commentExpended !== comment.uid ? comment.uid : null}}},[_vm._v(_vm._s(_vm.commentExpended !== comment.uid
                    ? _vm.$tc('label.read-more')
                    : _vm.$tc('label.show-less'))+"\n              ")])],1):_vm._e()])]),_vm._v(" "),_c('post-menu',{attrs:{"post":comment.original,"isComment":true},on:{"deleted":function($event){return _vm.deleteComment(index)}}})],1),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('post-voter',{attrs:{"post":comment,"comment":""}}),_vm._v(" "),(comment.isFirstChildrenLevel && _vm.post.isOwner)?_c('pin-button',{attrs:{"post":comment,"text-right":"","small":""},on:{"pinned":function($event){return _vm.$set(comment, 'pinnedAt', $event)}}}):_vm._e(),_vm._v(" "),_c('love-button',{attrs:{"post":comment,"parent":_vm.post,"text-right":"","small":""}}),_vm._v(" "),_c('v-btn',{staticClass:"ml-1",attrs:{"color":"grey","text":"","small":"","to":_vm.depth >= _vm.depthLimit
              ? {
                  name: 'Post',
                  params: { slug: comment.uid, post: comment.original },
                }
              : undefined,"target":"_blank"},on:{"click":function($event){_vm.depth >= _vm.depthLimit ? undefined : _vm.addResponseForm(comment.uid)}}},[_vm._v("\n          "+_vm._s(_vm.$tc('chat.answer'))+"\n          "),(_vm.depth >= _vm.depthLimit)?_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("mdi-open-in-new\n          ")]):_vm._e()],1)],1),_vm._v(" "),(_vm.showResponseForm.includes(comment.uid))?_c('div',{staticClass:"ml-2"},[_c('comment-form',{attrs:{"parent-post":comment,"sub":""},on:{"created":function($event){return _vm.commentCreated(comment, $event)}}}),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"ml-auto mt-1",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.hideResponseForm(comment.uid)}}},[_vm._v(_vm._s(_vm.$tc('button.cancel'))+"\n          ")])],1)],1):_vm._e(),_vm._v(" "),(_vm.shownChildren.includes(comment.uid))?_c('div',{staticClass:"children-wrapper"},[_c('comment-list',{attrs:{"parent-post":comment,"comments":comment.original.children,"sub":""},on:{"update:comments":function($event){return _vm.$set(comment.original, "children", $event)}}})],1):_vm._e(),_vm._v(" "),(comment.original.childrenCount > 0)?_c('div',[(!_vm.shownChildren.includes(comment.uid))?_c('v-btn',{attrs:{"text":"","small":"","color":"primary","to":_vm.depth >= _vm.depthLimit
              ? {
                  name: 'Post',
                  params: { slug: comment.uid, post: comment.original },
                }
              : undefined,"target":"_blank"},on:{"click":function($event){_vm.depth >= _vm.depthLimit ? undefined : _vm.showChildren(comment.uid)}}},[_vm._v("\n          "+_vm._s(_vm.$tc('label.show-responses', comment.original.childrenCount, {
              count: comment.original.childrenCount,
            }))+"\n          "),(_vm.depth >= _vm.depthLimit)?_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("\n            mdi-open-in-new\n          ")]):_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1):_c('v-btn',{attrs:{"text":"","small":"","color":"primary"},on:{"click":function($event){return _vm.hideChildren(comment.uid)}}},[_vm._v("\n          "+_vm._s(_vm.$tc('label.hide-responses', comment.original.childrenCount))+"\n          "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-up")])],1)],1):_vm._e()])])}),_vm._v(" "),(!_vm.endReached && !_vm.hasError && _vm.children.length)?_c('div',{staticClass:"d-flex mb-2",class:{ 'justify-center': !_vm.sub }},[_c('v-btn',{attrs:{"x-small":"","depressed":"","color":"primary","loading":_vm.commentsLoading},on:{"click":_vm.fetchComments}},[_c('v-icon',{attrs:{"left":"","x-small":""}},[_vm._v("mdi-message-plus")]),_vm._v("\n      "+_vm._s(_vm.$tc('label.load-more-of'))+"\n      "+_vm._s(_vm.sub ? _vm.$tc('label.response', 2) : _vm.$tc('chat.commentary', 2))+"\n    ")],1)],1):_vm._e(),_vm._v(" "),(!_vm.sub && _vm.children.length)?_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
      handler: _vm.onIntersectEnd,
      options: {
        rootMargin: '-50% 0px 500px 0px',
        threshold: [0, 1],
      },
    }),expression:"{\n      handler: onIntersectEnd,\n      options: {\n        rootMargin: '-50% 0px 500px 0px',\n        threshold: [0, 1],\n      },\n    }"}],staticStyle:{"min-height":"10px"}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }