<template>
  <div class="d-flex align-center" :class="{ 'mx-2': !small }" v-bind="$attrs">
    <v-btn
      class="d-inline-flex"
      :class="{ 'btn-vertical': text }"
      icon
      :small="small"
      :color="pinnedAt ? 'primary' : 'grey'"
      :loading="loading"
      @click.prevent.stop="pinnedAt ? unpin() : pin()"
    >
      <v-tooltip bottom color="grey">
        <template v-slot:activator="{ on }">
          <v-icon :small="small" v-on="on">
            mdi-pin
          </v-icon>
        </template>
        <span>{{ pinnedAt ? unpinText : pinText }}</span>
      </v-tooltip>
    </v-btn>
  </div>
</template>

<script>
import { requestService } from '@/services/request.service'

export default {
  name: 'PinButton',
  props: {
    post: { type: Object },
    small: { type: Boolean },
    textRight: { type: Boolean },
    text: { type: Boolean },
  },
  data() {
    return {
      loading: false,
      pinnedAt: null,
    }
  },
  computed: {
    pinText() {
      return this.$t('label.pin')
    },
    unpinText() {
      return this.$t('label.unpin')
    },
  },
  methods: {
    pin() {
      if (this.loading) {
        return
      }
      this.loading = true
      requestService
        .post(`/post/${this.post.uid}/pin`)
        .then(response => {
          if (response.success) {
            this.$set(this.post, 'pinnedAt', true)
            this.$emit('pinned', true)
            this.pinnedAt = true
          } else {
            this.$store.dispatch('alert/error', response.message)
          }
        })
        .catch(() => {
          this.loading = false
          this.$store.dispatch(
            'alert/error',
            this.$tc('error.an-error-has-occurred'),
          )
        })
        .finally(() => (this.loading = false))
    },
    unpin() {
      if (this.loading) {
        return
      }
      this.loading = true
      requestService
        .post(`/post/${this.post.uid}/unpin`)
        .then(response => {
          if (response.success) {
            this.$set(this.post, 'pinnedAt', null)
            this.pinnedAt = null
            this.$emit('pinned', null)
          } else {
            this.$store.dispatch('alert/error', response.message)
          }
        })
        .catch(() => {
          this.loading = false
          this.$store.dispatch(
            'alert/error',
            this.$tc('error.an-error-has-occurred'),
          )
        })
        .finally(() => (this.loading = false))
    },
  },
  mounted() {
    this.pinnedAt = this.post.pinnedAt
  },
}
</script>

<style scoped lang="scss">
.likes-count {
  padding-top: 3px;
}
</style>
