<template>
  <div class="d-flex align-center" :class="{ 'mx-2': !small }" v-bind="$attrs">
    <v-btn
      v-if="post.isFirstChildrenLevel && parent.isOwner"
      :class="{ 'btn-vertical': text }"
      :color="lovedAt ? 'primary' : 'grey'"
      :loading="loading"
      :small="small"
      class="d-inline-flex"
      icon
      @click.prevent.stop="lovedAt ? unlove() : love()"
    >
      <v-tooltip bottom color="grey">
        <template v-slot:activator="{ on }">
          <div v-on="on" class="loved-icon-wrapper">
            <channel-avatar
              :thumbnails="post.originalSender.thumbnails"
              :size="16"
              :channel-name="post.originalSender.name"
              v-show="lovedAt"
            ></channel-avatar>
            <v-icon :small="small" v-on="on" :class="{ 'loved-icon': lovedAt }">
              mdi-heart
            </v-icon>
          </div>
        </template>
        <span>{{ lovedAt ? idontlove : ilove }}</span>
      </v-tooltip>
    </v-btn>

    <v-tooltip bottom v-else-if="lovedAt" color="grey">
      <template v-slot:activator="{ on }">
        <div v-on="on" class="loved-icon-wrapper mx-2">
          <channel-avatar
            :thumbnails="post.originalSender.thumbnails"
            :size="16"
            :channel-name="post.originalSender.name"
          ></channel-avatar>
          <v-icon color="primary" class="loved-icon" :small="small" v-on="on">
            mdi-heart
          </v-icon>
        </div>
      </template>
      <span>{{ lovedBy + ' ' + post.originalSender.name }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { requestService } from '@/services/request.service'
import ChannelAvatar from '@/components/ChannelAvatar.vue'

export default {
  name: 'LoveButton',
  components: { ChannelAvatar },
  props: {
    post: { type: Object },
    parent: { type: Object },
    small: { type: Boolean },
    textRight: { type: Boolean },
    text: { type: Boolean },
  },
  data() {
    return {
      loading: false,
      lovedAt: null,
    }
  },
  computed: {
    ilove() {
      return this.$t('label.i-love')
    },
    idontlove() {
      return this.$t('label.i-dont-love')
    },
    lovedBy() {
      return this.$t('label.loved-by')
    },
  },
  methods: {
    love() {
      if (this.loading) {
        return
      }
      this.loading = true
      requestService
        .post(`/post/${this.post.uid}/love`)
        .then(response => {
          if (response.success) {
            this.$set(this.post, 'lovedAt', true)
            this.lovedAt = true
          } else {
            this.$store.dispatch('alert/error', response.message)
          }
        })
        .catch(() => {
          this.loading = false
          this.$store.dispatch(
            'alert/error',
            this.$tc('error.an-error-has-occurred'),
          )
        })
        .finally(() => (this.loading = false))
    },
    unlove() {
      if (this.loading) {
        return
      }
      this.loading = true
      requestService
        .post(`/post/${this.post.uid}/unlove`)
        .then(response => {
          if (response.success) {
            this.$set(this.post, 'lovedAt', null)
            this.lovedAt = null
          } else {
            this.$store.dispatch('alert/error', response.message)
          }
        })
        .catch(() => {
          this.loading = false
          this.$store.dispatch(
            'alert/error',
            this.$tc('error.an-error-has-occurred'),
          )
        })
        .finally(() => (this.loading = false))
    },
  },
  mounted() {
    this.lovedAt = this.post.lovedAt
  },
}
</script>

<style scoped lang="scss">
.loved-icon-wrapper {
  position: relative;
  line-height: 16px;
  .loved-icon {
    position: absolute;
    top: 7px;
    left: 9px;
    font-size: 16px;
    height: 16px;
    width: 16px;
  }
}
</style>
