import { render, staticRenderFns } from "./RelatedCard.vue?vue&type=template&id=387751b1&scoped=true&"
import script from "./RelatedCard.vue?vue&type=script&lang=ts&"
export * from "./RelatedCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "387751b1",
  null
  
)

export default component.exports