import store from '@/store/index'

function subscriptionLevelColor(level) {
  switch (level) {
    case 1:
      return 'yellow darken-3'
    case 2:
      return 'primary'
    case 3:
      return 'purple darken-1'
    default:
      return 'secondary'
  }
}

function canModifyPost(plateFormSubscription, post) {
  if (plateFormSubscription && post && post.isOwner) {
    let min = new Date(post.createdAt).getMinutes()
    switch (plateFormSubscription.planLevel) {
      case 1:
        if (new Date(post.createdAt).setMinutes(min + 30) > Date.now()) {
          return true
        }
        return false
      case 2:
        if (new Date(post.createdAt).setMinutes(min + 240) > Date.now()) {
          return true
        }
        return false
      case 3:
        return true
      default:
        return false
    }
  }
  return false
}

function getUserLevel() {
  return store.state.account?.plateFormSubscription?.planLevel ?? 0
}

export const subscriptionService = {
  subscriptionLevelColor,
  canModifyPost,
  getUserLevel,
}

export { subscriptionLevelColor, canModifyPost, getUserLevel }
