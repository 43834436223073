
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppTooltip',
  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.9)',
    },
  },
})
