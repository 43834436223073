<template>
  <div class="align-self-center" v-bind="$attrs">
    <video-button
      :text="$tc('label.support') | capitalize"
      icon="hand-coin"
      color="primary"
      :tooltip="$tc('label.show-solidarity-with-super-donation') | capitalize"
      @click="dialog = true"
    ></video-button>
    <v-dialog max-width="500" v-model="dialog" persistent>
      <v-card>
        <!-- <v-img
            v-if="post.channel.organization.banner.url"
            class="white--text align-end mb-2"
            height="100px"
            :src="post.channel.organization.banner.url"
          >
          </v-img> -->
        <v-toolbar dark color="success" flat dense>
          <v-toolbar-title>
            {{ $tc('label.support') | capitalize }}
            <span class="font-weight-medium">
              {{ post.channel.organization.name }}
            </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false" :disabled="loading">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- <v-card-subtitle class="pb-0 text-h5">
          Soutenir
          <span>
            {{ post.channel.organization.name }}
          </span>
        </v-card-subtitle> -->
        <v-card-title>
          <!-- <span class="headline">Rejoindre cette organisation</span> -->
        </v-card-title>
        <v-card-text>
          <div v-if="!donationFinished">
            {{
              $tc('label.buy-super-donation-to-support-directly') | capitalize
            }}
            {{ post.channel.organization.name }}.

            <div class="mt-2">
              <v-slider
                class="text-body-2"
                v-model="value"
                :tick-labels="ticksLabels"
                :max="ticksLabels.length - 1"
                step="1"
                :ticks="true"
                :disabled="loading || showCardElement"
              ></v-slider>
            </div>
            <!-- textfield form that contain donation msg and run validation after each keypress -->
            <div>
              {{ $store.state.account.user.user.username }}
              <v-icon
                v-if="!!plateFormSubscription"
                :color="getColor(plateFormSubscription.planLevel)"
                >mdi-seal</v-icon
              >
              <v-icon
                v-if="
                  memberOrganizations.find(
                    e => e.organizationUid === post?.channel?.organization?.uid,
                  )
                "
                color="grey"
                small
                >mdi-account-group-outline</v-icon
              >
            </div>
            <v-row justify="end">
              <v-col cols="12">
                <textarea-field
                  ref="donationMsgref"
                  class="text-subtitle-2 text--white comment-textarea"
                  :field="form.textContent"
                  :disabled="loading || showCardElement"
                  :placeholder="$tc('chat.comment-freely')"
                  hint="Votre commentaire sera visible publiquement avec le montant de votre don."
                  persistent-hint
                  dark
                  :counter="form.textContent.maxLength"
                  :rules="[
                    v => !!v || $tc('login.required'),
                    v =>
                      v.length <= form.textContent.maxLength ||
                      $tc('login.maximum-50-characters'),
                  ]"
                  rows="1"
                  auto-grow
                  solo-inverted
                  dense
                  background-color="secondary lighten-2"
                  :validate-on-blur="false"
                  append-icon="mdi-pencil-outline"
                  @change="donationMsgError = !$refs.donationMsgref.validate()"
                  flat
                >
                  <template v-slot:prepend-inner>
                    <div class="my-auto">
                      <v-chip small color="success">{{
                        ticksLabels[value]
                      }}</v-chip>
                    </div>
                  </template>
                </textarea-field>
              </v-col>
            </v-row>
          </div>
          <div v-if="!donationFinished && showCardElement">
            <p class="mt-2 mb-0">
              <v-alert
                text
                dense
                color="teal"
                icon="mdi-checkbox-marked-circle-plus-outline"
                class="text-caption"
                border="left"
              >
                <i18n path="label.you-will-buy-super-donation-for" tag="span">
                  <span>{{ ticksLabels[value] }}</span>
                </i18n>

                {{ post.channel.organization.name }}.
              </v-alert>
            </p>
            <!-- show paymentMethod ui with for 4 last digits, expMonth, expYear -->
            <div>
              <!-- Credit card element ui (v-card) -->
              <credit-card-details
                :isConnect="true"
                :confirmCard="confirmCard"
              ></credit-card-details>
            </div>
            <div class="error--text mt-1">{{ paymentError }}</div>
          </div>
          <div v-if="donationFinished">
            <v-alert color="success">
              Votre BonCoeur bien été envoyé à
              {{ post.channel.organization.name }} !
            </v-alert>
            <h3 class="primary--text text-center">
              {{ $tc('donation.thank-you') }} !
            </h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="donationFinished" text @click="cancel()">
            {{ $tc('button.close') | capitalize }}
          </v-btn>
          <v-btn v-else text @click="cancel()">
            {{ $tc('button.cancel') | capitalize }}
          </v-btn>
          <v-btn
            v-if="!showCardElement"
            color="success"
            @click="showPaymentForm()"
            :disabled="loading || donationMsgError"
            depressed
            >{{ $tc('button.continue') | capitalize }}</v-btn
          >
          <v-btn
            v-if="showCardElement && !donationFinished"
            :loading="loading"
            color="success"
            @click="submitPayment()"
            depressed
            >{{ $tc('button.buy') | capitalize }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CreditCardDetails from '@/components/CreditCardDetails'
import { requestService } from '@/services/request.service'
import Validation from '@/components/mixins/Validation'
import { subscriptionService } from '@/services/subscription.service'
import VideoButton from '@/components/video/VideoButton.vue'
import TextareaField from '@/components/form/TextareaField.vue'
export default {
  name: 'PostDonation',
  props: {
    post: { type: Object, required: true },
    isLive: { type: Boolean, default: false },
  },
  mixins: [Validation],
  components: { TextareaField, VideoButton, CreditCardDetails },
  data() {
    return {
      loading: false,
      dialog: false,
      showCardElement: false,
      donationFinished: false,
      value: 0,
      initLoading: true,
      ticksLabels: [
        '2 €',
        '5 €',
        '10 €',
        '20 €',
        '50 €',
        '100 €',
        '200 €',
        '500 €',
      ],
      paymentError: '',
      donationMsgError: false,
      form: {
        textContent: {
          value: '',
          maxLength: 50,
        },
        donation: {
          value: null,
        },
      },
      rebuild: 0,
      error: null,
      confirmCard: false,
    }
  },
  computed: {
    paymentMethodInfos() {
      return this.$store.state.account.paymentMethodConnect
    },
    stripe() {
      return this.$store.state.stripe.stripe
    },
    stripeCard() {
      return this.$store.state.stripe.stripeCard
    },
    stripeElementPaymentError() {
      return this.$store.state.stripe.stripeElementPaymentError
    },
    memberOrganizations() {
      return this.$store.state.account.memberOrganizations
    },
    plateFormSubscription() {
      return this.$store.state.account.plateFormSubscription
    },
  },

  methods: {
    getColor(level) {
      return subscriptionService.subscriptionLevelColor(level)
    },
    showPaymentForm() {
      this.showCardElement = true
    },
    cancel() {
      this.dialog = false
      this.showCardElement = false
      this.paymentError = ''
      this.value = 0
      this.donationFinished = false
      this.isLoading = false
      this.donationMsgError = false
      this.form.textContent.value = ''
    },

    submitPayment() {
      if (this.paymentMethodInfos === null && !this.stripeCard._complete) {
        this.$store.dispatch(
          'stripe/setStripeElementPaymentError',
          this.$tc('error.payment-data-incomplete'),
        )
        return
      }
      this.loading = true
      const finalAmount = parseInt(
        this.ticksLabels[this.value].replace(' €', ''),
      )

      this.error = null
      this.form.donation.value = finalAmount
      if (this.isLive) {
        this.form.isMember.value = this.memberOrganizations.find(
          elt => elt.organizationUid === this.post.channel?.organization?.uid,
        )
          ? true
          : false
      }
      this.formValidation(this.form, true)
      if (this.errorsNbr > 0) {
        return
      }
      const data = this.formatDataToSend(this.form)

      if (this.paymentMethodInfos !== null) {
        this.createSinglePayment(
          {
            paymentMethodId: this.paymentMethodInfos.id,
            amount: finalAmount,
            email: this.$store.state.account.user.user.email,
            postUid: this.post.uid,
          },
          data,
        )
        return
      }

      this.$store
        .dispatch('stripe/stripeCreatePaymentMethod', {
          email: this.$store.state.account.user.user.email,
        })
        .then(async result => {
          await this.$store.dispatch('account/savePaymentMethod', {
            paymentMethodId: result.paymentMethod.id,
            last4: result.paymentMethod.card.last4,
            brand: result.paymentMethod.card.brand,
            expMonth: result.paymentMethod.card.exp_month,
            expYear: result.paymentMethod.card.exp_year,
            country: result.paymentMethod.card.country,
            paymentMethodType: result.paymentMethod.type,
            isConnect: true,
          })
          this.createSinglePayment(
            {
              paymentMethodId: result.paymentMethod.id,
              amount: finalAmount,
              email: this.$store.state.account.user.user.email,
              postUid: this.post.uid,
            },
            data,
          )
        })
        .catch(err => {
          this.paymentError =
            typeof err == 'string' ? err : err.message || err.error
          this.loading = false
        })
    },
    createSinglePayment({ paymentMethodId, amount, email, postUid }, data) {
      requestService
        .post(
          process.env.VUE_APP_API_BASE_URL +
            '/payment/start-organization-donation',
          {
            paymentMethodId,
            amount,
            email,
            postUid,
          },
        )
        .then(response => {
          if (!response.success) {
            this.paymentError = response.message
              ? response.message
              : this.$tc('error.server-error-try-again')
            this.loading = false
            return
          }
          if (response.success && response.status === 'succeeded') {
            this.donationFinished = true
            this.sendDonationComment(data)
          } else {
            this.confirmCard = true
            setTimeout(() => {
              console.log(this.stripe.confirmCardPayment)
              this.stripe
                .confirmCardPayment(response.client_secret, {
                  payment_method: paymentMethodId,
                })
                .then(result => {
                  if (result.error) {
                    this.paymentError = result.error.message
                  } else {
                    // The payment has been processed!
                    this.donationFinished = true
                    this.sendDonationComment(data)
                  }
                })
                .catch(err => {
                  this.paymentError = err.message
                })
            }, 1000)
          }
        })
        .catch(() => {
          this.paymentError = this.$tc('error.error-while-creating-payment')
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    sendDonationComment(data) {
      let url = ''
      if (this.isLive) {
        url = '/chat/publish'
        if (this.post.channel.organisation.organizationId.value === null) {
          delete data.organizationId
        }
      } else {
        url = '/post/create'
        if (this.form.organization.value === null) {
          delete data.organization
        }
      }

      requestService
        .post(url, data)
        .then(response => {
          if (response.uid) {
            this.form.textContent.value = ''
            if (!this.isLive) {
              this.$store.commit('account/lastDonationComment', response)
            }
          }
        })
        .catch(response => {
          this.$store.dispatch(
            'alert/error',
            this.$tc('error.an-error-has-occurred'),
          )
          // console.log('error response sendDonationComment', response)
          const { error, validationFailed } = response
          if (validationFailed) {
            this.handleServerErrors(error, this.form)
            this.$store.dispatch(
              'alert/error',
              this.$tc('error.an-error-has-occurred'),
            )
          } else if (error.detail) {
            this.error = error.detail
          }
        })
    },
  },

  created() {
    if (this.isLive) {
      this.form = {
        ...this.form,
        organizationId: {
          value: this.post.channel.organization.id ?? null,
        },
        videoUniqueKey: {
          value: this.post.video.uniqueKey,
          required: true,
        },
        isMember: {
          value: false,
        },
      }
    } else {
      this.form = {
        ...this.form,
        organization: {
          value: this.post.channel.organization.id ?? null,
        },
        parent: {
          value: this.post.uid,
          required: true,
        },
      }
    }
  },
  beforeDestroy() {
    this.$store.commit('account/lastDonationComment', null)
  },
}
</script>
<style lang="scss" scoped>
::v-deep {
  .v-textarea {
    div.v-input__prepend-inner {
      margin: auto !important;
      margin-right: 6px !important;
    }
    textarea {
      color: #fff !important;
    }
  }
}
</style>
