<template>
  <v-col v-bind="$attrs">
    <v-hover v-slot="{ hover }">
      <router-link class="text-decoration-none" :to="getLink()">
        <v-card
          width="100%"
          elevation="0"
          class="list-item overflow-hidden"
          color="transparent"
        >
          <list-preview :list="list" :hover="hover"></list-preview>
          <v-card-title class="pb-0 pt-1">{{ list.name }}</v-card-title>
          <v-card-text class="pb-0">
            <div class="d-flex">
              <div class="overline text-truncate flex-grow-1">
                {{ list.itemsCount }}
                {{ $tc('post.post', list.itemsCount || 1) }}
              </div>
              <div :title="getVisibilityText()">
                <v-icon class="mr-1" size="18px">{{
                  getVisibilityIcon()
                }}</v-icon>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </router-link>
    </v-hover>
  </v-col>
</template>

<script>
import PostPreview from '@/components/post/PostPreview.vue'
import ListPreview from '@/components/list/ListPreview.vue'

export default {
  name: 'ListItem',
  components: { ListPreview },
  props: {
    list: { type: Object, required: true },
    listPage: { type: Boolean, default: false },
  },
  data() {
    return {}
  },
  methods: {
    getLink() {
      let routeName = 'List'
      let slug = this.list.uid
      if (this.listPage) {
        return {
          name: routeName,
          params: { slug, list: this.list },
        }
      }
      if (this.list.featuredPost?.video) {
        routeName = 'VideoPost'
        slug = this.list.featuredPost.uid
      } else if (this.list?.featuredPost) {
        routeName = 'Post'
        slug = this.list.featuredPost.uid
      }
      return {
        name: routeName,
        params: { slug, post: this.list.featuredPost },
        query: { list: this.list.uid },
      }
    },
    getVisibilityIcon() {
      if (this.list.visibility === 'public') {
        return 'mdi-earth'
      } else if (this.list.visibility === 'private') {
        return 'mdi-lock'
      } else if (this.list.visibility === 'unlisted') {
        return 'mdi-eye-off'
      }
    },
    getVisibilityText() {
      // with first letter uppercase
      let text = ''
      if (this.list.visibility === 'public') {
        text = this.$t('visibility.public')
      } else if (this.list.visibility === 'private') {
        text = this.$t('visibility.private')
      } else if (this.list.visibility === 'unlisted') {
        text = this.$t('visibility.unlisted')
      }

      return text.charAt(0).toUpperCase() + text.slice(1)
    },
  },
}
</script>

<style scoped lang="scss"></style>
