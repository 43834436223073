<template>
  <v-card>
    <v-card-title class="headline">
      {{ title | capitalize }}
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('close')" v-if="!embed">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="!paymentFinished && type === TYPE_VOUCHER" class="pb-1">
      <text-field
        :field="voucherForm.code"
        label="Votre code"
        outlined
      ></text-field>
    </v-card-text>
    <v-card-text v-else-if="!paymentFinished" class="pb-1">
      <div>
        <div>
          <span class="font-weight-bold"
            >{{ type === TYPE_PURCHASE ? 'Achat' : 'Location' }} de :</span
          >
          {{ post.video.title }}
        </div>
        <div>
          <span class="font-weight-bold">Tarif :</span>
          {{
            (type === TYPE_PURCHASE
              ? post.video.sellingPriceCents
              : post.video.rentalPriceCents) / 100
          }}€
        </div>
        <div class="success--text">
          <span v-if="type === TYPE_PURCHASE">
            Visionnez cette vidéo en illimité depuis votre compte CrowdBunker.
          </span>
          <span v-else>
            Visionnez cette vidéo en illimité depuis votre compte CrowdBunker
            jusqu'à 48h après avoir commencé la vidéo.
          </span>
        </div>

        <div v-if="presalePeriod">
          <span class="font-weight-bold">Date de disponibilité :</span>
          {{ releaseDate }}
        </div>
      </div>
      <br />
      <div>
        <!-- Credit card element ui (v-card) -->
        <credit-card-details
          v-if="type !== TYPE_VOUCHER"
          :isConnect="true"
          :confirmCard="confirmCard"
        ></credit-card-details>
      </div>
      <div class="error--text mt-1">{{ paymentError }}</div>
    </v-card-text>
    <v-card-text v-else>
      <v-alert color="success">
        {{ $tc('label.successful-payment') }}
      </v-alert>
      <h3 class="primary--text text-center">
        {{ $tc('donation.thank-you') }} !
      </h3>
      <br />
      <div class="success--text">
        Nous avons bien enregistré votre
        {{ type === TYPE_PURCHASE ? 'achat' : 'location' }} sur votre compte
        CrowdBunker.
      </div>
      <div v-if="presalePeriod">
        <div>Vous pourrez commencer à visionner cette vidéo dès sa sortie.</div>
        <div v-if="releaseDate">
          <span class="font-weight-bold">Date de disponibilité :</span>
          {{ releaseDate }}
        </div>
      </div>
      <div v-else>
        <div v-if="type === TYPE_PURCHASE">
          Vous pouvez visionner dès à présent cette vidéo et à tout moment
          depuis cette page.
        </div>
        <div v-else>
          Vous pouvez visionner dès à présent cette vidéo.
          <div class="error--text">
            Dès que vous débuterez le visionnage de la vidéo, vous aurez 48h
            pour terminer la vidéo ou la revoir.
          </div>
        </div>
        <div class="d-flex justify-center mt-2">
          <v-btn color="primary" @click="$emit('close')">Voir la vidéo</v-btn>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="$emit('close')" v-if="!embed">
        <span v-if="!paymentFinished">{{ $tc('button.cancel') }}</span>
        <span v-else>{{ $tc('button.close') }}</span>
      </v-btn>
      <v-btn
        color="success"
        @click="submitPayment"
        v-if="!paymentFinished"
        :loading="loading"
      >
        confirmer
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios'
import { requestService } from '@/services/request.service'
import TextField from '@/components/form/TextField'
import CreditCardDetails from '@/components/CreditCardDetails'
import Validation from '@/components/mixins/Validation'

const TYPE_PURCHASE = 'purchase'
const TYPE_RENTAL = 'rental'
const TYPE_VOUCHER = 'voucher'

export default {
  name: 'Payment',
  components: { TextField, CreditCardDetails },
  mixins: [Validation],
  props: {
    embed: { type: Boolean },
    post: { type: Object, required: true },
    type: { type: String, required: true },
  },
  data() {
    return {
      paymentFinished: false,
      loading: false,
      paymentError: '',
      rebuild: 0,
      recurring: false,
      payment_intent_pending: false,
      voucherForm: {
        code: {
          value: '',
          required: true,
        },
      },
      confirmCard: false,
    }
  },
  computed: {
    releaseDate() {
      if (!this.post.video.presaleMode) {
        return 'Immédiatement'
      }
      if (
        Object.prototype.hasOwnProperty.call(this.post.video, 'releaseDate') &&
        this.post.video.releaseDate !== null
      ) {
        const date = new Date(this.post.video.releaseDate)
        return date.toLocaleString()
      }

      return 'Prochainement'
    },
    presalePeriod() {
      return (
        this.post.video.price === 'paid' &&
        this.post.video.presaleMode &&
        (typeof this.post.video.releaseDate !== 'string' ||
          new Date() < new Date(this.post.video.releaseDate))
      )
    },
    title() {
      switch (this.type) {
        case TYPE_RENTAL:
          return 'location'
        case TYPE_VOUCHER:
          return 'voucher'
        default:
          return 'achat'
      }
    },
    paymentMethodInfos() {
      return this.$store.state.account.paymentMethodConnect
    },
    stripe() {
      return this.$store.state.stripe.stripe
    },
    stripeCard() {
      return this.$store.state.stripe.stripeCard
    },
    stripeElementPaymentError() {
      return this.$store.state.stripe.stripeElementPaymentError
    },
  },
  methods: {
    submitPayment() {
      if (this.type === TYPE_VOUCHER) {
        this.submitVoucher()
        return
      }
      if (this.paymentMethodInfos == null) {
        if (!this.stripeCard._complete) {
          this.paymentError = this.$tc('error.payment-data-incomplete')
          return
        }
      } else {
        this.loading = true
        const finalAmount = this.donationAmount + '.00'
        if (this.recurring) {
          this.createSubscription({
            paymentMethodId: this.paymentMethodInfos.id,
            priceId: this.priceId,
          })
        } else {
          // console.log('testab')
          this.createSinglePayment({
            paymentMethodId: this.paymentMethodInfos.id,
            finalAmount,
          })
        }
        return
      }

      this.loading = true
      const finalAmount = this.donationAmount + '.00'

      this.$store
        .dispatch('stripe/stripeCreatePaymentMethod', {
          email: this.$store.state.account.user.user.email,
        })
        .then(async result => {
          if (this.$store.state.account.status.loggedIn) {
            await this.$store.dispatch('account/savePaymentMethod', {
              paymentMethodId: result.paymentMethod.id,
              last4: result.paymentMethod.card.last4,
              brand: result.paymentMethod.card.brand,
              expMonth: result.paymentMethod.card.exp_month,
              expYear: result.paymentMethod.card.exp_year,
              country: result.paymentMethod.card.country,
              paymentMethodType: result.paymentMethod.type,
              isConnect: true,
            })
          }
          if (this.recurring) {
            this.createSubscription({
              paymentMethodId: result.paymentMethod.id,
              priceId: this.priceId,
            })
          } else {
            this.createSinglePayment({
              paymentMethodId: result.paymentMethod.id,
              finalAmount,
            })
          }
        })
        .catch(err => {
          this.paymentError =
            typeof err == 'string' ? err : err.message || err.error
          this.loading = false
        })
    },
    submitVoucher() {
      this.formValidation(this.voucherForm)
      if (this.errorsNbr > 0) {
        return
      }
      this.loading = true
      requestService
        .post(`/post/${this.post.uid}/use-voucher`, {
          code: this.voucherForm.code.value,
        })
        .then(response => {
          if (response.success) {
            this.paymentFinished = true
          } else {
            this.$set(this.voucherForm.code, 'error', response.message)
            this.addFieldError(this.voucherForm.code, response.message)
          }
        })
        .catch(() => {
          this.$set(
            this.voucherForm.code,
            'error',
            this.$t('error.an-error-has-occurred'),
          )
          this.loading = false
        })
        .finally(() => (this.loading = false))
    },
    createSinglePayment({ paymentMethodId, finalAmount }) {
      requestService
        .post(process.env.VUE_APP_API_BASE_URL + '/payment/start', {
          paymentMethodId: paymentMethodId,
          postUid: this.post.uid,
          type: this.type,
        })
        .then(response => {
          if (!response.success) {
            this.paymentError = response.message
              ? response.message
              : this.$tc('error.server-error-try-again')
            this.loading = false
            return
          }
          if (response.status === 'succeeded') {
            this.paymentFinished = true
            this.loading = false
            return
          }
          this.confirmCard = true
          setTimeout(() => {
            console.log(this.stripe.confirmCardPayment)
            this.stripe
              .confirmCardPayment(response.client_secret, {
                payment_method: paymentMethodId,
              })
              .then(result => {
                if (result.error) {
                  this.paymentError = result.error.message
                } else {
                  // The payment has been processed!
                  this.paymentFinished = true
                }
              })
              .catch(err => {
                // console.log(err)
              })
              .finally(() => {
                this.loading = false
              })
          }, 1000)
        })
        .catch(err => {
          // console.log(err)
          this.paymentError = this.$tc('error.error-while-creating-payment')
          this.loading = false
        })
    },
    createSubscription({ paymentMethodId, priceId }) {
      axios
        .post(process.env.VUE_APP_FUNCTIONS_BASE_URL + '/startPayment', {
          // amount: finalAmount,
          email: this.form.email.value,
          paymentMethodId: paymentMethodId,
          priceId: priceId,
        })
        .then(response => {
          if (!response.success) {
            this.paymentError = this.$tc('error.server-error-try-again')
            this.loading = false
            return
          }
          return {
            paymentMethodId: paymentMethodId,
            subscription: response.subscription,
          }
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(this.handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(this.handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(this.onSubscriptionComplete)
        .catch(err => {
          // console.log(err)
          this.paymentError = this.$tc(
            'error.error-while-creating-subscription',
          )
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    handlePaymentThatRequiresCustomerAction({
      subscription,
      paymentMethodId,
      isRetry,
    }) {
      const invoice = subscription.latest_invoice
      if (subscription && subscription.status === 'active') {
        // Subscription is active, no customer actions required.
        return { subscription, paymentMethodId }
      }

      // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
      // If it's a retry, the payment intent will be on the invoice itself.
      const paymentIntent = invoice
        ? invoice.payment_intent
        : subscription.latest_invoice.payment_intent

      if (
        paymentIntent.status === 'requires_action' ||
        (isRetry === true && paymentIntent.status === 'requires_payment_method')
      ) {
        this.confirmCard = true
        setTimeout(() => {
          return this.stripe
            .confirmCardPayment(paymentIntent.client_secret, {
              payment_method: paymentMethodId,
            })
            .then(result => {
              if (result.error) {
                // Start code flow to handle updating the payment details.
                // Display error message in your UI.
                // The card was declined (i.e. insufficient funds, card has expired, etc).
                this.paymentError = result.error.message
              } else {
                if (result.paymentIntent.status === 'succeeded') {
                  // Show a success message to your customer.
                  return {
                    subscription: subscription,
                    paymentMethodId: paymentMethodId,
                  }
                }
              }
            })
            .catch(error => {
              // console.log(error)
              this.paymentError = this.$tc(
                'error.error-while-confirming-payment',
              )
            })
        }, 1000)
      } else {
        // No customer action needed.
        return { subscription, paymentMethodId }
      }
    },
    handleRequiresPaymentMethod({ subscription, paymentMethodId }) {
      if (subscription.status === 'active') {
        // subscription is active, no customer actions required.
        return { success: true, subscription }
      } else if (
        subscription.latest_invoice.payment_intent.status ===
        'requires_payment_method'
      ) {
        // Using localStorage to manage the state of the retry here,
        // feel free to replace with what you prefer.
        // Store the latest invoice ID and status.
        localStorage.setItem(
          'paymentLatestInvoiceId',
          subscription.latest_invoice.id,
        )
        localStorage.setItem(
          'paymentLatestInvoicePaymentIntentStatus',
          subscription.latest_invoice.payment_intent.status,
        )
        this.payment_intent_pending = true
        this.isLoading = false
        this.paymentFinished = false
        this.paymentError = this.$tc('error.card-been-refused')
        this.$store.dispatch('stripe/setChangePaymentCardDialog', true)
        this.payment_intent_pending = true
      } else {
        return { subscription, paymentMethodId }
      }
    },
    retryInvoiceWithNewPaymentMethod({ paymentMethodId, invoiceId }) {
      return (
        requestService
          .post(process.env.VUE_APP_API_BASE_URL + '/payment/startPayment', {
            connect: true,
            paymentMethodId: paymentMethodId,
            invoiceId: invoiceId,
            retry: true,
          })
          // If the card is declined, display an error to the user.
          .then(response => {
            if (!response.success) {
              this.$store.dispatch(
                'stripe/setStripeElementPaymentError',
                response.message
                  ? response.message
                  : this.$tc('error.server-error-try-again'),
              )
              this.paymentError = response.message ?? ''
              if (
                response.code &&
                [
                  'card_declined',
                  'expired_card',
                  'insufficient_funds',
                ].includes(response.code)
              ) {
                this.$store.dispatch('stripe/setChangePaymentCardDialog', true)
              }
              this.clean()
              this.isLoading = false
              this.paymentFinished = false
              return
            }
            return {
              subscription: response.subscription,
              paymentMethodId: paymentMethodId,
              retry: true,
            }
          })
          // Normalize the result to contain the object returned by Stripe.
          // Add the additional details we need.
          // .then(result => {

          // })
          // Some payment methods require a customer to be on session
          // to complete the payment process. Check the status of the
          // payment intent to handle these actions.
          .then(this.handlePaymentThatRequiresCustomerAction)
          // No more actions required. Provision your service for the user.
          .then(this.handleRequiresPaymentMethod)
          .then(this.onSubscriptionComplete)
          .catch(error => {
            // An error has happened. Display the failure to the user here.
            // We utilize the HTML element we created.
            this.paymentError = error
          })
      )
    },
    onSubscriptionComplete() {
      this.paymentFinished = true
      this.clean()
      this.loading = false
    },
    clean() {
      localStorage.removeItem('paymentLatestInvoiceId')
      localStorage.removeItem('paymentLatestInvoicePaymentIntentStatus')
      this.payment_intent_pending = false
    },
  },
  watch: {
    paymentFinished(finished) {
      if (finished) {
        this.$emit('successfulPayment')
      }
    },
  },
  created() {
    this.TYPE_PURCHASE = TYPE_PURCHASE
    this.TYPE_RENTAL = TYPE_RENTAL
    this.TYPE_VOUCHER = TYPE_VOUCHER
    this.payment_intent_pending =
      localStorage.getItem('paymentLatestInvoiceId') !== null &&
      localStorage.getItem('paymentLatestInvoicePaymentIntentStatus') ===
        'requires_payment_method'
  },
  // mounted() {
  // if (this.type !== TYPE_VOUCHER) {
  //   this.importStripe().then(this.create)
  // }
  // },
}
</script>

<style scoped></style>
