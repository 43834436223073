import { requestService } from '@/services/request.service.js'
import store from '@/store/index'
import router from '../router/index.ts'

const actions = {
  subscribe(action, organizationUid) {
    return new Promise((resolve, reject) => {
      if (!store.state.account.status.loggedIn) {
        router.push({ name: 'Login' })
        return
      }
      requestService
        .post('/organization/subscribe', {
          organization: organizationUid,
        })
        .then(response => resolve(response))
        .catch(response => reject(response))
    })
  },
  unsubscribe(action, organizationUid) {
    return new Promise((resolve, reject) => {
      if (!store.state.account.status.loggedIn) {
        router.push({ name: 'Login' })
        return
      }
      requestService
        .post('/organization/unsubscribe', {
          organization: organizationUid,
        })
        .then(response => resolve(response))
        .catch(response => reject(response))
    })
  },
  updateNotifications(action, { organizationUid, notifications }) {
    return new Promise((resolve, reject) => {
      if (!store.state.account.status.loggedIn) {
        router.push({ name: 'Login' })
        return
      }
      const data = Object.assign(
        { organization: organizationUid },
        notifications,
      )
      requestService
        .post('/organization/subscribe-notifications', data)
        .then(response => resolve(response))
        .catch(response => reject(response))
    })
  },
}

export const subscription = {
  namespaced: true,
  actions,
}
