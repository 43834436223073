<script setup>
import ChannelAvatar from '@/components/ChannelAvatar.vue'

import { useState } from 'vuex-composition-helpers'
import { ref, watch } from 'vue'
import VerticalPostList from '@/components/VerticalPostList.vue'
import { useRoute } from 'vue-router/composables'
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter'
import {
  AisInstantSearch,
  AisInfiniteHits,
  AisConfigure,
  AisNumericMenu,
  AisStateResults,
} from 'vue-instantsearch'
import ChannelTooltip from '@/components/ChannelTooltip.vue'
import SearchFacet from '@/components/search/SearchFacet.vue'
import { useI18n } from 'vue-i18n-composable'
import { VMenu } from 'vuetify/lib'
import { useBreakpoints, breakpointsVuetify } from '@vueuse/core'
import { capitalizeFirstLetter } from '@/services/utils.service'

const route = useRoute()
const { globalSearchQuery } = useState(['globalSearchQuery'])
const { t, tc } = useI18n()
const resultsLoading = ref(false)
const postsPage = ref(0)
const endReached = ref(false)
const hasError = ref(false)
const query = ref('')
const dateRangeSelected = ref(null)
const defaultSearch = ref(route.query.q || '*')
const rawResults = ref([])
const searchParameters = ref({})
const queryUpdated = ref(false)

const breakpoints = useBreakpoints(breakpointsVuetify)
const smAndDown = breakpoints.smallerOrEqual('sm')

const typesenseNodes = JSON.parse(process.env.VUE_APP_TYPESENSE_NODES)
const typesenseOptions = {
  server: {
    apiKey: process.env.VUE_APP_TYPESENSE_API_KEY, // Be sure to use the search-only-api-key
    nodes: typesenseNodes,
    cacheSearchResultsForSeconds: 2 * 60, // Caches results for 2 minutes. Default is 2 minutes.
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  queryBy is required.
  collectionSpecificSearchParameters: {
    posts_channels: {
      query_by: 'x',
    },
  },
  additionalSearchParameters: {
    enable_highlight_v1: false,
    search_cutoff_ms: 3000,
    use_cache: true,
    prioritize_exact_match: false,
  },
}

// set query parameters only for development environment because in production these parameters are included in a scoped API key
if (process.env.NODE_ENV === 'development') {
  typesenseOptions.collectionSpecificSearchParameters.posts_channels = {
    ...typesenseOptions.collectionSpecificSearchParameters.posts_channels,
    query_by:
      'name,title,tags,textContent' +
      /*,linkMetadataIndexed.title,linkMetadataIndexed.description*/ ',channelName,uid,channelUid',
    sort_by:
      '_text_match(buckets: 5):desc,relevanceScore:desc,publishedAtTimestamp:desc',
    highlight_full_fields:
      'title,tags,linkMetadataIndexed.title,linkMetadataIndexed.description,channelName',
    exclude_fields: 'embedding',
  }
}

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter(
  typesenseOptions,
)
const searchClient = typesenseInstantsearchAdapter.searchClient

const props = defineProps({
  externalQuery: { type: String },
})

let doRefine = null

function searchInputLoaded(refine) {
  if (doRefine) {
    return
  }
  doRefine = refine
  return undefined
}

function base64Decode(encoded) {
  let decoded = atob(encoded)
  let utf8Decoder = new TextDecoder()
  let uint8Array = Uint8Array.from(decoded, c => c.charCodeAt(0))
  return utf8Decoder.decode(uint8Array)
}

function transformChannelRefinement(items) {
  return items.map(item => {
    const label = base64Decode(item.label.replace(/\s/g, ''))
    const parts = label.split('||')
    return {
      ...item,
      label: `@${parts[0]}`,
      uid: `@${parts[0]}`,
      name: parts[1],
      thumbnailUrl: parts[2] || null,
    }
  })
}

function transformMediaTypeRefinement(items) {
  return items.map(item => {
    let label = tc('label.video')
    switch (item.label) {
      case 'text':
        label = tc('label.text')
        break
      case 'image':
        label = tc('label.picture')
        break
      case 'image_and_text':
        label = t('label.image_with_text')
        break
      case 'channel':
        label = tc('label.channel')
        break
    }
    return {
      ...item,
      label: label.charAt(0).toUpperCase() + label.slice(1).toLowerCase(),
    }
  })
}

function sortDateTimestampRanges() {
  const now = new Date()
  const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  const last7DaysStart = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 7,
  )
  const last30DaysStart = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 30,
  )
  const thisYearStart = new Date(now.getFullYear(), 0, 1)
  return [
    {
      label: capitalizeFirstLetter(t('label.all')),
    },
    {
      label: capitalizeFirstLetter(t('time.today')),
      start: Math.floor(todayStart.getTime() / 1000), // Conversion en secondes
    },
    {
      label: capitalizeFirstLetter(t('time.these-last-7-days')),
      start: Math.floor(last7DaysStart.getTime() / 1000),
    },
    {
      label: capitalizeFirstLetter(t('time.these-last-30-days')),
      start: Math.floor(last30DaysStart.getTime() / 1000),
    },
    {
      label: capitalizeFirstLetter(t('time.this-year')),
      start: Math.floor(thisYearStart.getTime() / 1000),
    },
  ]
}

function setSearchParameters(newSearchParameters) {
  searchParameters.value = newSearchParameters
  return undefined
}

function search(searchQuery) {
  query.value = searchQuery.toString()
  if (doRefine) {
    searchParameters.value.query = searchQuery
    queryUpdated.value = true
    postsPage.value = 0
    doRefine(searchParameters.value)
  }
}

function searchFunction(helper) {
  if (helper.state.query) {
    helper.setPage(postsPage.value).search()
  }
  queryUpdated.value = false
}

function onStateChange({ uiState, setUiState }) {
  if (queryUpdated.value) {
    postsPage.value = 0
  } else {
    postsPage.value = uiState.posts_channels.page
      ? Math.max(uiState.posts_channels.page - 1, 0)
      : 0
  }
  setUiState(uiState)
}

watch(globalSearchQuery, search)
</script>

<template>
  <ais-instant-search
    :search-client="searchClient"
    index-name="posts_channels"
    :search-function="searchFunction"
    :on-state-change="onStateChange"
  >
    <ais-configure :hits-per-page.camel="15" :query="defaultSearch">
      <template v-slot="{ refine, searchParameters: newSearchParameters }">
        <div
          class="d-none"
          :data-params="setSearchParameters(newSearchParameters)"
          :data-onload="searchInputLoaded(refine)"
        ></div>
      </template>
    </ais-configure>
    <div class="mt-4">
      <v-row>
        <v-col cols="12" sm="4" md="4" lg="3">
          <component :is="smAndDown ? VMenu : 'div'" offset-y eager>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small color="primary" dark v-bind="attrs" v-on="on">
                <v-icon>mdi-filter-variant</v-icon> {{ $t('search.filter') }}
              </v-btn>
            </template>
            <div
              class="pa-2 pa-md-0 pl-md-2"
              :class="{ secondary: smAndDown }"
              style="max-width: 300px"
            >
              <search-facet
                :label="$t('search.content-type')"
                attribute="mediaType"
                :transform-items="transformMediaTypeRefinement"
              >
              </search-facet>
              <search-facet
                :label="$tc('label.channel')"
                attribute="channelFacet"
                :transform-items="transformChannelRefinement"
                v-slot="{ item }"
              >
                <channel-tooltip
                  :name="item.name"
                  :uid="item.uid"
                  :thumbnails="item.thumbnailUrl"
                >
                  <template #activator="{ on, attrs }">
                    <div
                      class="d-flex flex-grow-1 overflow-hidden"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-list-item-avatar :size="18" class="mr-2 my-0">
                        <channel-avatar
                          :thumbnails="item.thumbnailUrl"
                          :channel-name="item.name"
                          :size="18"
                          :bg-color="item.avatarColor"
                        ></channel-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content class="py-0">
                        <v-list-item-title v-text="item.name" />
                      </v-list-item-content>
                    </div>
                  </template>
                </channel-tooltip>
              </search-facet>
              <ais-numeric-menu
                :items="sortDateTimestampRanges()"
                attribute="publishedAtTimestamp"
              >
                <template #default="{ items, refine }">
                  <div class="overline">{{ $tc('time.date') }}</div>
                  <v-list rounded dense>
                    <v-radio-group
                      mandatory
                      v-model="dateRangeSelected"
                      class="my-0"
                      hide-details
                    >
                      <v-list-item
                        v-for="item in items"
                        :key="item.value"
                        style="min-height: 20px!important;"
                        :value="item.value"
                        @click="dateRangeSelected = item.value"
                        @change="refine(item.value)"
                        :input-value="item.isRefined"
                        active-class="item-active-discrete"
                      >
                        <template #default="{active}">
                          <v-list-item-action class="my-0">
                            <v-checkbox
                              dense
                              readonly
                              :input-value="item.isRefined || active"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content class="py-0">
                            <v-list-item-title v-text="item.label" />
                          </v-list-item-content>
                          <v-list-item-action class="my-0">
                            <v-list-item-action-text v-text="item.count" />
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                    </v-radio-group>
                  </v-list>
                </template>
              </ais-numeric-menu>
            </div>
          </component>
        </v-col>
        <v-col cols="12" sm="8" md="6">
          <ais-state-results>
            <template v-slot="{ results: { hits, _rawResults }, status }">
              <div
                v-show="!hits.length"
                :data-status="(resultsLoading = status === 'staled')"
                :data-resuts="(rawResults = _rawResults)"
              >
                <v-alert
                  :value="true"
                  type="info"
                  elevation="0"
                  class="mt-4"
                  icon="mdi-information"
                  color="secondary"
                >
                  {{ $t('search.no-result') | capitalize }}
                </v-alert>
              </div>
            </template>
          </ais-state-results>
          <ais-infinite-hits class="ml-auto mr-auto" style="max-width: 550px">
            <template #default="{ items, refineNext, isLastPage }">
              <VerticalPostList
                :posts="items"
                :data-loading="resultsLoading"
                :has-error="hasError"
                :end-reached="isLastPage"
                @claim-more-posts="refineNext"
                simplified
                wrapped
              />
            </template>
          </ais-infinite-hits>
        </v-col>
      </v-row>
    </div>
  </ais-instant-search>
</template>

<style scoped lang="scss">
.item-active-discrete {
  &::before {
    opacity: 0;
  }
  &:hover {
    &::before {
      opacity: 0.08;
    }
  }
}
</style>
