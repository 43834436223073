import { requestService } from '@/services/request.service.js'
import store from '@/store/index'
import router from '../router/index.ts'

const actions = {
  like(action, postUid) {
    return new Promise((resolve, reject) => {
      if (!store.state.account.status.loggedIn) {
        router.push({ name: 'Login' })
        return
      }
      requestService
        .post('/post/like', {
          post: postUid,
        })
        .then(response => resolve(response))
        .catch(response => reject(response))
    })
  },
  unlike(action, postUid) {
    return new Promise((resolve, reject) => {
      if (!store.state.account.status.loggedIn) {
        router.push({ name: 'Login' })
        return
      }
      requestService
        .post('/post/unlike', {
          post: postUid,
        })
        .then(response => resolve(response))
        .catch(response => reject(response))
    })
  },
}

export const like = {
  namespaced: true,
  actions,
}
