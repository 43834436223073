import Vue from 'vue'
import Vuex from 'vuex'
import { alert } from '@/store/alert'
import { account } from '@/store/account'
import { subscription } from '@/store/subscription'
import { like } from '@/store/like'
import { realtime } from '@/store/realtime'
import { list } from '@/store/list'
import { stripe } from '@/store/stripe'
import { vote } from '@/store/vote'
import { channel } from '@/store/channel'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    centralLoading: false,
    newsletterDialog: false,
    donationDialog: false,
    lastVideos: [],
    lastCensoredVideos: [],
    lastSubscribedPosts: [],
    lastFeaturedPosts: [],
    lastTrendingPosts: [],
    postEditionDialog: false,
    postToEdit: null,
    postEdited: false,
    timeout: null,
    leftMenuManuallySet: false,
    locales: [
      { text: 'Deutsch', value: 'de' },
      { text: 'English', value: 'en' },
      { text: 'Français', value: 'fr' },
    ],
    remoteConfig: {
      globalErrorMessage: '',
    },
    globalSearchQuery: '',
    preferredHomeSection: null,
    isModernBrowser: false,
  },
  mutations: {
    setGlobalErrorMessage(state, message) {
      state.remoteConfig.globalErrorMessage = message
    },
  },
  actions: {
    updateTitle(context, title) {
      document.title = `${title} - CrowdBunker`
    },
  },
  modules: {
    alert,
    account,
    subscription,
    like,
    realtime,
    list,
    vote,
    channel,
    stripe,
  },
})
