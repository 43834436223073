<template>
  <div>
    <div>
      <p class="text-subtitle-2">
        Sous-titre(s)
        <v-btn
          small
          rounded
          color="accent"
          :disabled="addNew"
          @click="addNew = true"
          class="ml-3"
          depressed
        >
          Ajouter
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </p>
    </div>
    <v-fade-transition leave-absolute>
      <v-row v-show="addNew" class="mb-5 secondary lighten-1 rounded">
        <div class="col-12">
          <v-card
            outlined
            width="100%"
            class="mb-2"
            v-if="subtitleInfos?.subtitleName !== null"
          >
            <v-card-text class="d-flex py-2 align-center">
              <v-progress-circular
                :size="50"
                color="primary"
                :value="uploadProgress"
                class="flex-shrink-0"
                >{{ uploadProgress }}%
              </v-progress-circular>
              <div class="ml-4 flex-grow-1 overflow-hidden">
                <div class="text-truncate">
                  {{ subtitleInfos?.subtitleName }}
                </div>
                <div class="text-caption" v-if="uploading">
                  {{ $tc('video.upload-in-progress') }}
                </div>
                <div
                  class="text-caption success--text"
                  v-else-if="uploadedSuccess"
                >
                  {{ $tc('video.successful-upload') }}
                </div>
                <div class="text-caption error--text" v-else>
                  {{ $tc('video.canceled-upload') }}
                </div>
              </div>
              <div class="ml-2 flex-shrink-0">
                <v-btn
                  fab
                  color="red"
                  x-small
                  v-if="uploadedSuccess"
                  :title="$tc('label.delete') | capitalize"
                  @click="deleteSubtitleUpload()"
                  depressed
                  class="ml-2"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  fab
                  color="red"
                  x-small
                  :disabled="!uploading"
                  :title="$tc('video.cancel-upload')"
                  @click="cancelSubtitleUpload()"
                  depressed
                >
                  <v-icon>mdi-stop</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
          <div
            v-show="!uploading && !uploadedSuccess"
            id="import-subtitle"
          ></div>
        </div>
        <div class="col-6 pb-0">
          <v-autocomplete
            class="pt-0"
            :items="choosalbeLangs"
            :item-text="el => el.nativeLanguage + ' - ' + el.languageCode"
            return-object
            v-model="value"
            label="Choisir la langue"
            placeholder="Tapez pour rechercher..."
            required
          ></v-autocomplete>
        </div>
        <div v-if="errorMessages.length" class="col-12 py-0">
          <p v-for="error of errorMessages" :key="error" class="red--text my-0">
            {{ error }}
          </p>
        </div>
        <div class="col-8">
          <v-btn
            small
            rounded
            depressed
            color="danger"
            :disabled="uploading"
            @click="cancel()"
            >Annuler</v-btn
          >
          <v-btn
            small
            rounded
            depressed
            color="success"
            :disabled="uploading"
            @click="validateSubtitle()"
            class="ml-2"
            >Valider</v-btn
          >
        </div>
      </v-row>
    </v-fade-transition>
    <div>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          v-for="(subtitle, index) in captions"
          :key="index"
        >
          <v-card outlined width="100%">
            <v-card-text class="d-flex py-2 align-center">
              <div class="ml-4 flex-grow-1 overflow-hidden">
                <div class="text-truncate">
                  {{ subtitle.subtitleName }}
                </div>
                <div class="text-caption">
                  {{ subtitle.nativeLanguage }}
                </div>
              </div>
              <div class="ml-2 flex-shrink-0">
                <app-tooltip>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      fab
                      color="red"
                      x-small
                      @click="$emit('delete', index)"
                      depressed
                      v-on="on"
                      v-bind="$attrs"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $tc('label.delete') | capitalize }}
                  </span>
                </app-tooltip>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { uppyService } from '@/services/uppy.service'
import AppTooltip from '@/components/AppTooltip'

import lang from '../assets/js/languages.json'

export default {
  name: 'ImportSubtitle',
  components: { AppTooltip },
  props: {
    captions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      uppy: null,
      addNew: false,
      value: null,
      langs: lang,
      uploadedSuccess: false,
      uploading: false,
      uploadProgress: 0,
      subtitleInfos: {
        subtitleName: null,
        subtitleKey: null,
        type: null,
      },
      currentFile: null,
      errors: [],
      errorMessages: [],
    }
  },
  computed: {
    choosalbeLangs() {
      return this.langs.filter(lang => {
        return !this.captions.some(
          caption => caption.languageCode === lang.languageCode,
        )
      })
    },
  },
  methods: {
    loadImages() {
      this.images = this.files
      this.showDragAndDrop = false
    },
    instantiateUppy() {
      this.uppy = uppyService.instantiate({
        id: 'subtitle',
        note: 'only .srt and .vtt file',
        useDashboard: false,
        useImageEditor: false,
        useDragDrop: true,
        allowedFileTypes: ['.srt', '.vtt'],
        maxFileSize: 10000000, // 10MB
        minFileSize: 1, // 1B
        target: '#import-subtitle',
        height: '100px',
        autoProceed: true,
      })

      this.uppy.on('upload-success', file => {
        if (file.s3Multipart) {
          this.subtitleInfos = {
            ...this.subtitleInfos,
            subtitleKey: file.s3Multipart.key,
          }
        } else {
          this.subtitleInfos = {
            ...this.subtitleInfos,
            subtitleKey: file.meta['key'],
          }
        }
        this.subtitleInfos = {
          ...this.subtitleInfos,
          type:
            file.type == 'application/x-subrip'
              ? 'srt'
              : file.type == 'text/vtt'
              ? 'vtt'
              : null,
        }
        this.uploadedSuccess = true
        this.uploading = false
      })
      this.uppy.on('file-added', file => {
        this.subtitleInfos = {
          ...this.subtitleInfos,
          subtitleName: file.data.name,
        }
        // console.log('file added', file)
        this.currentFile = file
        this.$emit('update:videoKey', null)
        this.$emit('error', null)
      })
      this.uppy.on('upload', () => {
        this.uploading = true
      })
      this.uppy.on('progress', data => {
        this.uploadProgress = data
      })
      this.uppy.on('error', error => {
        console.log('error', error)
        this.uploading = false
        this.uploadProgress = 0
        if (this.subtitleInfos?.subtitleName)
          delete this.subtitleInfos.subtitleName
        this.uppy.cancelAll()
      })
      this.uppy.on('upload-error', (file, response) => {
        this.uploading = false
        this.uploadProgress = 0
        if (this.subtitleInfos?.subtitleName)
          delete this.subtitleInfos.subtitleName
        console.log('error with file:', file.id)
        const { error } = response
        console.log('error message:', error, response)
        if (error.detail) {
          this.$emit('error', error.detail)
        }
        this.uppy.cancelAll()
      })
    },
    deleteSubtitleUpload() {
      this.subtitleInfos = {
        subtitleName: null,
        subtitleKey: null,
      }
      this.uploadedSuccess = false
      this.uppy.cancelAll()
      this.$emit('error', null)
    },
    cancelSubtitleUpload() {
      if (this.uppy === null || this.uppy.cancelAll === undefined) {
        return
      }
      this.uppy.cancelAll()
      this.uploadProgress = 0
      this.uploading = false
    },
    // validateSubtitleFile1(file) {
    //   // Validation du contenu du fichier
    //   if (['.srt', '.vtt'].includes(file.extension)) {
    //     // Le format du fichier est valide, continuez la validation
    //     const reader = new FileReader()
    //     reader.onload = event => {
    //       const content = event.target.result

    //       // Vérifiez s'il n'y a pas d'URL dans le contenu
    //       if (!content.match(/https?:\/\/\S+/)) {
    //         console.log("Le fichier ne contient pas d'URL.")
    //         // Ajoutez ici d'autres vérifications selon vos besoins
    //       } else {
    //         console.error('Le fichier contient une URL. Fichier non valide.')
    //         // Vous pouvez également ajouter d'autres logiques de gestion des fichiers non valides ici
    //       }
    //     }

    //     reader.readAsText(file.data)
    //   } else {
    //     console.error('Format de fichier non valide.')
    //     // Ajoutez ici la logique de gestion des fichiers non valides
    //   }
    // },

    validateSubtitle() {
      if (this.currentFile) {
        const reader = new FileReader()

        reader.onload = event => {
          const content = event.target.result
          this.validateSubtitleFile(content)
        }

        try {
          reader.readAsText(this.currentFile.data)
        } catch (error) {
          console.error(error)
        }
      } else {
        console.error('Please select a subtitle file.')
        this.errorMessages.includes('Please select a subtitle file.') ||
          this.errorMessages.push('Please select a subtitle file.')
      }
    },

    validateSubtitleFile(content) {
      // Adapted validation logic for client-side usage
      const subtitles = this.parseData(content)
      this.validate(subtitles)
      this.displayResults()
    },

    parseData(fileData) {
      const linesGrouped = this.by4(fileData)

      const subtitles = {}
      linesGrouped.forEach(lines => {
        const [id, time, subtitle] = lines
        const [start, stop] = time.split(' --> ').map(t => t.trim())
        subtitles[id] = { start, stop, subtitle }
      })

      return subtitles
    },
    validate(subtitles) {
      let previousStop = '00:00:00,000' // smallest possible value
      this.errors = []

      Object.entries(subtitles).forEach(([id, markers]) => {
        if (id.includes('WEBVTT')) {
          return
        }
        if (markers.start > markers.stop) {
          this.errors.push(`subtitle #${id} is invalid (start > stop)`)
        }
        if (previousStop > markers.start) {
          this.errors.push(
            `subtitle #${id} is overlapping with the previous one.`,
          )
          this.errors.push(
            `subtitle #${id} starts at ${markers.start} but previous subtitle ends at ${previousStop}`,
          )
          this.errors.push(markers)
        }
        previousStop = markers.stop
      })
    },
    displayResults() {
      this.errorMessages = []
      // console.log(this.errors)
      if (this.errors && this.errors.length > 0) {
        // this.errors.forEach(e => console.log(e))
        this.errorMessages.push('Subtitle file is not valid.')
      }
      if (!this.value) {
        this.errorMessages.push('Please select a language.')
      }
      if (this.errorMessages.length === 0) {
        this.captions.push({
          ...this.subtitleInfos,
          ...this.value,
        })
        // console.log('No errors found.')
        this.cancel()
      }
      // console.log('Done.')
    },
    by4(fileData) {
      const lines = fileData.split('\n')
      const by4Lines = []
      while (lines.length !== 0) {
        let counter = 4
        let block = lines.splice(0, 4)
        // check if last element of by4Lines is '/r' pass otherwire loop  and counter++ until find last element is '/r'
        if (block[block.length - 1][3] === '\r') {
          // console.log('last element is /r')
          by4Lines.push(block)
        } else {
          // console.log('last element is not /r')
          while (true) {
            counter++
            block = block.concat(lines.splice(0, 1))
            if (block[block.length - 1][3] === '\r') {
              by4Lines.push(block)
              break
            }
            if (counter > 100) {
              // show what remain in lines
              break
            }
          }
        }
      }
      // console.log(by4Lines)
      return by4Lines
    },

    cancel() {
      this.uppy.cancelAll()
      this.uploadProgress = 0
      this.uploading = false
      this.uploadedSuccess = false
      this.currentFile = null
      this.subtitleInfos = {
        subtitleName: null,
        subtitleKey: null,
      }
      this.value = null
      this.errorMessages = []
      this.errors = []
      this.addNew = false
    },
  },
  mounted() {
    this.instantiateUppy()
  },
}
</script>

<style scoped lang="scss">
#import-subtitle {
  color: #adadad;
  ::v-deep {
    .uppy-DragDrop-container {
      max-height: 100px;
      background-color: var(--v-secondary-lighten2);
      font-family: 'Roboto', sans-serif;
      font-size: 17px;
      color: rgba(255, 255, 255, 0.5);
    }
    .uppy-DragDrop-arrow {
      display: none;
    }
    .uppy-DragDrop-inner {
      padding: inherit !important;
    }
    .v-text-field__details {
      display: none;
    }
  }
}
.d-flex {
  display: flex;
}
</style>
