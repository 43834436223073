<template>
  <div>
    <v-expand-transition>
      <v-row
        v-show="
          (organization.banner && organization.banner.url) ||
            (loading && noBanner === false)
        "
        class="pt-3"
      >
        <v-responsive :aspect-ratio="1903 / 315" class="responsive-banner">
          <v-img
            v-if="organization.banner"
            :src="organization.banner.url"
            width="100%"
            contain
          ></v-img>
        </v-responsive>
      </v-row>
    </v-expand-transition>
    <v-row class="justify-center mt-0" style="min-height: 132px">
      <v-col md="11" xl="8" class="pb-0 secondary top-block mt-0 mt-sm-4">
        <div
          v-if="organization.name"
          class="d-flex align-center pa-1 channel-block flex-column flex-sm-row"
        >
          <channel-avatar
            :thumbnails="organization.thumbnails"
            :channel-name="organization.name"
            :object="organization"
          ></channel-avatar>
          <div class="ml-2">
            <div class="channel-name text-h5">
              {{ organization.name }}
              <v-icon
                v-if="!!organization.ownerMembershipLevel"
                :color="getColor(organization.ownerMembershipLevel)"
                >mdi-seal</v-icon
              >
              <v-tooltip
                right
                v-if="!organization.owned && organization.status === 'active'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="warning" v-bind="attrs" v-on="on">
                    mdi-information
                  </v-icon>
                </template>
                <div
                  v-html="
                    $tc('organization.organization-created-by-crowdbunker')
                  "
                ></div>
              </v-tooltip>
            </div>
            <div class="text-truncate text-caption">
              @{{ organization.uid }}
              <span v-if="organization.subscribersCount">
                &middot;
                {{ formatShortNumber(organization.subscribersCount) }}
                {{
                  $tc('organization.subscriber', organization.subscribersCount)
                }}</span
              >
            </div>
            <div>
              <trust-score
                :score="organization.trustScore"
                :is-user="false"
              ></trust-score>
            </div>
            <div
              v-if="organization.status === 'active' && organization.watched"
              class="caption primary--text"
            >
              {{ $tc('channel.protected-since') }}
              {{ formatDate(new Date(organization.createdAt)) }}
            </div>
          </div>
          <div
            class="d-inline-flex ml-auto overflow-x-auto overflow-y-hidden ml-sm-auto mt-1 mt-sm-0"
          >
            <membership-button
              v-if="
                isLoggedIn &&
                  organization &&
                  organization.membershipGroups &&
                  organization.membershipGroups.length > 0 &&
                  !organization.isAMemberOfOrganization
              "
              :organization="organization"
            ></membership-button>
            <subscription-button
              v-if="organization.status === 'active'"
              :organization="organization"
            ></subscription-button>
          </div>
        </div>
        <v-skeleton-loader
          v-else
          type="list-item-avatar"
          width="50%"
          :boilerplate="!loading"
        ></v-skeleton-loader>
        <div
          v-if="
            !organization.monetizationActive &&
              organization.currentUserIsAdminOfThisOrganization
          "
          class="mt-3"
        >
          <monetization-alert :organization="organization" />
        </div>
        <v-tabs
          v-model="tab"
          v-if="loading || organization.status === 'active'"
        >
          <v-tab
            v-for="(tab, key) in tabs"
            :key="key"
            v-text="tab.tabName"
            :to="{ name: tab.routeName }"
            exact
          ></v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col md="11" xl="8">
        <v-row>
          <v-col>
            <div v-if="notFound" class="text-h5 text-center primary--text">
              {{
                $tc(
                  'organization.organization-doesnt-exist-or-changed-username',
                )
              }}
            </div>
            <div
              v-else-if="organization.status === 'disabled'"
              class="text-center primary--text"
            >
              <v-alert color="error darken-2">
                <div class="text-h5">
                  {{ $tc('organization.deactivated-organization') }}
                </div>
                <div v-if="organization.statusReason">
                  {{ organization.statusReason }}
                </div>
              </v-alert>
            </div>
            <div
              v-else-if="organization.status === 'deleted'"
              class="text-center primary--text"
            >
              <v-alert color="error darken-2">
                <div class="text-h5">
                  {{ $tc('error.this-channel-has-been-deleted') }}
                </div>
                <div v-if="organization.statusReason">
                  {{ organization.statusReason }}
                </div>
              </v-alert>
            </div>
            <div v-else-if="hasError" class="text-h5 text-center primary--text">
              {{ $tc('error.an-error-has-occurred') }}
            </div>
          </v-col>
        </v-row>
        <v-tabs-items
          v-model="tab"
          class="transparent"
          v-if="loading || organization.status === 'active'"
        >
          <v-tab-item
            v-for="(tab, key) in tabs"
            :key="key"
            :value="$router.resolve({ name: tab.routeName }).href"
          >
            <div class="overline" style="min-height: 2rem">{{ tab.title }}</div>
            <component :is="tab.component" v-bind="tab.args"></component>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MonetizationAlert from '@/components/MonetizationAlert.vue'
import ChannelAvatar from '@/components/ChannelAvatar'
import VideoList from '@/components/VideoList'
import PostList from '@/components/PostList'
import ListList from '@/components/list/ListList.vue'
import { requestService } from '@/services/request.service'
import SubscriptionButton from '@/components/SubscriptionButton'
import MembershipButton from '@/components/MembershipButton'
import PostDonation from '@/components/PostDonation'
import { utilsService } from '@/services/utils.service'
import { subscriptionService } from '@/services/subscription.service'
import TrustScore from '@/components/TrustScore.vue'

export default {
  name: 'Organization',
  components: {
    MonetizationAlert,
    TrustScore,
    SubscriptionButton,
    VideoList,
    ChannelAvatar,
    PostList,
    MembershipButton,
    ListList,
  },
  data() {
    return {
      organization: {},
      loading: true,
      notFound: false,
      hasError: false,
      tab: 0,
      tabs: [
        {
          tabName: this.$tc('label.home'),
          routeName: 'Organization',
          component: 'PostList',
          args: {
            organizationId: this.$route.params.slug,
            ownerMembershipLevel:
              this.organization?.ownerMembershipLevel ?? null,
          },
        },
        // {
        //   tabName: this.$tc('label.video', 2),
        //   routeName: 'OrganizationVideos',
        //   component: 'VideoList',
        //   title: this.$tc('label.last-videos'),
        //   args: {
        //     organizationId: this.$route.params.slug,
        //   },
        // },
        {
          tabName: this.$tc('list.list', 2),
          routeName: 'OrganizationLists',
          component: 'ListList',
          args: {
            organizationUid: this.$route.params.slug,
            ownerMembershipLevel:
              this.organization?.ownerMembershipLevel ?? null,
          },
        },
      ],
      noBanner: false,
    }
  },
  metaInfo() {
    if (this.organization.name === undefined) {
      return {}
    }
    return {
      title: `${this.organization.name} - CrowdBunker`,
      meta: [
        {
          property: 'og:title',
          vmid: 'og:title',
          content: `${this.organization.name} - CrowdBunker`,
        },
        {
          property: 'twitter:title',
          vmid: 'twitter:title',
          content: `${this.organization.name} - CrowdBunker`,
        },
        { property: 'og:image', vmid: 'og:image', content: this.thumbnail.url },
        {
          property: 'twitter:image',
          vmid: 'twitter:image',
          content: this.thumbnail.url,
        },
        {
          property: 'og:url',
          vmid: 'og:url',
          content: this.organizationUrl,
        },
        {
          property: 'twitter:url',
          vmid: 'twitter:url',
          content: this.organizationUrl,
        },
      ],
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.account.status.loggedIn
    },
    tempThumbnail() {
      return (
        this.organization.thumbnails.find(
          thumbnail => thumbnail.name === 'default',
        ) || {}
      )
    },
    thumbnail() {
      if (this.organization.thumbnails === undefined) {
        return {}
      }
      return (
        this.organization.thumbnails.find(
          thumbnail => thumbnail.name === 'medium',
        ) || {}
      )
    },
    organizationUrl() {
      return (
        window.location.origin +
        this.$router.resolve({
          name: 'Organization',
          params: { slug: this.organization.uid },
        }).href
      )
    },
  },
  methods: {
    getColor(level) {
      return subscriptionService.subscriptionLevelColor(level)
    },
    getInfos() {
      this.loading = true
      this.notFound = false
      this.hasError = false
      requestService
        .get(`/organization/${this.$route.params.slug}/details`)
        .then(response => {
          this.organization = response
          this.tabs = this.tabs.map(tab => {
            return {
              ...tab,
              args: {
                ...tab.args,
                ownerMembershipLevel:
                  this.organization?.ownerMembershipLevel ?? null,
              },
            }
          })
        })
        .catch(response => {
          if (response && response.status === 404) {
            this.notFound = true
          } else {
            this.hasError = true
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatDate(date, hour = false) {
      return new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: hour ? 'numeric' : undefined,
        minute: hour ? 'numeric' : undefined,
      }).format(new Date(date))
    },
    formatShortNumber(views) {
      return utilsService.formatNumber(views)
    },
  },
  created() {
    if (this.$route.params.noBanner) {
      this.noBanner = this.$route.params.noBanner
    }
  },
  mounted() {
    this.getInfos()
  },
  beforeRouteUpdate(to, from, next) {
    this.$root.$emit('reload')
    next()
  },
}
// ok
</script>

<style scoped lang="scss">
.v-skeleton-loader {
  &::v-deep {
    .v-skeleton-loader__list-item-avatar {
      height: 100px;
      padding: 2px;

      .v-skeleton-loader__avatar {
        height: 100px;
        width: 100px;
      }

      .v-skeleton-loader__text {
        height: 25px;
      }
    }

    .v-skeleton-loader__image {
      height: 100%;
    }
  }
}
.responsive-banner,
.top-block {
  border-radius: 8px;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .responsive-banner,
  .top-block {
    border-radius: 0;
  }
}
</style>
