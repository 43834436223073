
import { defineComponent } from 'vue'
import LiveChatCard from '@/components/video/LiveChatCard.vue'
import { requestService } from '@/services/request.service'

export default defineComponent({
  name: 'LiveChatWindow',
  components: { LiveChatCard },
  data() {
    return {
      post: null,
      loading: false,
      notFound: false,
      hasError: false,
    }
  },
  methods: {
    fetchPost() {
      if (this.post === null) {
        this.loading = true
      }
      this.notFound = false
      this.hasError = false
      requestService
        .get(`/post/${this.$route.params.slug}/details`)
        .then(response => {
          if (
            response.deletedAt ||
            (response.video && response.video.deleted)
          ) {
            this.notFound = true
            return
          }
          this.post = response
        })
        .catch(response => {
          if (response.status === 404) {
            this.notFound = true
          } else {
            this.hasError = true
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  created() {
    this.fetchPost()
  },
})
