<template>
  <div class="mx-n3">
    <div class="primary pa-md-4 pa-3 overflow-hidden">
      <v-row class="align-center">
        <v-col>
          <div class="text-center text-h3 text-sm-h2 app-title">
            LIBÉRONS-NOUS
          </div>
          <div class="text-center text-h5">
            Finançons la plateforme indépendante de libre-expression dont nous
            avons tous besoin !
          </div>
        </v-col>
        <v-col
          class="justify-center d-flex align-center flex-column my-md-5"
          style="position: relative"
        >
          <v-img
            lazy-src="/images/fund/liquid-header-light.png"
            src="/images/fund/liquid-header-light-rocket.png"
            style="position:absolute;right: 0;opacity: 0.1"
            max-height="450px"
            height="450px"
            eager
          ></v-img>
          <v-btn
            color="success"
            class="mt-4"
            large
            @click="$store.state.donationDialog = true"
            >Je fais un don
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="pt-8 px-3 d-flex justify-center main-body">
      <div style="max-width: 800px;width: 100%;overflow-x: hidden;">
        <div>
          <p>
            Entièrement financée par les dons de ses utilisateurs, CrowdBunker
            est
            <b
              >une plateforme qui nous permet de nous exprimer, discuter, nous
              informer... <strong> librement.</strong></b
            >
          </p>
          <p>
            Afin qu'elle devienne une alternative pleinement satisfaisante aux
            services "gratuits" (mais liberticides) des GAFAM, nous avons tous
            intérêt à ce que cette plateforme devienne de plus en plus stable et
            riche en fonctionnalités.
          </p>
          <p>
            C'est pourquoi
            <b>nous lançons une campagne d'appel aux dons </b>pour permettre à
            CrowdBunker de devenir
            <strong>
              la véritable plateforme de libre-expression dont nous avons tous
              besoin.
            </strong>
          </p>
        </div>
        <div>
          <div class="text-h4 app-title">
            Pourquoi avons-nous besoin de financement ?
          </div>

          <div class="text-h5 app-title">
            Expansion et sécurité de l'infrastructure
          </div>
          <v-row>
            <v-col sm="8"
              ><p>
                Toute l&#39;infrastructure logicielle de CrowdBunker a été
                développée en partant de zéro et repose sur des serveurs
                distribués un peu partout dans le monde.
              </p>
              <p>
                <strong
                  >Des investissements colossaux sont nécessaires pour
                  développer une plateforme de streaming fiable. C&#39;est une
                  des raisons principales qui donnent autant de pouvoir à
                  YouTube.</strong
                >
              </p>
              <p>
                En effet, pour permettre une lecture fluide des vidéos sans
                chargement intempestif (buffering),
                <b>de nombreux serveurs</b> de diffusion doivent être placés au
                plus près des utilisateurs et supporter au total
                <strong
                  >des centaines de Gb par seconde de bande passante</strong
                >.
              </p>
            </v-col>
            <v-col cols="12" sm="4" class="d-flex justify-center">
              <v-img
                src="/images/fund/global-network.png"
                contain
                width="90%"
                max-width="90%"
              ></v-img>
            </v-col>
          </v-row>
          <p>
            De plus,
            <b>pour être paré à toutes éventualités</b> telles que la
            défaillance de disque dur, la corruption de fichier, les tentatives
            de piratage ou de censure,
            <b>de nombreuses copies des vidéos doivent exister</b>. Cela
            représente là aussi des coûts de stockage importants.
          </p>

          <p><strong>Des coûts divers :</strong></p>
          <p>
            Voilà un aperçu presque exhaustif des types de coût auxquels nous
            avons à faire face tous les mois juste pour faire fonctionner le
            service actuel (il ne s&#39;agit pas des coûts de développement qui
            sont en sus) :
          </p>
          <ul>
            <li>Les serveurs de diffusion</li>
            <li>
              Le transcodage des vidéos (conversion de chaque vidéo en plusieurs
              qualités différentes)
            </li>
            <li>
              Les serveurs qui &quot;crawl&quot; les autres plateformes
              (aujourd&#39;hui YouTube, demain Facebook et Twitter aussi) et
              téléchargent automatiquement les vidéos pour les protéger de la
              censure
            </li>
            <li>Le stockage principal</li>
            <li>Les sauvegardes de redondance</li>
            <li>La base de données redondée</li>
            <li>Les services de protections contre les attaques (DDoS...)</li>
            <li>L&#39;envoi d&#39;emails de notification</li>
            <li>Le traitement des images</li>
            <li>
              La diffusion de données en temps réel (chat en direct, nombre de
              spectateurs...)
            </li>
            <li>Indexation / moteur de recherche interne</li>
            <li>
              Services internes (tests, CI / CD, logs, monitoring, versioning,
              administration...)
            </li>
            <li>
              Les autres services qui permettent à la plateforme de fonctionner
              dans son intégralité.
            </li>
          </ul>
          <p class="mt-2">
            Les coûts sont nombreux et de plus en plus élevés au fil des mois.
          </p>
          <p>
            Mais nous avons fait notre possible pour optimiser les coûts de
            diffusion notamment (un des plus gros postes de coût), en créant
            <strong>notre propre infrastructure de diffusion</strong> (CDN).
          </p>
          <v-alert color="secondary">
            <p>
              Pour vous donner un ordre de grandeur des coûts, voici combien
              nous coûterait <b>uniquement la diffusion</b> (sans les autres
              coûts énumérés plus haut) si nous avions eu recours à un service
              spécialisé <b>au lieu</b> de créer notre propre infrastructure de
              serveurs comme <b>nous l&#39;avons fait</b> :
            </p>
            <p>
              <em
                >Exemple pour une sélection de 200 vidéos par mois d&#39;une
                durée moyenne d&#39;1h et 3000 vues, soit seulement 600k heures
                vues par mois au total (nous faisons déjà bien plus de trafic à
                ce stade sur CrowdBunker).</em
              >
            </p>
            <ul>
              <li>Cloudflare : 36 000$ / mois</li>
              <li>Google Cloud CDN : 23 358$ / mois</li>
              <li>Amazon CloudFront : 33 056$ / mois</li>
              <li>Fastly : 48 600$ / mois</li>
            </ul>
          </v-alert>
          <p>
            Créer notre propre infrastructure de serveurs de diffusion répartis
            dans de nombreux datacenters à-travers le monde nous permet donc de
            réaliser une considérable économie (> 90%) par rapport aux tarifs
            pratiqués par les fournisseurs spécialisés.
          </p>
          <p>
            Outre ces économies de coût, avoir notre propre infrastructure de
            serveurs améliore également notre indépendance vis-à-vis d&#39;une
            quelconque entreprise en particulier et nous protège donc davantage
            contre tout risque de censure arbitraire.
          </p>
          <p>
            <strong
              >Aujourd&#39;hui, ce qui limite la capacité de notre
              infrastructure - et donc la possibilité pour davantage de gens
              d&#39;accéder aux vidéos ou de suivre les diffusions en direct -
              est la barrière des coûts d&#39;investissement dans des serveurs.
              Co-financer ensemble CrowdBunker nous permet ainsi de bien mieux
              nous protéger contre la censure.</strong
            >
          </p>
          <div class="text-h5">
            Nouveaux outils et fonctionnalités
          </div>
          <p>
            Pour l&#39;instant, nous avons fait notre possible pour que les
            fonctionnalités vraiment essentielles, afin de résister à la censure
            des GAFAM, soient présentes sur CrowdBunker.
          </p>
          <p>
            Cependant,
            <b>notre vision pour l&#39;avenir n&#39;a pas de limite</b>
            <em>
              (si ce n&#39;est celle des coûts de développement et de
              fonctionnement).</em
            >😅
          </p>
          <p>
            <b>Les vidéos ne sont qu&#39;un point de départ.</b> Nous souhaitons
            que CrowdBunker soit non seulement une alternative parfaitement
            viable à YouTube, mais également aux différents réseaux sociaux et
            sites d&#39;information financés par des multimilliardaires dont les
            intérêts ne sont pas forcément les mêmes que les nôtres.
          </p>
          <v-alert type="info" color="secondary lighten-1" dark>
            <b
              >CrowdBunker est une plateforme issue du peuple, financée par le
              peuple, pour servir le peuple. Tel est notre credo et nous
              n&#39;en démordrons jamais sous aucun prétexte</b
            >
            <em>
              (même si Google, Amazon ou Facebook venait nous séduire avec des
              MILLIARDS d&#39;euros).
            </em>
            <b>Notre Vie vaut bien plus. ❤️‍🔥🙏</b>
          </v-alert>
          <p>
            Notre vision pour l&#39;avenir est que
            <strong
              >CrowdBunker -le bunker du peuple- doit nous permettre
              d&#39;échanger du contenu librement, sous toutes ses
              formes</strong
            >
            (vidéos, images, textes, contenus extérieurs, messages instantanés,
            visioconférence...),
            <strong>de manière au moins aussi simple et plaisante</strong> que
            nous le faisons actuellement avec les big tech, mais surtout
            librement, sans censure !
          </p>
          <p>
            <b>
              Voici quelques exemples d&#39;outils et de fonctionnalités que
              nous avons l&#39;intention de développer lorsque cette campagne de
              financement aura atteint ses objectifs :
            </b>
          </p>
          <div class="text-h6">
            Fonctions collaboratives
          </div>
          <p>
            L'une des premières évolutions majeures sera de
            <strong>donner plus de contrôle aux utilisateurs</strong>
            sur la plateforme. Nous voulons à tout prix nous éloigner du système
            de dictat employé par la quasi-totalité des plateformes aujourd’hui.
          </p>
          <p>
            Ainsi, beaucoup de fonctionnalités seront mises en place afin de
            <strong>laisser l’intelligence collective s’exprimer</strong>.
          </p>
          <p>
            Par exemple, nous estimons qu'une plateforme qui donne réellement le
            contrôle à ses utilisateurs ne doit ni leur imposer les contenus
            qu'ils peuvent voir (ou ne pas voir), ni les obliger à fouiller
            parmi des milliers de contenus. Les contenus mis “à la une” seront
            choisis collectivement, en continu.
          </p>
          <p>
            De plus, <strong>chacun aura la possibilité de contribuer</strong> à
            n’importe quelle publication pour faciliter leur découverte : leur
            ajouter des tags, un lieu ou un évènement associé, suggérer des
            liens manquants (sources…), un chapitrage, des sous-titres et bien
            plus encore !
          </p>
          <v-row>
            <v-col>
              <v-carousel hide-delimiters>
                <v-carousel-item v-for="n in 6" :key="n">
                  <v-row class="fill-height" align="center" justify="center">
                    <div style="max-width: 100%">
                      <v-img
                        :src="`/images/fund/contribute/${n}.png`"
                        contain
                        width="90%"
                        max-width="90%"
                        max-height="100%"
                        class="mx-auto"
                      ></v-img>
                    </div>
                  </v-row>
                </v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>
          <p>
            Parfois, rassembler des données autour d’un sujet, qui sont perdues
            au milieu de millions d’autres sur le web, peut être fastidieux et
            chronophage. Souvent, un même sujet intéresse plusieurs personnes,
            mais généralement chacun refait le même travail de recherche de son
            côté, faute d’alternative efficace. Et si nous avions un outil qui
            nous permettait de le faire collectivement ?
          </p>
          <p>
            C’est toute la magie qu’offrira la nouvelle section Sujets, qui
            permettra à n’importe qui de créer un sujet autour duquel tous ceux
            qui le souhaitent pourront suggérer des contenus. Les autres
            pourront, ici aussi de manière collective, voter pour la pertinence
            des suggestions afin de les trier en temps réel.
          </p>
          <div class="text-h6">
            Nouveautés et améliorations
          </div>
          <v-row>
            <icon-block icon="cellphone">Application mobile</icon-block>
            <icon-block icon="download">Téléchargement des vidéos</icon-block>
            <icon-block icon="share-variant">Groupes privés</icon-block>
            <icon-block icon="message-text">Messagerie interne</icon-block>
            <icon-block icon="message-video"
              >Salons vocaux et vidéos
            </icon-block>
            <icon-block icon="bitcoin">Création d'une blockchain</icon-block>
            <icon-block icon="account-multiple-check"
              >Modération participative
            </icon-block>
            <icon-block icon="send"
              ><v-icon>mi-telegram</v-icon>Intégration de Telegram</icon-block
            >
            <icon-block icon="hexagon-multiple" md="6">
              <b>Décentralisation des contenus :</b> <br />
              - Devenez vous-même garant de la "non-censurabilité" des contenus
              en hébergeant une partie des sauvegardes chez vous, en installant
              simplement un logiciel. <br />
              - Réplication des contenus sur des réseaux décentralisés
              existants, accessibles même si CrowdBunker venait à disparaitre
              (mais cela n'arrivera pas !) ; implémentation d'IPFS, ActivityPub,
              etc. et création de ponts avec des réseaux fédératifs comme
              PeerTube.
            </icon-block>
            <icon-block icon="cash-multiple" md="6">
              <b>Monétisation des contenus pour les créateurs :</b> <br />
              - Possibilité de faire des dons aux créateurs de contenus <br />
              - Possibilité de créer un espace privé réservé aux contributeurs
              d'une chaine (abonnement mensuel) <br />
              - Possibilité d'avoir une boutique intégrée à sa chaine (vente de
              produits physiques, produits numériques, services sur
              rendez-vous...)
              <br />
              - Pouvoir créer des cagnottes en ligne <br />
              - Cryptomonnaies
            </icon-block>

            <icon-block icon="account-group"
              >Onglet communauté dans chaque chaine, où les abonnés peuvent
              interagir
            </icon-block>
            <icon-block icon="source-fork"
              >Amélioration de la capacité de la plateforme via la co-diffusion
              en P2P
            </icon-block>
            <icon-block icon="playlist-plus"
              >Playlists privées et publiques, y compris "à regarder plus tard".
            </icon-block>
            <icon-block icon="post"
              >Créez des pages personnalisées et des articles de blog
            </icon-block>
            <icon-block icon="application-import">
              Intégration et partage facilités de contenus d'autres plateformes
              (Odysee, Telegram...)
            </icon-block>
            <icon-block icon="broadcast"
              >Outil de diffusion vidéo en direct 100% en ligne (alternative à
              StreamYard, OBS, etc).
            </icon-block>
            <icon-block icon="arrow-decision-outline"
              ><b>Multistreaming :</b> diffusez simultanément vos lives sur
              plusieurs plateformes (YouTube, Facebook, etc)
            </icon-block>
            <icon-block icon="movie-open-edit"
              >Générez et publiez directement depuis CrowdBunker des extraits de
              vos vidéos
            </icon-block>
            <icon-block icon="movie-open-star"
              >Amélioration de l'expérience utilisateur durant les diffusions en
              direct
            </icon-block>
            <icon-block icon="download-lock"
              >Importation automatique de contenus d'autres plateformes, en plus
              de YouTube déjà présente, pour les protéger de la censure
              (Facebook, Twitter...).
            </icon-block>
            <icon-block icon="safe-square"
              ><b>Coffre-fort personnel :</b> importez et protégez les vidéos
              (et autres contenus) de votre choix depuis d'autres plateformes
              (via Keeep)
            </icon-block>
            <icon-block icon="translate"
              >Internationalisation et traductions
            </icon-block>
            <icon-block icon="square-edit-outline"
              >Pouvoir éditer ses publications
            </icon-block>
            <icon-block icon="share-all"
              >Repartage d'autres publications
            </icon-block>
            <icon-block icon="all-inclusive"
              >Bien plus encore, toujours grâce à vos suggestions !
            </icon-block>
          </v-row>
          <p class="mt-3">
            Tout est possible, seule notre imagination nous limite. Mais il est
            évident que quelque soit le service proposé, cela aura un coût.
            C'est pourquoi, plus cette campagne permettra de récolter de dons,
            plus nous serons en mesure de développer de nouvelles
            fonctionnalités rapidement.
          </p>
          <div class="d-flex justify-center my-10">
            <v-btn
              color="success"
              large
              @click="$store.state.donationDialog = true"
              >Je fais un don
            </v-btn>
          </div>

          <div class="text-h4 mb-2" id="quels-sont-nos-besoins-financiers-">
            Quels sont nos besoins financiers ?
          </div>
          <div
            class="mb-5 font-weight-bold"
            id="seuil-1-assurer-la-continuit-de-la-plateforme-et-am-liorer-la-qualit-de-service"
          >
            <div class="primary px-1 rounded mt-3 d-inline-block">
              Seuil 1 : assurer la continuité de la plateforme et améliorer la
              qualité de service
            </div>
          </div>
          <p><b>Montant requis :</b> 300 000 € (pour 1 an)</p>
          <p><u>Objectifs principaux de cette phase :</u></p>
          <ul>
            <li>
              Investissements dans l&#39;infrastructure :
              <ul>
                <li>
                  Augmentation des capacités de charge à 20 000 spectateurs de
                  vidéos en simultané (minimum)
                </li>
                <li>
                  Extension des capacités de transcodage pour permettre plus de
                  vidéos transcodées en simultané et plus rapidement
                </li>
              </ul>
            </li>
            <li>
              Au moins deux ingénieurs informatique à plein temps (pour
              développer de nouvelles fonctionnalités et maintenir la
              plateforme)
            </li>
            <li>Quelques prestataires occasionnels</li>
            <li>Réserve juridique</li>
          </ul>
          <div
            class="font-weight-bold mt-8 mb-5"
            id="seuil-2-et-acc-l-rer-les-d-veloppements-et-augmenter-la-r-silience"
          >
            <div class="primary px-1 rounded mt-3 d-inline-block">
              Seuil 2 et + : accélérer les développements et augmenter la
              résilience
            </div>
          </div>
          <p>
            <b>Montant requis :</b> Tranches de 120 000 € / an supplémentaires
          </p>
          <p><u>Objectifs principaux de cette phase :</u></p>
          <ul>
            <li>
              Investissements dans l&#39;infrastructure :
              <ul>
                <li>
                  Augmentation des capacités de charge de + 15 000 spectateurs
                  de vidéos en simultané (soit 35 000 spectateurs, puis 50
                  000...)
                </li>
                <li>
                  Déploiement de serveurs dans plus de pays à travers le monde
                  afin de diminuer les temps de latence (chargements plus
                  rapides) et améliorer la résilience en cas de panne, attaque
                  ou censure
                </li>
              </ul>
            </li>
            <li>
              Agrandissement de l&#39;équipe technique afin d&#39;accélérer les
              développements continus
            </li>
            <li>
              Création ou consolidation d'autres postes : community management,
              support client, juridique...
            </li>
          </ul>
        </div>
        <div class="d-flex justify-center my-10">
          <v-btn
            color="success"
            large
            @click="$store.state.donationDialog = true"
            >Je fais un don
          </v-btn>
        </div>
        <div>
          <div class="text-h4" id="le-contexte-de-la-gen-se-de-crowdbunker">
            Le contexte de la genèse de CrowdBunker
          </div>
          <v-row>
            <v-col sm="6">
              <p>
                Il ne vous a sans doute pas échappé que depuis bientôt deux ans,
                <strong>les grandes plateformes mainstream</strong> - également
                appelées GAFAM ou big techs -
                <strong
                  >ont définitivement enterré notre liberté
                  d&#39;expression.</strong
                >
              </p>
              <p>
                En effet, sans la complicité active des GAFAM (ainsi que celle
                des médias officiels, mis en avant par ces plateformes), il
                n&#39;aurait pas été possible pour les gouvernements du monde de
                nous mener à un tel niveau de délitement de nos droits et
                libertés.
              </p>
              <p>
                En fait c&#39;est <b>notre dépendance</b> aux GAFAM qui a signé
                l&#39;arrêt de mort de nos libertés.
              </p>
            </v-col>
            <v-col sm="6">
              <v-img src="/images/fund/mask-emoji.png" width="350px"></v-img>
            </v-col>
          </v-row>
          <div
            class="text-h5"
            id="subrepticement-ils-ont-tu-la-libert-d-expression-"
          >
            Subrepticement, ils ont tué la liberté d&#39;expression.
          </div>
          <p>Et la liberté d&#39;opinion.</p>
          <p>Et la liberté de s&#39;informer.</p>
          <p>
            Et la liberté d&#39;exercer la médecine ou de faire de la recherche
            scientifique - qui ne peut pourtant se développer qu&#39;au travers
            de la diversité et la confrontation des points de vue.
          </p>
          <blockquote>
            <p>
              Tant de libertés pourtant fondamentales dont on doit désormais
              faire le deuil car notre droit à la liberté d&#39;expression a
              disparu...
            </p>
          </blockquote>
          <div class="text-h5" id="comment-a-t-on-pu-en-arriver-l-">
            Comment a-t-on pu en arriver là ?
          </div>
          <p>La réponse est simple : <strong>notre accord tacite.</strong></p>
          <p>
            Ce sont nos choix individuels qui nous ont amené à cette situation.
          </p>
          <p>
            Nous avons choisi -
            <b>par défaut, facilité ou absence d&#39;alternative</b> - de
            confier toutes nos interactions en ligne à des multinationales (pour
            certaines plus puissantes que bien des gouvernements) qui n&#39;ont
            d&#39;autres intérêts que ceux de leurs actionnaires et annonceurs.
          </p>
          <p>
            Car oui, YouTube, Facebook, Twitter et leurs semblables sont des
            entreprises privées et si elles proposent soi-disant
            &quot;gratuitement&quot; la majeure partie de leurs services,
            c&#39;est parce qu&#39;<strong
              >elles tirent quasiment 100% de leurs revenus de la publicité et,
              surtout, d&#39;un seul produit : VOUS.</strong
            >
          </p>
          <div
            class="text-h5"
            id="vous-et-vos-donn-es-tes-le-produit-de-ces-multinationales-"
          >
            Vous et vos données êtes le produit de ces multinationales.
          </div>
          <p><strong>Sans vos données, les GAFAM ne sont rien.</strong></p>
          <p>
            <strong
              >En leur fournissant une multitude de données nous concernant
              depuis plusieurs années, nous avons contribué à construire leur
              position dominante.</strong
            >
          </p>
          <p>
            Directement ou indirectement, elles vendent nos données personnelles
            à des annonceurs et peuvent même les mettre à la disposition de
            certains gouvernements pour &quot;ficher&quot; leurs citoyens ou les
            monter les uns contre les autres.
          </p>
          <p>
            Il est ainsi très facile pour de grosses entreprises, des
            gouvernements ou des lobbies de mettre la pression sur ces
            plateformes pour s&#39;assurer qu&#39;elles respectent leurs
            intérêts.
          </p>
          <p>
            Souvenez-vous par exemple de
            <a
              href="https://www.novethic.fr/lexique/detail/cambridge-analytica.html"
              target="_blank"
              >l&#39;affaire Cambridge Analytica avec Facebook</a
            >...
          </p>
          <div
            class="text-h5"
            id="c-est-notre-d-pendance-aux-gafam-qui-a-sign-l-arr-t-de-mort-de-nos-libert-s-"
          >
            C&#39;est notre dépendance aux GAFAM qui a signé l&#39;arrêt de mort
            de nos libertés.
          </div>
          <p>
            La qualité de nos vies modernes est aujourd&#39;hui directement liée
            à notre capacité à accéder à Internet ainsi qu&#39;aux nombreux
            services qu&#39;on y trouve pour nous permettre d&#39;interagir avec
            le monde.
          </p>
          <v-alert type="info" color="secondary lighten-1" dark>
            L&#39;ubiquité de Google pour nos recherches, Gmail pour nos
            e-mails, Twitter et Facebook pour nos besoins d&#39;information et
            d&#39;interaction sociale, YouTube pour les vidéos, LinkedIn pour
            nos relations professionnelles... a créé
            <b
              >une dépendance massive de nos sociétés modernes aux entreprises
              qui fournissent ces services.</b
            >
          </v-alert>
          <div class="text-h5" id="ces-entreprises-ne-nous-respectent-pas-">
            Ces entreprises ne nous respectent pas.
          </div>
          <p>
            Si ces entreprises nous respectaient, il n&#39;y aurait pas de
            problème à continuer à utiliser leurs services.
          </p>
          <p>
            Mais force est de constater que ce n&#39;est pas le cas. Nos vies
            ont peu de valeur aux yeux de ces titans technologiques qui nous
            exploitent tels de vulgaires produits qu&#39;ils vendent à leurs
            annonceurs.
          </p>
          <p>
            Et pourtant,
            <strong
              >nous continuons d&#39;utiliser leurs services, jour après
              jour</strong
            >.
          </p>
          <p>
            Pourquoi ? Certainement car nous n&#39;avons
            <strong
              >pas vraiment d&#39;alternative suffisamment satisfaisante</strong
            >
            à leur écrasante domination...
          </p>
          <div class="text-h4" id="y-a-t-il-une-solution-">
            Y a-t-il une solution ?
          </div>
          <p>
            Les seules solutions pour s&#39;extirper de l&#39;ornière actuelle
            seraient de :
          </p>
          <ul>
            <li>
              Soit renoncer
              <b>totalement à utiliser toute technologie</b> qui risquerait de
              nous créer une quelconque dépendance à l&#39;égard d&#39;une
              entreprise privée; c&#39;est RADICAL !
            </li>
            <li>
              Soit parvenir à
              <b
                >moraliser les entreprises qui nous fournissent actuellement
                leurs services</b
              >, afin qu&#39;elles respectent véritablement nos droits et nos
              libertés; c&#39;est ILLUSOIRE !
            </li>
            <li>
              Soit créer
              <b
                >nous-mêmes des entreprises authentiquement au service de leurs
                utilisateurs</b
              >, qui puissent remplacer les GAFAM; c&#39;est POSSIBLE, mais ARDU
              !
            </li>
          </ul>
          <p>
            De toute évidence, aucune de ces solutions n&#39;est aisée. Nous
            nous sommes désormais collectivement habitués à la facilité
            d&#39;utiliser les services soi-disant &quot;gratuits&quot; fournis
            par les GAFAM.
          </p>
          <p>
            Or, n&#39;oublions pas, ils ne sont pas gratuits, mais financés par
            nous tous, d&#39;une manière opaque et inféodée à des intérêts
            autres que les nôtres.
          </p>
          <p><strong>Et le coût, c&#39;est notre Liberté.</strong></p>
          <div
            class="text-h5"
            id="il-y-a-imp-rieusement-besoin-d-une-plate-forme-alternative-"
          >
            Il y a impérieusement besoin d&#39;une plate-forme alternative.
          </div>
          <v-alert class="my-2" color="secondary lighten-1" type="info" dark>
            Si l&#39;une des principales causes du délitement de nos droits
            aujourd&#39;hui est la non-indépendance des plateformes numériques
            que nous utilisons, pourquoi alors ne pas co-construire tous
            ensemble la première plateforme indépendante de libre-expression,
            100% financée par ses utilisateurs et fondée sur des principes
            clairs et transparents ?
          </v-alert>

          <p><em>L&#39;argent est, comme on dit, le nerf de la guerre.</em></p>
          <p>
            Les GAFAM ont accumulé de véritables butins et occupent des
            monopoles de situation au niveau planétaire. De fait, il semble
            quasiment impossible, aujourd&#39;hui, d&#39;envisager des
            alternatives viables qui puissent être aussi largement adoptées.
          </p>
          <div class="text-h5" id="alors-comment-nous-sortir-de-leur-emprise-">
            Alors comment nous sortir de leur emprise ?
          </div>
          <p>
            Vous diriez certainement : &quot;il faudrait beaucoup de temps,
            d&#39;argent et de compétences techniques. Ca ne court pas les
            rues.&quot;
          </p>
          <p>
            Vous diriez peut-être aussi &quot;À qui s&#39;adresser ? Par où
            commencer pour créer une alternative aux GAFAM ?&quot;.
          </p>
          <p>
            Et &quot;Sous quelle forme juridique cette plate-forme pourrait-elle
            exister ?...&quot;
          </p>
          <div
            class="text-h5"
            id="eh-bien-nous-avons-une-bonne-nouvelle-cette-plateforme-existe-d-j-"
          >
            Eh bien nous avons une bonne nouvelle : cette plateforme existe déjà
            !
          </div>
          <v-alert type="info" color="secondary lighten-1" dark>
            Du moins,
            <b>l&#39;essentiel à la diffusion de vidéos</b> a déjà été développé
            et fonctionne remarquablement bien, avec des centaines de vidéos
            uploadées ou streamées en live tous les jours.
          </v-alert>
          <p>
            Cette plate-forme s&#39;appelle - vous vous en doutez 😉 -
            <strong>CrowdBunker,</strong> le bien-nommé &quot;bunker des
            foules&quot; ; dont la raison d&#39;être, dès ses premiers jours de
            développement en novembre 2020,
            <strong>en plein milieu de la folie censoriale mondiale,</strong>
            fut d&#39;offrir un havre protecteur à la liberté d&#39;expression
            là où, presque partout ailleurs, elle était violemment malmenée.
          </p>
          <div class="d-flex justify-center my-10">
            <v-btn
              color="success"
              large
              @click="$store.state.donationDialog = true"
              >Je fais un don
            </v-btn>
          </div>
          <div
            class="text-h5"
            id="crowdbunker-un-projet-profond-ment-humain-dont-la-libre-expression-est-l-adn"
          >
            CrowdBunker : un projet dont la libre-expression est l&#39;ADN
          </div>
          <p>
            CrowdBunker est un projet profondément humain, pensé autour d&#39;un
            pilier fondamental : la liberté d&#39;expression.
          </p>
          <p>
            Dès lors que cette liberté est malmenée et la diversité
            d&#39;opinion censurée, le risque devient grand, pour une société,
            de sombrer dans l&#39;obscurantisme et le totalitarisme. Car à la
            source de tous les autres droits fondamentaux, la liberté
            d&#39;expression est toujours le ferment inaliénable d&#39;une
            société d&#39;éveil et de conscience.
          </p>
          <p>
            <strong
              >Aujourd&#39;hui, l&#39;enjeu fondamental pour notre
              Humanité</strong
            >
            est bien de pouvoir
            <strong
              >recommencer à nous exprimer librement et sans censure,</strong
            >
            au plus vite.
          </p>
          <p>
            Sur CrowdBunker, tout est donc pensé afin que les opinions les plus
            diverses soient sincèrement libres de venir s&#39;y exprimer et
            qu&#39;un débat rationnel, scientifique et vertueux puisse émerger
            de notre Intelligence collective, sans risque de se voir censuré par
            la doxa de la pensée unique.
          </p>
          <p>
            Pour nous, ce n&#39;est que par la libre-expression - dans la limite
            de la légalité bien-sûr - qu&#39;il est possible, pour nos
            semblables, d&#39;accéder à une information non biaisée, permettant
            de prendre des décisions éclairées, justes pour eux et pour autrui.
          </p>
          <p>
            Notre projet ayant avant toute une vocation profondément humaniste,
            ce droit fondamental, la liberté d&#39;expression restera
            <b>TOUJOURS</b>
            le cœur de CrowdBunker. ❤️
          </p>
          <div
            class="text-h5"
            id="voil-comment-la-libert-peut-d-ores-et-d-j-s-exprimer-sur-crowdbunker-"
          >
            Voilà comment la liberté peut d&#39;ores et déjà s&#39;exprimer sur
            CrowdBunker.
          </div>
          <p>
            À ce jour, voici un aperçu de quelques-unes des fonctionnalités
            majeures déjà développées :
          </p>
          <ul>
            <li>
              Synchroniser les vidéos d&#39;une chaîne YouTube sur CrowdBunker ;
            </li>
            <li>Protéger ses vidéos YouTube de la censure ;</li>
            <li>
              Créer une chaîne CrowdBunker et publier des vidéos directement
              dessus;
            </li>
            <li>
              Informer par mail les abonnés à une chaîne à chaque nouvelle
              publication de vidéo;
            </li>
            <li>Publier du texte sur CrowdBunker ;</li>
            <li>Faire des émissions en live sur CrowdBunker ;</li>
            <li>Commenter, liker, chatter ;</li>
            <li>
              Utiliser CrowdBunker dans plusieurs langues (seul l&#39;allemand a
              été totalement traduit à ce jour mais l&#39;anglais est pour
              bientôt).
            </li>
          </ul>
          <p>
            De nouvelles fonctionnalités sont régulièrement ajoutées au gré de
            ce que nos moyens financiers limités nous permettent de faire.
          </p>
          <div class="text-h5" id="mais-ce-n-est-que-le-d-but-">
            Mais ce n&#39;est que le début !
          </div>
          <p>
            En moins d&#39;un an d&#39;existence,
            <strong
              >CrowdBunker est déjà une plate-forme vidéo relativement stable
              avec certaines fonctionnalités dignes d&#39;une
              multinationale</strong
            >
            dotée de millions de dollars de budget et de larges équipes.
          </p>
          <p>
            Or il n&#39;en est rien : CrowdBunker est une initiative née
            organiquement de l&#39;impérieuse nécessité de libérer notre
            expression. C&#39;est sur la seule base du volontariat et du don de
            son équipe et de ses utilisateurs, qu&#39;elle se construit depuis,
            brique par brique.
          </p>
          <p>
            Grâce à cette démarche de pure abnégation, des milliers
            d&#39;auditeurs en simultané peuvent déjà visionner, à l&#39;abri de
            toute censure et avec une expérience d&#39;utilisation relativement
            fluide, des vidéos importantes leur permettant de se forger une
            opinion propre sur le monde dans lequel nous vivons.
          </p>
          <div class="text-h5" id="quelques-faits-importants-sur-crowdbunker">
            Quelques faits importants sur CrowdBunker
          </div>
          <p>
            Nous sommes convaincus que CrowdBunker est un projet de la plus
            haute importance pour l&#39;avenir de notre Humanité.
          </p>
          <p>Voici quelques faits notables au sujet de ce projet :</p>
          <ul>
            <li>
              Tous les développements de CrowdBunker ont été réalisés par
              <strong>un seul développeur</strong>, qui a consacré bénévolement
              au départ des centaines d&#39;heures de son temps à ce projet.
            </li>
            <li>
              À ce jour, CrowdBunker a <b>donné</b> -sans aucune demande de
              contrepartie- la possibilité à ses utilisateurs de
              s&#39;exprimer/s&#39;informer pour prendre des décisions
              éclairées. Absolument tout a été gratuit jusque-là, tant pour les
              spectateurs que pour les producteurs de contenu.
            </li>
            <li>
              Jusqu'en 2023, CrowdBunker a
              <strong
                >compté uniquement sur la responsabilité de ses
                utilisateurs</strong
              >
              pour financer ses frais de fonctionnement et d&#39;investissement.
              Seule la vente d'un documentaire en 2022 est venue compléter ce
              financement.
            </li>
            <li>
              <strong
                >Aucune publicité n&#39;est proposée sur CrowdBunker</strong
              >. Les données des utilisateurs ne sont pas et ne seront jamais
              exploitées à des fins commerciales.
            </li>
            <li>
              Lorsque les dons n&#39;ont pas été suffisants pour couvrir les
              coûts mensuels de fonctionnement de CrowdBunker,
              <strong
                >les frais ont été complétés par le développeur lui-même</strong
              >.
            </li>
            <li>
              Chaque mois, CrowdBunker comptabilisé au moins
              <strong>un million et demi de visiteurs uniques.</strong>
            </li>
            <li>
              Plus de 3500 organisations ont été créées à ce jour sur
              CrowdBunker.
            </li>
            <li>
              Pas moins de 120 000 utilisateurs sont inscrits sur CrowdBunker.
            </li>
            <li>
              Plus de 100 000 vidéos sont actuellement protégées contre la
              censure.
            </li>
            <li>
              Jusqu'à 60 000 emails sont envoyés quotidiennement pour informer
              les utilisateurs des publications de nouvelles vidéos (en direct)
              sur les chaînes auxquelles ils sont abonnés.
            </li>
          </ul>
          <div
            class="text-h5"
            id="crowdbunker-com-a-le-potentiel-de-lib-rer-d-finitivement-notre-parole-"
          >
            CrowdBunker.com a le potentiel de libérer définitivement notre
            parole. ✊
          </div>
          <p>
            En ligne, la vidéo est le média roi, bien plus consommé que les
            images ou le texte. Cela n&#39;a pas échappé aux ennemis de nos
            libertés.
          </p>
          <p>
            C&#39;est pourquoi les GAFAM, YouTube en tête, censurent à tour de
            bras les vidéos qu&#39;ils ne souhaitent pas voir circuler...
          </p>
          <p>
            Il est néanmoins difficile de se passer d&#39;eux pour la vidéo, car
            ce média nécessite des ressources -notamment financières-
            importantes. C&#39;est ce qui empêche d&#39;autres acteurs plus
            modestes de proposer des solutions alternatives pour la vidéo du
            même niveau de performance et de fonctionnalité que YouTube.
          </p>
          <p>
            Et pourtant,
            <strong
              >c&#39;est ce que CrowdBunker a commencé à faire : créer une
              plateforme vidéo alternative avec une qualité de diffusion
              comparable</strong
            >
            à YouTube et Facebook, pour une fraction du coût et, surtout, sans
            leur ignominieuse censure.
          </p>
          <p>
            Voilà donc ce qui donne à CrowdBunker tout son potentiel
            <strong>pour libérer définitivement notre parole :</strong> la
            <strong>fonctionnalité vidéo reste le strict minimum</strong> pour
            résister à la censure aveugle des GAFAM. Développée nativement sur
            CrowdBunker malgré nos modestes moyens, il est ainsi possible de
            présager du meilleur pour la suite.🎥🥳. En effet, la plupart des
            autres fonctionnalités dont on pourrait rêver sur une plateforme de
            libre-expression sont bien moins coûteuses à mettre en œuvre que la
            vidéo.
          </p>
          <p>
            Mais, de toute évidence, nous ne comptons pas nous contenter du
            minimum...
            <strong
              >Nous avons de très grands projets pour protéger notre liberté
              d&#39;expression et d&#39;information grâce à CrowdBunker.
              😉🚀</strong
            >
            C&#39;est pourquoi nous avons besoin de vous ! ✊
          </p>
          <div class="d-flex justify-center my-10">
            <v-btn
              color="success"
              large
              @click="$store.state.donationDialog = true"
              >Je fais un don
            </v-btn>
          </div>
          <div class="text-h4" id="quels-sont-nos-principes-">
            Quels sont nos principes ?
          </div>
          <div
            class="text-h5"
            id="la-libert-d-expression-et-d-opinion-pour-de-vrai-"
          >
            La liberté d&#39;expression et d&#39;opinion, pour de vrai.
          </div>
          <p>
            En France, comme dans toutes les nations réputées libres du monde,
            seul ce qui est défendu par la Loi est interdit. Tout le reste, dès
            lors que ce n&#39;est pas défendu par la Loi, est autorisé.
          </p>
          <blockquote>
            <p>
              La libre communication des pensées et des opinions est un des
              droits les plus précieux de l&#39;Homme : tout Citoyen peut donc
              parler, écrire, imprimer librement, sauf à répondre de l&#39;abus
              de cette liberté dans les cas déterminés par la Loi.
              <em
                >Article 11,
                <a
                  href="https://www.legifrance.gouv.fr/contenu/menu/droit-national-en-vigueur/constitution/declaration-des-droits-de-l-homme-et-du-citoyen-de-1789"
                  >Déclaration des Droits de l&#39;Homme et du Citoyen de
                  1789</a
                >.</em
              >
            </p>
          </blockquote>
          <p>
            La libre-expression est un droit sacré et inaliénable que nul ne
            devrait pouvoir nous ôter pour quelque raison que ce soit. Alors
            demandons-nous :
          </p>
          <v-alert type="warning" color="primary lighten-1" dark>
            Qui a le droit d&#39;interdire à un individu de s&#39;exprimer dès
            lors qu&#39;il respecte le cadre légal ?
          </v-alert>
          <v-alert type="warning" color="primary lighten-1" dark>
            De quel droit peut-on interdire à une personne d&#39;accéder
            légalement à des informations, pour se forger sa propre opinion, sur
            un sujet le concernant ?
          </v-alert>
          <p>
            Ce n&#39;est pas à une plate-forme sur Internet, aussi ubiquitaire
            soit-elle :
          </p>
          <ul>
            <li>de déterminer ce qui est vrai de ce qui est faux,</li>
            <li>de juger de qui a le droit de s&#39;exprimer ou non,</li>
            <li>
              d&#39;octroyer le droit d&#39;accéder ou pas à telle ou telle
              information.
            </li>
          </ul>
          <p>
            Ce n&#39;est pas non plus le rôle d&#39;une entreprise privée de se
            substituer aux cours de justice d&#39;un pays, d&#39;autant plus
            quand il s&#39;agit d&#39;un droit aussi fondamental que la liberté
            d&#39;expression.
          </p>
          <div
            class="text-h5"
            id="sur-crowdbunker-la-loi-est-r-put-e-faire-foi-"
          >
            Sur CrowdBunker, la Loi est réputée faire foi.
          </div>
          <p>
            Et ça devrait être le cas partout aussi.
            <strong
              >Chaque être humain a le droit d&#39;exprimer ses idées</strong
            >, qu&#39;il ait un BAC+10 dans son domaine ou qu&#39;il soit
            autodidacte, qu&#39;il soit humoriste ou politicien, infirmier ou
            serveur dans un restaurant, que ses opinions plaisent à la majorité
            ou non, qu&#39;il soit adulé ou décrié.
          </p>
          <v-alert type="info" color="secondary lighten-1" dark>
            <b
              >Toute personne devrait être libre de s&#39;exprimer, qu&#39;elle
              soit jugée qualifiée pour le faire ou non, dès lors que son propos
              n&#39;enfreint aucune loi.</b
            >
          </v-alert>
          <p>
            Sincèrement, qui pourrait prétendre être expert sur tous les sujets
            pour évaluer qu&#39;un autre est suffisamment qualifié pour
            s&#39;exprimer ?😵
          </p>
          <p>
            Le prétexte d&#39;une pseudo-lutte contre la désinformation est en
            réalité une guerre contre l&#39;information.
          </p>
          <div
            class="text-h5"
            id="la-responsabilit-individuelle-plut-t-que-la-censure-"
          >
            La responsabilité individuelle plutôt que la censure.
          </div>
          <p>
            Il serait bien illusoire de vouloir croire qu&#39;une quelconque
            entreprise privée, aussi bien-intentionnée soit-elle, serait en
            mesure de s&#39;ériger en juge impartial de toutes les situations
            ayant trait à la liberté d&#39;expression, tout particulièrement si
            elle use, pour cela, d&#39;algorithmes informatiques incapables de
            faire la différence entre ce qui relève, par exemple, de
            l&#39;humour et ce qui est signifié littéralement.
          </p>
          <p><strong>La censure est toujours un fléau.</strong></p>
          <p>
            Ceci étant dit, afin d&#39;offrir un espace de partage où chacun
            peut se sentir libre et respecté, la responsabilité individuelle de
            chaque utilisateur est de mise. Si certains utilisateurs s&#39;en
            éloignent, une certaine forme de modération des contenus partagés
            est alors nécessaire afin de s&#39;assurer le respect de la Loi et
            des règles de bienséance.
          </p>
          <p>
            <b>Ainsi sont notamment proscrits sur CrowdBunker :</b>
          </p>
          <ul>
            <li>les contenus appelant à la violence ;</li>
            <li>la violence, le harcèlement ou la cyberintimidation ;</li>
            <li>la pornographie ou nudité ;</li>
            <li>l&#39;atteinte aux droits d&#39;auteurs.</li>
          </ul>
          <p>
            Pour aller encore plus loin dans notre démarche garantissant la
            liberté d&#39;expression, nous sommes en train de développer un
            <strong>système de modération participatif</strong> qui sera
            présenté très prochainement au public afin que chacun puisse prendre
            part au maintien sur CrowdBunker d&#39;une atmosphère cordiale et
            respectueuse des sensibilités de chacun, sans l&#39;intervention
            arbitraire d&#39;algorithmes de modération.
          </p>
          <div class="text-h5" id="le-respect-des-donn-es-personnelles-">
            Le respect des données personnelles.
          </div>
          <p>
            Vos données personnelles vous appartiennent. C&#39;est pourquoi sur
            CrowdBunker, il ne vous sera jamais demandé plus d&#39;informations
            que nécessaire.
          </p>
          <p>
            <b
              >Nous sommes libres -et comptons absolument le rester- de tout
              lien avec des annonceurs.</b
            >
          </p>
          <p>
            Vos données personnelles ne seront donc jamais vendues, louées ou
            cédées à des fins publicitaires ou commerciales.
          </p>
          <p>
            Preuve en est : pour créer un compte,
            <strong
              >seuls une adresse e-mail et un nom d&#39;utilisateur sont
              demandés</strong
            >, pas même votre prénom ou votre nom*.
          </p>
          <p>
            <b>Nous n&#39;appliquons pas non plus de suivi (cookies...)</b>
            au-delà du strict nécessaire au bon fonctionnement de la plateforme.
          </p>
          <p>
            <em
              >* Certaines fonctionnalités spécifiques comme la réception de
              dons peuvent nécessiter des informations légales
              supplémentaires.</em
            >
          </p>
          <div class="text-h4" id="faq">FAQ</div>
          <div class="text-h5" id="quid-d-odysee-rumble-peertube-">
            Quid d&#39;Odysee, Rumble, PeerTube... ?
          </div>
          <p>
            Tout d&#39;abord, l&#39;objectif de CrowdBunker n&#39;est pas
            d&#39;être simplement une alternative à YouTube. Nous souhaitons
            foncièrement créer une plateforme dédiée à la liberté
            d&#39;expression, de manière bien plus large qu&#39;une simple
            plateforme vidéo.
          </p>
          <p>
            CrowdBunker est vraiment quelque chose de nouveau, qui regroupera
            les meilleures fonctionnalités des réseaux sociaux et des
            plateformes de streaming, tout en y apportant des innovations que
            l&#39;on ne trouvera nulle part ailleurs.
          </p>
          <p>
            Par ailleurs, de par notre dévotion clairement affichée à la liberté
            d&#39;expression, vous trouverez bien plus de contenus à caractère
            informatif ou engagé que de vidéos de chats ou de gaming.
          </p>
          <p>
            Il est probable que les gens iront bien plus sur CrowdBunker pour
            s&#39;informer, débattre ou s&#39;exprimer plutôt que pour se
            divertir. Cela n&#39;empêche en rien l&#39;interconnectivité entre
            les plateformes.
          </p>
          <p>
            Enfin, nous croyons qu&#39;un média indépendant, un lanceur
            d&#39;alerte, un humoriste engagé ou un scientifique (par exemple)
            trouvera bien plus sa place sur une plateforme qui a été
            spécifiquement conçue pour ses besoins.
          </p>
          <div
            class="text-h5"
            id="qu-est-ce-qui-garantit-que-crowdbunker-ne-va-pas-devenir-comme-les-autres-"
          >
            Qu&#39;est-ce qui garantit que CrowdBunker ne va pas devenir comme
            les autres ?
          </div>
          <p>
            <em>&quot;C&#39;est celui qui finance le patron&quot;.</em> En
            optant pour un financement participatif, cette plateforme nous
            appartient à tous. Si demain CrowdBunker venait à changer ses
            valeurs, les utilisateurs mécontents n&#39;auraient qu&#39;à stopper
            leur contribution.
          </p>
          <p>
            De plus, plusieurs développements en préparation sont là pour
            fournir quelques garde-fous, comme par exemple la décentralisation
            des contenus (CrowdBunker ne sera tout simplement pas en mesure de
            supprimer complètement des contenus) ou encore la modération
            participative.
          </p>
          <p>
            Enfin, nous sommes parfaitement lucides sur le fait que l&#39;argent
            est le moyen par lequel nos sociétés ont été asservies. Si nous
            pouvions nous en passer à ce stade, nous le ferions.
            Malheureusement, il ne nous est pas possible d&#39;opérer ce genre
            de service au niveau auquel nous aspirons sans en avoir les moyens
            financiers.
          </p>
          <p>
            N&#39;oublions pas : notre raison d&#39;être n&#39;est pas de gagner
            des millions, encore moins des milliards, mais bel et bien de VIVRE.
            <strong>Aucune somme d&#39;argent ne vaut notre Vie.</strong>
          </p>
          <div class="d-flex justify-center my-10">
            <v-btn
              color="success"
              large
              @click="$store.state.donationDialog = true"
              >Je fais un don
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconBlock from '@/components/fund/IconBlock'
import CampaignProgress from '@/components/CampaignProgress'

export default {
  name: 'Fund',
  components: { IconBlock },
  metaInfo() {
    return {
      title: 'LIBÉRONS-NOUS - CrowdBunker',
      meta: [
        {
          name: 'description',
          vmid: 'description',
          content:
            'Finançons la plateforme indépendante de libre-expression dont nous avons tous besoin.',
        },
        {
          property: 'og:description',
          vmid: 'og:description',
          content:
            'Finançons la plateforme indépendante de libre-expression dont nous avons tous besoin.',
        },
        {
          property: 'og:title',
          vmid: 'og:title',
          content: 'LIBÉRONS-NOUS - CrowdBunker',
        },
        {
          property: 'twitter:title',
          vmid: 'twitter:title',
          content: 'LIBÉRONS-NOUS - CrowdBunker',
        },
        {
          property: 'og:image',
          vmid: 'og:image',
          content: 'https://crowdbunker.com/images/fund/liberons-nous.png',
        },
        {
          property: 'twitter:image',
          vmid: 'twitter:image',
          content: 'https://crowdbunker.com/images/fund/liberons-nous.png',
        },
        {
          property: 'og:site_name',
          vmid: 'og:site_name',
          content: 'CrowdBunker',
        },
        { property: 'og:type', vmid: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    }
  },
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

.app-title {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 900;
}

.text-h4,
.text-h5,
.text-h6,
.caption {
  font-family: 'Montserrat', sans-serif !important;
}

.main-body {
  //font-weight: 300;
  font-family: 'Montserrat', sans-serif;

  b {
    font-weight: 500;
  }

  strong {
    color: var(--v-primary-base);
  }

  .text-h5 {
    margin-top: 3rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
  }

  .text-h4 {
    margin-top: 3.5rem;
    font-weight: 700;
  }

  blockquote {
    border-left: 2px white solid;
    padding-left: 15px;
  }
}

.v-carousel {
  ::v-deep {
    .v-window__next,
    .v-window__prev {
      background-color: var(--v-primary-base);
    }
    .v-carousel__controls {
      .v-btn.v-btn--icon {
        color: var(--v-primary-base);
      }
    }
  }
}
</style>
