var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.deleted),expression:"!deleted"}],class:{ 'publicly-hidden': _vm.publiclyHidden }},'v-col',_vm.$attrs,false),[(_vm.post?.uid)?_c(_vm.component,{tag:"component",staticClass:"text-decoration-none d-block",class:{ 'pl-12': _vm.sub },attrs:{"to":_vm.link}},[_c('v-card',{staticClass:"transparent",class:{ censored: _vm.censored, sub: _vm.sub },attrs:{"width":"100%","elevation":"0"}},[_c('v-card-title',{staticClass:"pa-0"},[(!_vm.noChannel)?_c('v-list-item',{staticClass:"px-0 channel-block-list-item"},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-subtitle',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"flex-grow-1 text-truncate"},[(_vm.creator)?_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":_vm.channelLink || {},"tag":_vm.channelLink ? 'a' : 'div'}},[_c('v-sheet',{staticClass:"align-center channel-block rounded-lg",class:[
                      _vm.large ? 'd-inline-flex' : 'd-flex',
                      _vm.large ? 'pa-1' : '',
                    ]},[_c('channel-avatar',{key:_vm.channelName,attrs:{"thumbnails":_vm.channelThumbnails,"channel-name":_vm.channelName,"size":30,"bg-color":_vm.creator.avatarColor ? _vm.creator.avatarColor : 'primary'}}),_vm._v(" "),_c('div',{staticClass:"ml-2 text-truncate",attrs:{"title":_vm.channelTooltip}},[_vm._v("\n                      "+_vm._s(_vm.channelName)+"\n                    ")]),_vm._v(" "),(
                        !!_vm.post.channel?.organization?.ownerMembershipLevel
                      )?_c('v-icon',{staticClass:"ml-1",attrs:{"color":_vm.getColor(
                          _vm.post.channel?.organization?.ownerMembershipLevel,
                        ),"size":"20"}},[_vm._v("mdi-seal")]):_vm._e(),_vm._v(" "),(_vm.getPlatformIcon(_vm.post))?_c('div',{staticClass:"ml-auto"},[_c('v-icon',{staticClass:"mx-1",attrs:{"small":"","color":_vm.getPlatformIcon(_vm.post).color},domProps:{"textContent":_vm._s(_vm.getPlatformIcon(_vm.post).iconName)}})],1):_vm._e()],1)],1):_vm._e()],1),_vm._v(" "),(_vm.large && _vm.video)?_c('v-btn',{staticClass:"ml-2 flex-shrink-0",attrs:{"to":{ name: 'VideoPost', params: { slug: _vm.post.uid } },"outlined":"","small":"","color":"primary"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-theater")]),_vm._v("\n                "+_vm._s(_vm.$tc('button.open'))+"\n              ")],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"ml-2 flex-shrink-0"},[(!_vm.isDeletedButNotHidden)?_c('post-menu',{attrs:{"post":_vm.post,"listName":_vm.listName},on:{"deleted":_vm.postDeleted}}):_vm._e()],1)],1)],1)],1):_vm._e()],1),_vm._v(" "),(_vm.video && !_vm.videoMeta)?_c('video-inset',{attrs:{"post":_vm.post,"video":_vm.video,"large":_vm.large,"hidden":_vm.publiclyHidden,"no-inner-player":_vm.forceOpenPost},on:{"update:video":function($event){_vm.video=$event}}}):(_vm.video && _vm.videoMeta)?_c('video-with-meta',{attrs:{"post":_vm.post}}):(
          (Array.isArray(_vm.post.images) && _vm.post.images.length) ||
            ['image', 'image_and_text'].includes(_vm.post.mediaType)
        )?_c('images-inset',{attrs:{"post":_vm.post,"large":_vm.large}}):(_vm.post.linkMetadata)?_c('snippet-inset',{attrs:{"post":_vm.post,"large":_vm.large}}):_c('text-post-inset',{attrs:{"text":_vm.post.textContent,"large":_vm.large,"uid":_vm.post.uid,"force-open-post":_vm.forceOpenPost}}),_vm._v(" "),_c('v-card-text',{staticClass:"subtitle-2 text-break py-1 px-0"},[_c('div',{staticClass:"px-0 list-text-block"},[_c('div',{staticClass:"py-0 d-flex"},[_c('div',{staticClass:"font-weight-light caption flex-grow-1 align-center d-flex post-details flex-wrap"},[(_vm.publiclyHidden && _vm.visibility)?_c('span',[_vm._v(_vm._s(_vm.visibility))]):_vm._e(),_vm._v(" "),(
                  _vm.video &&
                    ['live_created', 'live_scheduled'].includes(_vm.video.status)
                )?_c('span',[_vm._v("\n                "+_vm._s(_vm.utilsService.formatScheduledTime(
                    _vm.video.liveStreamingDetails?.scheduledStartTime,
                  ))+"\n              ")]):_vm._e(),_vm._v(" "),(
                  _vm.video &&
                    ['live_created', 'live_scheduled', 'live'].includes(
                      _vm.video.status,
                    ) &&
                    typeof _vm.post.viewerCount === 'number'
                )?_c('span',[_vm._v(_vm._s(_vm.formatViews(_vm.post.viewerCount))+"\n                "+_vm._s(_vm.$tc('video.spectator', _vm.post.viewerCount || 1))+"\n                "+_vm._s(['live_created', 'live_scheduled'].includes(_vm.video.status)
                    ? ' ' + _vm.$tc('live.waiting')
                    : ''))]):(_vm.viewCount)?_c('span',[_vm._v(_vm._s(_vm.formatViews(_vm.viewCount))+_vm._s(' ' + _vm.$tc('video.view', _vm.viewCount)))]):_vm._e(),_vm._v(" "),(_vm.post.censoredAt)?_c('span',[_vm._v(_vm._s(_vm._f("capitalize")((_vm.$tc('censored.censored') +
                  ' ' +
                  _vm.formatDateRelative(new Date(_vm.post.censoredAt), true)))))]):(
                  !_vm.video ||
                    !['live_created', 'live_scheduled'].includes(_vm.video.status)
                )?_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.formatDateRelative(new Date(_vm.post.publishedAt), true))))]):_vm._e()]),_vm._v(" "),(!_vm.isDeletedButNotHidden)?[(_vm.large)?_c('post-voter',{attrs:{"post":_vm.post}}):_vm._e(),_vm._v(" "),(_vm.post.childrenCount > 0)?_c('div',{staticClass:"d-flex align-center mr-1"},[_c('span',{staticClass:"likes-count d-inline-flex text-no-wrap caption",staticStyle:{"min-width":"10px"},domProps:{"textContent":_vm._s(_vm.post.childrenCount > 0 ? _vm.post.childrenCount : '')}}),_vm._v(" "),_c('v-icon',{staticClass:"mx-1",attrs:{"small":"","color":"grey"}},[_vm._v("mdi-comment-multiple-outline")])],1):_vm._e(),_vm._v(" "),(!_vm.large)?_c('post-votes-percentage',{attrs:{"up-votes":_vm.post.relevanceVoteTotal?.up ?? _vm.post.upVotes,"down-votes":_vm.post.relevanceVoteTotal?.down ?? _vm.post.downVotes}}):_vm._e()]:_vm._e()],2)])])],1)],1):_c('v-card',{attrs:{"flat":""}},[_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar, list-item-three-line, actions"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }