var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(_vm.component,{tag:"component",staticClass:"overflow-hidden align-center rounded-lg",class:{ responsive: !_vm.large, 'secondary lighten-1': !_vm.large },staticStyle:{"position":"relative"},attrs:{"aspect-ratio":_vm.fromList ? 16 / 9 : 320 / 215,"max-height":"100%"}},[_c('div',{staticClass:"pa-4 pt-0 d-flex justify-center text-pre-wrap",class:{
        'align-center': !_vm.isBigTest && !_vm.noCenter,
        'text-center': !_vm.isBigTest,
        'text-wrapper': _vm.large,
      },staticStyle:{"height":"100%"},style:({ fontSize: _vm.fontSize })},[_c('span',{staticClass:"user-text",style:({
          width: _vm.isBigTest ? '100%' : 'none',
        }),domProps:{"innerHTML":_vm._s(_vm.formattedText)},on:{"click":_vm.textClick}})]),_vm._v(" "),(_vm.idState.textTooLong && _vm.large)?_c('a',{staticClass:"read-more px-4 text-right white--text",attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.readMore.apply(null, arguments)}}},[_vm._v("\n      "+_vm._s(_vm._f("capitalize")(_vm.idState.truncate
          ? _vm.$tc('label.read-more')
          : _vm.$tc('label.reduce')))+"\n    ")]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }