<template>
  <div>
    <div class="overflow-hidden" style="position:relative;">
      <div>
        <text-post-preview
          v-if="format === 'card' && !large"
          :post="post"
        ></text-post-preview>
        <text-post-inset
          v-else-if="post.textContent"
          :text="post.textContent"
          :large="large"
          :uid="post.uid"
          no-center
        ></text-post-inset>
        <!--        <div v-else class="text-wrapper px-3" v-html="formattedText"></div>-->
        <component
          :is="component"
          :aspect-ratio="16 / 9"
          max-height="100%"
          class="overflow-hidden responsive"
        >
          <snippet
            v-show="format !== null"
            :post="post"
            :link-metadata="
              typeof post.linkMetadata === 'string'
                ? JSON.parse(post.linkMetadata)
                : post.linkMetadata
            "
            class="snippet"
            :class="{ absolute: !large, 'format-card': format === 'card' }"
            :large="large"
            @format="format = $event"
          ></snippet>
        </component>
      </div>
    </div>
  </div>
</template>

<script>
import Snippet from '@/components/Snippet'
import TextPostInset from '@/components/TextPostInset'
import { VResponsive } from 'vuetify/lib/components'
import TextPostPreview from '@/components/TextPostPreview'
export default {
  name: 'SnippetInset',
  components: { TextPostPreview, TextPostInset, Snippet },
  props: {
    post: { type: Object },
    large: Boolean,
  },
  data() {
    return {
      format: null,
    }
  },
  computed: {
    component() {
      return this.format === 'card' && !this.large ? VResponsive : 'div'
    },
    formattedText() {
      if (
        this.post.textContent === null ||
        this.post.textContent.length === 0
      ) {
        return ''
      }
      const text = this.post.textContent
        .replace(/[\u00A0-\u9999<>&]/g, i => {
          return '&#' + i.charCodeAt(0) + ';'
        })
        .replace(
          /(https?:\/\/\S+)/gim,
          '<a rel="nofollow" class="user-link" target="_blank" href="$1">$1</a>',
        )
        .replace(/(\r\n|\r|\n){3,}/gm, `\n`)
      return text
    },
  },
}
</script>

<style scoped lang="scss">
.snippet {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  &.absolute {
    position: absolute;
    &:after {
      content: '';
      text-align: right;
      position: absolute;
      top: -3.5em;
      right: 0;
      width: 100%;
      height: 3.5em;
      background: linear-gradient(
        to bottom,
        rgba(#002031, 0),
        var(--v-secondary-base) 90%
      );
    }
  }
}
.responsive {
  ::v-deep {
    .v-responsive__content {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
    }
  }

  .read-more {
    position: absolute;
    bottom: 0;
    z-index: 3;
    right: 10px;
  }
}
</style>
