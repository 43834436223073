<template>
  <v-card flat class="flex-fill">
    <v-card-title class="headline">
      {{ $tc('donation.lets-do-it-together') }}
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('close')" v-if="!embed">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="!donationFinished" class="pb-1">
      <div v-html="$tc('donation.need-you-create-platform')"></div>
      <br />
      <h3 class="mb-1">{{ $tc('donation.donate-to-crowdbunker') }}</h3>
      <div class="caption mt-2 text-capitalize">
        {{ $tc('donation.method') }} :
      </div>
      <v-btn
        small
        depressed
        :input-value="tab === 0"
        color="primary"
        class="mr-1 mb-2"
        @click="tab = 0"
        >{{ $tc('label.bank-card') }}
      </v-btn>
      <v-btn
        small
        depressed
        class="mr-1 mb-2"
        color="primary"
        href="https://www.paypal.com/donate/?hosted_button_id=M8SWG7HDDFAWQ"
        target="_blank"
      >
        PayPal
        <v-icon small right>mdi-open-in-new</v-icon>
      </v-btn>
      <v-btn
        small
        depressed
        color="primary"
        class="mr-1 mb-2"
        @click="tab = 2"
        :input-value="tab === 2"
        >Virement
      </v-btn>
      <v-btn
        small
        depressed
        color="primary"
        class="mr-1 mb-2"
        @click="tab = 1"
        :input-value="tab === 1"
        >Crypto
      </v-btn>
      <v-tabs-items v-model="tab">
        <v-tab-item :key="0">
          <div class="caption mt-2 text-capitalize">
            {{ $tc('donation.amount') }} :
          </div>
          <v-chip-group
            mandatory
            show-arrows
            active-class="success--text"
            v-model="amountSelected"
          >
            <v-chip v-for="amount of amountList" :key="amount" pill>
              {{ amount }}€
            </v-chip>
          </v-chip-group>
          <v-radio-group v-model="recurring" hide-details>
            <v-radio
              :label="$tc('donation.every-month')"
              :value="true"
            ></v-radio>
            <v-radio
              :label="$tc('donation.only-once')"
              :value="false"
            ></v-radio> </v-radio-group
          ><v-text-field
            outlined
            :label="$tc('label.your-email-address')"
            class="mt-5"
            name="email"
            type="email"
            ref="don-email"
            v-model="form.email.value"
            :rules="[rules.required, rules.email]"
          ></v-text-field>
          <div>
            <!-- Credit card element ui (v-card) -->
            <credit-card-details
              :forDonation="true"
              :confirmCard="confirmCard"
            ></credit-card-details>
          </div>
          <div class="error--text mt-1">{{ donationError }}</div>
        </v-tab-item>
        <v-tab-item :key="1">
          <v-select
            class="mt-2"
            :items="cryptoList"
            v-model="crypto"
            label="Coin"
            outlined
          ></v-select>
          <div v-if="crypto !== null" class="mb-2 text-center">
            <vue-qrcode
              :value="crypto"
              :scale="5"
              :margin="1"
              :errorCorrectionLevel="'H'"
            ></vue-qrcode>
            <div class="text-center">{{ crypto }}</div>
          </div>
        </v-tab-item>
        <v-tab-item :key="2">
          <h3 class="mt-2 primary--text">
            Depuis l'UE et zone SEPA :
          </h3>
          <div><strong>IBAN : </strong><span>BE24 9674 4599 3838</span></div>
          <div><strong>BIC : </strong><span>TRWIBEB1XXX</span></div>
          <div><strong>Destinataire : </strong><span>CrowdBunker</span></div>
          <h3 class="mt-2 primary--text">
            Depuis le canada :
          </h3>
          <div><strong>Numéro d'institution : </strong><span>621</span></div>
          <div>
            <strong>Numéro de compte : </strong><span>200110409742</span>
          </div>
          <div><strong>Numéro de transit : </strong><span>16001</span></div>
          <div><strong>Destinataire : </strong><span>CrowdBunker</span></div>
          <div class="mt-4">
            Vous avez besoin d'informations complémentaires ou vous souhaitez
            utiliser une autre méthode de paiement ? Contactez-nous via
            <a href="mailto:contact@crowdbunker.com">contact@crowdbunker.com</a>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-card-text v-else>
      <v-alert color="success">
        {{ $tc('donation.donation-registered') }}
      </v-alert>
      <h3 class="primary--text text-center">
        {{ $tc('donation.thank-you') }} !
      </h3>
      <br />
      {{ $tc('donation.protection-thank-to-you') }} <br />
      {{ $tc('donation.share-initiative-around') }}
      <br />
      <p v-if="recurring" class="mt-3">
        {{ $tc('donation.modify-cancel-monthly-donation') }}
        <a href="mailto:contact@crowdbunker.com">contact@crowdbunker.com</a>
      </p>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="$emit('close')" v-if="!embed">
        <span v-if="!donationFinished">{{ $tc('button.cancel') }}</span>
        <span v-else>{{ $tc('button.close') }}</span>
      </v-btn>
      <v-btn
        color="success"
        @click="submitDonation"
        v-if="
          payment_intent_pending && recurring && !donationFinished && tab === 0
        "
        :loading="loading"
        depressed
      >
        {{ $tc('button.continue-to-pay') }}
      </v-btn>
      <v-btn
        color="success"
        @click="submitDonation"
        v-if="
          (!payment_intent_pending || !recurring) &&
            !donationFinished &&
            tab === 0
        "
        :loading="loading"
        depressed
      >
        {{ $tc('donation.donate') }} {{ donationAmount }}€
        <span v-if="recurring" class="ml-1">{{
          $tc('donation.by-month')
        }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios'
import { requestService } from '@/services/request.service'
import CreditCardDetails from '@/components/CreditCardDetails'
import VueQrcode from 'vue-qrcode'

const cryptoList = [
  { text: 'Bitcoin (BTC)', value: '1GKU4gQSDfzziYPBGVs9ZvtGWXrn3XKzL4' },
  {
    text: 'Binance Coin BSC (BNB)',
    value: '0x0c0edad7b2b9c130b59069c239e0b7a187303d4b',
  },
  {
    text: 'Cardano (ADA)',
    value:
      'addr1qxjj2q562ha9gkkl9hk0p73fstk8xk22z477wtsszl69ghzcgmyxzfrcl7xyvsf28fn6tanfl54455x3yqd5cy35krnsp5dyht',
  },
  {
    text: 'Dogecoin (DOGE)',
    value: 'DLTZbwM5X5uHFYZn15ri7h3sPfb5LZvARU',
  },
  {
    text: 'Ethereum (ETH)',
    value: '0x0c0edad7b2b9c130b59069c239e0b7a187303d4b',
  },
  {
    text: 'Polkadot (DOT)',
    value: '15CLZ3XB263nWfH28fYfb4eDmPEq4xZBAeQAtj5HRKTSLtpN',
  },
  {
    text: 'Solana (SOL)',
    value: 'DVTV9RZneU1BVAbvKxXnsjQnGLyRiG9FNTs5HfGYuHUT',
  },
]

export default {
  name: 'Donation',
  components: { VueQrcode, CreditCardDetails },
  props: {
    embed: { type: Boolean },
  },
  data() {
    return {
      donationFinished: false,
      form: {
        email: {
          value: this.$store.state.account.status.loggedIn
            ? this.$store.state.account.user.user.email
            : '',
        },
      },
      rules: {
        required: value => !!value || this.$tc('login.required') + '.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$tc('error.invalid-email')
        },
      },
      loading: false,
      amountList: [2, 5, 10, 25, 50, 100, 200, 500],
      amountSelected: 2,
      donationError: '',
      recurring: false,
      testPricesId: [
        'price_1Lefn3DQV3VdhpTxPNB8UOMQ',
        'price_1LefnsDQV3VdhpTxavA1q0a1',
        'price_1LefpADQV3VdhpTx7C2DtWNJ',
      ],
      pricesId: [
        'price_1Lef6EDQV3VdhpTxXwRAQMJn',
        'price_1Lef8IDQV3VdhpTxLOvV61dD',
        'price_1Lef8vDQV3VdhpTxDEp2BsBN',
        'price_1Lef9NDQV3VdhpTxv7pOaK4f',
        'price_1LefA2DQV3VdhpTxRe2X3iBy',
        'price_1LefBcDQV3VdhpTx1Uy80vj4',
        'price_1LefGNDQV3VdhpTxALKUBVm0',
        'price_1LefHoDQV3VdhpTxjMrR48nS',
      ],
      rebuild: 0,
      tab: 0,
      cryptoList: cryptoList,
      crypto: cryptoList[0].value,
      payment_intent_pending: false,
      confirmCard: false,
    }
  },
  computed: {
    donationAmount() {
      return this.amountList[this.amountSelected]
    },
    priceId() {
      if (process.env.VUE_APP_ENV === 'production') {
        return this.pricesId[this.amountSelected]
      }
      return this.testPricesId[this.amountSelected]
    },
    paymentMethodInfos() {
      return this.$store.state.account.paymentMethod
    },
    stripe() {
      return this.$store.state.stripe.stripe
    },
    stripeCard() {
      return this.$store.state.stripe.stripeCard
    },
    stripeElementPaymentError() {
      return this.$store.state.stripe.stripeElementPaymentError
    },
    isLoggedIn() {
      return this.$store.state.account.status.loggedIn
    },
  },
  methods: {
    submitDonation() {
      this.loading = true
      const finalAmount = this.donationAmount + '.00'
      if (this.paymentMethodInfos == null) {
        if (
          !this.$refs['don-email'].validate(true) ||
          this.stripeElementPaymentError.length
        ) {
          return
        }
        if (!this.stripeCard._complete) {
          this.donationError = this.$tc('error.payment-data-incomplete')
          return
        }
      } else {
        if (this.recurring) {
          if (this.isLoggedIn && this.payment_intent_pending) {
            this.retryInvoiceWithNewPaymentMethod({
              paymentMethodId: this.paymentMethodInfos.id,
              invoiceId: localStorage.getItem('donationLatestInvoiceId'),
            })
          } else {
            this.createSubscription({
              paymentMethodId: this.paymentMethodInfos.id,
              priceId: this.priceId,
            })
          }
        } else {
          this.createSiglePayment({
            paymentMethodId: this.paymentMethodInfos.id,
            finalAmount,
          })
        }
        return
      }

      this.$store
        .dispatch('stripe/stripeCreatePaymentMethod', {
          email: this.form.email.value,
        })
        .then(async result => {
          if (this.isLoggedIn) {
            await this.$store.dispatch('account/savePaymentMethod', {
              paymentMethodId: result.paymentMethod.id,
              last4: result.paymentMethod.card.last4,
              brand: result.paymentMethod.card.brand,
              expMonth: result.paymentMethod.card.exp_month,
              expYear: result.paymentMethod.card.exp_year,
              country: result.paymentMethod.card.country,
              paymentMethodType: result.paymentMethod.type,
            })
          }
          if (this.recurring) {
            this.createSubscription({
              paymentMethodId: result.paymentMethod.id,
              priceId: this.priceId,
            })
          } else {
            this.createSiglePayment({
              paymentMethodId: result.paymentMethod.id,
              finalAmount,
            })
          }
        })
        .catch(err => {
          this.donationError =
            typeof err == 'string' ? err : err.message || err.error
          this.loading = false
        })
    },
    createSiglePayment({ paymentMethodId, finalAmount }) {
      requestService
        .post(
          process.env.VUE_APP_API_BASE_URL + '/payment/start-platform-donation',
          {
            amount: finalAmount,
            email: this.isLoggedIn
              ? this.$store.state.account.user.user.email
              : this.form.email.value,
            paymentMethodId: paymentMethodId,
          },
        )
        .then(response => {
          if (!response.success) {
            this.donationError = response.message
              ? response.message
              : this.$tc('error.server-error-try-again')
            this.loading = false
            return
          }
          if (response.status === 'succeeded') {
            this.donationFinished = true
            this.loading = false
            return
          }
          this.confirmCard = true
          setTimeout(() => {
            this.stripe
              .confirmCardPayment(response.client_secret, {
                payment_method: paymentMethodId,
              })
              .then(result => {
                if (result.error) {
                  this.donationError = result.error.message
                } else {
                  // The payment has been processed!
                  this.donationFinished = true
                }
              })
              .finally(() => {
                this.loading = false
              })
          }, 1000)
        })
        .catch(err => {
          // console.log(err)
          // this.donationError = err.message || err.error
          this.donationError = this.$tc('error.error-while-creating-payment')
          this.loading = false
        })
    },
    createSubscription({ paymentMethodId, priceId }) {
      requestService
        .post(
          process.env.VUE_APP_API_BASE_URL + '/payment/start-platform-donation',
          {
            // amount: finalAmount,
            email: this.isLoggedIn
              ? this.$store.state.account.user.user.email
              : this.form.email.value,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
          },
        )
        .then(response => {
          if (!response.success) {
            this.donationError = response.message
              ? response.message
              : this.$tc('error.server-error-try-again')
            throw new Error(response.message)
          }
          if (
            this.isLoggedIn &&
            response.code &&
            [
              'card_declined',
              'expired_card',
              'insufficient_funds',
              'processing_error',
              'incorrect_cvc',
              'incorrect_number',
              'expired_card',
            ].includes(response.code)
          ) {
            this.$store.dispatch('stripe/setChangePaymentCardDialog', true)
          }
          if (response.status === 'succeeded' || response.success) {
            return {
              subscription: response.subscription,
              paymentMethodId: paymentMethodId,
              isRetry: false,
            }
          }
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(this.handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(this.handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(this.onSubscriptionComplete)
        .catch(err => {
          console.log(err)
          this.donationError =
            err.message ?? this.$tc('error.error-while-creating-subscription')
        })
        .finally(() => {
          this.loading = false
        })
    },
    async handlePaymentThatRequiresCustomerAction({
      subscription,
      paymentMethodId,
      isRetry,
    }) {
      const invoice = subscription.latest_invoice
      if (subscription && subscription.status === 'active') {
        // Subscription is active, no customer actions required.
        return { subscription, paymentMethodId }
      }

      // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
      // If it's a retry, the payment intent will be on the invoice itself.
      const paymentIntent = invoice
        ? invoice.payment_intent
        : subscription.latest_invoice.payment_intent

      if (
        paymentIntent.status === 'requires_action' ||
        (isRetry === true && paymentIntent.status === 'requires_payment_method')
      ) {
        return await this.confirmCardHandler(
          subscription,
          paymentIntent,
          paymentMethodId,
          isRetry,
        )
      } else {
        // No customer action needed.
        return { subscription, paymentMethodId }
      }
    },
    handleRequiresPaymentMethod({ subscription, paymentMethodId }) {
      if (subscription.status === 'active') {
        // subscription is active, no customer actions required.
        return { subscription, paymentMethodId }
      } else if (
        subscription.latest_invoice.payment_intent.status ===
        'requires_payment_method'
      ) {
        if (!this.isLoggedIn)
          throw new Error(this.$tc('error.card-been-refused'))
        // Using localStorage to manage the state of the retry here,
        // feel free to replace with what you prefer.
        // Store the latest invoice ID and status.
        localStorage.setItem(
          'donationLatestInvoiceId',
          subscription.latest_invoice.id,
        )
        localStorage.setItem(
          'donationLatestInvoicePaymentIntentStatus',
          subscription.latest_invoice.payment_intent.status,
        )
        this.payment_intent_pending = true
        this.donationFinished = false
        this.donationError = this.$tc('error.card-been-refused')
        this.$store.dispatch('stripe/setChangePaymentCardDialog', true)
        throw new Error(this.donationError)
      } else {
        return { subscription, paymentMethodId }
      }
    },
    retryInvoiceWithNewPaymentMethod({ paymentMethodId, invoiceId }) {
      return (
        requestService
          .post(process.env.VUE_APP_API_BASE_URL + '/payment/startPayment', {
            connect: false,
            paymentMethodId: paymentMethodId,
            invoiceId: invoiceId,
            retry: true,
            fund: true,
          })
          // If the card is declined, display an error to the user.
          .then(response => {
            if (!response.success) {
              // The card had an error when trying to attach it to a customer.
              this.$store.dispatch(
                'stripe/setStripeElementPaymentError',
                response.message
                  ? response.message
                  : this.$tc('error.server-error-try-again'),
              )
              this.donationError =
                response.message ?? this.$tc('error.server-error-try-again')
              if (
                response.code &&
                [
                  'card_declined',
                  'expired_card',
                  'insufficient_funds',
                ].includes(response.code)
              ) {
                this.$store.dispatch('stripe/setChangePaymentCardDialog', true)
              }
              this.clean()
              this.loading = false
              this.donationFinished = false
              if (response.code === 400) throw new Error(this.subscriptionError)
              return
            }
            return {
              subscription: response.subscription,
              paymentMethodId: paymentMethodId,
              retry: true,
            }
          })
          // Some payment methods require a customer to be on session
          // to complete the payment process. Check the status of the
          // payment intent to handle these actions.
          .then(this.handlePaymentThatRequiresCustomerAction)
          // No more actions required. Provision your service for the user.
          .then(this.onSubscriptionComplete)
          .catch(error => {
            // An error has happened. Display the failure to the user here.
            // We utilize the HTML element we created.
            this.donationError = error
          })
          .finally(() => {
            this.loading = false
          })
      )
    },
    onSubscriptionComplete() {
      this.donationFinished = true
      this.clean()
      this.loading = false
    },
    clean() {
      localStorage.removeItem('donationLatestInvoiceId')
      localStorage.removeItem('donationLatestInvoicePaymentIntentStatus')
      this.payment_intent_pending = false
    },
    confirmCardHandler(subscription, paymentIntent, paymentMethodId, isRetry) {
      this.confirmCard = true
      // eslint-disable-next-line compat/compat
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.stripe
            .confirmCardPayment(paymentIntent.client_secret, {
              payment_method: paymentMethodId,
            })
            .then(result => {
              if (result.error) {
                // Start code flow to handle updating the payment details.
                // Display error message in your UI.
                // The card was declined (i.e. insufficient funds, card has expired, etc).
                this.donationError = result.error.message
                this.confirmCard = false
                reject(result.error)
              } else {
                if (result.paymentIntent.status === 'succeeded') {
                  // Show a success message to your customer.
                  resolve({
                    subscription: subscription,
                    paymentMethodId: paymentMethodId,
                    isRetry,
                  })
                }
              }
            })
            .catch(err => {
              this.confirmCard = false
              this.donationError =
                err.message ?? this.$tc('error.error-while-confirming-payment')
              reject(err)
            })
        }, 1000)
      })
    },
  },
  created() {
    if (
      this.isLoggedIn &&
      localStorage.getItem('donationLatestInvoiceId') !== null &&
      localStorage.getItem('donationLatestInvoicePaymentIntentStatus') ===
        'requires_payment_method'
    ) {
      this.payment_intent_pending = true
    }
  },
}
</script>

<style scoped></style>
