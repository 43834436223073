<template>
  <div>
    <v-banner two-line v-if="visible" :shaped="$vuetify.breakpoint.smAndDown">
      <v-avatar
        slot="icon"
        color="primary"
        size="40"
        v-if="$vuetify.breakpoint.smAndUp"
      >
        <v-icon icon="mdi-lock" color="white">
          mdi-information-variant
        </v-icon>
      </v-avatar>

      {{ $tc('home.banner-make-donation') }}

      <template v-slot:actions>
        <!--        <v-btn
          class="ml-0"
          text
          color="primary"
          @click="$store.state.newsletterDialog = true"
        >
          Tenez-moi informé
        </v-btn>-->
        <v-btn text color="primary" @click="openDonationDialog()" class="ml-0">
          {{ $tc('donation.donate-to-crowdbunker') }}
        </v-btn>
      </template>
    </v-banner>
    <v-dialog
      v-model="donationDialog"
      :persistent="$vuetify.breakpoint.mdAndUp"
      max-width="500"
    >
      <donation @close="donationDialog = false"></donation>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import Donation from '@/components/Donation'

export default {
  name: 'HeaderBanner',
  components: { Donation },
  props: {
    visible: { type: Boolean, default: true },
  },
  data() {
    return {
      donationDialog: false,
      newsletterFinished: false,
      loading: false,
    }
  },
  methods: {
    submitNewsletter() {
      if (!this.$refs['nl-email'].validate(true)) {
        return
      }
      this.loading = true
      axios
        .post(process.env.VUE_APP_FUNCTIONS_BASE_URL + '/addEmail', {
          email: this.form.email.value,
        })
        .then(() => {
          this.newsletterFinished = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    openDonationDialog() {
      this.donationDialog = true
    },
  },
  watch: {
    donationDialog(value) {
      this.$store.state.donationDialog = value
    },
    '$store.state.donationDialog'() {
      if (this.$store.state.donationDialog) {
        this.openDonationDialog()
      }
      return true
    },
  },
}
</script>

<style lang="scss">
//@import '~vuetify/src/components/VTextField/variables';
//@import '~vuetify/src/styles/tools/_theme.sass';

@include theme(v-sheet) using($material) {
  &.card-input-outline {
    border-color: map-deep-get($material, 'text-fields', 'outlined');
    color: map-deep-get($material, 'text-fields', 'outlined');

    &:hover {
      color: map-deep-get($material, 'text-fields', 'outlined-hover');
      border-color: map-deep-get($material, 'text-fields', 'outlined-hover');
    }
  }
}
</style>
