<template>
  <div class="d-flex justify-center flex-grow-1">
    <div>
      <div class="text-h4 text-center mb-5">
        {{ $tc('error.page-not-found') }}
      </div>
      <div>
        <v-img
          src="@/assets/404-ufo.svg"
          width="300px"
          max-width="100%"
        ></v-img>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
}
</script>

<style scoped></style>
