<template>
  <v-icon
    v-if="siteBadge.icon"
    :color="siteBadge.color"
    size="12px"
    class="ml-1"
    >{{ siteBadge.icon }}</v-icon
  >
  <span
    v-else-if="siteBadge.text"
    v-text="siteBadge.text"
    :style="{ color: siteBadge.color }"
    class="ml-1"
  ></span>
</template>

<script>
export default {
  name: 'SiteBadge',
  props: {
    siteBadge: Object,
  },
}
</script>

<style scoped></style>
