<template>
  <v-dialog
    :value="value"
    @input="$emit('input', value)"
    fullscreen
    hide-overlay
  >
    <v-card color="#002031eb">
      <v-card-text
        class="py-0 px-0 px-md-2"
        @click="showButtons = !showButtons"
      >
        <v-carousel
          height="100vh"
          :value="carouselItem"
          :show-arrows="showButtons && images.length > 1"
          :hide-delimiters="images.length === 1"
        >
          <v-carousel-item v-for="image in images" :key="image.cid">
            <v-img :src="image.url" height="100%" contain></v-img>
          </v-carousel-item>
          <v-btn
            v-show="showButtons"
            icon
            absolute
            style="top:15px;left:15px;background-color: rgba(0, 0, 0, 0.3);"
            @click.stop="$emit('input', false)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-carousel>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MediaDialog',
  props: {
    value: Boolean,
    images: Array,
    carouselItem: { type: Number },
  },
  data() {
    return {
      showButtons: true,
    }
  },
}
</script>

<style scoped></style>
