var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.hasError)?[_c('div',{staticClass:"text-center"},[_vm._v("\n        "+_vm._s(_vm.$tc('error.an-error-has-occurred-refresh'))+"\n      ")])]:[_c('v-row',[_c('v-col',[_c('div',{staticClass:"overline"},[_vm._v("\n            "+_vm._s(_vm.$tc('censored.content-censored-by-other-platforms'))+"\n          ")])])],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('post-grid',{attrs:{"posts":_vm.censoredPosts,"loading":_vm.postsLoading}})],1),_vm._v(" "),_c('v-col',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersectEnd,
            options: {
              rootMargin: '-50% 0px 500px 0px',
              threshold: [0, 1],
            },
          }),expression:"{\n            handler: onIntersectEnd,\n            options: {\n              rootMargin: '-50% 0px 500px 0px',\n              threshold: [0, 1],\n            },\n          }"}],staticStyle:{"min-height":"50px"},attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('div',{staticStyle:{"min-height":"50px"}})])],1),_vm._v(" "),(!_vm.endReached && !_vm.hasError)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.postsLoading},on:{"click":_vm.fetchCensoredPosts}},[_vm._v(_vm._s(_vm.$tc('button.load-more'))+"\n        ")])],1):_vm._e()]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }