<template>
  <div>
    <div v-if="loggedIn" class="d-flex">
      <div class="pt-5" @click="onBehalfMenu = true">
        <v-menu v-model="onBehalfMenu" offset-y bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="select-behalf">
              <channel-avatar
                :thumbnails="
                  onBehalf
                    ? onBehalf.thumbnails
                    : $store.state.account.user.user.thumbnails
                "
                :size="sub ? 30 : 45"
                :channel-name="onBehalfName"
                :bg-color="onBehalfAvatarColor || 'primary'"
                class="mr-3"
              ></channel-avatar>
              <v-icon small class="select-behalf-icon"
                >mdi-chevron-down-circle
              </v-icon>
            </div>
          </template>
          <v-card>
            <v-card-text v-if="organizations.length > 3">
              <v-text-field
                v-model="onBehalfSearch"
                :label="$tc('label.search') | capitalize"
                flat
                solo-inverted
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
                @click.stop.prevent
                dense
                autofocus
              ></v-text-field>
            </v-card-text>
            <div style="max-height: 200px;overflow-y: auto" class="pa-2">
              <v-treeview
                :active.sync="onBehalfSelect"
                :items="onBehalfList"
                activatable
                item-key="id"
                open-on-click
                open-all
                dense
                :search="onBehalfSearch"
              >
                <template v-slot:prepend="{ item }">
                  <channel-avatar
                    v-if="item.children === undefined"
                    :thumbnails="
                      item.thumbnails
                        ? item.thumbnails
                        : $store.state.account.user.user.thumbnails
                    "
                    :size="30"
                    :channel-name="item.name"
                    :bg-color="
                      item && item.avatarColor
                        ? item.avatarColor
                        : onBehalfAvatarColor
                        ? onBehalfAvatarColor
                        : 'primary'
                    "
                    class="mr-3"
                  ></channel-avatar>
                </template>
              </v-treeview>
            </div>
          </v-card>
        </v-menu>
      </div>
      <div class="flex-grow-1 text-caption">
        <div>
          {{ sub ? $tc('chat.answer') : $tc('chat.comment') | capitalize
          }}{{ ' ' + $tc('chat.as') }}
          <b>{{ onBehalfName }}</b>
        </div>
        <textarea-field
          solo-inverted
          flat
          :placeholder="$tc('chat.comment-freely')"
          background-color="secondary lighten-2"
          :hide-details="
            (form.textContent.value === null ||
              form.textContent.value.length < 1800) &&
              (!form.textContent.errors || form.textContent.value.length === 0)
          "
          auto-grow
          :counter="
            form.textContent.value !== null &&
            form.textContent.value.length > 1800
              ? 2000
              : undefined
          "
          :field="form.textContent"
          rows="1"
          :dense="sub"
          :autofocus="sub"
          @keyup.ctrl.enter="submit"
        >
          <template #append>
            <v-btn
              small
              icon
              @click="submit"
              :loading="submitLoading"
              :disabled="form.textContent.value.length === 0"
            >
              <v-icon :small="sub">mdi-send</v-icon>
            </v-btn>
          </template>
        </textarea-field>
        <div v-show="unhandledErrors">
          <template v-for="(errorField, index) of unhandledErrors">
            <div
              v-for="(error, i) of errorField"
              :key="index + i"
              class="error--text"
            >
              <b>{{ error }}</b>
            </div>
          </template>
        </div>
      </div>
    </div>
    <v-alert v-else type="info" color="secondary" class="text-center mt-1">
      <router-link :to="{ name: 'Login' }">{{
        $tc('chat.connect')
      }}</router-link
      >{{ ' ' + $tc('label.or') }}
      <router-link :to="{ name: 'Register' }">{{
        $tc('label.register-yourself')
      }}</router-link
      >{{ ' ' + $tc('chat.to-comment') }}
    </v-alert>
  </div>
</template>

<script>
import TextareaField from '@/components/form/TextareaField'
import { requestService } from '@/services/request.service'
import ChannelAvatar from '@/components/ChannelAvatar'
import Validation from '@/components/mixins/Validation'

export default {
  name: 'CommentForm',
  components: { ChannelAvatar, TextareaField },
  props: {
    parentPost: { type: Object, required: true },
    sub: { type: Boolean },
  },
  mixins: [Validation],
  data() {
    return {
      form: {
        textContent: {
          value: '',
          required: true,
        },
        organization: {
          value: null,
        },
        parent: {
          value: null,
          required: true,
        },
      },
      organizations: [],
      currentOrganization: null,
      onBehalfMenu: false,
      onBehalfSelect: [],
      onBehalfSearch: '',
      submitLoading: false,
    }
  },
  computed: {
    onBehalfName() {
      if (this.onBehalf) {
        return this.onBehalf.name
      }
      return this.$store.state.account.user.user.username
    },
    onBehalfAvatarColor() {
      if (this.onBehalf) {
        return this.onBehalf.avatarColor
      }
      return this.$store.state.account.user.user.avatarColor
    },
    onBehalfThumbnails() {
      return this.$store.state.account.user.user.thumbnails
    },
    onBehalfList() {
      const list = [
        {
          name: this.$store.state.account.user.user.username,
          id: null,
        },
      ]
      if (this.organizations.length) {
        list.push({
          name: this.$options.filters.capitalize(
            this.$tc('channel.organization', 2),
          ),
          children: this.organizations,
        })
      }
      return list
    },
    onBehalf() {
      return this.organizations.find(
        organization => organization.id === this.form.organization.value,
      )
    },
    loggedIn() {
      return !!this.$store.state.account.status.loggedIn
    },
  },
  methods: {
    async fetchInternalChannels() {
      this.datasLoading = true
      requestService
        .get('/organization/mine', {
          params: {
            isCommentForm: true,
          },
        })
        .then(response => {
          this.organizations = response.items
          this.$store.commit('account/organizations', response.items)
          this.autoSelectChannel()
        })
        .finally(() => (this.datasLoading = false))
    },
    autoSelectChannel() {
      const postOnBehalf = localStorage.getItem('postOnBehalf')
      if (postOnBehalf && this.organizations.length) {
        const onBehalf = JSON.parse(postOnBehalf)
        const organization = this.organizations.find(
          organization => organization.id === onBehalf,
        )
        if (organization === undefined) {
          this.form.organization.value = null
        } else {
          this.form.organization.value = onBehalf
        }
        return
      }
      if (this.organizations.length) {
        this.form.organization.value = this.organizations[0].id
      }
    },
    submit() {
      this.error = null
      this.formValidation(this.form, true)
      if (this.errorsNbr > 0) {
        return
      }
      this.submitLoading = true
      const url = '/post/create'
      const data = this.formatDataToSend(this.form)
      if (this.form.organization.value === null) {
        delete data.organization
      }
      requestService
        .post(url, data)
        .then(response => {
          if (response.uid) {
            this.form.textContent.value = ''
            this.$emit('created', response)
            this.unhandledErrors = {}
          }
        })
        .catch(response => {
          console.log('error response', response)
          const { error, validationFailed } = response
          if (validationFailed) {
            this.handleServerErrors(error, this.form)
          } else if (error.detail) {
            this.error = error.detail
          }
        })
        .finally(() => (this.submitLoading = false))
    },
  },
  watch: {
    parentPost: function(newVal, oldVal) {
      this.form.parent.value = this.parentPost.uid
    },
    onBehalfSelect(value) {
      this.form.organization.value = value[0]
      localStorage.setItem('postOnBehalf', JSON.stringify(value[0]))
      this.unhandledErrors = {}
    },
  },
  created() {
    this.form.parent.value = this.parentPost.uid
  },
  mounted() {
    if (this.loggedIn) {
      const storedOrganizations = this.$store.state.account.organizations
      if (storedOrganizations) {
        this.organizations = storedOrganizations
      }
      this.autoSelectChannel()
      this.fetchInternalChannels()
      this.$root.$on('channelCreated', () => {
        this.$emit('rerender')
      })
    }
  },
}
</script>

<style scoped lang="scss">
.select-behalf {
  position: relative;
  cursor: pointer;

  .select-behalf-icon {
    position: absolute;
    bottom: 0;
    right: 8px;
    filter: url(#icon-outline);
  }
}

::v-deep {
  .v-treeview-node__prepend {
    min-width: 0;
  }
}
</style>
