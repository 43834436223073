<template>
  <v-app>
    <div class="background-gradient"></div>
    <HeaderBar
      @switch-left-menu="leftMenuOpen = !leftMenuOpen"
      has-left-menu
    ></HeaderBar>
    <left-menu :open.sync="leftMenuOpen"></left-menu>
    <v-main id="main" class="">
      <!--      <v-expansion-panels
        v-if="$vuetify.breakpoint.smAndDown && !!$route.meta.headerBanner"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $tc('censored.platform-protect-liberties') + '...' }}
          </v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <header-banner
              :visible="!!$route.meta.headerBanner"
            ></header-banner>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <header-banner
        v-else
        :visible="!!$route.meta.headerBanner"
      ></header-banner>-->
      <v-alert
        color="red"
        type="info"
        class="mx-3 my-1"
        dismissible
        v-if="$store.state.remoteConfig.globalErrorMessage.length > 0"
        >{{ $store.state.remoteConfig.globalErrorMessage }}</v-alert
      >
      <v-container
        fluid
        :class="{
          'fill-height': !!$route.meta.fillHeight,
          'pt-0': !!$route.meta.noPaddingTop,
        }"
      >
        <keep-alive :include="['Home']" :max="3">
          <slot />
        </keep-alive>
      </v-container>
      <slot name="outside"></slot>
    </v-main>
    <bottom-bar></bottom-bar>
    <v-footer
      class="font-weight-medium mt-4"
      :class="{ 'left-menu-open': leftMenuOpen }"
      color="transparent"
      padless
    >
      <v-col class="text-center footer font-weight-light px-0" cols="12">
        <v-divider class="mb-2 d-none"></v-divider>
        <strong>{{ $tc('chat.want-chat-with-us') + ' ?' }}</strong>
        <a
          href="mailto:contact@crowdbunker.com"
          class="mx-1 text-decoration-none"
          >contact@crowdbunker.com</a
        >
        |
        <span class="ml-1">{{ $tc('home.freedom-of-expression') }}</span>
      </v-col>
    </v-footer>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      class="svg-filters d-none"
    >
      <defs>
        <filter id="icon-outline">
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="1"
            result="dilate"
          />
          <feColorMatrix
            in="dilate"
            type="matrix"
            values="0 0 0 0 0  0 0 0 0 0.1255  0 0 0 0 0.1922  0 0 0 1 0"
            result="color"
          />
          <feMerge>
            <feMergeNode in="color" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
    </svg>
  </v-app>
</template>

<script>
import HeaderBar from '../components/HeaderBar'
import HeaderBanner from '@/components/HeaderBanner'
import LeftMenu from '@/components/LeftMenu'
import BottomBar from '@/components/BottomBar.vue'

export default {
  name: 'DefaultLayout',
  components: { BottomBar, LeftMenu, HeaderBanner, HeaderBar },
  data: () => ({
    leftMenuOpen: null,
  }),
  watch: {
    '$route.meta.leftMenu'(value) {
      if (
        !this.$store.state.leftMenuManuallySet &&
        typeof value === 'boolean' &&
        this.$vuetify.breakpoint.mdAndUp
      ) {
        setTimeout(() => {
          this.leftMenuOpen = value
        }, 600)
      }
    },
  },
  mounted() {
    this.$root.$on('leftMenu', value => (this.leftMenuOpen = value))
  },
}
</script>

<style scoped lang="scss">
#main {
  background-color: var(--v-background-base);
}

.footer {
  opacity: 0.7;
  font-size: 14px;

  a {
    color: inherit;
  }
}
.v-footer {
  transition: 0.25s padding-left ease;
  &.left-menu-open {
    //padding-left: 256px;
  }
}

.background-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 27, 41);
  background: linear-gradient(
    180deg,
    rgba(0, 27, 41, 1) 0%,
    rgba(1, 22, 33, 1) 100%
  );
}
</style>
