<template>
  <v-row>
    <video-item
      v-for="video in videos"
      :key="video.id + 'a'"
      :video="video"
      :cols="12"
      :sm="4"
      :md="3"
    ></video-item>
    <template v-if="videosLoading">
      <!--      <v-col v-for="item in 8" :key="item" cols="6" sm="4" md="3">
        <v-sheet color="secondary" rounded>
          <v-responsive :aspect-ratio="320 / 180">
            <v-skeleton-loader height="100%" type="image"></v-skeleton-loader>
          </v-responsive>
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
        </v-sheet>
      </v-col>-->
      <video-item-loader
        v-for="item in 8"
        :key="item"
        :cols="12"
        :sm="4"
        :md="3"
      ></video-item-loader>
    </template>
    <v-col
      :cols="12"
      sm="4"
      md="3"
      v-intersect="{
        handler: onIntersectEnd,
        options: {
          rootMargin: '0px 0px 500px 0px',
        },
      }"
    ></v-col>
  </v-row>
</template>

<script>
import VideoItem from './VideoItem'
import { requestService } from '../services/request.service'
import VideoItemLoader from '@/components/VideoItemLoader'
export default {
  name: 'VideoList',
  components: { VideoItemLoader, VideoItem },
  props: {
    organizationId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      videos: [],
      videosLoading: true,
      queryLast: null,
      queryTotal: 0,
      hasError: false,
      endReached: false,
    }
  },
  methods: {
    getLastVideos() {
      this.videosLoading = true
      requestService
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/organization/${this.organizationId}/videos`,
          {
            params: {
              after: this.queryLast,
            },
          },
        )
        .then(response => {
          Array.prototype.push.apply(this.videos, response.items)
          if (response.last !== null) {
            this.queryLast = response.last
          } else {
            this.endReached = true
          }
          this.queryTotal = response.total
        })
        .catch(error => {
          this.endReached = true
          if (error.response && error.response.status === 404) {
            this.notFound = true
          } else {
            this.hasError = true
          }
        })
        .finally(() => {
          this.videosLoading = false
        })
    },
    onIntersectEnd() {
      if (this.videosLoading || this.endReached) {
        return
      }
      this.getLastVideos()
    },
  },
  mounted() {
    this.getLastVideos()
  },
}
</script>

<style scoped></style>
