<template>
  <v-col v-bind="$attrs">
    <router-link
      :to="{ name: 'VideoPost', params: { slug: video.id } }"
      class="text-decoration-none"
    >
      <v-hover v-slot="{ hover }">
        <v-card
          width="100%"
          elevation="0"
          :class="{ censored: video.censored }"
        >
          <video-thumbnail
            :thumbnails="video.thumbnails"
            :in-second-duration="video.duration"
            :hover="hover"
            :censored="video.censored"
            width="100%"
            :in-second-last-stop="video.lastStop"
            :video="video"
          ></video-thumbnail>
          <v-card-title class="subtitle-2 text-break py-1">
            <v-list-item three-line class="px-0 list-text-block">
              <v-list-item-content class="py-0">
                <v-list-item-subtitle :title="video.title">
                  {{ video.title }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  class="font-weight-light align-self-end caption"
                >
                  {{
                    formatDateRelative(new Date(video.publishedAt), true)
                      | capitalize
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!noChannel" class="px-0 channel-block-list-item">
              <v-list-item-content class="py-0">
                <v-list-item-subtitle>
                  <router-link
                    v-if="usedChannel !== null"
                    :to="channelLink"
                    class="text-decoration-none"
                  >
                    <v-sheet class="d-flex align-center channel-block" rounded>
                      <channel-avatar
                        :thumbnails="channelThumbnails"
                        :channel-name="channelName"
                        :size="30"
                        bg-color="primary"
                      ></channel-avatar>
                      <div class="ml-2 text-truncate" :title="channelTooltip">
                        {{ channelName }}
                        <v-icon
                          v-if="
                            !!$store.state.account.plateFormSubscription &&
                              $store.state.account.organizations.find(
                                elt => elt.uid === usedChannel.organization.uid,
                              )
                          "
                          :color="
                            getColor(
                              $store.state.plateFormSubscription
                                .plateFormSubscription.planLevel,
                            )
                          "
                          >mdi-seal</v-icon
                        >
                      </div>
                      <div class="ml-auto" v-if="getPlatformIcon(video)">
                        <v-icon
                          class="mx-1"
                          small
                          v-text="getPlatformIcon(video).iconName"
                          :color="getPlatformIcon(video).color"
                        ></v-icon>
                      </div>
                    </v-sheet>
                  </router-link>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-title>
        </v-card>
      </v-hover>
    </router-link>
  </v-col>
</template>

<script>
import VideoThumbnail from './VideoThumbnail'
import ChannelAvatar from './ChannelAvatar'
import { utilsService } from '@/services/utils.service'
import { subscriptionService } from '@/services/subscription.service'

export default {
  name: 'VideoItem',
  components: { ChannelAvatar, VideoThumbnail },
  props: {
    video: Object,
    noChannel: {
      type: Boolean,
      default: false,
    },
    channel: {
      type: Object,
      default: null,
    },
    showOrganization: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    usedChannel() {
      if (this.channel) {
        return this.channel
      }
      if (this.video && this.video.channel) {
        return this.video.channel
      }
      return {}
    },
    channelName() {
      if (this.showOrganization && this.usedChannel.organization) {
        return this.usedChannel.organization.name
      }
      return this.usedChannel.name
    },
    channelTooltip() {
      if (this.showOrganization && this.usedChannel.organization) {
        return `${this.channelName} (${this.usedChannel.name})`
      }
      return this.usedChannel.name
    },
    channelThumbnails() {
      if (this.showOrganization && this.usedChannel.organization) {
        return this.usedChannel.organization.thumbnails
      }
      return this.usedChannel.thumbnails
    },
    channelLink() {
      if (this.showOrganization && this.usedChannel.organization) {
        return {
          name: 'Organization',
          params: { slug: this.usedChannel.organization.uid },
        }
      }
      return {
        name: 'Channel',
        params: { slug: this.usedChannel.id },
      }
    },
  },
  methods: {
    getColor(level) {
      return subscriptionService.subscriptionLevelColor(level)
    },
    formatDate(date, hour = false) {
      return utilsService.formatDate(date, hour)
    },
    formatDateRelative(date) {
      return utilsService.formatDateRelative(date)
    },
    getPlatformIcon(post) {
      if (post.platform) {
        switch (post.platform) {
          case 'youtube':
            return {
              iconName: 'mdi-youtube',
              color: '#f00',
            }
          case 'internal':
            return null
        }
      }
    },
  },
  created() {
    if (this.channel !== null) {
      this.video.channel = this.channel
    }
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .list-text-block {
    min-height: 60px !important;
  }
  .v-list-item:hover::before {
    opacity: 0 !important;
  }
}

.censored {
  border: 1px solid;
  border-color: var(--v-primary-base);
}

.channel-block-list-item {
  max-width: 100%;

  .channel-block {
    &:hover {
      background-color: var(--v-background-lighten1);
    }
  }
}
</style>
