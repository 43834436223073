<template>
  <div>
    <v-expand-transition>
      <v-row
        v-show="
          (channel.banner && channel.banner.url) ||
            (loading && noBanner === false)
        "
        class="pt-3"
      >
        <v-responsive :aspect-ratio="1903 / 315" class="rounded-lg">
          <v-img
            v-if="channel.banner"
            :src="channel.banner.url"
            width="100%"
            contain
          ></v-img>
        </v-responsive>
      </v-row>
    </v-expand-transition>
    <v-row class="justify-center mt-0" style="min-height: 132px">
      <v-col md="11" xl="8" class="secondary mt-0 mt-4 rounded-lg">
        <div
          v-if="channel.name"
          class="d-flex align-center pa-1 channel-block flex-column flex-sm-row"
        >
          <channel-avatar
            :thumbnails="channel.thumbnails"
            :channel-name="channel.name"
            bg-color="primary"
            :object="channel"
          ></channel-avatar>
          <div class="ml-2">
            <div class="channel-name text-h5">
              {{ channel.name }}
              <v-tooltip
                right
                v-if="!channel.owned && channel.status === 'active'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="warning" v-bind="attrs" v-on="on">
                    mdi-information
                  </v-icon>
                </template>
                <div>
                  {{ $tc('channel.channel-protected-by-crowdbunker-team') }}
                  <br />
                  {{ $tc('channel.author-can-register-regain-control') }}
                </div>
              </v-tooltip>
            </div>
            <div
              v-if="channel.status === 'active' && channel.watched"
              class="caption primary--text"
            >
              {{ $tc('channel.protected-since') }}
              {{ formatDate(new Date(channel.createdAt)) }}
            </div>
            <div v-if="channel.censoredAt" class="caption error--text">
              <v-icon small color="error">mdi-alert-octagon</v-icon>
              {{ $tc('channel.censored-by') }}
              <platform-name :platform="channel.platform"></platform-name>
              {{ $tc('channel.the') }}
              {{ formatDate(new Date(channel.censoredAt)) }}
            </div>
          </div>
          <div
            class="ml-sm-auto mt-1 mt-sm-0 justify-center d-inline-flex flex-column align-center"
            v-if="channel.organization"
          >
            <div class="text-caption text-center">
              {{ $tc('channel.a-channel') }}
              <platform-name :platform="channel.platform"></platform-name>
              {{ $tc('channel.by') }}
            </div>
            <router-link
              class="text-decoration-none"
              :to="{
                name: 'Organization',
                params: {
                  slug: channel.organization.uid,
                  noBanner: !channel.organization.hasBanner,
                },
              }"
            >
              <v-sheet class="d-flex align-center channel-block pa-1" rounded>
                <channel-avatar
                  :thumbnails="channel.organization.thumbnails"
                  :channel-name="channel.organization.name"
                  :size="50"
                  :object="channel.organization"
                ></channel-avatar>
                <div class="ml-2 ">
                  <div class="text-truncate">
                    {{ channel.organization.name }}
                  </div>
                  <div class="text-truncate text-caption">
                    @{{ channel.organization.uid }}
                  </div>
                  <v-icon
                    v-if="!!channel?.organization?.ownerMembershipLevel"
                    :color="
                      getColor(channel?.organization?.ownerMembershipLevel)
                    "
                    size="20"
                    class="ml-1"
                    >mdi-seal</v-icon
                  >
                </div>
              </v-sheet>
            </router-link>
            <subscription-button
              v-if="channel.organization.status === 'active'"
              :organization="channel.organization"
              class="mt-2"
            ></subscription-button>
          </div>
        </div>
        <v-skeleton-loader
          v-else
          type="list-item-avatar"
          width="50%"
          :boilerplate="!loading"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col md="11" xl="8">
        <v-row>
          <v-col>
            <div v-if="notFound" class="text-h5 text-center primary--text">
              {{ $tc('error.this-channel-not-found') }}
            </div>
            <div
              v-else-if="channel.status === 'disabled'"
              class="text-center primary--text"
            >
              <v-alert color="error darken-2">
                <div class="text-h5">
                  {{ $tc('error.this-channel-is-disabled') }}
                </div>
                <div v-if="channel.statusReason">
                  {{ channel.statusReason }}
                </div>
              </v-alert>
            </div>
            <div
              v-else-if="channel.status === 'deleted'"
              class="text-center primary--text"
            >
              <v-alert color="error darken-2">
                <div class="text-h5">
                  {{ $tc('error.this-channel-has-been-deleted') }}
                </div>
                <div v-if="channel.statusReason">
                  {{ channel.statusReason }}
                </div>
              </v-alert>
            </div>
            <div
              v-else-if="hasError && posts.length === 0 && !videosLoading"
              class="text-h5 text-center primary--text"
            >
              {{ $tc('error.an-error-has-occurred') }}
            </div>
            <div v-else class="overline">
              {{ $tc('channel.latest-publications') }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <post-item
            v-for="post in posts"
            :key="post.uid"
            :post="post"
            cols="12"
            sm="6"
            md="4"
            no-channel
          ></post-item>
          <template v-if="videosLoading && channel.status !== 'deleted'">
            <post-item-loader
              v-for="item in 8"
              :key="item"
              cols="12"
              sm="6"
              md="4"
            ></post-item-loader>
          </template>
          <v-col
            sm="4"
            md="3"
            v-intersect="{
              handler: onIntersectEnd,
              options: {
                rootMargin: '0px 0px 500px 0px',
              },
            }"
          ></v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import { requestService } from '@/services/request.service.js'
import ChannelAvatar from '@/components/ChannelAvatar'
import PlatformName from '@/components/PlatformName'
import PostItem from '@/components/PostItem'
import PostItemLoader from '@/components/PostItemLoader'
import SubscriptionButton from '@/components/SubscriptionButton'
import { subscriptionService } from '@/services/subscription.service'

export default {
  name: 'Channel',
  components: {
    SubscriptionButton,
    PostItemLoader,
    PostItem,
    PlatformName,
    ChannelAvatar,
  },
  data() {
    return {
      channel: {},
      posts: [],
      loading: true,
      videosLoading: true,
      queryLast: null,
      queryTotal: 0,
      notFound: false,
      hasError: false,
      endReached: false,
      noBanner: false,
    }
  },
  metaInfo() {
    if (this.channel.name === undefined) {
      return {}
    }
    return {
      title: `${this.channel.name} - CrowdBunker`,
      meta: [
        {
          property: 'og:title',
          vmid: 'og:title',
          content: `${this.channel.name} - CrowdBunker`,
        },
        {
          property: 'twitter:title',
          vmid: 'twitter:title',
          content: `${this.channel.name} - CrowdBunker`,
        },
        {
          name: 'description',
          vmid: 'description',
          content:
            this.channel.description || this.$tc('home.freedom-of-expression'),
        },
        {
          property: 'og:description',
          vmid: 'og:description',
          content:
            this.channel.description || this.$tc('home.freedom-of-expression'),
        },
        { property: 'og:image', vmid: 'og:image', content: this.thumbnail.url },
        {
          property: 'twitter:image',
          vmid: 'twitter:image',
          content: this.thumbnail.url,
        },
        {
          property: 'og:url',
          vmid: 'og:url',
          content: this.channelUrl,
        },
        {
          property: 'twitter:url',
          vmid: 'twitter:url',
          content: this.channelUrl,
        },
      ],
    }
  },
  computed: {
    thumbnail() {
      if (this.channel.thumbnails === undefined) {
        return {}
      }
      return (
        this.channel.thumbnails.find(
          thumbnail => thumbnail.name === 'medium',
        ) || {}
      )
    },
    channelUrl() {
      return (
        window.location.origin +
        this.$router.resolve({
          name: 'Channel',
          params: { slug: this.channel.id },
        }).href
      )
    },
  },
  methods: {
    getColor(level) {
      return subscriptionService.subscriptionLevelColor(level)
    },
    getChannelInfos() {
      this.loading = true
      this.notFound = false
      this.hasError = false
      requestService
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/channel/${this.$route.params.slug}/details`,
        )
        .then(response => {
          this.channel = response
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            this.notFound = true
          } else {
            this.hasError = true
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    getLastVideos() {
      this.videosLoading = true
      requestService
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/channel/${this.$route.params.slug}/posts`,
          {
            params: {
              after: this.queryLast,
            },
          },
        )
        .then(response => {
          Array.prototype.push.apply(this.posts, response.items)
          if (response.last !== null) {
            this.queryLast = response.last
          } else {
            this.endReached = true
          }
          this.queryTotal = response.total
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            this.notFound = true
          } else {
            this.hasError = true
          }
        })
        .finally(() => {
          this.videosLoading = false
        })
    },
    onIntersectEnd() {
      if (this.videosLoading || this.endReached) {
        return
      }
      this.getLastVideos()
    },
    formatDate(date, hour = false) {
      return new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: hour ? 'numeric' : undefined,
        minute: hour ? 'numeric' : undefined,
      }).format(new Date(date))
    },
    watchNewPosts() {
      this.$root.$on('postCreated', post => {
        if (post.channel.id === this.channel.id) {
          this.posts.unshift(post)
        }
      })
    },
  },
  created() {
    if (this.$route.params.noBanner) {
      this.noBanner = this.$route.params.noBanner
    }
  },
  mounted() {
    this.getChannelInfos()
    this.getLastVideos()
    this.watchNewPosts()
  },
  beforeRouteUpdate(to, from, next) {
    this.$root.$emit('reload')
    next()
  },
}
// ok
</script>

<style scoped lang="scss">
.v-skeleton-loader {
  &::v-deep {
    .v-skeleton-loader__list-item-avatar {
      height: 100px;
      padding: 2px;

      .v-skeleton-loader__avatar {
        height: 100px;
        width: 100px;
      }

      .v-skeleton-loader__text {
        height: 25px;
      }
    }

    .v-skeleton-loader__image {
      height: 100%;
    }
  }
}
</style>
