<template>
  <video-button
    text="Télécharger"
    icon="download"
    :tooltip="$tc('video.download-video')"
    @click="retrieveVideo"
  >
    <template>
      <v-progress-circular
        v-if="isRetrieving || loading"
        size="20"
        width="2"
        indeterminate
        color="success"
        class="mr-1 progress-download"
        style="font-size: 9px"
      >
        {{ percentage }}
      </v-progress-circular>
      <v-icon
        v-if="retrieved"
        color="success"
        small
        left
        class="mr-1"
        :class="{ 'icon-hidden': isRetrieving || loading }"
        >mdi-check-circle</v-icon
      >
      <v-icon
        v-else
        small
        left
        class="mr-1"
        :class="{ 'icon-hidden': isRetrieving || loading }"
        >mdi-download</v-icon
      >
      {{ $tc('video.download') | capitalize }}
    </template>
  </video-button>
</template>
<script>
import { requestService } from '@/services/request.service'
import axios from 'axios'
import VideoButton from '@/components/video/VideoButton.vue'

export default {
  name: 'RetrieveVideo',
  components: { VideoButton },
  props: {
    video: { type: Object },
  },
  data() {
    return {
      loading: false,
      isRetrieving: false,
      retrieved: false,
      percentage: 0,
    }
  },
  methods: {
    async retrieveVideo() {
      if (this.isRetrieving || this.loading) return
      console.log('retrieveVideo')
      this.loading = true

      const url = `/video/${this.video.id}/retrieve`
      requestService
        .get(url)
        .then(async response => {
          // this.video.retrieveCount = response.data.retrieveCount
          if (response.success && response.data.url) {
            this.loading = false
            await this.sendVideoFordownload(
              response.data.url,
              response.data.url.split('/').pop(),
            )
          }
          this.$set(this.video, 'retrieveCount', response.data.retrieveCount)
        })
        .catch(error => {
          this.loading = false
          this.$store.dispatch('showError', error)
        })
    },
    // async sendVideoFordownload(url, filename) {
    //   const blob = await fetch(url).then(response => response.blob())

    //   const file = window.URL.createObjectURL(blob)
    //   const anchor = document.createElement('a')
    //   anchor.href = file
    //   anchor.download = filename ?? 'video.mp4'

    //   anchor.click()
    // },
    async sendVideoFordownload(url, filename) {
      this.isRetrieving = true
      try {
        const response = await axios.get(url, {
          responseType: 'blob',
          onDownloadProgress: progressEvent => {
            this.percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            )
            if (this.percentage === 100) {
              this.isRetrieving = false
              this.retrieved = true
            }
            // Update the progress bar with the percentage value
          },
        })

        const fileBlob = response.data
        const downloadLink = document.createElement('a')
        downloadLink.href = URL.createObjectURL(fileBlob)
        downloadLink.download = filename || 'file'

        downloadLink.click()
        URL.revokeObjectURL(downloadLink.href)
      } catch (error) {
        this.isRetrieving = false
        console.error('Error while downloading the file:', error)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.icon-hidden {
  opacity: 0;
  visibility: hidden;
}
.progress-download {
  position: absolute;
  left: -5px;
}
</style>
