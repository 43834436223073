<template>
  <v-row>
    <list-item
      v-for="list in lists"
      :key="list.uid"
      :list="list"
      :list-page="organizationUid === null"
      cols="12"
      sm="6"
      md="4"
    ></list-item>
    <template v-if="listsLoading">
      <post-item-loader
        v-for="item in 8"
        :key="item"
        cols="12"
        sm="6"
        md="4"
      ></post-item-loader>
    </template>
    <v-col
      v-if="hasMoreLists"
      cols="12"
      class="text-center"
      v-intersect="{
        handler: () => fetchPaginatedLists(),
        rootMargin: '500px 0px 0px 0px',
      }"
    >
      <v-btn
        :loading="listsLoading"
        :disabled="listsLoading"
        @click="fetchPaginatedLists"
        color="primary"
        text
      >
        {{ $t('button.see-more') }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import ListItem from '@/components/list/ListItem.vue'
import PostItemLoader from '@/components/PostItemLoader.vue'

export default {
  name: 'ListList',
  components: { PostItemLoader, ListItem },
  props: {
    organizationUid: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      lists: [],
      listsLoading: false,
      hasError: false,
      hasMoreLists: false,
    }
  },
  methods: {
    fetchPaginatedLists() {
      if (this.listsLoading) {
        return
      }
      const params = {
        offset: this.lists.length,
        organizationUid: this.organizationUid,
      }
      this.listsLoading = true
      const action = this.organizationUid
        ? 'list/fetchOrganizationLists'
        : 'list/fetchLoggedInUserLists'
      this.$store
        .dispatch(action, params)
        .then(response => {
          this.lists.push(...this.formatLists(response.lists))
          this.hasMoreLists = response.lists.length > 0 && response.hasMore
        })
        .catch(() => {
          this.hasError = true
        })
        .finally(() => {
          this.listsLoading = false
        })
    },
    formatLists(lists) {
      // if list is default, set the name to the translated name for watch-later
      return lists.map(list => {
        if (list.isDefault) {
          list.name = this.$tc('list.watch-later')
          list.uid = 'watch-later'
        }
        return list
      })
    },
  },
  mounted() {
    this.fetchPaginatedLists()
  },
}
</script>

<style scoped></style>
