
import { defineComponent } from 'vue'
import { requestService } from '@/services/request.service'
import PostPreview from '@/components/post/PostPreview.vue'
import PostMenu from '@/components/PostMenu.vue'

export default defineComponent({
  name: 'ListCard',
  components: { PostMenu, PostPreview },
  props: {
    listUid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      items: [],
      list: {},
      itemsLoading: false,
      hasError: false,
      hasNext: false,
      hasPrevious: false,
    }
  },
  methods: {
    fetchListItems(direction = null) {
      this.itemsLoading = true
      const params = {
        listUid: this.listUid,
        postUid: this.$route.params.slug,
        direction: null,
      }
      if (this.items.length && direction !== null) {
        params.direction = direction
        if (direction === 'previous') {
          params.postUid = this.items[0].post.uid
        } else {
          params.postUid = this.items[this.items.length - 1].post.uid
        }
      }
      this.$store
        .dispatch('list/fetchListItemsNear', params)
        .then(response => {
          if (direction === 'previous') {
            this.items.unshift(...response.items)
            this.hasPrevious = response.hasPrevious
          } else if (direction === 'next') {
            this.items.push(...response.items)
            this.hasNext = response.hasNext
          } else {
            this.items = response.items
            this.hasPrevious = response.hasPrevious
            this.hasNext = response.hasNext
          }
          if (response.list.isDefault) {
            response.list.name = this.$t('list.watch-later')
            response.list.uid = 'watch-later'
          }
          this.list = response.list
        })
        .catch(() => {
          this.hasError = true
        })
        .finally(() => {
          this.itemsLoading = false
        })
    },
    getVisibilityIcon(visibility) {
      switch (visibility) {
        case 'unlisted':
          return 'mdi-eye-off'
        case 'private':
          return 'mdi-lock'
        default:
          return 'mdi-earth'
      }
    },
    getVisibilityText(visibility) {
      let list = this.$tc('list.list')
      list = list.charAt(0).toUpperCase() + list.slice(1) + ' '
      switch (visibility) {
        case 'unlisted':
          return list + this.$t('visibility.unlisted')
        case 'private':
          return list + this.$t('visibility.private')
        default:
          return list + this.$t('visibility.public')
      }
    },
    getLink(item) {
      const routeName = item.post.video ? 'VideoPost' : 'Post'
      return {
        name: routeName,
        params: { slug: item.post.uid, post: item.post },
        query: { list: this.list.uid },
      }
    },
    itemRemovedFromList(event) {
      if (event.list.uid === this.list.uid) {
        this.items.splice(
          this.items.findIndex(item => item.post.uid === event.postUid),
          1,
        )
      }
    },
    getItemPosition(item) {
      if (this.list.isDefault) {
        return this.list.itemsCount - item.position + 1
      }
      return item.position
    },
  },
  created() {
    this.fetchListItems()
  },
})
