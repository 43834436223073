var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-expand-transition',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(
        (_vm.organization.banner && _vm.organization.banner.url) ||
          (_vm.loading && _vm.noBanner === false)
      ),expression:"\n        (organization.banner && organization.banner.url) ||\n          (loading && noBanner === false)\n      "}],staticClass:"pt-3"},[_c('v-responsive',{staticClass:"responsive-banner",attrs:{"aspect-ratio":1903 / 315}},[(_vm.organization.banner)?_c('v-img',{attrs:{"src":_vm.organization.banner.url,"width":"100%","contain":""}}):_vm._e()],1)],1)],1),_vm._v(" "),_c('v-row',{staticClass:"justify-center mt-0",staticStyle:{"min-height":"132px"}},[_c('v-col',{staticClass:"pb-0 secondary top-block mt-0 mt-sm-4",attrs:{"md":"11","xl":"8"}},[(_vm.organization.name)?_c('div',{staticClass:"d-flex align-center pa-1 channel-block flex-column flex-sm-row"},[_c('channel-avatar',{attrs:{"thumbnails":_vm.organization.thumbnails,"channel-name":_vm.organization.name,"object":_vm.organization}}),_vm._v(" "),_c('div',{staticClass:"ml-2"},[_c('div',{staticClass:"channel-name text-h5"},[_vm._v("\n            "+_vm._s(_vm.organization.name)+"\n            "),(!!_vm.organization.ownerMembershipLevel)?_c('v-icon',{attrs:{"color":_vm.getColor(_vm.organization.ownerMembershipLevel)}},[_vm._v("mdi-seal")]):_vm._e(),_vm._v(" "),(!_vm.organization.owned && _vm.organization.status === 'active')?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v("\n                  mdi-information\n                ")])]}}],null,false,479189807)},[_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(
                  _vm.$tc('organization.organization-created-by-crowdbunker')
                )}})]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"text-truncate text-caption"},[_vm._v("\n            @"+_vm._s(_vm.organization.uid)+"\n            "),(_vm.organization.subscribersCount)?_c('span',[_vm._v("\n              ·\n              "+_vm._s(_vm.formatShortNumber(_vm.organization.subscribersCount))+"\n              "+_vm._s(_vm.$tc('organization.subscriber', _vm.organization.subscribersCount)))]):_vm._e()]),_vm._v(" "),_c('div',[_c('trust-score',{attrs:{"score":_vm.organization.trustScore,"is-user":false}})],1),_vm._v(" "),(_vm.organization.status === 'active' && _vm.organization.watched)?_c('div',{staticClass:"caption primary--text"},[_vm._v("\n            "+_vm._s(_vm.$tc('channel.protected-since'))+"\n            "+_vm._s(_vm.formatDate(new Date(_vm.organization.createdAt)))+"\n          ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"d-inline-flex ml-auto overflow-x-auto overflow-y-hidden ml-sm-auto mt-1 mt-sm-0"},[(
              _vm.isLoggedIn &&
                _vm.organization &&
                _vm.organization.membershipGroups &&
                _vm.organization.membershipGroups.length > 0 &&
                !_vm.organization.isAMemberOfOrganization
            )?_c('membership-button',{attrs:{"organization":_vm.organization}}):_vm._e(),_vm._v(" "),(_vm.organization.status === 'active')?_c('subscription-button',{attrs:{"organization":_vm.organization}}):_vm._e()],1)],1):_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar","width":"50%","boilerplate":!_vm.loading}}),_vm._v(" "),(
          !_vm.organization.monetizationActive &&
            _vm.organization.currentUserIsAdminOfThisOrganization
        )?_c('div',{staticClass:"mt-3"},[_c('monetization-alert',{attrs:{"organization":_vm.organization}})],1):_vm._e(),_vm._v(" "),(_vm.loading || _vm.organization.status === 'active')?_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab,key){return _c('v-tab',{key:key,attrs:{"to":{ name: tab.routeName },"exact":""},domProps:{"textContent":_vm._s(tab.tabName)}})}),1):_vm._e()],1)],1),_vm._v(" "),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"md":"11","xl":"8"}},[_c('v-row',[_c('v-col',[(_vm.notFound)?_c('div',{staticClass:"text-h5 text-center primary--text"},[_vm._v("\n            "+_vm._s(_vm.$tc(
                'organization.organization-doesnt-exist-or-changed-username',
              ))+"\n          ")]):(_vm.organization.status === 'disabled')?_c('div',{staticClass:"text-center primary--text"},[_c('v-alert',{attrs:{"color":"error darken-2"}},[_c('div',{staticClass:"text-h5"},[_vm._v("\n                "+_vm._s(_vm.$tc('organization.deactivated-organization'))+"\n              ")]),_vm._v(" "),(_vm.organization.statusReason)?_c('div',[_vm._v("\n                "+_vm._s(_vm.organization.statusReason)+"\n              ")]):_vm._e()])],1):(_vm.organization.status === 'deleted')?_c('div',{staticClass:"text-center primary--text"},[_c('v-alert',{attrs:{"color":"error darken-2"}},[_c('div',{staticClass:"text-h5"},[_vm._v("\n                "+_vm._s(_vm.$tc('error.this-channel-has-been-deleted'))+"\n              ")]),_vm._v(" "),(_vm.organization.statusReason)?_c('div',[_vm._v("\n                "+_vm._s(_vm.organization.statusReason)+"\n              ")]):_vm._e()])],1):(_vm.hasError)?_c('div',{staticClass:"text-h5 text-center primary--text"},[_vm._v("\n            "+_vm._s(_vm.$tc('error.an-error-has-occurred'))+"\n          ")]):_vm._e()])],1),_vm._v(" "),(_vm.loading || _vm.organization.status === 'active')?_c('v-tabs-items',{staticClass:"transparent",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab,key){return _c('v-tab-item',{key:key,attrs:{"value":_vm.$router.resolve({ name: tab.routeName }).href}},[_c('div',{staticClass:"overline",staticStyle:{"min-height":"2rem"}},[_vm._v(_vm._s(tab.title))]),_vm._v(" "),_c(tab.component,_vm._b({tag:"component"},'component',tab.args,false))],1)}),1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }