<template>
  <v-row justify="center">
    <v-col cols="12" sm="9" md="7" lg="6" style="max-width: 600px">
      <slot>
        <div class="overline">
          {{ title }}
        </div>
      </slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
  },
}
</script>

<style scoped lang="scss"></style>
