<template>
  <span v-bind="$attrs">{{ formattedName }}</span>
</template>

<script>
export default {
  name: 'PlatformName',
  props: {
    platform: String,
  },
  computed: {
    formattedName() {
      const formatted = {
        youtube: 'YouTube',
        internal: 'CrowdBunker',
      }
      return formatted[this.platform] !== undefined
        ? formatted[this.platform]
        : this.platform
    },
  },
}
</script>

<style scoped></style>
