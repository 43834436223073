<template>
  <v-navigation-drawer
    app
    clipped
    floating
    color="background"
    :value.sync="open"
    @input="$emit('update:open', $event)"
  >
    <v-list dense rounded>
      <v-subheader class="pl-3 text-uppercase">{{
        $tc('channel.organization', 2)
      }}</v-subheader>
      <v-item-group>
        <v-list-item :to="{ name: 'MyOrganizations' }">
          <v-list-item-content>{{
            $tc('organization.my-organizations') | capitalize
          }}</v-list-item-content>
        </v-list-item>
      </v-item-group>
      <v-subheader class="pl-3 text-uppercase">{{
        $tc('channel.channel', 2)
      }}</v-subheader>
      <v-item-group>
        <v-list-item :to="{ name: 'InternalChannels' }">
          <v-list-item-content>CrowdBunker</v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'YouTubeChannels' }">
          <v-list-item-content>YouTube</v-list-item-content>
        </v-list-item>
      </v-item-group>
    </v-list>
    <template #append>
      <div class="d-flex align-center justify-center mb-2">
        <v-btn text :to="{ name: 'Home' }" exact>
          <v-icon left>mdi-exit-to-app</v-icon>
          {{ $t('label.exit-console') }}
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'ConsoleLeftMenu',
  props: {
    open: { default: null },
  },
}
</script>

<style scoped></style>
