<template>
  <button
    class="v-btn v-btn--fab v-btn--has-bg v-btn--round theme--dark v-size--default primary"
    type="button"
  >
    <span class="v-btn__content">
      <i
        aria-hidden="true"
        class="v-icon notranslate mdi mdi-play theme--dark"
        style="font-size: 36px;"
      ></i>
    </span>
  </button>
</template>

<script>
export default {
  name: 'poster',
}
</script>
