<template>
  <div class="grid-container">
    <post-item
      v-for="post in formattedPosts"
      :key="post.uid"
      :ref="`post-${post.uid}`"
      :post="post"
      class="py-0 py-sm-1 px-0 px-sm-2 grid-post-item"
      show-organization
    ></post-item>
    <template v-if="loading">
      <post-item-loader
        v-for="item in 16"
        :key="item"
        style="min-width: 300px"
      ></post-item-loader>
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import PostItem from '@/components/PostItem.vue'
import PostItemLoader from '@/components/PostItemLoader.vue'
import { requestService } from '@/services/request.service'

export default defineComponent({
  name: 'PostGrid',
  components: { PostItemLoader, PostItem, RecycleScroller },
  props: {
    posts: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    endReached: {
      type: Boolean,
      default: false,
    },
    simplified: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      observer: null,
      visiblePosts: new Set(),
      invisiblePosts: new Set(),
      acknowledgedPosts: new Set(),
    }
  },
  computed: {
    formattedPosts() {
      if (!this.posts || !Array.isArray(this.posts) || this.posts.length === 0)
        return []
      if (!this.simplified) {
        return this.posts
      }
      return this.posts
        .map(post => {
          const formattedPost = {
            ...post,
            uid: post.uid,
            publishedAt: (post.publishedAtTimestamp || 0) * 1000,
            channel: {
              organization: {
                name: post.channelName,
                thumbnails: post.channelThumbnailUrl,
                uid: post.channelUid,
                avatarColor: post.avatarColor,
              },
            },
          }
          if (post.title && post.mediaType !== 'channel') {
            formattedPost.video = {
              title: post.title,
              description: post.textContent,
              thumbnails: post.thumbnailUrl,
              duration: post.videoDuration,
              lastStop: post.lastStop,
              hlsManifest: {
                url: post.hlsUrl,
              },
              id: post.uid,
            }
          }
          return formattedPost
        })
        .filter(post => post.uid)
    },
  },
  methods: {
    initObserver() {
      this.observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            const uid = entry.target.dataset.uid
            if (entry.isIntersecting) {
              this.visiblePosts.add(uid)
              this.invisiblePosts.delete(uid)
            } else {
              this.invisiblePosts.add(uid)
              this.visiblePosts.delete(uid)
            }
          })

          if (this.visiblePosts.size > 0) {
            clearTimeout(this.fetchDataTimer)
            this.fetchDataTimer = setTimeout(() => {
              this.acknowledgeVisiblePosts()
            }, 1000)
          }
        },
        {
          threshold: 1.0,
        },
      )
    },
    observePosts() {
      this.formattedPosts.forEach(post => {
        const postElement = this.$refs[`post-${post.uid}`][0].$el
        postElement.dataset.uid = post.uid
        this.observer.observe(postElement)
      })
    },
    acknowledgeVisiblePosts() {
      if (!this.$store.state.account?.status?.loggedIn) {
        return
      }

      const postsToSend = Array.from(this.visiblePosts).filter(
        uid => !this.acknowledgedPosts.has(uid),
      )

      if (postsToSend.length > 0) {
        // Your AJAX call or other asynchronous operations here
        requestService
          .post('/post/acknowledge-posts', {
            postUids: postsToSend,
          })
          .catch(error => {
            console.error(error)
          })

        postsToSend.forEach(uid => this.acknowledgedPosts.add(uid))
      }
    },
  },
  mounted() {
    this.initObserver()
    this.observePosts()
  },
  watch: {
    posts() {
      this.$nextTick(() => {
        this.observePosts()
      })
    },
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect()
    }
  },
})
</script>

<style scoped lang="scss">
.scroller {
  height: 100%;
  width: 100%;
  .user {
    height: 32px;
    width: 100%;
    padding: 0 12px;
    display: flex;
    align-items: center;
  }
}
.grid-container {
  display: grid;
  //grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-columns: repeat(
    auto-fit,
    minmax(min(100%/1, max(300px, 100%/7)), 1fr)
  );
  grid-gap: 10px;
  max-width: 2800px;
  margin: auto;
}

.grid-post-item {
  max-width: 500px;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .grid-post-item {
    max-width: 100%;
  }
}
</style>
