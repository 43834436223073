<template>
  <div>
    <!--    <div v-if="post.textContent" style="height: 38px">-->
    <!--      {{ post.textContent }}-->
    <!--    </div>-->
    <text-post-preview
      v-if="post.textContent && !large && !noText"
      :post="post"
    >
    </text-post-preview>
    <text-post-inset
      v-else-if="post.textContent && large && !noText"
      :text="post.textContent"
      :text-limit="250"
      :uid="post.uid"
      large
      no-big-font
      class="mb-2"
    ></text-post-inset>
    <div v-if="!large">
      <div v-if="images.length === 1">
        <v-img
          :src="images[0].url"
          :aspect-ratio="getAspectRatio()"
          class="rounded-lg"
        ></v-img>
      </div>
      <v-responsive
        v-else-if="images.length === 2"
        :aspect-ratio="getAspectRatio()"
      >
        <v-row class="my-0" style="height: 100%">
          <v-col cols="6" class="py-0 px-1">
            <v-img :src="images[0].url" height="100%"></v-img>
          </v-col>
          <v-col cols="6" class="py-0 px-1">
            <v-img :src="images[1].url" height="100%"></v-img>
          </v-col>
        </v-row>
      </v-responsive>
      <div v-else-if="images.length === 3">
        <v-row class="my-0">
          <v-col cols="12" class="pb-1 pt-0">
            <v-img
              :src="images[0].url"
              height="100%"
              :aspect-ratio="getAspectRatio(false, true)"
            ></v-img>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col cols="6" class="pb-0 pt-1 pr-1">
            <v-img
              :src="images[1].url"
              height="100%"
              :aspect-ratio="getAspectRatio(true, true)"
            ></v-img>
          </v-col>
          <v-col cols="6" class="pb-0 pt-1 pl-1">
            <v-img
              :src="images[2].url"
              height="100%"
              :aspect-ratio="getAspectRatio(true, true)"
            ></v-img>
          </v-col>
        </v-row>
      </div>

      <div v-else-if="images.length >= 4">
        <v-row class="my-0">
          <v-col cols="6" class="pb-1 pt-0 pr-1">
            <v-img
              :src="images[0].url"
              height="100%"
              :aspect-ratio="getAspectRatio(true, true)"
            ></v-img>
          </v-col>
          <v-col cols="6" class="pb-1 pt-0 pl-1">
            <v-img
              :src="images[1].url"
              height="100%"
              :aspect-ratio="getAspectRatio(true, true)"
            ></v-img>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col cols="6" class="pb-0 pt-1 pr-1">
            <v-img
              :src="images[2].url"
              height="100%"
              :aspect-ratio="getAspectRatio(true, true)"
            ></v-img>
          </v-col>
          <v-col cols="6" class="pb-0 pt-1 pl-1">
            <v-img
              :src="images[3].url"
              height="100%"
              :aspect-ratio="getAspectRatio(true, true)"
            ></v-img>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else-if="images.length > 1">
      <v-divider></v-divider>
      <v-carousel @change.stop.prevent v-model="carouselItem">
        <template #prev="{on}">
          <v-btn icon v-on="on" @click.stop.prevent>
            <v-icon large>mdi-chevron-left</v-icon>
          </v-btn>
        </template>
        <template #next="{on}">
          <v-btn icon v-on="on" @click.stop.prevent>
            <v-icon large>mdi-chevron-right</v-icon>
          </v-btn>
        </template>
        <v-carousel-item v-for="image in images" :key="image.cid">
          <v-img
            :src="image.url"
            :lazy-src="image.url"
            height="100%"
            contain
            @click="openMediaDialog"
          ></v-img>
        </v-carousel-item>
      </v-carousel>
      <media-dialog
        v-model="mediaDialog"
        :images="images"
        :carousel-item="dialogCarouselItem"
      ></media-dialog>
    </div>
    <div v-else>
      <v-img
        :src="images[0].url"
        :lazy-src="images[0].url"
        height="100%"
        max-height="400px"
        min-height="400px"
        contain
        @click="openMediaDialog"
        class="rounded-lg"
      >
      </v-img>
      <media-dialog
        v-model="mediaDialog"
        :images="images"
        :carousel-item="dialogCarouselItem"
      ></media-dialog>
    </div>
  </div>
</template>

<script>
import TextPostInset from '@/components/TextPostInset'
import MediaDialog from '@/components/MediaDialog'
import TextPostPreview from '@/components/TextPostPreview'
export default {
  name: 'ImagesInset',
  components: { TextPostPreview, MediaDialog, TextPostInset },
  props: {
    post: { type: Object },
    large: Boolean,
    noText: Boolean,
    sixteenNine: Boolean,
  },
  data() {
    return {
      mediaDialog: false,
      carouselItem: 0,
      dialogCarouselItem: 0,
    }
  },
  computed: {
    images() {
      if (!this.post.images) {
        return [
          {
            cid: 0,
            url: this.post.thumbnailUrl,
            name: 'default',
            status: 'original',
          },
        ]
      }
      let list = []
      list = this.post.images.filter(image => {
        return (
          image.name === (this.large ? 'large' : 'default') &&
          image.status !== 'original'
        )
      })
      if (list.length === 0) {
        list = this.post.images.filter(image => {
          return image.status === 'original'
        })
      }
      return [...new Map(list.map(item => [item['cid'], item])).values()]
    },
    aspectRatio() {
      let x = 320
      let y = 205
      if (this.post.textContent) {
        x = 16
        y = 9
      }
      if (this.images.length === 3) {
        y = y / 2
      }
      return x / y
    },
  },
  methods: {
    getAspectRatio(halfX = false, halfY = false) {
      let x = 320
      let y = 215
      if (this.post.textContent || this.sixteenNine) {
        x = 16
        y = 9
      }
      if (halfX) {
        x = x / 2
      }
      if (halfY) {
        y = y / 2
      }
      return x / y
    },
    openMediaDialog() {
      this.dialogCarouselItem = this.carouselItem
      this.mediaDialog = true
    },
  },
}
</script>

<style scoped lang="scss">
.list-text-block {
  min-height: 32px !important;
  height: 32px;
}
</style>
