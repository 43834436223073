<template>
  <v-app>
    <div class="background-gradient"></div>
    <HeaderBar
      @switch-left-menu="leftMenuOpen = !leftMenuOpen"
      has-left-menu
    ></HeaderBar>
    <console-left-menu :open.sync="leftMenuOpen"></console-left-menu>
    <v-main id="main" class="">
      <v-container
        v-show="$store.state.centralLoading"
        class="fill-height justify-center"
      >
        <v-progress-circular size="80" indeterminate></v-progress-circular>
      </v-container>
      <v-container
        v-show="!$store.state.centralLoading"
        fluid
        :class="{ 'fill-height': !!$route.meta.fillHeight }"
      >
        <slot />
      </v-container>
      <slot name="outside"></slot>
    </v-main>
    <v-footer class="font-weight-medium mt-4" color="transparent" padless>
      <v-col class="text-center footer font-weight-light px-0" cols="12">
        <v-divider class="mb-2 d-none"></v-divider>
        <strong>{{ $tc('chat.want-chat-with-us') + ' ?' }}</strong>
        <a
          href="mailto:contact@crowdbunker.com"
          class="mx-1 text-decoration-none"
          >contact@crowdbunker.com</a
        >
        |
        <span class="ml-1">{{ $tc('home.freedom-of-expression') }}</span>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import HeaderBar from '../components/HeaderBar'
import ConsoleLeftMenu from '../components/ConsoleLeftMenu'

export default {
  name: 'ConsoleLayout',
  components: { ConsoleLeftMenu, HeaderBar },
  data: () => ({
    leftMenuOpen: null,
  }),
  watch: {
    '$route.meta.leftMenu'(value) {
      if (
        !this.$store.state.leftMenuManuallySet &&
        typeof value === 'boolean' &&
        this.$vuetify.breakpoint.mdAndUp
      ) {
        setTimeout(() => {
          this.leftMenuOpen = value
        }, 600)
      }
    },
  },
  created() {
    if (this.$route.meta.leftMenu === false) {
      this.leftMenuOpen = false
    }
  },
}
</script>

<style scoped lang="scss">
#main {
  background-color: var(--v);
}
.footer {
  opacity: 0.7;
  font-size: 14px;
  a {
    color: inherit;
  }
}
.background-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 27, 41);
  background: linear-gradient(
    180deg,
    rgba(0, 27, 41, 1) 0%,
    rgba(1, 22, 33, 1) 100%
  );
}
</style>
