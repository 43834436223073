<template>
  <iframe
    :src="iframeSrc"
    class="youtube"
    :class="{ 'player-embed': embed }"
    ref="youtubePlayer"
    width="100%"
    height="100%"
    frameborder="0"
    @load="$emit('update:loading', false)"
    allowfullscreen
  ></iframe>
</template>

<script>
// import '@/assets/js/iframe_api'
export default {
  name: 'Youtube',
  data() {
    return {
      player: null,
    }
  },
  props: {
    externalId: String,
    loading: Boolean,
    statTimeout: { type: Number, default: null },
    embed: Boolean,
  },
  computed: {
    iframeSrc() {
      return `https://www.youtube-nocookie.com/embed/${this.externalId}?origin=${window.location.origin}&controls=1&autoplay=1&iv_load_policy=3&modestbranding=1&playsinline=1&showinfo=0&rel=0&enablejsapi=1&customControls=true&noCookie=true&widgetid=2&widget_referrer=${window.location.href}`
    },
  },
  methods: {
    initPlayer() {
      // eslint-disable-next-line no-undef
      if (typeof YT === 'undefined') {
        setTimeout(this.initPlayer, 500)
      }
      // eslint-disable-next-line no-undef
      this.player = new YT.Player(this.$refs.youtubePlayer, {
        events: {
          onReady: event => {
            this.$emit('update:loading', false)
            event.target.playVideo()
            if (this.player.playerInfo.playerState === -1) {
              this.$emit('error')
              console.log('error', this.player.playerInfo)
            }
          },
          onStateChange: state => {
            // play
            if (state.data === 1) {
              clearTimeout(this.statTimeout)
              this.$emit(
                'update:statTimeout',
                setTimeout(() => {
                  this.$emit('sendViewStat')
                }, 5000),
              )
            } else if (state.data === 2) {
              clearTimeout(this.statTimeout)
            }
          },
          onError: () => this.$emit('error'),
        },
      })
      this.player.addEventListener('onError', error =>
        console.log('onError', error),
      )
    },
  },
  mounted() {
    import('@/assets/js/iframe_api').then(() => {
      this.initPlayer()
    })
  },
}
</script>

<style scoped lang="scss">
iframe.youtube {
  aspect-ratio: 16/9;
  max-height: calc(100vh - 70px);
  &.player-embed {
    min-height: 150px;
    max-height: 100%;
  }
}
</style>
