<template>
  <v-container style="max-width: 500px;width: 100%">
    <v-card>
      <v-card-title class="headline">Premium Subscription Details</v-card-title>
      <v-card-text>
        <div>
          <h2>How It Works</h2>
          <p>
            Our premium subscription offers you exclusive access to a wide range
            of benefits and features. To get started, simply choose a
            subscription plan that suits your needs.
          </p>
        </div>

        <div>
          <h2>Advantages</h2>
          <ul>
            <li>Unlimited access to premium content</li>
            <li>Ad-free experience</li>
            <li>Early access to new features</li>
            <li>Priority customer support</li>
          </ul>
        </div>

        <div>
          <h2>Subscription Periodicity</h2>
          <p>
            You can choose from various subscription periodicities, including
            monthly, quarterly, or annual plans. Pick the one that best fits
            your preferences and budget.
          </p>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: 'PlateformSubscriptionInfos',
  data() {
    return {
      subscription: {
        id: 1,
        user: 'User 1',
        howItWorks:
          'Our premium subscription offers you exclusive access to a wide range of benefits and features...',
        advantages: [
          'Unlimited access to premium content',
          'Ad-free experience',
          'Early access to new features',
          'Priority customer support',
        ],
        periodicity: 'Monthly, Quarterly, Annual',
      },
    }
  },
}
</script>
