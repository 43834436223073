
import { defineComponent } from 'vue'
import VideoButton from '@/components/video/VideoButton.vue'
import AppTooltip from '@/components/AppTooltip.vue'
import { formatNumber } from '@/services/utils.service'

export default defineComponent({
  name: 'PostVoter',
  components: { AppTooltip, VideoButton },
  props: {
    comment: { type: Boolean },
    post: { type: Object, required: true },
  },
  data() {
    return {
      percentage: 50,
      userVote: 'none',
      upVotes: 0,
      downVotes: 0,
      resultVotes: 0,
      upVoteLoading: false,
      downVoteLoading: false,
    }
  },
  computed: {
    disableButtons() {
      return (
        this.upVoteLoading ||
        this.downVoteLoading ||
        (this.post.canEdit && !this.$store.state.account.user.user.isSuperUser)
      )
    },
  },
  methods: {
    sendVote(vote) {
      this.$store
        .dispatch('vote/vote', {
          postUid: this.post.uid,
          vote,
        })
        .then(response => {
          this.userVote = response.userVote
          this.upVotes = response.up !== undefined ? response.up : this.upVotes
          this.downVotes =
            response.down !== undefined ? response.down : this.downVotes
          this.resultVotes =
            response.result !== undefined ? response.result : this.resultVotes
          this.updatePercentage()
        })
        .catch(error => {
          this.$store.dispatch('alert/error', error.error)
        })
        .finally(() => {
          this.upVoteLoading = false
          this.downVoteLoading = false
        })
    },
    upVote() {
      this.upVoteLoading = true
      if (this.userVote === 'up') {
        return this.cancelVote()
      }
      this.sendVote('up')
    },
    downVote() {
      this.downVoteLoading = true
      if (this.userVote === 'down') {
        return this.cancelVote()
      }
      this.sendVote('down')
    },
    cancelVote() {
      this.sendVote('none')
    },
    updatePercentage() {
      const total = this.upVotes + this.downVotes
      if (total === 0) {
        this.percentage = 50
        return
      }
      this.percentage = Math.round((this.upVotes / total) * 100)
    },
    init() {
      this.upVotes = this.post.relevanceVoteTotal?.up || 0
      this.downVotes = this.post.relevanceVoteTotal?.down || 0
      this.resultVotes = this.post.relevanceVoteTotal?.result || 0
      this.userVote = this.post.userVote || 'none'
      this.updatePercentage()
    },
    formatNumber,
  },
  watch: {
    post() {
      this.init()
    },
    'post.relevanceVoteTotal'() {
      this.init()
    },
  },
  created() {
    this.init()
  },
})
