<template>
  <div class="align-self-center" v-bind="$attrs">
    <!--    <v-tooltip bottom color="grey">
      <template v-slot:activator="{ on }">
        <v-btn
          class="d-inline-flex btn-vertical px-1 px-sm-2"
          text
          color="grey"
          @click="shareDialog = true"
          v-on="on"
        >
          <v-icon>mdi-share-variant</v-icon>
          <span
            class="text-caption text-sm-overline"
            v-text="$t('label.share')"
          ></span>
        </v-btn>
      </template>
      {{ $t('label.share') | capitalize }}
    </v-tooltip>-->
    <video-button
      :text="$tc('label.share')"
      icon="share-variant"
      :tooltip="$tc('label.share')"
      @click="shareDialog = true"
    ></video-button>
    <v-dialog width="420px" v-model="shareDialog">
      <v-card>
        <v-card-title>
          {{ $tc('label.share') | capitalize }}
          <v-btn icon small class="ml-auto" @click="shareDialog = false">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="text-caption">{{ $tc('live.video-url') }}</div>
          <v-text-field
            solo-inverted
            hide-details
            single-line
            dense
            readonly
            :value="shareURL"
          >
            <template #append-outer>
              <v-btn
                small
                icon
                v-clipboard:copy="shareURL"
                @click="copyPostUrl($tc('label.copied-url'))"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
          </v-text-field>
          <div class="text-caption mt-4">
            {{ $t('label.anti-censorship-url') }}
          </div>
          <v-text-field
            solo-inverted
            dense
            readonly
            :value="antiCensorshipURL"
            :hint="$t('label.anti-censorship-url-details')"
            persistent-hint
          >
            <template #append-outer>
              <v-btn
                small
                icon
                v-clipboard:copy="antiCensorshipURL"
                @click="copyPostUrl($tc('label.copied-url'))"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
          </v-text-field>
          <div class="mt-4 text-caption">{{ $tc('video.embed-video') }}</div>
          <v-textarea
            id="embed-code-textarea"
            solo-inverted
            hide-details
            rows="6"
            background-color="secondary lighten-2"
            :value="embedCode"
          >
            <template #append-outer>
              <v-btn
                small
                icon
                @click="copyPostUrl($tc('button.embed-code-copied'))"
                v-clipboard:copy="embedCode"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
          </v-textarea>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VideoButton from '@/components/video/VideoButton.vue'

export default {
  name: 'ShareVideo',
  components: { VideoButton },
  props: {
    post: { type: Object },
  },
  data() {
    return {
      shareDialog: false,
    }
  },
  computed: {
    shareURL() {
      return (
        window.location.origin +
        this.$router.resolve({
          name: 'VideoPost',
          params: { slug: this.post.uid, post: this.post },
        }).href
      )
    },
    embedURL() {
      return (
        window.location.origin +
        this.$router.resolve({
          name: 'EmbedVideo',
          params: {
            slug: this.post.embedUid ? this.post.embedUid : this.post.uid,
            post: this.post,
          },
        }).href
      )
    },
    antiCensorshipURL() {
      return `024725476172/${this.post.uid}?=`
    },
    embedCode() {
      return `<iframe 
        width="560"
        height="315"
        src="${this.embedURL}"
        frameborder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        scrolling="no"
      ></iframe>`.replace(/(\s+)/gm, ' ')
    },
  },
  methods: {
    copyPostUrl(message) {
      this.$store.dispatch(
        'alert/success',
        message || this.$tc('label.copied-url'),
      )
    },
  },
}
</script>

<style scoped lang="scss">
.likes-count {
  padding-top: 3px;
}

::v-deep {
  textarea {
    line-height: 19px;
  }
}
</style>
