<template>
  <div>
    <v-menu offset-y v-model="menuOpen">
      <template v-slot:activator="{ on, attrs }">
        <div class="align-self-center">
          <v-btn
            v-if="!inVideo"
            icon
            small
            color="grey"
            v-bind="attrs"
            v-on="on"
            @click.stop.prevent
          >
            <v-icon small>mdi-dots-vertical</v-icon>
          </v-btn>
          <video-button
            v-else
            icon="dots-vertical"
            v-bind="attrs"
            @click="menuOpen = !menuOpen"
          ></video-button>
        </div>
      </template>
      <v-list outlined elevation="0" dense class="pa-0" @mouseover.stop.prevent>
        <v-list-item class="post-menu-item" @click="copyPostUrl" link>
          <v-list-item-icon>
            <v-icon>mdi-content-copy</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-uppercase"
              >{{ $tc('label.copy-link') | capitalize }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          class="post-menu-item"
          link
          v-if="isLoggedIn && !inVideo && inList && inList.canEditItems"
          @click="removeFormList"
        >
          <v-list-item-icon>
            <v-icon>mdi-playlist-remove</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-uppercase"
              >{{ $tc('list.remove-from-this-list') | capitalize }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          class="post-menu-item"
          link
          v-show="isLoggedIn && (!inList || inList.uid !== 'watch-later')"
          @click="addToWatchLater"
        >
          <v-list-item-icon>
            <v-icon>mdi-clock-plus-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-uppercase"
              >{{ $tc('list.watch-later') | capitalize }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          class="post-menu-item"
          link
          v-if="isLoggedIn && !inVideo"
          @click="showAddToListDialog($event)"
        >
          <v-list-item-icon>
            <v-icon>mdi-playlist-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-uppercase"
              >{{ $tc('list.add-to-list') | capitalize }}
            </v-list-item-title>
            <add-to-list
              ref="AddToList"
              :post="post"
              :displayBtn="false"
            ></add-to-list>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          class="post-menu-item"
          :class="
            !subscriptionAllowEdition() && !post.video ? 'item-disabled' : ''
          "
          :to="editVideoLink"
          @click="openEditionDialog($event)"
          v-if="canEditPost"
        >
          <v-list-item-icon>
            <v-icon>mdi-pencil</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-uppercase"
              >{{ $tc('label.modify') | capitalize }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <contributor-feature
              v-if="!post.video"
              level-needed="1"
              :allowed.sync="userLevelAllowEdition"
            ></contributor-feature>
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          class="post-menu-item"
          @click="moderatePost"
          v-if="post.canModerate && !post.isAMemberOfOrganization"
        >
          <v-list-item-icon>
            <v-icon>mdi-police-badge-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-uppercase"
              >{{ $tc('moderate-form.moderate') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          class="post-menu-item"
          @click="deletePost"
          v-if="post.canDelete"
        >
          <v-list-item-icon>
            <v-icon>mdi-delete</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-uppercase"
              >{{ $tc('label.delete') | capitalize }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          class="post-menu-item"
          @click="archivePost"
          v-if="isAllowedToArchive"
        >
          <v-list-item-icon>
            <v-icon v-if="!post.isArchived"
              >mdi-archive-arrow-down-outline</v-icon
            >
            <v-icon v-else>mdi-archive-arrow-up-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-if="!post.isArchived" class="text-uppercase"
              >{{ $t('post.archive') | capitalize }}
            </v-list-item-title>
            <v-list-item-title v-else class="text-uppercase"
              >{{ $t('post.unarchive') | capitalize }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="
            $store.state.account.status.loggedIn &&
              !post.isOwner &&
              !post.isAMemberOfOrganization
          "
          class="post-menu-item"
          @click="report"
        >
          <v-list-item-icon>
            <v-icon>mdi-alert-octagon-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-uppercase"
              >{{ $tc('label.report') | capitalize }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog v-model="showModerateForm" max-width="800px">
      <v-card>
        <v-card-title>
          <v-icon class="mr-2" color="primary">mdi-police-badge-outline</v-icon>
          {{ $tc('moderate-form.moderation') }}
          <v-icon class="ml-2" color="primary"
            >mdi-police-badge-outline
          </v-icon>
        </v-card-title>
        <v-card-text>
          <ModerateForm
            @closeModerateForm="showModerateForm = false"
            @moderated="$emit('deleted')"
            :post="post"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { requestService } from '@/services/request.service'
import { subscriptionService } from '@/services/subscription.service'
import ModerateForm from '@/components/ModerateForm.vue'
import AddToList from '@/components/list/AddToList.vue'
import VideoButton from '@/components/video/VideoButton.vue'
import ContributorFeature from '@/components/label/ContributorFeature.vue'

export default {
  name: 'PostMenu',
  components: {
    ContributorFeature,
    ModerateForm,
    VideoButton,
    AddToList,
  },
  props: {
    post: { type: Object },
    inVideo: { type: Boolean },
    inList: { type: Object, default: null },
    isComment: { type: Boolean, default: false },
  },
  data() {
    return {
      menuOpen: false,
      showModerateForm: false,
      confirmArchiveMessage: '',
      userLevelAllowEdition: false,
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.account.status.loggedIn
    },
    isAllowedToArchive() {
      const {
        isComment,
        isOwner,
        isAMemberOfOrganization,
        roleInOrganization,
        canModerate,
      } = this.post
      const rolesAllowed = ['owner', 'administrator', 'editor', 'moderator']
      return (
        isComment &&
        !isOwner &&
        !isAMemberOfOrganization &&
        (rolesAllowed.includes(roleInOrganization) || canModerate)
      )
    },
    canEditPost() {
      return (
        ['editor', 'administrator', 'owner'].includes(
          this.post.roleInOrganization,
        ) ||
        this.post.canModerate ||
        this.post.canEdit
      )
    },
    editVideoLink() {
      return !!this.post.video
        ? {
            name: !!this.post.video ? 'ManageVideo' : '',
            params: { uid: !!this.post.video ? this.post.video.id : '' },
          }
        : undefined
    },
  },
  methods: {
    subscriptionAllowEdition() {
      return subscriptionService.canModifyPost(
        this.$store.state.account.plateFormSubscription,
        this.post,
      )
    },
    openEditionDialog() {
      if (this.post.video || !this.subscriptionAllowEdition()) {
        return
      }
      this.$store.state.postToEdit = { ...this.post, isComment: this.isComment }
      this.$store.state.postEditionDialog = true
    },
    copyPostUrl() {
      let url
      if (this.post.video !== null) {
        url =
          window.location.origin +
          this.$router.resolve({
            name: 'VideoPost',
            params: { slug: this.post.uid, post: this.post },
          }).href
      } else {
        url =
          window.location.origin +
          this.$router.resolve({
            name: 'Post',
            params: { slug: this.post.uid, post: this.post },
          }).href
      }

      this.$copyText(url).then(
        () => {
          this.$store.dispatch('alert/success', this.$tc('label.copied-url'))
        },
        () => {
          this.$store.dispatch(
            'alert/error',
            this.$tc('error.impossible-copy-url'),
          )
        },
      )
    },
    showAddToListDialog(e) {
      if (typeof e !== 'undefined') {
        e.preventDefault()
        e.stopPropagation()
      }
      this.$refs.AddToList.showListDialog()
    },
    removeFormList() {
      this.$store
        .dispatch('list/removePostFromList', {
          listUid: this.inList.uid,
          postUid: this.post.uid,
        })
        .then(() => {
          this.$emit('itemRemovedFromList', {
            list: this.inList,
            postUid: this.post.uid,
          })
          this.$store.dispatch(
            'alert/success',
            this.$t('list.post-removed-from-list', {
              name: this.inList.name,
            }),
          )
        })
        .catch(() => {
          this.$store.dispatch(
            'alert/error',
            this.$tc('error.impossible-remove-post-from-list'),
          )
        })
    },
    addToWatchLater() {
      this.$store
        .dispatch('list/addPostToList', {
          listUid: 'watch-later',
          postUid: this.post.uid,
        })
        .then(() => {
          this.$store.dispatch(
            'alert/success',
            this.$t('list.post-added-to-list', {
              name: this.$tc('list.watch-later'),
            }),
          )
        })
        .catch(() => {
          this.$store.dispatch(
            'alert/error',
            this.$tc('error.impossible-add-post-to-list'),
          )
        })
    },
    archivePost() {
      if (this.post.isArchived) {
        this.confirmArchiveMessage = this.$t('post.confirm-unarchive-comment')
      } else {
        this.confirmArchiveMessage = this.$t('post.confirm-archive-comment')
      }
      this.$root.$emit('confirm', this.confirmArchiveMessage, () => {
        requestService
          .post(`/post/archive/${this.post.uid}/add`)
          .then(response => {
            this.$store.dispatch('alert/success', response.message)
            this.$delete = true
            this.$emit('deleted')
          })
          .catch(response => {
            if (response.status === 404) {
              this.$store.dispatch(
                'alert/error',
                this.$tc('post.post-doesnt-exist'),
                œ,
              )
            } else {
              this.$store.dispatch('alert/error', response.message)
            }
          })
      })
    },
    deletePost() {
      this.$root.$emit(
        'confirm',
        this.$tc('post.want-delete-post-irreversible'),
        () => {
          requestService
            .delete(`/post/${this.post.uid}`)
            .then(() => {
              this.$store.dispatch(
                'alert/success',
                this.$tc('post.deleted-post'),
              )
              this.deleted = true
              this.$emit('deleted')
              this.$root.$emit('deletedPost')
            })
            .catch(response => {
              if (response.status === 404) {
                this.$store.dispatch(
                  'alert/error',
                  this.$tc('post.post-doesnt-exist'),
                )
              } else {
                this.$store.dispatch(
                  'alert/error',
                  this.$tc('error.impossible-delete-publication'),
                )
              }
            })
        },
      )
    },
    moderatePost() {
      this.showModerateForm = true
    },
    report() {
      this.$root.$emit('report', this.post)
    },
  },
}
</script>

<style scoped lang="scss">
.item-disabled {
  cursor: not-allowed;
  .v-list-item__icon,
  .v-list-item__title {
    opacity: 0.5;
  }
}
</style>
