<template>
  <div class="d-flex justify-center pt-6">
    <donation embed style="max-width: 500px;width: 100%"></donation>
  </div>
</template>

<script>
import Donation from '../components/Donation'
export default {
  name: 'DonationPage',
  components: { Donation },
}
</script>

<style scoped></style>
