<template v-if="showDialog">
  <div>
    <v-card
      v-if="moderation.numberOfModerations > 0"
      class="mb-5"
      color="primary"
      :style="{ 'max-height': '300px', 'overflow-y': 'auto' }"
    >
      <v-card-text>
        <p
          v-if="
            moderation.bannedUntil !== null &&
              moderation.currentModerations[0].organization !== null
          "
          class="font-weight-bold"
        >
          {{
            $t('moderate-form.organization-existing-ban-sanction', {
              date_var: utilsService.formatDateWithLocale(
                moderation.bannedUntil,
                false,
              ),
            })
          }}
        </p>
        <p
          v-if="
            moderation.bannedUntil !== null &&
              moderation.currentModerations[0].organization === null
          "
          class="font-weight-bold"
        >
          {{
            $t('moderate-form.user-existing-ban-sanction', {
              date_var: utilsService.formatDateWithLocale(
                moderation.bannedUntil,
                false,
              ),
            })
          }}
        </p>
        <p
          v-if="
            moderation.numberOfModerations === 1 &&
              moderation.currentModerations[0].organization !== null
          "
          class="font-weight-bold"
        >
          {{ $tc('moderate-form.organization-already-warned-one-time') }} :
        </p>
        <p
          v-if="
            moderation.numberOfModerations === 1 &&
              moderation.currentModerations[0].organization === null
          "
          class="font-weight-bold"
        >
          {{ $tc('moderate-form.user-already-warned-once') }} :
        </p>
        <p
          v-if="
            moderation.numberOfModerations > 1 &&
              moderation.currentModerations[0].organization !== null
          "
          class="font-weight-bold"
        >
          {{ $tc('moderate-form.organization-already-warned-many-times') }} :
        </p>
        <p
          v-if="
            moderation.numberOfModerations > 1 &&
              moderation.currentModerations[0].organization === null
          "
          class="font-weight-bold"
        >
          {{ $tc('moderate-form.user-already-warned-twice') }} :
        </p>
        <v-divider></v-divider>
        <v-list dense>
          <v-list-item-group
            v-for="(currentModeration, index) in moderation.currentModerations"
            :key="index"
          >
            <v-list-item disabled>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $tc('moderate-form.moderation-received-on') }} :
                  {{ formatDate(currentModeration.createdAt) }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $tc('moderate-form.reason-title') }} :
                  {{
                    $tc(
                      'moderate-form.reason.' +
                        currentModeration.reason +
                        '-form',
                    )
                  }}
                </v-list-item-subtitle>
                <v-list-item-title
                  v-if="currentModeration.additionalDetails !== null"
                >
                  {{ $tc('moderate-form.additional-details') }} :
                  {{ currentModeration.additionalDetails }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="currentModeration.warning">
                  <span v-if="currentModeration.bannedUntil">
                    {{
                      $t('moderate-form.warning-and-ban-sanction', {
                        date_var: utilsService.formatDateWithLocale(
                          currentModeration.bannedUntil,
                          false,
                        ),
                      })
                    }}
                  </span>
                  <span v-else>
                    {{ $tc('moderate-form.warning-issued') }}
                  </span>
                  <v-icon class="mr-2" color="primary"
                    >mdi-police-badge-outline</v-icon
                  >
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              v-if="index < moderation.currentModerations.length - 1"
            ></v-divider>
          </v-list-item-group>
        </v-list>
        <p
          v-if="
            moderation.numberOfStrike === 2 &&
              moderation.currentModerations[0].organization !== null
          "
          class="mt-4 font-weight-bold mb-0"
        >
          {{
            $t('moderate-form.third-warning-notice-organization', {
              organizationName:
                moderation.currentModerations[0].organization.name,
            })
          }}
        </p>
        <p
          v-if="
            moderation.numberOfStrike === 2 &&
              moderation.currentModerations[0].organization === null
          "
          class="mt-4 font-weight-bold mb-0"
        >
          {{
            $t('moderate-form.third-warning-notice-user', {
              userName: moderation.currentModerations[0].user.username,
            })
          }}
        </p>
      </v-card-text>
    </v-card>
    <v-switch
      v-model="isWarning"
      :label="$tc('moderate-form.count-as-warning')"
      color="primary"
    ></v-switch>
    <v-select
      v-if="isWarning"
      :items="limitedSanctions"
      v-model="selectedSanction"
      item-text="label"
      item-value="value"
      :label="$tc('moderate-form.select-sanction')"
      :rules="sanctionRules"
      outlined
    ></v-select>
    <v-select
      :items="reasons"
      v-model="selectedReason"
      item-text="label"
      item-value="value"
      :label="$tc('moderate-form.select-reason')"
      :rules="rules"
      outlined
    ></v-select>
    <v-textarea
      v-model="moreDetails"
      :label="$tc('moderate-form.additional-details')"
      outlined
      rows="4"
    ></v-textarea>
    <p class="mb-5 font-weight-bold">
      {{ $tc('moderate-form.all-org-members-will-be-notified') }}
    </p>
    <v-btn color="primary" @click="promptDelete">
      {{ $tc('moderate-form.delete') }}
    </v-btn>
    <v-btn class="ml-2" text @click="cancelModeration">
      {{ $tc('moderate-form.cancel') }}
    </v-btn>
    <v-dialog v-model="confirmDelete" max-width="500">
      <v-card>
        <v-card-title class="headline">
          {{ $tc('moderate-form.confirmation') }}
        </v-card-title>
        <v-card-text>
          {{ $tc('moderate-form.confirm-message') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="confirmDelete = false">
            {{ $tc('moderate-form.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="proceedToDelete">
            {{ $tc('moderate-form.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { requestService } from '@/services/request.service'
import { utilsService } from '@/services/utils.service'
export default {
  name: 'ModerateForm',
  props: ['post'],
  data() {
    return {
      utilsService: utilsService,
      showDialog: true,
      selectedReason: '',
      moreDetails: '',
      isWarning: false,
      sanction: null,
      confirmDelete: false,
      moderation: '',
      reasons: [
        {
          label: this.$tc('moderate-form.reason.spam-form'),
          value: 'spam',
        },
        {
          label: this.$tc('moderate-form.reason.violence_promotion-form'),
          value: 'violence_promotion',
        },
        {
          label: this.$tc('moderate-form.reason.harassment-form'),
          value: 'harassment',
        },
        {
          label: this.$tc('moderate-form.reason.pornography-form'),
          value: 'pornography',
        },
        {
          label: this.$tc('moderate-form.reason.intellectual_property-form'),
          value: 'intellectual_property',
        },
      ],
      rules: [
        value => !!value || this.$tc('moderate-form.reason-must-be-selected'),
      ],
      deleted: false,
      selectedSanction: '',
      possibleSanctions: [
        {
          label: this.$tc('moderate-form.sanction.none'),
          value: 'none',
        },
        {
          label: '3 ' + this.$tc('moderate-form.sanction.days'),
          value: '3_days',
        },
        {
          label: '7 ' + this.$tc('moderate-form.sanction.days'),
          value: '7_days',
        },
        {
          label: '30 ' + this.$tc('moderate-form.sanction.days'),
          value: '30_days',
        },
        {
          label: '3 ' + this.$tc('moderate-form.sanction.months'),
          value: '3_months',
        },
        {
          label: this.$tc('moderate-form.sanction.permanent'),
          value: 'permanent',
        },
      ],
      sanctionRules: [
        value => !!value || this.$tc('moderate-form.sanction.must-be-selected'),
      ],
    }
  },
  methods: {
    async submitModeration() {
      if (!this.selectedReason) {
        this.$store.dispatch(
          'alert/error',
          this.$tc('moderate-form.reason-must-be-selected'),
        )
        return
      }

      if (this.isWarning && !this.selectedSanction) {
        this.$store.dispatch(
          'alert/error',
          this.$tc('moderate-form.sanction.must-be-selected'),
        )
        return
      }

      const formData = {
        reason: this.selectedReason,
        moreDetails: this.moreDetails,
        isWarning: this.isWarning,
        sanction: this.sanction,
      }

      if (this.isWarning) {
        formData.sanction = this.selectedSanction
      }

      try {
        await requestService.post(`/post/moderate/${this.post.uid}`, formData)

        this.$store.dispatch(
          'alert/success',
          this.$tc('moderate-form.post-moderated-and-deleted'),
        )
        this.showDialog = false
        this.deleted = true
        this.$emit('moderated')
        this.$emit('closeModerateForm')
      } catch (error) {
        this.$store.dispatch(
          'alert/error',
          this.$tc('moderate-form.unable-to-moderate-or-delete'),
        )
      }
    },

    async fetchModerationData() {
      try {
        const response = await requestService.get(
          `/post/moderation/${this.post.uid}`,
        )
        this.moderation = response
      } catch (error) {
        console.error('Moderation dada error request', error)
      }
    },

    formatDate(date) {
      return utilsService.formatDateWithLocale(date)
    },

    promptDelete() {
      this.confirmDelete = true
    },

    proceedToDelete() {
      this.submitModeration()
      this.confirmDelete = false
    },

    cancelModeration() {
      this.$emit('closeModerateForm')
    },
  },
  mounted() {
    this.fetchModerationData()
  },
  computed: {
    isThirdWarning() {
      return this.moderation.numberOfStrike === 2
    },
    allPossibleSanctions() {
      return this.possibleSanctions
    },
    limitedSanctions() {
      if (this.isThirdWarning) {
        return this.possibleSanctions.filter(
          sanction => sanction.value !== 'none',
        )
      }
      return this.possibleSanctions.filter(
        sanction => sanction.value !== 'permanent',
      )
    },
  },
}
</script>

<style scoped lang="scss"></style>
