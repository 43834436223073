<template>
  <div
    style="max-width: 500px !important;"
    :class="
      (isConnect ? paymentMethodConnect == null : paymentMethod == null) &&
      onUserProfile
        ? 'row px-2 align-center'
        : ''
    "
  >
    <template>
      <template
        v-if="
          (isConnect ? paymentMethodConnect == null : paymentMethod == null) &&
            !onUserProfile
        "
      >
        <div>{{ $tc('label.credit-card-payment') }} :</div>
        <credit-card-forms
          :forDonation="forDonation"
          :isConnect="isConnect"
        ></credit-card-forms>
      </template>
      <template
        v-else-if="
          (isConnect ? paymentMethodConnect == null : paymentMethod == null) &&
            onUserProfile
        "
      >
        <v-col cols="8" sm="6" md="8" lg="10">
          <div>
            {{
              isConnect
                ? $tc('label.no-credit-card-payment-for-organizations-payment')
                : $tc('label.no-credit-card-payment')
            }}
          </div>
        </v-col>
        <v-col cols="4" sm="6" md="4" lg="2">
          <v-subheader class="my-auto"
            ><a class="custom-fs" v-on:click="showPaymentFormDialog()">{{
              $tc('label.add') | capitalize
            }}</a></v-subheader
          >
        </v-col>
      </template>
      <v-card v-else class="pa-0" color="transparent" flat>
        <template>
          <div v-if="!onUserProfile" class="mb-1 font-weight-bold">
            {{ $tc('label.credit-card-payment') }}
          </div>
          <div class="secondary">
            <v-card-title class="pt-0 text-subtitle-1" v-if="!onUserProfile">{{
              $tc('label.saved-card')
            }}</v-card-title>
            <v-card-text :class="!onUserProfile ? '' : 'ps-0'">
              <v-row justify="center" justify-md="space-between" align="center">
                <v-col
                  cols="auto"
                  :class="onUserProfile ? 'px-md-0 ps-lg-3' : ''"
                >
                  <v-img
                    :src="getCardProviderLogo()"
                    alt="Card Provider"
                    class="card-logo"
                  />
                </v-col>
                <v-col cols="auto">
                  <div class="card-number text-no-wrap my-auto">
                    {{
                      `**** **** **** ${
                        isConnect
                          ? paymentMethodConnect.last4
                          : paymentMethod.last4
                      }`
                    }}
                  </div>
                </v-col>
                <v-col
                  cols="auto"
                  :class="onUserProfile ? 'px-md-0 ps-lg-3' : ''"
                >
                  <div class="expiration my-auto">
                    {{
                      isConnect
                        ? paymentMethodConnect.expMonth
                        : paymentMethod.expMonth
                    }}
                    /
                    {{
                      isConnect
                        ? paymentMethodConnect.expYear
                        : paymentMethod.expYear
                    }}
                  </div>
                </v-col>
              </v-row>
              <v-row
                v-if="confirmCard"
                justify="center"
                justify-md="space-between"
                align="center"
              >
                <credit-card-confirm-payment
                  :isConnect="isConnect"
                ></credit-card-confirm-payment>
              </v-row>
            </v-card-text>
            <v-btn
            v-if="!showSmall"
              id="change-card-btn"
              @click="showPaymentFormDialog()"
              color="primary"
              text
              class="text-caption"
              >{{ $tc('account.change-credit-card') }}</v-btn
            >
          </div>
        </template>
      </v-card>
    </template>
    <!-- <p
      class="error--text mt-1"
      v-if="
        (isConnect ? paymentMethodConnect == null : paymentMethod == null) &&
          stripeElementPaymentError.length > 0
      "
    >
      {{ stripeElementPaymentError }}
    </p> -->

    <v-dialog
      max-width="500"
      v-model="changingUserPaymentMethod"
      v-if="changingUserPaymentMethod"
      persistent
    >
      <v-card color="background">
        <v-toolbar dark color="secondary" flat dense>
          <v-toolbar-title>
            Changement de carte
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn :disabled="loadingPayment" icon dark @click="cancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <div>
            <div class="my-1">
              {{
                (isConnect && paymentMethodConnect) ||
                (!isConnect && paymentMethod)
                  ? 'Changer la carte de paiment par défaut :'
                  : 'Ajouter une nouvelle carte de paiment par défaut:'
              }}
            </div>
            <credit-card-forms
              :forDonation="forDonation"
              :isConnect="isConnect"
            ></credit-card-forms>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel()">Annuler</v-btn>
          <v-btn
            color="success"
            @click="submitCard()"
            :loading="loadingPayment"
            depressed
            >{{ $t('button.save') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="saveCardConfirmationDialog" width="400" persistent>
      <v-card>
        <v-card-title class="headline"
          >{{ $tc('label.confirmation') | capitalize }}
        </v-card-title>
        <v-card-text>
          {{ confirmMessage }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmCancel">{{ $tc('button.cancel') }}</v-btn>
          <v-btn text color="success" @click="confirmValid"
            >{{ $tc('button.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CreditCardForms from '@/components/CreditCardForms'
import CreditCardConfirmPayment from '@/components/CreditCardConfirmPayment'
export default {
  name: 'CreditCardDetails',
  components: {
    CreditCardForms,
    CreditCardConfirmPayment,
  },
  props: {
    onUserProfile: {
      type: Boolean,
      default: false,
    },
    forDonation: {
      type: Boolean,
      default: false,
    },
    isConnect: {
      type: Boolean,
      default: false,
    },
    confirmCard: {
      type: Boolean,
      default: false,
    },
    showSmall: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingPayment: false,
      changingUserPaymentMethod: false,
      saveCardConfirmationDialog: false,
      confirmMessage:
        'Voulez vous enregistrer cette carte pour vos prochains paiements ?',
    }
  },
  computed: {
    paymentMethodConnect() {
      return this.$store.state.account.paymentMethodConnect
    },
    paymentMethod() {
      return this.$store.state.account.paymentMethod || null
    },
    stripeCard() {
      return this.$store.state.stripe.stripeCard
    },
    stripeElementPaymentError() {
      return this.$store.state.stripe.stripeElementPaymentError
    },
  },
  methods: {
    getCardProviderLogo() {
      // Replace with logic to fetch and return the card provider logo URL based on the cardProvider prop
      // Example: with a visa logo urrl
      if (
        this.isConnect
          ? this.paymentMethodConnect.brand === 'visa'
          : this.paymentMethod.brand === 'visa'
      )
        return 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/Visa_2021.svg/1920px-Visa_2021.svg.png'
      if (
        this.isConnect
          ? this.paymentMethodConnect.brand === 'mastercard'
          : this.paymentMethod.brand === 'mastercard'
      )
        return 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Mastercard_2019_logo.svg/1920px-Mastercard_2019_logo.svg.png'
      else return '/images/debit-card.jpg'
      // return '/path/to/default-logo.png'
    },
    showPaymentFormDialog() {
      this.changingUserPaymentMethod = true
    },
    cancel() {
      this.changingUserPaymentMethod = false
      this.loadingPayment = false
    },
    confirmCancel() {
      this.saveCardConfirmationDialog = false
    },
    confirmValid() {
      this.saveCardConfirmationDialog = false
      this.saveUserPaymentMethodInfos({
        paymentMethodId: this.paymentMethod.id,
        last4: this.paymentMethod.last4,
        brand: this.paymentMethod.brand,
        expMonth: this.paymentMethod.expMonth,
        expYear: this.paymentMethod.expYear,
        country: this.paymentMethod.country,
        paymentMethodType: this.paymentMethod.paymentMethodType,
        isConnect: this.isConnect,
      })
    },
    submitCard() {
      if (this.paymentMethod === null && !this.stripeCard._complete) {
        this.$store.dispatch(
          'stripe/setStripeElementPaymentError',
          this.$tc('error.payment-data-incomplete'),
        )
        return
      }
      this.loadingPayment = true

      this.$store
        .dispatch('stripe/stripeCreatePaymentMethod', {
          email: this.$store.state.account.user.user.email,
        })
        .then(result => {
          this.saveUserPaymentMethodInfos({
            paymentMethodId: result.paymentMethod.id,
            last4: result.paymentMethod.card.last4,
            brand: result.paymentMethod.card.brand,
            expMonth: result.paymentMethod.card.exp_month,
            expYear: result.paymentMethod.card.exp_year,
            country: result.paymentMethod.card.country,
            paymentMethodType: result.paymentMethod.type,
            isConnect: this.isConnect,
          })
        })
        .catch(err => {
          this.stripeElementPaymentError = err.message
          this.loadingPayment = false
        })
    },
    saveUserPaymentMethodInfos({
      paymentMethodId,
      last4,
      brand,
      expMonth,
      expYear,
      country,
      paymentMethodType,
      isConnect,
    }) {
      this.$store
        .dispatch('account/savePaymentMethod', {
          paymentMethodId,
          last4,
          brand,
          country,
          expMonth,
          expYear,
          paymentMethodType,
          isConnect,
        })
        .then(() => {
          this.cancel()
        })
        .catch(err => {
          this.$store.dispatch('stripe/setStripeElementPaymentError', err)
        })
        .finally(() => {
          this.loadingPayment = false
        })
    },
  },
}
</script>

<style scoped>
@media (min-width: 640px) {
  .custom-fs {
    font-size: 1.2em;
  }
}
@media (min-width: 540px) {
  .custom-p {
    position: relative;
  }
}
.card-logo {
  max-width: 40px;
}
.card-number {
  font-size: 16px;
}
.card-holder {
  font-size: 16px;
  margin-top: 10px;
}
.expiration {
  font-size: 16px;
}
</style>
