<template>
  <v-card>
    <v-card-title class="headline secondary lighten-1">
      {{ $tc('donation.participate-to-this-fundraising') | capitalize }}
      <v-spacer></v-spacer>
      <v-btn
        icon
        dark
        @click="$emit('close')"
        v-if="!embed && !$vuetify.breakpoint.mobile"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text v-if="!donationFinished" class="mt-2 pb-1">
      <div class="caption mt-2 text-capitalize">
        {{ $tc('donation.method') }} :
      </div>
      <v-btn
        small
        depressed
        :input-value="tab === 0"
        color="primary"
        class="mr-1 mb-2"
        @click="tab = 0"
        >{{ $tc('label.bank-card') }}
      </v-btn>
      <!--      <v-btn
        small
        depressed
        color="primary"
        class="mr-1 mb-2"
        @click="tab = 2"
        :input-value="tab === 2"
        >Virement
      </v-btn>-->
      <v-btn
        small
        depressed
        color="primary"
        class="mr-1 mb-2"
        @click="tab = 1"
        :input-value="tab === 1"
        >Crypto
      </v-btn>
      <v-tabs-items v-model="tab">
        <v-tab-item :key="0">
          <div class="caption mt-2 text-capitalize">
            {{ $tc('donation.amount') }} :
          </div>
          <v-chip-group
            mandatory
            show-arrows
            active-class="success--text"
            v-model="amountSelected"
          >
            <v-chip v-for="amount of amountList" :key="amount" pill>
              {{ amount }}€
            </v-chip>
          </v-chip-group>
          <v-checkbox
            v-model="coverBankFees"
            :label="`${$t('donation.cover-bank-fees')} (${bankFeesFormatted})`"
            hide-details
          >
          </v-checkbox>
          <v-text-field
            outlined
            :label="$tc('label.your-email-address')"
            class="mt-5"
            name="email"
            type="email"
            ref="don-email"
            v-model="form.email.value"
            :rules="[rules.required, rules.email]"
          ></v-text-field>
          <v-sheet
            outlined
            rounded
            height="56px"
            class="d-flex align-center px-2 card-input-outline"
            style="position:relative;"
          >
            <v-progress-linear
              indeterminate
              :active="initLoading"
              absolute
              top
            ></v-progress-linear>
            <div ref="cardElement" id="card-element" style="width: 100%">
              <!-- Elements will create input elements here -->
            </div>
          </v-sheet>
          <v-row class="mt-2">
            <v-col>
              <v-sheet
                outlined
                rounded
                height="56px"
                class="d-flex align-center px-2 card-input-outline"
                style="position:relative;"
              >
                <div id="card-expiry" style="width: 100%">
                  <!-- Elements will create input elements here -->
                </div>
              </v-sheet>
            </v-col>
            <v-col>
              <v-sheet
                outlined
                rounded
                height="56px"
                class="d-flex align-center px-2 card-input-outline"
                style="position:relative;"
              >
                <div id="card-cvc" style="width: 100%">
                  <!-- Elements will create input elements here -->
                </div>
              </v-sheet>
            </v-col>
          </v-row>

          <div
            class="error--text mt-1"
            v-if="stripeElementDonationError.length"
          >
            {{ stripeElementDonationError }}
          </div>
          <div class="error--text mt-1">{{ donationError }}</div>
          <div class="mt-3 grey--text text--darken-1">
            <v-icon small color="success" class="mb-1">mdi-shield-check</v-icon>
            {{ $tc('donation.secure-payment-via-stripe') }}
          </div>
        </v-tab-item>
        <v-tab-item :key="1">
          <v-select
            class="mt-2"
            :items="cryptoList"
            v-model="crypto"
            label="Coin"
            outlined
          ></v-select>
          <div
            v-if="crypto !== null"
            class="mb-2 text-center"
            style="min-height: 210px"
          >
            <vue-qrcode
              :value="crypto"
              :scale="5"
              :margin="1"
              :errorCorrectionLevel="'H'"
            ></vue-qrcode>
            <div class="text-center">{{ crypto }}</div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-card-text v-else>
      <v-alert class="mt-2" color="success">
        {{ $tc('donation.donation-registered') }}
      </v-alert>
      <h3 class="primary--text text-center">
        {{ $tc('donation.thank-you') }} !
      </h3>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="secondary lighten-1">
      <v-spacer></v-spacer>
      <v-btn text @click="$emit('close')" v-if="!embed">
        <span v-if="!donationFinished">{{ $tc('button.cancel') }}</span>
        <span v-else>{{ $tc('button.close') }}</span>
      </v-btn>
      <v-btn
        color="success"
        @click="submitDonation"
        v-if="!donationFinished && tab === 0"
        :loading="loading"
        depressed
      >
        {{ $tc('donation.donate') }} {{ donationAmountWithFees }}€
        <span v-if="recurring" class="ml-1">{{
          $tc('donation.by-month')
        }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios'
import VueQrcode from 'vue-qrcode'
import { requestService } from '@/services/request.service'
import { utilsService } from '@/services/utils.service'

// list of crypto address ONLY for Thierry fund !
const cryptoList = [
  {
    text: 'Bitcoin (BTC)',
    value: 'bc1qw9s0pgyerpus8jrm2tyjr8xpec0ejx4gqrfsa6',
  },
  {
    text: 'Ethereum ETH (ERC20)',
    value: '0xC5C401b3c3DaA5A2387FEfDBF7695eAB7beEF475',
  },
  {
    text: 'Lite Coin (LTC)',
    value: 'ltc1q6axcjphkqazeuzz67u436azwzrx48csszv3c7n',
  },
  {
    text: 'Tether USDT (TRC20)',
    value: 'TFgidHXkWKFeagXQUwVttjB9ReXzh2QNZE',
  },
  {
    text: 'Tether USDT (ERC20)',
    value: '0xC5C401b3c3DaA5A2387FEfDBF7695eAB7beEF475',
  },
  {
    text: 'USDC (TRC20)',
    value: 'TFgidHXkWKFeagXQUwVttjB9ReXzh2QNZE',
  },
  {
    text: 'USDC (ERC20)',
    value: '0xC5C401b3c3DaA5A2387FEfDBF7695eAB7beEF475',
  },
  {
    text: 'BUSD (ERC20)',
    value: '0xC5C401b3c3DaA5A2387FEfDBF7695eAB7beEF475',
  },
  {
    text: 'Cardano (ADA)',
    value:
      'addr1qxwhqwed67t7f4996va5gcj65alpwvemugjnvdctg3l7h3gx7vwndcwgfxp32wn6m5ku3vyhty39hqkr7h0elltps9ssmqvpx0',
  },
  {
    text: 'Dogecoin (DOGE)',
    value: 'DEV1sWLxVMEnYzkDKjjhbhzBxiFsgUAByT',
  },
  {
    text: 'Polkadot (DOT)',
    value: '13UBCkzF8dJ1P8AQsSbnUD4rBmVgXJ9JbKrEciEVMEFY9eN4',
  },
  {
    text: 'Solana (SOL)',
    value: 'GJ7UYX8EfQM5CxmaCRQXwyRTkmh3ojnFZaqY1EamWx4i',
  },
]

export default {
  name: 'FundPayment',
  components: {
    VueQrcode,
  },
  props: {
    embed: { type: Boolean },
    fund: { type: Object, required: true },
  },
  data() {
    return {
      donationFinished: false,
      form: {
        email: {
          value: '',
        },
      },
      rules: {
        required: value => !!value || this.$tc('login.required') + '.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$tc('error.invalid-email')
        },
      },
      loading: false,
      initLoading: true,
      stripe: null,
      stripeElements: null,
      stripeCard: null,
      amountList: [2, 5, 10, 25, 50, 100, 200, 500, 1000],
      amountSelected: 1,
      donationError: '',
      stripeElementDonationError: '',
      recurring: false,
      testPricesId: [
        'price_1Lefn3DQV3VdhpTxPNB8UOMQ',
        'price_1LefnsDQV3VdhpTxavA1q0a1',
        'price_1LefpADQV3VdhpTx7C2DtWNJ',
      ],
      pricesId: [
        'price_1Lef8IDQV3VdhpTxLOvV61dD',
        'price_1Lef8vDQV3VdhpTxDEp2BsBN',
        'price_1Lef9NDQV3VdhpTxv7pOaK4f',
        'price_1LefA2DQV3VdhpTxRe2X3iBy',
        'price_1LefBcDQV3VdhpTx1Uy80vj4',
        'price_1LefGNDQV3VdhpTxALKUBVm0',
        'price_1LefHoDQV3VdhpTxjMrR48nS',
      ],
      rebuild: 0,
      tab: 0,
      cryptoList: cryptoList,
      crypto: cryptoList[0].value,
      coverBankFees: false,
    }
  },
  computed: {
    donationAmount() {
      return this.amountList[this.amountSelected]
    },
    donationAmountWithFees() {
      return (this.coverBankFees
        ? this.donationAmount + this.bankFees
        : this.donationAmount
      ).toFixed(2)
    },
    priceId() {
      if (this.stripe === null || this.stripe._keyMode === undefined) {
        return null
      }
      return this.stripe._keyMode === 'test'
        ? this.testPricesId[this.amountSelected]
        : this.pricesId[this.amountSelected]
    },
    bankFees() {
      return this.donationAmount / 0.94 + 0.3 - this.donationAmount
    },
    bankFeesFormatted() {
      return utilsService.formatCurrency(this.bankFees, 2)
    },
  },
  methods: {
    submitDonation() {
      if (
        !this.$refs['don-email'].validate(true) ||
        this.stripeElementDonationError.length
      ) {
        return
      }
      if (!this.stripeCard._complete) {
        this.donationError = this.$tc('error.payment-data-incomplete')
        return
      }
      this.loading = true
      const finalAmount = this.donationAmountWithFees

      this.stripe
        .createPaymentMethod({
          type: 'card',
          card: this.stripeCard,
          billing_details: {
            email: this.form.email.value,
          },
        })
        .then(result => {
          if (result.error) {
            this.donationError = result.error.message
            this.loading = false
          } else {
            if (this.recurring) {
              this.createSubscription({
                paymentMethodId: result.paymentMethod.id,
                priceId: this.priceId,
              })
            } else {
              this.createSiglePayment({
                paymentMethodId: result.paymentMethod.id,
                finalAmount,
              })
            }
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    createSubscription({ paymentMethodId, priceId }) {
      requestService
        .post(
          process.env.VUE_APP_API_BASE_URL + '/payment/start-platform-donation',
          {
            // amount: finalAmount,
            email: this.form.email.value,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
          },
        )
        .then(response => {
          if (!response.success) {
            this.donationError = response.message
              ? response.message
              : this.$tc('error.server-error-try-again')
            this.loading = false
            return
          }
          return {
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            subscription: response.subscription,
          }
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(this.handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(this.handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(this.onSubscriptionComplete)
        .catch(() => {
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    createSiglePayment({ paymentMethodId, finalAmount }) {
      requestService
        .post(
          process.env.VUE_APP_API_BASE_URL +
            `/payment/fund-start/${this.fund.uniqueId}`,
          {
            amount: finalAmount,
            email: this.form.email.value,
            paymentMethodId: paymentMethodId,
          },
        )
        .then(response => {
          if (!response.success) {
            this.donationError = response.message
              ? response.message
              : this.$tc('error.server-error-try-again')
            this.loading = false
            return
          }
          if (response.status === 'succeeded') {
            this.donationFinished = true
            this.loading = false
            return
          }
          this.stripe
            .confirmCardPayment(response.client_secret, {
              payment_method: {
                card: this.stripeCard,
                billing_details: {
                  email: this.form.email.value,
                },
              },
            })
            .then(result => {
              if (result.error) {
                this.donationError = result.error.message
              } else {
                // The payment has been processed!
                this.donationFinished = true
              }
            })
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {
          this.loading = false
        })
    },
    handlePaymentThatRequiresCustomerAction({
      subscription,
      invoice,
      priceId,
      paymentMethodId,
      isRetry,
    }) {
      if (subscription && subscription.status === 'active') {
        // Subscription is active, no customer actions required.
        return { subscription, priceId, paymentMethodId }
      }

      // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
      // If it's a retry, the payment intent will be on the invoice itself.
      const paymentIntent = invoice
        ? invoice.payment_intent
        : subscription.latest_invoice.payment_intent

      if (
        paymentIntent.status === 'requires_action' ||
        (isRetry === true && paymentIntent.status === 'requires_payment_method')
      ) {
        return this.stripe
          .confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentMethodId,
          })
          .then(result => {
            if (result.error) {
              // Start code flow to handle updating the payment details.
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc).
              this.donationError = result.error.message
            } else {
              if (result.paymentIntent.status === 'succeeded') {
                // Show a success message to your customer.
                this.donationFinished = true
                return {
                  priceId: priceId,
                  subscription: subscription,
                  invoice: invoice,
                  paymentMethodId: paymentMethodId,
                }
              }
            }
          })
          .catch(error => {
            console.log(error)
            this.donationError = this.$tc(
              'error.error-while-confirming-payment',
            )
          })
      } else {
        // No customer action needed.
        return { subscription, priceId, paymentMethodId }
      }
    },
    handleRequiresPaymentMethod({ subscription, paymentMethodId, priceId }) {
      if (subscription.status === 'active') {
        // subscription is active, no customer actions required.
        return { subscription, priceId, paymentMethodId }
      } else if (
        subscription.latest_invoice.payment_intent.status ===
        'requires_payment_method'
      ) {
        // Using localStorage to manage the state of the retry here,
        // feel free to replace with what you prefer.
        // Store the latest invoice ID and status.
        localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id)
        localStorage.setItem(
          'latestInvoicePaymentIntentStatus',
          subscription.latest_invoice.payment_intent.status,
        )
        this.donationError = this.$tc('error.card-been-refused')
      } else {
        return { subscription, priceId, paymentMethodId }
      }
    },
    retryInvoiceWithNewPaymentMethod({
      customerId,
      paymentMethodId,
      invoiceId,
      priceId,
    }) {
      return (
        requestService
          .post(process.env.VUE_APP_FUNCTIONS_BASE_URL + '/startPayment', {
            customerId: customerId,
            email: this.form.email.value,
            paymentMethodId: paymentMethodId,
            invoiceId: invoiceId,
            retry: true,
          })
          // If the card is declined, display an error to the user.
          .then(response => {
            if (!response.success) {
              // The card had an error when trying to attach it to a customer.
              this.donationError = response.message
            }
            return response.invoice
          })
          // Normalize the result to contain the object returned by Stripe.
          // Add the additional details we need.
          .then(result => {
            return {
              // Use the Stripe 'object' property on the
              // returned result to understand what object is returned.
              invoice: result,
              paymentMethodId: paymentMethodId,
              priceId: priceId,
              isRetry: true,
            }
          })
          // Some payment methods require a customer to be on session
          // to complete the payment process. Check the status of the
          // payment intent to handle these actions.
          .then(this.handlePaymentThatRequiresCustomerAction)
          // No more actions required. Provision your service for the user.
          .then(this.onSubscriptionComplete)
          .catch(error => {
            // An error has happened. Display the failure to the user here.
            // We utilize the HTML element we created.
            this.donationError = error
          })
      )
    },
    onSubscriptionComplete() {
      this.donationFinished = true
      this.loading = false
    },
    init() {
      if (this.stripe !== null) {
        return
      }
      this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY)
      this.stripeElements = this.stripe.elements({
        theme: 'stripe',
      })

      const style = {
        base: {
          color: this.$vuetify.theme.dark ? '#FFFFFF' : '#000000',
          fontSize: '16px',
          fontFamily: '"Roboto", sans-serif',
          fontWeight: 400,
          '::placeholder': {
            color: this.$vuetify.theme.dark
              ? 'rgba(255, 255, 255, 0.7)'
              : 'rgba(0, 0, 0, 0.6)',
            fontWeight: 400,
          },
        },
      }

      this.stripeCard = this.stripeElements.create('cardNumber', {
        style: style,
      })
      const cardExpiry = this.stripeElements.create('cardExpiry', {
        style: style,
      })
      const cardCVC = this.stripeElements.create('cardCvc', { style: style })
      this.$nextTick(() => {
        this.stripeCard.mount('#card-element')
        cardExpiry.mount('#card-expiry')
        cardCVC.mount('#card-cvc')
        this.stripeCard.on('change', event => {
          if (event.error) {
            this.stripeElementDonationError = event.error.message
          } else {
            this.stripeElementDonationError = ''
          }
        })
        this.initLoading = false
      })
    },
    create() {
      try {
        this.init()
      } catch (e) {
        if (this.rebuild < 10) {
          this.rebuild++
          setTimeout(this.create, 500)
        }
      }
    },
    importStripe: () => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.onload = () => {
          resolve()
        }
        script.async = true
        script.src = 'https://js.stripe.com/v3/'
        document.head.appendChild(script)
      })
    },
  },
  mounted() {
    this.importStripe().then(this.create)
    if (this.$store.state.account.status.loggedIn) {
      this.form.email.value = this.$store.state.account.user.user.email
    }
  },
}
</script>

<style scoped></style>
