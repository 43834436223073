<template>
  <iframe
    :srcdoc="formattedHtmlContent"
    sandbox="allow-scripts"
    style="border: none; width: 100%;overflow: hidden;"
    :style="{ height: iframeHeight + 'px' }"
    ref="descriptionIframe"
    scrolling="no"
    v-resize="onIframeUpdate"
  ></iframe>
</template>

<script>
export default {
  props: {
    htmlContent: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      iframeHeight: 200,
      iframeId: Math.random(),
    }
  },
  name: 'FundDescription',
  computed: {
    formattedHtmlContent() {
      // noinspection HtmlRequiredTitleElement
      let html = `
      <!DOCTYPE html>
      <html lang="fr">
      <head>
        <link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900'>
        <style>* {font-family: 'Roboto', sans-serif;color: white;}</style>
      </head>
      <body>
        ${this.htmlContent}
        <script >
          const iframeId = ${this.iframeId}
          function sendHeight() {
            const height = document.body.scrollHeight + 50;
            window.parent.postMessage({ height: height, id: iframeId }, '*')
          }
          window.addEventListener('load', sendHeight)
          window.addEventListener('message', event => {
            if (event.data === 'sendHeight') {
              sendHeight()
            }
          }, false)
        </scr\i\pt>
      </body>
      </html>
      `
      return html.replace('//scr\i\pt>', '/script>')
    },
  },
  methods: {
    onIframeUpdate() {
      this.$emit('update')
      this.$refs.descriptionIframe.contentWindow.postMessage('sendHeight', '*')
    },
    receiveIframeHeight(event) {
      if (typeof event.data !== 'object' || event.data.id !== this.iframeId) {
        // Vérifie que l'origine de l'événement est correcte pour des raisons de sécurité
        return
      }

      const height = event.data.height
      this.iframeHeight = height
    },
  },
  beforeMount() {
    window.addEventListener('message', this.receiveIframeHeight, false)
  },
}
</script>

<style scoped></style>
