<template>
  <div>
    <v-row justify="center" align="center" class="flex-column">
      <v-col cols="12" sm="9" md="7" lg="6" style="max-width: 700px">
        <v-row justify="center" align="center" class="flex-column">
          <post-item-loader v-if="loading" cols="12" large></post-item-loader>
          <template v-else-if="post !== null && !post.deletedAt">
            <v-col cols="12">
              <v-card>
                <post-item
                  v-if="post.parent"
                  cols="12"
                  large
                  :post="post.parent"
                  show-organization
                  sub
                ></post-item>
                <post-item
                  large
                  cols="12"
                  :post="post"
                  solo
                  show-organization
                  class="pb-0 px-0"
                  :class="{ 'pa-0': !post.parent }"
                ></post-item>
              </v-card>
            </v-col>
          </template>
          <v-alert v-else type="error" class="mt-5">
            <span v-if="notFound && this.bannedReason">
              {{ $tc('moderate-form.reason.' + this.bannedReason) }}</span
            >
            <span v-else-if="notFound && this.channelDeleted">
              {{ $tc('error.this-channel-has-been-deleted') }}</span
            >
            <span v-else-if="notFound">{{
              $tc('error.post-doesnt-exist-or-deleted')
            }}</span>
            <!-- {{
              $tc('error.you-are-not-allowed-to-see-this-post')
            }} -->
            <span v-else-if="forbidden"> {{ forbiddenReason }} </span>
            <span v-else>{{ $tc('error.an-error-has-occurred') }}</span>
          </v-alert>
        </v-row>
        <v-row justify="center" class="mt-3">
          <v-col cols="12">
            <comment-area v-if="post" :parent-post="post"></comment-area>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PostItem from '../components/PostItem'
import { requestService } from '@/services/request.service'
import PostItemLoader from '@/components/PostItemLoader'
import CommentArea from '@/components/CommentArea'
export default {
  name: 'Post',
  components: { CommentArea, PostItemLoader, PostItem },
  data() {
    return {
      post: null,
      loading: false,
      notFound: false,
      forbidden: false,
      forbiddenReason: '',
      hasError: false,
      bannedReason: null,
      channelDeleted: false,
    }
  },
  methods: {
    fetchPost() {
      if (this.post === null) {
        this.loading = true
      }
      this.notFound = false
      this.hasError = false
      requestService
        .get(`/post/${this.$route.params.slug}/details`)
        .then(response => {
          if (
            response.deletedAt ||
            (response.video && response.video.deleted) ||
            response.channel?.status === 'deleted' ||
            response.video?.channel?.status === 'deleted'
          ) {
            if (response.bannedReason) {
              this.bannedReason = response.bannedReason
            } else if (
              response.channel?.status === 'deleted' ||
              response.video?.channel?.status === 'deleted'
            ) {
              this.channelDeleted = true
            }
            this.notFound = true
            return
          }
          this.post = response
          if (response.channel?.name) {
            this.$store.dispatch('updateTitle', response.channel.name)
          }
        })
        .catch(response => {
          if (response.status === 404) {
            this.notFound = true
          }
          if (response.status === 403) {
            this.forbidden = true
            this.forbiddenReason = response.error.message
          } else {
            this.hasError = true
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  created() {
    if (this.$route.params.post) {
      this.post = this.$route.params.post
    }
    this.fetchPost()
  },
  beforeRouteUpdate(to, from, next) {
    this.$root.$emit('reload')
    next()
  },
}
</script>

<style scoped lang="scss"></style>
