<template>
  <div class="mx-n3 page">
    <div class="primary pa-md-4 pa-3 overflow-hidden">
      <div class="text-center text-h5 white--text">
        Contribuer à la campagne de financement de CrowdBunker
      </div>
    </div>
    <div class="pt-8 px-3 d-flex justify-center main-body">
      <div style="max-width: 800px;">
        <v-alert type="info" color="secondary">
          <div>
            <b
              >Page de la campagne :
              <a href="https://crowdbunker.com/fund" target="_blank"
                >crowdbunker.com/fund</a
              ></b
            >
          </div>
        </v-alert>
        <div class="text-h5 mb-5">
          Cette vidéo à laquelle nous vous proposons de participer a vocation à
          être un hommage à notre Liberté d'expression.
        </div>
        <p>
          CrowdBunker est littéralement le "bunker des foules". Cette
          plate-forme de libre-expression nous permet de nous assurer que notre
          parole demeure libre malgré les attaques répétées des censeurs.
        </p>
        <p>
          Vous êtes sur cette page parce que nous vous avons personnellement
          demandé de prêter votre voix et votre image à une vidéo que nous
          sommes en train de réaliser pour promouvoir la liberté d'expression.
        </p>
        <p>
          Merci du fond du coeur de contribuer ainsi à emmener CrowdBunker vers
          son plein potentiel pour défendre durablement notre liberté
          d'expression ! 🗣️✊
        </p>
        <p>
          Vous serez un certain nombre à vous relayer à l'écran et il y aura 40
          phrases qui constitueront la vidéo finale.
        </p>
        <p>
          Nous ne vous demandons pas de prononcer toutes les phrases mais afin
          de s'assurer que chaque phrase est dite par au moins une personne,
          nous vous avons en principe proposé certaines phrases personnellement
          (demandez-nous si ce n'est pas le cas).
        </p>
        <p>
          Vous êtes ceci dit également libre de prononcer des phrases
          supplémentaires, ou même tout le texte, si vous le souhaitez. Vous
          pouvez également modifier ou inventer une phrase si cela vous semble
          préférable.
        </p>
        <p>
          NB : Veillez à laisser un blanc d'environ 3-5 secondes avant et après
          chaque phrase, où vous regardez juste face caméra en silence (cela
          aidera au montage). 😉🙏
        </p>
        ️
        <v-alert color="secondary" class="mb-5">
          <div>
            <div class="font-weight-bold mb-2">Script de la vidéo</div>
            <div
              class="script-text"
              :class="{ 'script-text-long': scriptTextLong }"
            >
              <ol class="mb-4">
                <li>Ensemble, retrouvons notre liberté d'expression</li>
                <li>Notre liberté d'opinion</li>
                <li>
                  Notre liberté d'informer les autres et de s'informer soi-même
                </li>
                <li>
                  Libérons-nous de l'emprise de ceux qui se sont arrogé le droit
                  de décider, pour nous,
                </li>
                <li>de ce qui est vrai et ce qui est faux,</li>
                <li>de qui a le droit de s'exprimer et qui ne l'a pas</li>
                <li>
                  De toute urgence, nous avons besoin de nous retrouver,
                  dialoguer, partager,
                </li>
                <li>
                  dans des espaces où notre droit fondamental de nous exprimer
                  librement est respecté.
                </li>
                <li>
                  Nous avons besoin d'une plateforme qui NOUS appartienne,
                </li>
                <li>nous les médias indépendants,</li>
                <li>
                  nous, les lanceurs d'alerte, les scientifiques, les
                  libre-penseurs,
                </li>
                <li>nous, qui souhaitons nous informer, discuter.</li>
                <li>Librement.</li>
                <li>
                  Et sans être traité comme un “produit” dont les données seront
                  vendues à des annonceurs.
                </li>
                <li>Eh bien bonne nouvelle, cette plateforme est née.</li>
                <li>Elle s'appelle...</li>
                <li>CrowdBunker</li>
                <li>LA plate-forme de libre-expression.</li>
                <li>CrowdBunker est encore en version bêta</li>
                <li>
                  mais offre déjà la possibilité à des milliers d'utilisateurs
                  chaque jour,
                </li>
                <li>
                  de s'informer, de protéger des chaînes YouTube contre la
                  censure,
                </li>
                <li>de publier des vidéos directement sur CrowdBunker</li>
                <li>Et même de faire des diffusions en direct.</li>
                <li>
                  Mais nous avons besoin de chacun d'entre vous pour emmener
                  cette plateforme beaucoup plus loin.
                </li>
                <li>
                  À ce jour, CrowdBunker fonctionne exclusivement sur la base de
                  vos dons.
                </li>
                <li>
                  Plus vous donnez, plus la capacité des serveurs augmente
                </li>
                <li>
                  Plus vous donnez, plus il devient possible d'accueillir des
                  milliers et des milliers de personnes en simultané,
                </li>
                <li>y compris lors des diffusions en direct.</li>
                <li>Mais en plus demain, grâce à vos dons,</li>
                <li>CrowdBunker va continuer à évoluer,</li>
                <li>en écoutant vos besoins,</li>
                <li>
                  en devenant une alternative complète aux plateformes
                  technologiques inféodées au pouvoir politico-financier,
                </li>
                <li>en recrutant davantage de développeurs,</li>
                <li>
                  pour inclure toutes les fonctionnalités dont vous avez
                  l'habitude sur vos réseaux,
                </li>
                <li>
                  mais aussi des fonctionnalités totalement innovantes pour
                  libérer notre intelligence collective.
                </li>
                <li>Alors de tout cœur, si vous en avez les moyens,</li>
                <li>
                  Co-finançons ensemble cette plateforme dont nous avons tous
                  besoin.
                </li>
                <li>
                  Devenons chacun, chacune, les garants de notre indépendance.
                </li>
                <li>Faisons un don pour notre liberté.</li>
                <li>Faisons un don à CrowdBunker.</li>
              </ol>
              <v-btn
                text
                color="primary"
                href="/script-video.txt"
                target="_blank"
                download="Script vidéo campagne CrowdBunker"
                >Télécharger au format TXT
                <v-icon right>mdi-download-circle</v-icon>
              </v-btn>
            </div>
            <div class="d-flex justify-center">
              <v-btn
                v-if="!scriptTextLong"
                @click="scriptTextLong = true"
                color="primary"
                >Voir plus
              </v-btn>
              <v-btn v-else @click="scriptTextLong = false" color="primary"
                >Voir moins
              </v-btn>
            </div>
          </div>
        </v-alert>
        <p class="font-weight-bold">
          Voici quelques points importants pour que votre vidéo soit exploitable
          :
        </p>
        <p>
          1. Assurez-vous que vous avez un bon éclairage avant de vous filmer.
          <br />
          <br />
          2. Filmez-vous en mode portrait (téléphone tenu à la verticale) <br />
          <br />
          3. Avant de prononcer votre phrase, laissez au moins 3-5 secondes de
          blanc où vous regardez juste face caméra. <br />
          <br />
          4. Idem, après avoir prononcé votre phrase, laissez au moins 3-5
          secondes de blanc où vous regardez face caméra. <br />
          <br />
          5. Si vous souhaitez vous filmer en train de prononcer plusieurs
          phrases, laissez bien au moins 3-5 secondes de blanc entre chaque
          phrase. (vous pouvez d'ailleurs, si vous le souhaitez, utiliser une
          application gratuite de téléprompteur sur votre téléphone pour faire
          défiler le texte en même temps que vous vous filmez, par exemple
          celles-ci :
          <a
            href="https://play.google.com/store/apps/details?id=com.corepix.videorecording"
            target="_blank"
            >Android</a
          >,
          <a
            href="https://apps.apple.com/us/app/teleprompter/id941620509"
            target="_blank"
            >iOS</a
          >).
        </p>
        <p>
          <span class="font-weight-bold">Important :</span> enregistrez votre
          vidéo en 720p ou 1080p, pas une autre définition (360p, 4k, etc).
        </p>
        <p class="font-weight-bold">
          Une fois votre enregistrement terminé, utilisez le formulaire
          ci-dessous pour nous l'envoyer :
        </p>
      </div>
    </div>
    <div>
      <script
        type="application/javascript"
        src="https://static.airtable.com/js/embed/embed_snippet_v1.js"
      ></script>
      <iframe
        class="airtable-embed airtable-dynamic-height"
        src="https://airtable.com/embed/shr7U46XvvRnuWCfR?backgroundColor=#000000"
        frameborder="0"
        onmousewheel=""
        width="100%"
        height="3356.2"
        style="background: transparent; overflow: hidden;"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpCampaign',
  data() {
    return {
      scriptTextLong: false,
    }
  },
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

.page,
.text-h4,
.text-h5 {
  font-family: 'Montserrat', sans-serif !important;
  //color: #333;
}

.page {
  //background-color: white;
}

ol {
  list-style: none;
  counter-reset: my-awesome-counter;
}

ol li {
  counter-increment: my-awesome-counter;
  display: flex;
}

ol li::before {
  content: counter(my-awesome-counter) '. ';
  color: var(--v-primary-base);
  font-weight: bold;
  min-width: 2rem;
  display: inline-block;
}

.script-text {
  max-height: 100px;
  overflow: hidden;

  &.script-text-long {
    max-height: 100%;
  }
}
</style>
