<template>
  <v-col
    v-bind="$attrs"
    v-show="!deleted"
    :class="{ 'publicly-hidden': publiclyHidden }"
  >
    <component
      :is="component"
      v-if="post?.uid"
      :to="link"
      class="text-decoration-none d-block"
      :class="{ 'pl-12': sub }"
    >
      <v-card
        width="100%"
        elevation="0"
        :class="{ censored: censored, sub: sub }"
        class="transparent"
      >
        <v-card-title class="pa-0">
          <v-list-item v-if="!noChannel" class="px-0 channel-block-list-item">
            <v-list-item-content class="py-0">
              <v-list-item-subtitle class="d-flex align-center">
                <div class="flex-grow-1 text-truncate">
                  <router-link
                    v-if="creator"
                    :to="channelLink || {}"
                    :tag="channelLink ? 'a' : 'div'"
                    class="text-decoration-none"
                  >
                    <v-sheet
                      class="align-center channel-block rounded-lg"
                      :class="[
                        large ? 'd-inline-flex' : 'd-flex',
                        large ? 'pa-1' : '',
                      ]"
                    >
                      <channel-avatar
                        :thumbnails="channelThumbnails"
                        :channel-name="channelName"
                        :size="30"
                        :bg-color="
                          creator.avatarColor ? creator.avatarColor : 'primary'
                        "
                        :key="channelName"
                      ></channel-avatar>
                      <div class="ml-2 text-truncate" :title="channelTooltip">
                        {{ channelName }}
                      </div>
                      <v-icon
                        v-if="
                          !!post.channel?.organization?.ownerMembershipLevel
                        "
                        :color="
                          getColor(
                            post.channel?.organization?.ownerMembershipLevel,
                          )
                        "
                        size="20"
                        class="ml-1"
                        >mdi-seal</v-icon
                      >
                      <div class="ml-auto" v-if="getPlatformIcon(post)">
                        <v-icon
                          class="mx-1"
                          small
                          v-text="getPlatformIcon(post).iconName"
                          :color="getPlatformIcon(post).color"
                        ></v-icon>
                      </div>
                    </v-sheet>
                  </router-link>
                </div>

                <v-btn
                  v-if="large && video"
                  :to="{ name: 'VideoPost', params: { slug: post.uid } }"
                  outlined
                  small
                  color="primary"
                  class="ml-2 flex-shrink-0"
                >
                  <v-icon left>mdi-theater</v-icon>
                  {{ $tc('button.open') }}
                </v-btn>
                <div class="ml-2 flex-shrink-0">
                  <post-menu
                    v-if="!isDeletedButNotHidden"
                    @deleted="postDeleted"
                    :post="post"
                    :listName="listName"
                  ></post-menu>
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-title>
        <video-inset
          v-if="video && !videoMeta"
          :post="post"
          :video.sync="video"
          :large="large"
          :hidden="publiclyHidden"
          :no-inner-player="forceOpenPost"
        ></video-inset>
        <video-with-meta
          v-else-if="video && videoMeta"
          :post="post"
        ></video-with-meta>
        <images-inset
          v-else-if="
            (Array.isArray(post.images) && post.images.length) ||
              ['image', 'image_and_text'].includes(post.mediaType)
          "
          :post="post"
          :large="large"
        ></images-inset>
        <snippet-inset
          v-else-if="post.linkMetadata"
          :post="post"
          :large="large"
        ></snippet-inset>
        <text-post-inset
          v-else
          :text="post.textContent"
          :large="large"
          :uid="post.uid"
          :force-open-post="forceOpenPost"
        ></text-post-inset>
        <v-card-text class="subtitle-2 text-break py-1 px-0">
          <div class="px-0 list-text-block">
            <div class="py-0 d-flex">
              <div
                class="font-weight-light caption flex-grow-1 align-center d-flex post-details flex-wrap"
              >
                <span v-if="publiclyHidden && visibility">{{
                  visibility
                }}</span>

                <span
                  v-if="
                    video &&
                      ['live_created', 'live_scheduled'].includes(video.status)
                  "
                >
                  {{
                    utilsService.formatScheduledTime(
                      video.liveStreamingDetails?.scheduledStartTime,
                    )
                  }}
                </span>

                <span
                  v-if="
                    video &&
                      ['live_created', 'live_scheduled', 'live'].includes(
                        video.status,
                      ) &&
                      typeof post.viewerCount === 'number'
                  "
                  >{{ formatViews(post.viewerCount) }}
                  {{ $tc('video.spectator', post.viewerCount || 1) }}
                  {{
                    ['live_created', 'live_scheduled'].includes(video.status)
                      ? ' ' + $tc('live.waiting')
                      : ''
                  }}</span
                >
                <span v-else-if="viewCount"
                  >{{ formatViews(viewCount)
                  }}{{ ' ' + $tc('video.view', viewCount) }}</span
                >
                <span v-if="post.censoredAt">{{
                  ($tc('censored.censored') +
                    ' ' +
                    formatDateRelative(new Date(post.censoredAt), true))
                    | capitalize
                }}</span>
                <span
                  v-else-if="
                    !video ||
                      !['live_created', 'live_scheduled'].includes(video.status)
                  "
                  >{{
                    formatDateRelative(new Date(post.publishedAt), true)
                      | capitalize
                  }}</span
                >
              </div>
              <template v-if="!isDeletedButNotHidden">
                <post-voter v-if="large" :post="post"></post-voter>
                <div
                  v-if="post.childrenCount > 0"
                  class="d-flex align-center mr-1"
                >
                  <span
                    v-text="post.childrenCount > 0 ? post.childrenCount : ''"
                    class="likes-count d-inline-flex text-no-wrap caption"
                    style="min-width: 10px"
                  ></span>
                  <v-icon small color="grey" class="mx-1"
                    >mdi-comment-multiple-outline</v-icon
                  >
                </div>
                <post-votes-percentage
                  v-if="!large"
                  :up-votes="post.relevanceVoteTotal?.up ?? post.upVotes"
                  :down-votes="post.relevanceVoteTotal?.down ?? post.downVotes"
                ></post-votes-percentage>
              </template>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </component>
    <v-card v-else flat>
      <v-skeleton-loader
        type="list-item-avatar, list-item-three-line, actions"
      ></v-skeleton-loader>
    </v-card>
  </v-col>
</template>

<script>
import ChannelAvatar from './ChannelAvatar'
import { utilsService } from '@/services/utils.service'
import VideoInset from '@/components/VideoInset'
import TextPostInset from '@/components/TextPostInset'
import LikeButton from './LikeButton'
import PostMenu from '@/components/PostMenu'
import ImagesInset from '@/components/ImagesInset'
import SnippetInset from '@/components/SnippetInset'
import VideoWithMeta from '@/components/VideoWithMeta.vue'
import { subscriptionService } from '@/services/subscription.service'
import PostVoter from '@/components/post/PostVoter.vue'
import PostVotesPercentage from '@/components/post/PostVotesPercentages.vue'

export default {
  name: 'PostItem',
  components: {
    PostVotesPercentage,
    PostVoter,
    VideoWithMeta,
    SnippetInset,
    ImagesInset,
    PostMenu,
    LikeButton,
    TextPostInset,
    VideoInset,
    ChannelAvatar,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
    large: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    noChannel: {
      type: Boolean,
      default: false,
    },
    showOrganization: {
      type: Boolean,
      default: false,
    },
    sub: {
      type: Boolean,
      default: false,
    },
    forceOpenPost: {
      type: Boolean,
      default: false,
    },
    listName: {
      type: String,
      default: null,
    },
    videoMeta: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deleted: false,
      video: null,
      isPlayListPostItem: !!this.listName,
      utilsService: utilsService,
    }
  },
  computed: {
    component() {
      return this.solo ||
        (this.video && this.large && !this.forceOpenPost) ||
        this.isDeletedButNotHidden
        ? 'div'
        : 'router-link'
    },
    isDeletedButNotHidden() {
      return this.post.uid === 'deleted'
    },
    creator() {
      let creator = {}
      if (this.post.organization) {
        creator = this.post.organization
      } else if (
        this.post.channel &&
        this.showOrganization &&
        this.post.channel.organization
      ) {
        creator = this.post.channel.organization
      } else if (this.post.channel) {
        creator = this.post.channel
      } else if (this.post.user) {
        creator = this.post.user
        creator.name = this.post.user.username
      }
      return creator
    },
    channelName() {
      if (
        this.showOrganization &&
        this.post.channel &&
        this.post.channel.organization
      ) {
        return this.post.channel.organization.name
      }
      return this.creator.name
    },
    channelTooltip() {
      if (
        this.showOrganization &&
        this.post.channel &&
        this.post.channel.organization
      ) {
        return this.channelName
      }
      return this.creator.name
    },
    channelThumbnails() {
      if (
        this.showOrganization &&
        this.post.channel &&
        this.post.channel.organization
      ) {
        return this.post.channel.organization.thumbnails
      }
      return this.creator.thumbnails || []
    },
    channelLink() {
      if (
        this.showOrganization &&
        this.post.channel &&
        this.post.channel.organization
      ) {
        return {
          name: 'Organization',
          params: {
            slug: this.post.channel.organization.uid,
            noBanner: !this.post.channel.organization.hasBanner,
          },
        }
      } else if (this.post.organization) {
        return {
          name: 'Organization',
          params: {
            slug: this.post.organization.uid,
            noBanner: !this.post.organization.hasBanner,
          },
        }
      } else if (this.post.channel) {
        return {
          name: 'Channel',
          params: {
            slug: this.post.channel.id,
            noBanner: !this.post.channel.hasBanner,
          },
        }
      }
      return null
    },
    // video() {
    //   if (this.post === null) {
    //     return null
    //   }
    //   return this.post.video
    // },
    link() {
      if (this.isPlayListPostItem) {
        return {}
      }
      if (this.video) {
        // return { name: 'Video', params: { slug: this.post.video.id } }
        return {
          name: 'VideoPost',
          params: { slug: this.post.uid, post: this.post },
        }
      }
      return this.post
        ? { name: 'Post', params: { slug: this.post.uid, post: this.post } }
        : null
    },
    censored() {
      return this.post && this.post.video && this.post.video.censored
    },
    publiclyHidden() {
      if (!this.video || this.large) {
        return false
      }
      return (
        (this.video.duration === 0 ||
          this.video.status === 'created' ||
          (this.video.visibility && this.video.visibility !== 'public')) &&
        !['live', 'live_processing', 'live_created', 'live_scheduled'].includes(
          this.video.status,
        ) &&
        this.post.liveActive === undefined
      )
    },
    visibility() {
      if (!this.video) {
        return null
      }
      switch (this.video.visibility) {
        case 'private':
          return this.$options.filters.capitalize(this.$tc('live.private'))
        case 'unlisted':
          return this.$options.filters.capitalize(this.$tc('live.unlisted'))
        default:
          return null
      }
    },
    viewCount() {
      if (this.post.viewCount > 1 && this.video?.price !== 'paid') {
        return this.post.viewCount
      }

      const isVideoValid =
        this.video && this.video.viewCount > 1 && this.video.price !== 'paid'
      const isLive = ['live_created', 'live_scheduled', 'live'].includes(
        this.video?.status,
      )
      if (isVideoValid && !isLive) {
        return this.video.viewCount
      }
      return null
    },
  },
  methods: {
    getColor(level) {
      return subscriptionService.subscriptionLevelColor(level)
    },
    formatDate(date, hour = false) {
      return utilsService.formatDate(date, hour)
    },
    formatDateRelative(date) {
      return utilsService.formatDateRelative(date)
    },
    getPlatformIcon(post) {
      if (post.platform) {
        switch (post.platform) {
          case 'youtube':
            return {
              iconName: 'mdi-youtube',
              color: '#f00',
            }
          case 'internal':
            return null
        }
      }
    },
    like() {
      this.$store.dispatch('like/like', this.post.uid).then(response => {
        const oldLikesCount = this.post.likesCount
        this.$set(this.post, 'liked', true)
        if (response.success && response.likesCount !== undefined) {
          this.$set(this.post, 'liked', true)
          this.$set(this.post, 'likesCount', response.likesCount)
        } else {
          this.$set(this.post, 'liked', false)
          this.$set(this.post, 'likesCount', oldLikesCount)
          this.$store.dispatch('alert/error', response.message)
        }
      })
    },
    unlike() {
      this.$store.dispatch('like/unlike', this.post.uid).then(response => {
        if (response.success && response.likesCount !== undefined) {
          this.$set(this.post, 'likesCount', response.likesCount)
          this.post.liked = false
        }
      })
    },
    formatViews(views) {
      return utilsService.formatNumber(views)
    },
    initData() {
      if (this.video) {
        this.video.channel = this.post.channel
      }
      if (this.post.videoStatus) {
        this.video.status = this.post.videoStatus
      }
      if (
        this.post.liveActive !== undefined &&
        this.post.liveActive !== '' &&
        this.video
      ) {
        this.video.isLiveActive = !!this.post.liveActive
      }
      if (
        this.video &&
        typeof this.post.liveScheduledAtTimestamp === 'number'
      ) {
        this.video.liveStreamingDetails = this.video.liveStreamingDetails ?? {}
        this.video.liveStreamingDetails.scheduledStartTime = new Date(
          this.post.liveScheduledAtTimestamp * 1000,
        )
      }
      if (
        this.post.upVotes !== undefined &&
        this.post.downVotes !== undefined
      ) {
        this.post.relevanceVoteTotal = {
          up: this.post.upVotes,
          down: this.post.downVotes,
        }
      }
    },
    postDeleted() {
      this.$route.params.post = null
      this.$root.$emit('reload')
    },
  },
  watch: {
    post(post) {
      this.video = post ? post.video : null
      this.initData()
    },
  },
  created() {
    this.video = this.post ? this.post.video : null
    this.initData()
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .list-text-block {
    min-height: 20px;
  }
}

.censored {
  border: 1px solid;
  border-color: var(--v-primary-base);
}

.sub:not(.censored) {
  border: 1px solid;
  border-color: var(--v-secondary-lighten1);
}

.channel-block-list-item {
  max-width: 100%;

  .channel-block {
    background-color: transparent;
    &:hover {
      background-color: var(--v-background-lighten1);
    }
  }
}

.post-menu-item {
  min-height: 24px;
  padding: 0 10px;

  .v-list-item__icon {
    margin-right: 13px;
  }
}

.publicly-hidden {
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

.post-details {
  span:not(:last-child):after {
    content: ' \002022 ';
    margin-right: 3px;
  }
}

.line-left {
  padding-left: 40px;
  border-left: 1px var(--v-secondary-lighten1) solid;
}
</style>
