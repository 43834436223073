<template>
  <div class="d-flex justify-center pt-6">
    <v-card flat style="max-width: 1000px;width: 100%">
      <!-- <v-img
        class="white--text align-end mb-2"
        height="100px"
        src="/images/logo-blanc.png"
      >
      </v-img> -->
      <v-card-title>
        <p class="headline text-center mb-0 mx-auto text-break">
          {{ $t('membership.become-crowdbunker-contributor') }}
        </p>
        <p class="body-2 mx-auto text-center text-break">
          {{ $t('membership.user-funded-platform-statement') }}
          <br />
          {{ $t('membership.become-a-guarantor-of-our-independence') }}
        </p>
      </v-card-title>
      <v-card-text v-if="plateFormSubscription">
        <v-alert
          :icon="
            plateFormSubscription?.status === 'past_due'
              ? 'mdi-alert-outline'
              : ''
          "
          :prominent="plateFormSubscription?.status === 'past_due'"
          :type="plateFormSubscription?.status === 'past_due' ? 'warning' : ''"
          outlined
        >
          <v-row justify="content-between">
            <v-col>
              <div class="success--text font-weight-bold">
                <span>{{ $t('membership.subscription') }}</span>
                {{ plateFormSubscription.plan }}
                <span> {{ $t('membership.in-progress') }}</span>
              </div>
              <div>
                <p class="mb-0">
                  <span class="text-overline">{{
                    $t('membership.contribution')
                  }}</span>
                  :{{
                    formatCurrency(plateFormSubscription.amountCents / 100, 2)
                  }}
                  <span> / {{ $t('membership.month') }}</span>
                </p>
                <p class="mb-0">
                  <span class="text-overline">{{
                    $t('membership.start-date')
                  }}</span>
                  :
                  {{
                    new Date(
                      plateFormSubscription.subscriptionStartDate,
                    ).toLocaleDateString()
                  }}
                </p>
                <p v-if="plateFormSubscription.nextBillingDate" class="mb-0">
                  <span class="text-overline">{{
                    $t('membership.next-billing-date')
                  }}</span>
                  :
                  {{
                    new Date(
                      plateFormSubscription.nextBillingDate,
                    ).toLocaleDateString()
                  }}
                </p>
                <p v-else class="mb-0">
                  <span class="text-overline">{{
                    $t('membership.end-date')
                  }}</span>
                  :
                  {{
                    new Date(
                      plateFormSubscription.subscriptionEndDate,
                    ).toLocaleDateString()
                  }}
                </p>
                <v-alert
                  v-if="plateFormSubscription.status === 'pending'"
                  dense
                  text
                  type="info"
                  v-html="$tc('membership.subscription-pending-message')"
                >
                </v-alert>
                <v-alert
                  v-if="plateFormSubscription.status === 'canceled'"
                  dense
                  text
                  type="warning"
                  v-html="$tc('membership.subscription-canceled-message')"
                ></v-alert>
              </div>
            </v-col>
            <v-col
              v-if="
                plateFormSubscription.status === 'past_due' &&
                  ['requires_action', 'requires_payment_method'].includes(
                    plateFormSubscription.lastPaymentIntentStatus,
                  )
              "
            >
              <p class="text-subtitle-2 text-center mb-0">
                {{ $tc('error.a-platform-subscription-payment-failed') }}
              </p>
              <div
                v-if="
                  plateFormSubscription.failureErrorCode ===
                    'authentication_required'
                "
                class="text-center"
              >
                <p class="text-body-2 mb-1">
                  {{
                    plateFormSubscription.lastPaymentMethodId ===
                    paymentMethodInfos.id
                      ? $tc('label.payment-need-confirmation')
                      : ''
                  }}
                </p>
                <v-btn
                  :loading="isLoading"
                  color="info"
                  outlined
                  small
                  @click="confirmPayment()"
                >
                  {{
                    plateFormSubscription.lastPaymentMethodId ===
                    paymentMethodInfos.id
                      ? $tc('button.confirm-payment')
                      : $tc('button.retry-payment')
                  }}
                </v-btn>
              </div>
              <div
                v-else-if="
                  plateFormSubscription.failureErrorCode ===
                    'payment_intent_authentication_failure'
                "
                class="text-center"
              >
                <p
                  v-if="
                    plateFormSubscription.lastPaymentMethodId ===
                      paymentMethodInfos.id
                  "
                  class="text-body-2 mb-1"
                >
                  {{ $tc('label.card-authentication-failed') }}
                </p>
                <p class="text-body-2 mb-1">
                  {{ $tc('label.change-card-and-retry') }}
                </p>
                <v-btn
                  :loading="isLoading"
                  color="info"
                  outlined
                  small
                  @click="changePaymentMethodAndRetry()"
                >
                  {{
                    plateFormSubscription.lastPaymentMethodId ===
                    paymentMethodInfos.id
                      ? $tc('button.change-card')
                      : $tc('button.retry-payment')
                  }}
                </v-btn>
              </div>
              <div
                v-else-if="
                  plateFormSubscription.failureErrorCode === 'card_declined'
                "
                class="text-center"
              >
                <p
                  v-if="
                    plateFormSubscription.lastPaymentMethodId ===
                      paymentMethodInfos.id
                  "
                  class="text-body-2 mb-1"
                >
                  {{ $tc('error.card-been-refused') }}
                </p>
                <p class="text-body-2 mb-1">
                  {{ $tc('label.change-card-and-retry') }}
                </p>
                <v-btn
                  :loading="isLoading"
                  color="info"
                  outlined
                  small
                  @click="changePaymentMethodAndRetry()"
                >
                  {{
                    plateFormSubscription.lastPaymentMethodId ===
                    paymentMethodInfos.id
                      ? $tc('button.change-card')
                      : $tc('button.retry-payment')
                  }}
                </v-btn>
              </div>
            </v-col>
          </v-row>

          <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>

          <v-row align="center" class="flex-column flex-md-row">
            <v-col class="grow text-subtitle-2">
              {{ $tc('membership.protection-thank-to-you') }} <br />
              <p class="mt-1 mb-0">
                {{ $tc('membership.modify-cancel-monthly-subscription') }}
              </p>
            </v-col>
            <!-- <v-spacer></v-spacer> -->
            <v-col class="shrink justify-center align-center d-flex">
              <v-btn
                v-if="
                  ['active', 'past_due'].includes(plateFormSubscription.status)
                "
                :loading="isLoading"
                color="grey"
                outlined
                @click="membershipDisangageConfirmDialog = true"
              >
                {{ $tc('button.disangage') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-card-text>
      <div class="ma-5 mt-1 mx-1 mx-md-5">
        <h3 class="text-center">
          {{ $tc('membership.choose-a-monthly-amount') }} <br />
          <span class="text-subtitle-2">{{
            $tc('membership.and-enjoy-corresponding-benefits')
          }}</span>
        </h3>
        <v-skeleton-loader
          v-if="isLoading"
          type="list-item"
        ></v-skeleton-loader>
        <v-slider
          v-else
          v-model="tickedValue"
          :disabled="plans.length <= 1"
          :max="computedTicksLabels.length - 1"
          :tick-labels="computedTicksLabels"
          :ticks="true"
          class="text-body-2"
          step="1"
        ></v-slider>
      </div>
      <template v-if="!isLoading">
        <v-row v-if="$vuetify.breakpoint.smAndUp" class="pa-2" justify="center">
          <v-col v-for="(plan, index) of plans" :key="plan.id" cols="auto">
            <v-card
              :color="
                selectedPlanId === plan.id
                  ? getColor(plan.subscriptionLevel)
                  : ''
              "
              :elevation="selectedPlanId === plan.id ? 6 : 0"
              outline
              shaped
              style="min-width: 228px !important; max-width: 230px !important;"
            >
              <v-card-title class="justify-center py-1">
                <div>
                  <div class="text-center">
                    {{ plan.subscriptionName }}
                    <v-icon
                      :color="
                        selectedPlanId === plan.id
                          ? ''
                          : getColor(plan.subscriptionLevel)
                      "
                      class="ml-1"
                      >mdi-seal
                    </v-icon>
                  </div>
                  <template v-if="plan.subscriptionExtraPrices.length > 0">
                    <h3
                      v-if="
                        tickedValue === 0 ||
                          [undefined, null].includes(
                            flattenedPlans[tickedValue - 1].parentId,
                          )
                      "
                      class="headline mb-0 text-center"
                    >
                      <span class="text-h6 text-caption">À partir de </span>
                      {{
                        formatCurrency(plan.subscriptionPriceInCent / 100, 2)
                      }}
                      <span class="text-h6 text-overline"> / Mois</span>
                    </h3>
                    <h3
                      v-else-if="
                        tickedValue > 0 &&
                          flattenedPlans[tickedValue - 1].parentId === plan.id
                      "
                      class="headline mb-0 text-center"
                    >
                      {{
                        formatCurrency(
                          flattenedPlans[tickedValue - 1]
                            .subscriptionPriceInCent / 100,
                          2,
                          flattenedPlans[tickedValue - 1].subscriptionCurrency,
                        )
                      }}
                      <span class="text-h6 text-overline"> / Mois</span>
                    </h3>
                    <h3 v-else class="headline mb-0 text-center">
                      {{
                        formatCurrency(
                          plan.subscriptionPriceInCent / 100,
                          2,
                          plan.subscriptionCurrency,
                        )
                      }}
                      <span class="text-h6 text-overline"> / Mois</span>
                    </h3>
                  </template>
                  <template v-else>
                    <h3 class="headline mb-0 text-center">
                      {{
                        formatCurrency(
                          plan.subscriptionPriceInCent / 100,
                          2,
                          plan.subscriptionCurrency,
                        )
                      }}
                      <span class="text-h6 text-overline"> / Mois</span>
                    </h3>
                  </template>
                </div>
              </v-card-title>
              <v-card-text class="py-0">
                <!--                <div class="mb-0">
                  Our {{ plan.subscriptionName }} plan provides essential
                  features and access to a range of content
                </div>-->

                <v-list-item
                  v-for="advantage of plan.subscriptionAdvantages"
                  :key="advantage"
                  class="px-0 text-caption"
                >
                  <v-list-item-icon
                    class="me-2 my-1 align-self-center"
                    style="width: 0 !important;"
                  >
                    <v-icon color="green lighten-2">mdi-check-decagram</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="py-1">
                    {{ advantage }}
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-btn
                  v-if="
                    selectedPlanId === plan.id &&
                      plateFormSubscription?.priceId ===
                        selectedPlan?.subscriptionStripePriceId
                  "
                  :outlined="selectedPlanId === plan.id"
                  class="mx-auto font-italic"
                  color="white"
                  text
                  >{{ $tc('label.subscribed') }}
                </v-btn>
                <v-btn
                  v-else
                  :color="selectedPlanId === plan.id ? 'info' : 'white'"
                  :disabled="selectedPlanId === plan.id"
                  class="mx-auto"
                  depressed
                  outlined
                  @click="selectPlan(index + 1)"
                  >{{
                    selectedPlanId === plan.id
                      ? $tc('label.choosen')
                      : $tc('label.choose')
                  }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-card-text v-else class="px-1">
          <v-expansion-panels :focusable="tickedValue < 1" flat popout>
            <v-expansion-panel
              v-for="(plan, index) of plans"
              :key="plan.id"
              class="mb-2"
            >
              <v-expansion-panel-header
                :color="
                  selectedPlanId === plan.id
                    ? getColor(plan.subscriptionLevel)
                    : 'secondary'
                "
                class="rounded"
              >
                <v-card-title class="justify-space-between pa-0">
                  <div class="headline d-inline">
                    {{ plan.subscriptionName }}
                  </div>
                  <template v-if="plan.subscriptionExtraPrices.length > 0">
                    <div
                      v-if="
                        tickedValue == 0 ||
                          [undefined, null].includes(
                            flattenedPlans[tickedValue - 1].parentId,
                          )
                      "
                      class="text-body-2 mb-0"
                    >
                      <span class="text-h6 text-caption">À partir de </span>
                      {{
                        formatCurrency(
                          plan.subscriptionPriceInCent / 100,
                          2,
                          plan.subscriptionCurrency,
                        )
                      }}
                      <span class="text-h6 text-caption"> / Mois</span>
                    </div>
                    <div
                      v-else-if="
                        tickedValue > 0 &&
                          flattenedPlans[tickedValue - 1].parentId == plan.id
                      "
                      class="text-body-2 mb-0"
                    >
                      {{
                        formatCurrency(
                          flattenedPlans[tickedValue - 1]
                            .subscriptionPriceInCent / 100,
                          2,
                          flattenedPlans[tickedValue - 1].subscriptionCurrency,
                        )
                      }}
                      <span class="text-h6 text-caption"> / Mois</span>
                    </div>
                    <div v-else class="text-body-2 mb-0">
                      {{
                        formatCurrency(
                          plan.subscriptionPriceInCent / 100,
                          2,
                          plan.subscriptionCurrency,
                        )
                      }}
                      <span class="text-h6 text-caption"> / Mois</span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="text-body-2 mb-0">
                      {{
                        formatCurrency(
                          plan.subscriptionPriceInCent / 100,
                          2,
                          plan.subscriptionCurrency,
                        )
                      }}
                      <span class="text-h6 text-caption"> / Mois</span>
                    </div>
                  </template>
                </v-card-title>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text class="pt-3">
                  <v-list-item
                    v-for="advantage of plan.subscriptionAdvantages"
                    :key="advantage"
                    class="px-0 text-caption"
                  >
                    <v-list-item-icon
                      class="me-2 my-1 align-self-center"
                      style="width: 0px !important;"
                    >
                      <v-icon color="green lighten-2"
                        >mdi-check-decagram
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="py-0">
                      {{ advantage }}
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-btn
                    v-if="
                      selectedPlanId == plan.id &&
                        plateFormSubscription?.priceId ==
                          selectedPlan?.subscriptionStripePriceId
                    "
                    :outlined="selectedPlanId === plan.id"
                    class="mx-auto font-italic"
                    color="grey"
                    disabled
                    text
                    >{{ $tc('organization.subscriber') }}
                  </v-btn>
                  <v-btn
                    v-else
                    :color="selectedPlanId === plan.id ? 'info' : 'white'"
                    :disabled="selectedPlanId === plan.id"
                    class="mx-auto"
                    outlined
                    @click="selectPlan(index + 1)"
                    >{{ selectedPlanId == plan.id ? 'Choisi' : 'Choisir' }}
                  </v-btn>
                  <!-- <v-btn
                    :text="selectedPlanId == plan.id ? false : true"
                    :color="selectedPlanId == plan.id ? 'info' : 'accent'"
                    class="mx-auto"
                    @click="selectPlan(index + 1)"
                    :disabled="selectedPlanId == plan.id ? true : false"
                    >{{
                      selectedPlanId == plan.id ? 'Choisi' : 'Choisir'
                    }}</v-btn
                  > -->
                </v-card-actions>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </template>
      <template v-else>
        <v-row justify="center">
          <v-col cols="12" md="4">
            <v-skeleton-loader
              type="card-avatar, article, actions"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="4">
            <v-skeleton-loader
              type="card-avatar, article, actions"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </template>
      <v-fade-transition group>
        <v-card-text v-if="showCreditCardSection" :key="0">
          <div>
            <!-- Credit card element ui (v-card) -->
            <credit-card-details
              :confirmCard="confirmCard"
              class="mx-auto"
            ></credit-card-details>
          </div>
          <div class="text-center error--text mt-1">
            {{ subscriptionError }}
          </div>
        </v-card-text>
      </v-fade-transition>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn text @click="$emit('cancel')" :disabled="isLoading">{{
          subscriptionFinished ? $tc('button.close') : $tc('button.cancel')
        }}</v-btn> -->
        <v-btn
          id="subs-btn"
          :disabled="
            tickedValue === 0 ||
              plans.length <= 1 ||
              (!!plateFormSubscription &&
                plateFormSubscription?.priceId ===
                  selectedPlan?.subscriptionStripePriceId) ||
              ['pending', 'canceled'].includes(plateFormSubscription?.status)
          "
          :loading="isLoading"
          color="success"
          depressed
          @click="showSubscriptionConfirmDialog()"
        >
          {{
            !!plateFormSubscription
              ? $tc('button.upgrade')
              : $tc('button.join') | capitalize
          }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-if="membershipDisangageConfirmDialog"
      v-model="membershipDisangageConfirmDialog"
      max-width="500"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ $tc('membership.disangage-confirmation') }}
          </span>
        </v-card-title>
        <v-card-text>
          <div>
            {{
              $tc('membership.disangage-confirmation-description-plateforme')
                | capitalize
            }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="isLoading"
            color="primary"
            text
            @click="membershipDisangageConfirmDialog = false"
          >
            {{ $tc('button.cancel') }}
          </v-btn>
          <v-btn
            :loading="isLoading"
            class="text-body-2"
            text
            @click="disangage()"
          >
            {{ $tc('button.disangage') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="subscriptionConfirmDialog && selectedPlan != null"
      v-model="subscriptionConfirmDialog"
      max-width="400px"
      persistent
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar class="mb-3" color="info" dark dense>
          <v-toolbar-title class="healine">
            {{ $tc('label.please-confirm') | capitalize }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="isLoading"
            dark
            icon
            @click="subscriptionConfirmDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="subscriptionFinished">
          <div class="text-center">
            <p class="headline">
              <v-icon color="success" style="font-size: 48px;"
                >mdi-check-circle
              </v-icon>
            </p>
            <p class="text-overline">
              {{ $tc('membership.subscription-registered') | capitalize }}
            </p>
          </div>
        </v-card-text>
        <v-card-text v-else>
          <p>
            {{
              plateFormSubscription
                ? $tc('label.about-to-upgrade-subscribe-to-plan')
                : $tc('label.about-to-subscribe-to-plan') | capitalize
            }}
            :
          </p>
          <div>
            <h4>
              <span class="text--primary"
                >{{ $tc('membership.adhesion-level') | capitalize }} :</span
              >
              {{ selectedPlan.subscriptionName }}
            </h4>
            <span class="text--primary"
              >{{ $tc('membership.subscription-fees') }} € :</span
            >&nbsp;
            <strong
              >{{
                formatCurrency(
                  selectedPlan.subscriptionPriceInCent / 100,
                  2,
                  selectedPlan.subscriptionCurrency,
                )
              }}
              {{ $tc('donation.by-month') }}</strong
            >
          </div>
          <p class="mb-0">
            <span class="text--primary text-decoration-underline"
              >{{ $tc('membership.exclusive-advantages') }} :</span
            >
          </p>
          <v-list class="overflow-y-auto" max-height="180px">
            <v-list-item
              v-for="advantage of selectedPlan.subscriptionAdvantages"
              :key="advantage"
              class="px-0 text-caption"
            >
              <v-list-item-icon
                class="me-2 my-1 align-self-center"
                style="width: 0px !important;"
              >
                <v-icon color="green lighten-2">mdi-check-decagram</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ advantage }}
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <div class="my-3">
            <i18n path="label.you-will-be-charged-monthly" tag="span">
              <span>{{
                formatCurrency(
                  selectedPlan.subscriptionPriceInCent / 100,
                  2,
                  selectedPlan.subscriptionCurrency,
                )
              }}</span>
            </i18n>
            <br />
            <strong>{{
              $tc('label.you-can-cancel-at-any-time-since-member-space')
                | capitalize
            }}</strong>
          </div>
          <div v-if="paymentMethodInfos">
            <!-- Credit card element ui (v-card) -->
            <credit-card-details class="mx-auto"></credit-card-details>
          </div>
          <div v-if="subscriptionError" class="red--text">
            {{ subscriptionError }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="isLoading"
            color="blue-grey lighten-2"
            text
            @click="subscriptionConfirmDialog = false"
          >
            {{
              !!plateFormSubscription
                ? $tc('button.return')
                : $tc('button.cancel')
            }}
          </v-btn>
          <v-btn
            :disabled="subscriptionFinished"
            :loading="isLoading"
            color="success"
            depressed
            @click="submitPayment()"
          >
            {{
              payment_intent_pending
                ? $tc('button.continue-to-pay')
                : !!plateFormSubscription
                ? $tc('button.upgrade')
                : $tc('button.join') | capitalize
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { requestService } from '@/services/request.service'
import CreditCardDetails from '@/components/CreditCardDetails'
import { subscriptionService } from '@/services/subscription.service'
import { formatCurrency } from '../services/utils.service'

export default {
  name: 'PlateformSubscription',
  components: { CreditCardDetails },
  data() {
    return {
      isLoading: false,
      subscriptionFinished: false,
      subscriptionError: '',
      membershipDisangageConfirmDialog: false,
      subscriptionConfirmDialog: false,
      plans: [],
      tab: 0,
      tickedValue: 0,
      payment_intent_pending: false,
      confirmCard: false,
    }
  },
  computed: {
    paymentMethodInfos() {
      return this.$store.state.account.paymentMethod
    },
    stripeCard() {
      return this.$store.state.stripe.stripeCard
    },
    stripe() {
      return this.$store.state.stripe.stripe
    },
    stripeElementPaymentError() {
      return this.$store.state.stripe.stripeElementPaymentError
    },
    plateFormSubscription() {
      return this.$store.state.account.plateFormSubscription
    },
    getSubscriptionLevel() {
      switch (this.plans[this.tab]?.subscriptionLevel) {
        case 1:
          return 'premium'
        case 2:
          return 'basic'
        case 100:
          return 'gratuit'
        default:
          return 'default'
      }
    },
    getSubscriptionInterval() {
      switch (this.plans[this.tab]?.subscriptionInterval) {
        case 'day':
          return this.$tc('membership.by-day')
        case 'week':
          return this.$tc('membership.by-week')
        case 'month':
          return this.$tc('membership.by-month')
        case 'year':
          return this.$tc('membership.by-year')
        default:
          return ''
      }
    },
    computedTicksLabels() {
      const tickLabels = ['']
      if (this.plans.length > 0) {
        this.plans.forEach(plan => {
          tickLabels.push(
            `${(plan.subscriptionPriceInCent / 100).toFixed(2)}${
              this.$vuetify.breakpoint.smAndDown ? '€' : '€/mois'
            }`,
          )
          if (
            plan.subscriptionExtraPrices &&
            plan.subscriptionExtraPrices.length
          ) {
            plan.subscriptionExtraPrices.forEach(extraPrice => {
              tickLabels.push(
                `${extraPrice.extraPriceInCent / 100}${
                  this.$vuetify.breakpoint.smAndDown ? '€' : '€/mois'
                }`,
              )
            })
          }
        })
        return tickLabels
      }
      return tickLabels
    },
    flattenedPlans() {
      if (this.plans.length > 0) {
        const flatPlans = []
        this.plans.forEach(plan => {
          flatPlans.push(plan)
          if (plan.subscriptionExtraPrices.length) {
            plan.subscriptionExtraPrices.forEach(extraPrice => {
              flatPlans.push({
                ...plan,
                parentId: plan.id,
                id: extraPrice.id,
                subscriptionPriceInCent: extraPrice.extraPriceInCent,
                subscriptionStripePriceId: extraPrice.extraStripePriceId,
              })
            })
          }
        })
        return flatPlans
      }
      return []
    },
    selectedPlanId() {
      if (this.tickedValue > 0) {
        const parentId = this.flattenedPlans[this.tickedValue - 1].parentId
        if (parentId && parentId.length > 0) return parentId
        return this.flattenedPlans[this.tickedValue - 1].id
      }
      return ''
    },
    selectedPlan() {
      if (this.tickedValue > 0) {
        return this.flattenedPlans[this.tickedValue - 1]
      }
      return null
    },
    showCreditCardSection() {
      return (
        (this.tickedValue > 0 &&
          this.plateFormSubscription?.priceId !==
            this.selectedPlan?.subscriptionStripePriceId) ||
        (this.plateFormSubscription?.status == 'past_due' &&
          this.plateFormSubscription.status === 'past_due' &&
          ['requires_action', 'requires_payment_method'].includes(
            this.plateFormSubscription.lastPaymentIntentStatus,
          ))
      )
    },
  },
  methods: {
    formatCurrency,
    getColor(level) {
      return subscriptionService.subscriptionLevelColor(level)
    },
    getCurrencySymbol(currency) {
      switch (currency) {
        case 'eur':
          return '€'
        case 'usd':
          return '$'
        default:
          return '€'
      }
    },
    selectPlan(index) {
      this.tickedValue = index
      // setTimeout(() => {
      //   document
      //     .getElementById('subs-btn')
      //     .scrollIntoView({ behavior: 'smooth' })
      // }, 250)
    },
    showSubscriptionConfirmDialog() {
      if (this.paymentMethodInfos === null && !this.stripeCard._complete) {
        this.$store.dispatch(
          'stripe/setStripeElementPaymentError',
          this.$tc('error.payment-data-incomplete'),
        )
        return
      }

      this.subscriptionConfirmDialog = true
    },
    submitPayment() {
      this.isLoading = true
      if (this.paymentMethodInfos !== null) {
        if (this.payment_intent_pending) {
          this.retryInvoiceWithNewPaymentMethod({
            paymentMethodId: this.paymentMethodInfos.id,
            invoiceId: localStorage.getItem('subscriptionLatestInvoiceId'),
            priceId: this.selectedPlan.subscriptionStripePriceId,
          })
        } else {
          this.createSubscription({
            paymentMethodId: this.paymentMethodInfos.id,
            priceId: this.selectedPlan.subscriptionStripePriceId,
            planId: this.selectedPlan.parentId
              ? this.selectedPlan.parentId
              : this.selectedPlan.id,
            email: this.$store.state.account.user.user.email,
          })
        }
        return
      }

      this.$store
        .dispatch('stripe/stripeCreatePaymentMethod', {
          email: this.$store.state.account.user.user.email,
        })
        .then(async result => {
          await this.$store.dispatch('account/savePaymentMethod', {
            paymentMethodId: result.paymentMethod.id,
            last4: result.paymentMethod.card.last4,
            brand: result.paymentMethod.card.brand,
            expMonth: result.paymentMethod.card.exp_month,
            expYear: result.paymentMethod.card.exp_year,
            country: result.paymentMethod.card.country,
            paymentMethodType: result.paymentMethod.type,
          })
          this.createSubscription({
            paymentMethodId: result.paymentMethod.id,
            priceId: this.selectedPlan.subscriptionStripePriceId,
            planId: this.selectedPlan.parentId
              ? this.selectedPlan.parentId
              : this.selectedPlan.id,
            email: this.$store.state.account.user.user.email,
          })
        })
        .catch(err => {
          this.subscriptionError =
            typeof err == 'string' ? err : err.message || err.error
          this.isLoading = false
        })
    },
    createSubscription({ paymentMethodId, priceId, planId, email }) {
      requestService
        .post(
          process.env.VUE_APP_API_BASE_URL +
            `/payment/${
              !!this.plateFormSubscription
                ? 'upgrade-subscription'
                : 'start-platform-subscription'
            }`,
          {
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            planId: planId,
            email: email,
          },
        )
        .then(response => {
          if (!response.success) {
            this.$store.dispatch(
              'stripe/setStripeElementPaymentError',
              response.message
                ? response.message
                : this.$tc('error.server-error-try-again'),
            )
            if (
              response.code &&
              [
                'card_declined',
                'expired_card',
                'insufficient_funds',
                'processing_error',
              ].includes(response.code)
            ) {
              this.$store.dispatch('stripe/setChangePaymentCardDialog', true)
            }
            this.subscriptionFinished = false
            throw new Error(
              response.message ?? this.$tc('error.server-error-try-again'),
            )
          }
          return {
            subscription: response.subscription,
            paymentMethodId: paymentMethodId,
          }
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(this.handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(this.handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(this.onSubscriptionComplete)
        .catch(err => {
          if (!this.subscriptionError && !this.subscriptionFinished) {
            this.subscriptionError = this.$tc(
              'error.error-while-creating-subscription',
            )
          } else {
            this.subscriptionError = err.message || err.error
          }
          this.isLoading = false
        })
    },
    async handlePaymentThatRequiresCustomerAction({
      subscription,
      paymentMethodId,
      isRetry,
    }) {
      const invoice = subscription.latest_invoice
      if (subscription && subscription.status === 'active') {
        // Subscription is active, no customer actions required.
        return { subscription, paymentMethodId }
      }

      // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
      // If it's a retry, the payment intent will be on the invoice itself.
      const paymentIntent = invoice
        ? invoice.payment_intent
        : subscription.latest_invoice.payment_intent

      if (
        paymentIntent.status === 'requires_action' ||
        (isRetry === true && paymentIntent.status === 'requires_payment_method')
      ) {
        return await this.confirmCardHandler(
          subscription,
          paymentIntent,
          paymentMethodId,
          isRetry,
        )
      } else {
        // No customer action needed.
        return { subscription, paymentMethodId, isRetry }
      }
    },
    handleRequiresPaymentMethod({ subscription, paymentMethodId, isRetry }) {
      if (subscription.status === 'active') {
        // subscription is active, no customer actions required.
        return { success: true, subscription }
      } else if (
        subscription.latest_invoice.payment_intent.status ===
        'requires_payment_method'
      ) {
        // Using localStorage to manage the state of the retry here,
        // feel free to replace with what you prefer.
        // Store the latest invoice ID and status.
        localStorage.setItem(
          'subscriptionLatestInvoiceId',
          subscription.latest_invoice.id,
        )
        localStorage.setItem(
          'subscriptionLatestInvoicePaymentIntentStatus',
          subscription.latest_invoice.payment_intent.status,
        )
        this.payment_intent_pending = true
        this.isLoading = false
        this.subscriptionFinished = false
        this.subscriptionError = this.$tc('error.card-been-refused')
        this.$store.dispatch('stripe/setChangePaymentCardDialog', true)
        throw new Error(this.subscriptionError)
      } else {
        return { subscription, paymentMethodId }
      }
    },
    retryInvoiceWithNewPaymentMethod(
      { paymentMethodId, invoiceId, priceId },
      membershipId,
    ) {
      return (
        requestService
          .post(process.env.VUE_APP_API_BASE_URL + '/payment/startPayment', {
            connect: false,
            paymentMethodId: paymentMethodId,
            invoiceId: invoiceId,
            retry: true,
            priceId: priceId,
            ...(membershipId && { membershipId }),
          })
          // If the card is declined, display an error to the user.
          .then(response => {
            if (!response.success) {
              this.$store.dispatch(
                'stripe/setStripeElementPaymentError',
                response.message
                  ? response.message
                  : this.$tc('error.server-error-try-again'),
              )
              this.subscriptionError =
                response.message ?? this.$tc('error.server-error-try-again')
              if (
                response.code &&
                [
                  'card_declined',
                  'expired_card',
                  'insufficient_funds',
                ].includes(response.code)
              ) {
                this.$store.dispatch('stripe/setChangePaymentCardDialog', true)
              }
              this.clean()
              this.isLoading = false
              this.subscriptionFinished = false
              if (response.code === 400) throw new Error(this.subscriptionError)
              return
            }
            return {
              subscription: response.subscription,
              paymentMethodId: paymentMethodId,
              retry: true,
            }
          })
          // Normalize the result to contain the object returned by Stripe.
          // Add the additional details we need.
          // .then(result => {

          // })
          // Some payment methods require a customer to be on session
          // to complete the payment process. Check the status of the
          // payment intent to handle these actions.
          .then(this.handlePaymentThatRequiresCustomerAction)
          // No more actions required. Provision your service for the user.
          .then(this.handleRequiresPaymentMethod)
          .then(this.onSubscriptionComplete)
          .catch(error => {
            // An error has happened. Display the failure to the user here.
            // We utilize the HTML element we created.
            this.subscriptionError =
              error.message ?? this.$tc('error.server-error-try-again')
            this.isLoading = false
          })
      )
    },
    async onSubscriptionComplete() {
      this.subscriptionFinished = true
      this.$root.$emit('subscribedOrganizationUpdated')
      this.$root.$emit('organizationCreated')
      this.subscriptionError = ''
      this.clean()
      if (this.memberOrganization.status === 'past_due') {
        this.$store.dispatch(
          'alert/success',
          this.$tc('membership.subscription-renewed'),
        )
        await this.$pause(5000)
        this.$store.dispatch('account/refreshToken')
      } else {
        this.$store.dispatch('account/refreshToken')
      }
    },
    confirmPayment() {
      this.isLoading = true
      requestService
        .post(
          process.env.VUE_APP_API_BASE_URL + `/payment/payment-intent-info`,
          {
            connect: false,
            membershipId: this.plateFormSubscription?.membershipId,
            paymentMethodId: this.plateFormSubscription?.lastPaymentMethodId,
          },
        )
        .then(async res => {
          if (!res.success) {
            this.subscriptionError = res.message
            return
          }
          this.confirmCard = true
          await this.$pause(1000)
          this.stripe
            .confirmCardPayment(res.client_secret, {
              payment_method: this.plateFormSubscription?.lastPaymentMethodId,
            })
            .then(result => {
              if (result.error) {
                // Start code flow to handle updating the payment details.
                // Display error message in your UI.
                // The card was declined (i.e. insufficient funds, card has expired, etc).
                this.subscriptionError = result.error.message
                throw new Error(this.subscriptionError)
              } else {
                if (result.paymentIntent.status === 'succeeded') {
                  // Show a success message to your customer.
                  this.subscriptionFinished = true
                  this.$store.dispatch(
                    'alert/success',
                    this.$tc('membership.subscription-renewed'),
                  )
                  this.subscriptionError = ''
                }
              }
            })
            .catch(error => {
              // console.log(error)
              this.subscriptionError = this.$tc(
                'error.error-while-confirming-payment',
              )
            })
            .finally(async () => {
              await this.$pause(5000)
              this.$store.dispatch('account/refreshToken')
              this.confirmCard = false
              this.isLoading = false
            })
        })
        .catch(error => {
          // console.log(error)
          this.subscriptionError = this.$tc(
            'error.error-while-confirming-payment',
          )
          this.isLoading = false
        })
    },
    async confirmCardHandler(
      subscription,
      paymentIntent,
      paymentMethodId,
      isRetry,
    ) {
      this.confirmCard = true
      await this.$pause(1000)
      // eslint-disable-next-line compat/compat
      return new Promise((resolve, reject) => {
        this.stripe
          .confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentMethodId,
          })
          .then(result => {
            if (result.error) {
              // Start code flow to handle updating the payment details.
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc).
              this.subscriptionError = result.error.message
              throw new Error(this.subscriptionError)
            } else {
              if (result.paymentIntent.status === 'succeeded') {
                // Show a success message to your customer.
                resolve({
                  subscription: subscription,
                  paymentMethodId: paymentMethodId,
                  isRetry,
                })
              }
            }
          })
          .catch(async err => {
            this.confirmCard = false
            this.subscriptionError =
              err.message ?? this.$tc('error.error-while-confirming-payment')
            await this.$pause(5000)
            this.$store.dispatch('account/refreshToken')
            reject(err)
          })
      })
    },
    changePaymentMethodAndRetry() {
      if (
        this.plateFormSubscription?.lastPaymentMethodId ===
        this.paymentMethodInfos.id
      ) {
        this.$store.dispatch('stripe/setChangePaymentCardDialog', true)
      } else {
        this.isLoading = true
        this.retryInvoiceWithNewPaymentMethod(
          {
            paymentMethodId: this.paymentMethodInfos.id,
            invoiceId: null,
            priceId: this.selectedPlan.subscriptionStripePriceId,
          },
          this.plateFormSubscription?.membershipId,
        )
      }
    },
    getAvailablePlans() {
      this.isLoading = true
      requestService
        .get(process.env.VUE_APP_API_BASE_URL + '/platform-subscription/all')
        .then(response => {
          if (!response.success) {
            return
          }
          if (response.success && response.data === null) {
            return
          }
          if (response.success && response.data !== null) {
            this.plans = response.data
            if (this.plateFormSubscription) {
              this.flattenedPlans.forEach((plan, index) => {
                if (
                  (plan.id === this.plateFormSubscription.planId ||
                    plan.parentId === this.plateFormSubscription.planId) &&
                  plan.subscriptionStripePriceId ===
                    this.plateFormSubscription.priceId
                ) {
                  this.tickedValue = index + 1
                }
              })
            }
          }
        })
        .catch(err => {
          this.$store.dispatch(
            'alert/error',
            err.message || this.$tc('error.an-error-has-occurred'),
          )
          // console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async disangage() {
      this.isLoading = true
      try {
        const res = await requestService.post(
          `/payment/start-unsubscribe-platform`,
        )

        if (!res.success) {
          this.$store.dispatch(
            'alert/error',
            res.message || this.$tc('error.an-error-has-occurred'),
          )
          return
        }

        this.subscriptionFinished = false
        this.$store.dispatch(
          'alert/success',
          this.$tc('membership.disangage-success'),
        )
        this.$store.dispatch('account/fetchPlateformSubscription')
      } catch (error) {
        this.membershipError = error
        this.$store.dispatch(
          'alert/error',
          this.$tc('error.an-error-has-occurred'),
        )
      } finally {
        this.membershipDisangageConfirmDialog = false
        this.isLoading = false
      }
    },
    clean() {
      localStorage.removeItem('subscriptionLatestInvoiceId')
      localStorage.removeItem('subscriptionLatestInvoicePaymentIntentStatus')
      this.payment_intent_pending = false
    },
  },
  watch: {
    plateFormSubscription(plateFormSubscription) {
      this.isLoading = false
      if (plateFormSubscription) {
        this.flattenedPlans.forEach((plan, index) => {
          if (
            (plan.id === this.plateFormSubscription.planId ||
              plan.parentId === this.plateFormSubscription.planId) &&
            plan.subscriptionStripePriceId ===
              this.plateFormSubscription.priceId
          ) {
            this.tickedValue = index + 1
          }
        })
      }
    },
  },
  created() {
    this.getAvailablePlans()
    this.$store.dispatch('account/refreshToken')
    this.payment_intent_pending =
      localStorage.getItem('subscriptionLatestInvoiceId') !== null &&
      localStorage.getItem('subscriptionLatestInvoicePaymentIntentStatus') ===
        'requires_payment_method'
    // this.getUserPlatformSubscription()
  },
  beforeDestroy() {
    this.$store.dispatch('stripe/setStripeElementPaymentError', '')
  },
}
</script>
<style scoped>
.v-card {
  transition: background-color 0.3s;
}

.v-expansion-panel-header {
  transition: background-color 0.3s;
}

.v-list-item {
  min-height: 32px !important;
}
</style>
