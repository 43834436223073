<template>
  <div>
    <page-list-header :title="$tc('subscribed.your-subscriptions')">
      <template v-if="hasError">
        <div class="text-center">
          {{ $tc('error.an-error-has-occurred-refresh') }}
        </div>
      </template>
      <template v-else-if="!postsLoading && subscribedPosts.length === 0">
        <div class="text-center mb-5">
          <div class="text-h6">
            {{ $tc('subscribed.gather-everything') }}
          </div>
          <div v-if="this.$store.state.account.status.loggedIn">
            {{ $tc('subscribed.content-of-channel') }}
          </div>
          <div v-else class="my-4">
            <v-btn color="primary" :to="{ name: 'Login' }">{{
              $tc('label.login')
            }}</v-btn>
          </div>
        </div>
        <div class="d-flex justify-center">
          <v-img
            src="@/assets/no-subscription.svg"
            width="300px"
            max-width="300px"
          ></v-img>
        </div>
      </template>
    </page-list-header>
    <vertical-post-list
      v-if="this.$store.state.account.status.loggedIn"
      :posts="subscribedPosts"
      :data-loading="postsLoading"
      :end-reached="endReached"
      :has-error="hasError"
      @claim-more-posts="fetchSubscribedPosts"
      simplified
      play-inline
    ></vertical-post-list>
  </div>
</template>

<script>
import { requestService } from '../services/request.service'
import PageListHeader from '@/components/PageListHeader.vue'
import VerticalPostList from '@/components/VerticalPostList.vue'

export default {
  name: 'Subscribed',
  components: { VerticalPostList, PageListHeader },
  data() {
    return {
      postsLoading: false,
      hasError: false,
      queryLast: null,
      subscribedPosts: [],
      endReached: false,
    }
  },
  methods: {
    async fetchSubscribedPosts() {
      this.postsLoading = true
      this.hasError = false
      requestService
        .get(process.env.VUE_APP_API_BASE_URL + '/post/subscribed', {
          params: {
            after: this.queryLast,
          },
        })
        .then(response => {
          if (this.subscribedPosts.length && this.queryLast !== null) {
            this.subscribedPosts = this.subscribedPosts.concat(response.posts)
          } else {
            this.subscribedPosts = response.posts
          }

          if (response.last !== null) {
            this.queryLast = response.last
          } else {
            this.endReached = true
          }
          this.$store.state.lastSubscribedPosts = this.subscribedPosts
        })
        .catch(() => {
          this.hasError = true
        })
        .finally(() => {
          this.postsLoading = false
        })
    },
    onIntersectEnd() {
      if (this.postsLoading || this.endReached || this.hasError) {
        return
      }
      this.fetchSubscribedPosts()
    },
  },
  mounted() {
    if (this.$store.state.account.status.loggedIn) {
      this.fetchSubscribedPosts()
    }
  },
}
</script>

<style scoped></style>
