
import { defineComponent } from 'vue'
import PostPreview from '@/components/post/PostPreview.vue'

export default defineComponent({
  name: 'ListPreview',
  components: { PostPreview },
  props: {
    list: { type: Object, required: true },
    hover: { type: Boolean, default: false },
    showDuration: {
      type: Boolean,
      default: false,
    },
  },
})
