<template>
  <div
    class="video-block"
    ref="videoBlock"
    :class="{ 'has-subtitles': captions.length }"
    v-show="playerVisible"
    @mousemove="mouseMove"
    @mouseleave="mouseOut"
    @touchstart="onTouchEvent"
  >
    <!--    <div v-show="controlShown">
      <v-btn
        fab
        depressed
        class="mobile-fab rewind-btn"
        @click="rewind"
        @touchstart="rewind"
      >
        <v-icon>mdi-rewind-10</v-icon>
      </v-btn>
      <v-btn
        fab
        depressed
        class="mobile-fab fast-forward-btn"
        @click="fastForward"
        @touchstart="fastForward"
      >
        <v-icon>mdi-fast-forward-10</v-icon>
      </v-btn>
    </div>-->
  </div>
</template>

<script>
/* eslint-disable no-undef */
import Clappr from '@clappr/player'
import PlaybackRatePlugin from 'clappr-playback-rate-plugin'
// import ClapprMarkersPlugin from 'clappr-markers-plugin'
import LevelSelector from 'level-selector'
import Hls from 'hls.js/dist/hls.min'
import mux from 'mux-embed'
import PosterPlugin from './plugins/poster/poster'
// import HlsjsPlayback from '@clappr/hlsjs-playback'
// import ChromecastPlugin from 'clappr-chromecast-plugin'
// import DashShakaPlayback from 'dash-shaka-playback'

export default {
  name: 'Clappr',
  props: {
    muted: Boolean,
    thumbnail: String,
    autoplay: Boolean,
    sources: { type: Array, default: () => [{ src: '' }] },
    statTimeout: { type: Number, default: null },
    startPosition: { type: Number, default: 0 },
    isLiveActive: Boolean,
    finished: Boolean,
    hasMediaError: Boolean,
    mediaReady: Boolean,
    title: String,
    live: { type: Boolean, default: false },
    video: { type: Object },
    embed: Boolean,
  },
  data() {
    return {
      playerInitialized: false,
      player: null,
      retryTimeout: null,
      loadTimeout: null,
      disableMediaControlTimeout: null,
      enableMediaControlTimeout: null,
      playerVisible: false,
      qualityLabels: [],
      qualityHeights: [],
      canUpdatePosition: true,
      controlShown: false,
    }
  },
  computed: {
    captions() {
      if (this.video === undefined || !Array.isArray(this.video.captions)) {
        return []
      }
      return this.video.captions
        .filter(caption => {
          return caption.file !== null
        })
        .map(caption => {
          return {
            lang: caption.languageCode,
            label: caption.label,
            src: caption.file.url,
            kind: 'subtitles',
          }
        })
    },
    markers() {
      if (
        this.video === undefined ||
        typeof this.video.description !== 'string'
      ) {
        return []
      }
      const matches = [
        ...this.video.description.matchAll(/^(\d{1,2}):(\d{2})\s+(.+)$/gm),
      ]
      return matches.map(match => {
        const seconds = parseInt(match[1]) * 60 + parseInt(match[2])
        return new ClapprMarkersPlugin.StandardMarker(
          seconds,
          match[3].trim().slice(0, 80),
        )
      })
    },
    isLastChild() {
      if (!this.video.children || !this.video.children.length) {
        return true
      }
      return (
        this.video.id === this.video.children[this.video.children.length - 1].id
      )
    },
    filteredSources() {
      // return this.sources.filter(source => {
      //   return source.src.match(/\.m3u8$/)
      // })
      return this.sources
    },
  },
  methods: {
    initPlayer() {
      if (this.live && typeof this.sources[0].src !== 'string') {
        clearTimeout(this.retryTimeout)
        this.retryTimeout = setTimeout(() => {
          this.initPlayer()
        }, 3000)
        return
      }
      this.playerInitialized = true
      this.$emit('playerInitialized', true)

      const hlsJsConfig = {
        liveSyncDurationCount: 7,
        // initialLiveManifestSize: 3,
        // backBufferLength: 10 * 60,
        startPosition: this.startPosition,
        // maxBufferSize: 0,
        maxBufferLength: 30,
        // manifestLoadingMaxRetry: 5,
        // stretchShortVideoTrack: true,
        // maxFragLookUpTolerance: 0.5,
        capLevelOnFPSDrop: true,
        capLevelToPlayerSize: true,
      }

      let loader, engine
      let useP2P = false
      if (
        this.video.isLiveActive &&
        !this.$vuetify.breakpoint.mobile &&
        !this.isIos() &&
        p2pml.hlsjs.Engine.isSupported() &&
        false
      ) {
        const config = {
          loader: {
            trackerAnnounce: [
              'wss://tracker.crowdbunker.com',
              'wss://tracker.openwebtorrent.com',
            ],
            simultaneousHttpDownloads: 1,
            requiredSegmentsPriority: 1,
            httpFailedSegmentTimeout: 1000,
          },
        }
        engine = new p2pml.hlsjs.Engine(config)
        loader = engine.createLoaderClass()
        hlsJsConfig.loader = loader
        useP2P = true
      }

      try {
        this.player = new Clappr.Player({
          parent: this.$refs.videoBlock,
          plugins: [
            // HlsjsPlayback,
            LevelSelector,
            PlaybackRatePlugin,
            // ClapprMarkersPlugin,
            PosterPlugin,
            // DashShakaPlayback,
            // ChromecastPlugin,
          ],
          source: this.filteredSources[0].src,
          // source: 'https://wowza.peer5.com/live/smil:bbb_abr.smil/playlist.m3u8',
          width: '100%',
          height: '100%',
          autoPlay: this.autoPlay,
          autoSeekFromUrl: false,
          watermark: this.video.secureWatermark
            ? `https://fakeimg.pl/350x200/ff0000%2C0/ff0000%2C100/?text=NE+PAS+COPIER+%0A+${
                this.$store.state.account.user
                  ? this.$store.state.account.user.user.username
                  : ''
              }&font_size=25`
            : undefined,
          position: 'bottom-left',
          playback: {
            hlsjsConfig: hlsJsConfig,
            crossOrigin: 'anonymous',
            externalTracks: this.captions,
            triggerFatalErrorOnResourceDenied: true,
            playInline: true,
            preload: 'auto',
            disableContextMenu: true,
          },
          mediacontrol: { seekbar: '#9f1949' },
          // poster: this.thumbnail,
          levelSelectorConfig: {
            labelCallback: playbackLevel => {
              const label = playbackLevel.level.height + 'p'
              if (
                this.qualityLabels.includes(label) &&
                playbackLevel.label !== label
              ) {
                return null
              }
              this.qualityLabels.push(label)
              return label
            },
          },
          // chromecast: {
          //   media: {
          //     type: ChromecastPlugin.Movie,
          //     title: this.title,
          //     subtitle: 'CrowdBunker',
          //   },
          // },
          playbackRateConfig: {
            defaultValue: 1,
            options: [
              { value: 0.25, label: '0.25x' },
              { value: 0.5, label: '0.5x' },
              { value: 0.75, label: '0.75x' },
              { value: 1, label: '1x' },
              { value: 1.25, label: '1.25x' },
              { value: 1.5, label: '1.5x' },
              { value: 1.75, label: '1.75x' },
              { value: 2, label: '2x' },
            ],
            // rateSuffix: 'x',
          },
          // markersPlugin: {
          //   markers: this.markers,
          //   tooltipBottomMargin: 17, // optional
          // },
          mute: this.muted,
          hideMediaControl: false,
          events: {
            onReady: function() {
              if (Clappr.Browser.isMobile) {
                const plugin = this.getPlugin('click_to_pause')
                plugin && plugin.disable()
              }
            },
          },
        })
      } catch (e) {
        console.log('player creation error', e)
      }

      window.$player = this
      if (useP2P) {
        p2pml.hlsjs.initClapprPlayer(this.player)
      }

      this.player.core.activePlayback.on(
        Clappr.Events.PLAYBACK_LOADEDMETADATA,
        () => {
          if (this.isLiveActive && this.isLastChild) {
            this.player.seekPercentage(100)
          }
        },
      )

      this.player.on(Clappr.Events.PLAYER_PLAY, () => {
        clearTimeout(this.statTimeout)
        this.$emit(
          'update:statTimeout',
          setTimeout(() => {
            this.$emit('sendViewStat')
          }, 5000),
        )
        this.$root.$emit('playerPlay', this.player.uniqueId)
        clearTimeout(this.disableMediaControlTimeout)
        this.disableMediaControlTimeout = setTimeout(
          () => this.player.core.mediaControl.disable(),
          2000,
        )
      })
      this.player.on(Clappr.Events.PLAYER_PAUSE, () => {
        clearTimeout(this.statTimeout)
        clearTimeout(this.disableMediaControlTimeout)
        this.player.core.mediaControl.enable()
      })
      this.player.core.mediaControl.on(Clappr.Events.MEDIACONTROL_SHOW, () => {
        this.controlShown = true
        this.$emit('controlShow')
      })
      this.player.core.mediaControl.on(Clappr.Events.MEDIACONTROL_HIDE, () => {
        this.controlShown = false
        this.$emit('controlHide')
      })
      // this.player.core.mediaControl.on(
      //   Clappr.Events.MEDIACONTROL_PLAYING,
      //   event => {
      //   },
      // )
      this.player.on(Clappr.Events.PLAYER_TIMEUPDATE, error => {
        if (this.hasMediaError) {
          this.$emit('update:hasMediaError', false)
        }
      })
      this.player.core.mediaControl.on(
        Clappr.Events.MEDIACONTROL_PLAYING,
        () => {
          this.$emit('playing', true)
        },
      )
      this.player.core.mediaControl.on(
        Clappr.Events.MEDIACONTROL_NOTPLAYING,
        () => {
          this.$emit('playing', false)
        },
      )
      this.player.core.activePlayback.on(
        Clappr.Events.PLAYBACK_LEVELS_AVAILABLE,
        levels => {
          for (let i = 0; i < levels.length; i++) {
            if (this.qualityHeights.includes(levels[i].level.height)) {
              levels.splice(i, 1)
              i--
            } else {
              this.qualityHeights.push(levels[i].level.height)
            }
          }
          this.player.core.mediaControl.render()
        },
      )

      this.player.core.activeContainer.on(
        Clappr.Events.CONTAINER_SUBTITLE_CHANGED,
        data => {
          if (data.id === -1) {
            this.player.core.activePlayback._hls.subtitleDisplay = 0
          } else {
            this.player.core.activePlayback._hls.subtitleDisplay = 1
          }
        },
      )

      this.player.on(Clappr.Events.PLAYER_ERROR, error => {
        console.log('PLAYER_ERROR', error)
        this.$emit('loading', false)
        this.$emit('update:hasMediaError', true)

        if (error.origin === 'hls' && this.isLiveActive) {
          clearTimeout(this.retryTimeout)
          this.retryTimeout = setTimeout(this.loadSrc, 3000)
        }
      })

      this.player.core.activePlayback.on(
        Clappr.Events.PLAYBACK_TIMEUPDATE,
        progress => {
          if (!this.canUpdatePosition) {
            return
          }
          this.canUpdatePosition = false
          this.$emit('updatePosition', Math.floor(progress.current))
          setTimeout(() => (this.canUpdatePosition = true), 1000)
        },
      )

      this.player.core.activePlayback.on(Clappr.Events.PLAYBACK_ENDED, () =>
        this.$emit('ended'),
      )

      this.monitor()

      this.$emit('playerReady', true)
      this.$emit('loading', false)
      if (this.autoplay) {
        this.player.play(true)
      }
      this.$nextTick(() => (this.playerVisible = true))
    },
    loadSrc() {
      this.destroy()
      this.initPlayer()
    },
    destroy() {
      if (this.player) {
        clearTimeout(this.loadTimeout)
        clearTimeout(this.retryTimeout)
        this.player.destroy()
      }
    },
    play() {
      if (this.player) {
        this.player.play()
      }
    },
    pause() {
      if (this.player) {
        this.player.pause()
      }
    },
    mouseMove() {
      clearTimeout(this.disableMediaControlTimeout)
      this.disableMediaControlTimeout = setTimeout(
        () => this.player.core.mediaControl.disable(),
        2000,
      )
      if (this.player.core.mediaControl.userDisabled) {
        this.player.core.mediaControl.enable()
      }
    },
    mouseOut() {
      clearTimeout(this.disableMediaControlTimeout)
      this.player.core.mediaControl.disable()
    },
    onTouchEvent(event) {
      if (
        event.target.closest('.media-control') !== null ||
        event.target.closest('.play-wrapper') !== null
      ) {
        return
      }
      event.preventDefault()
      this.mouseMove()
    },
    rewind() {
      this.player.core.mediaControl.seekRelative(-10)
    },
    fastForward() {
      const activePlayback = this.player.core.activePlayback
      if (
        activePlayback.isLive &&
        activePlayback.getDuration() - activePlayback.getCurrentTime() < 12
      ) {
        return
      }
      this.player.core.mediaControl.seekRelative(10)
    },
    monitor() {
      mux.monitor(this.$refs.videoBlock.querySelector('video'), {
        debug: false,
        hlsjs: this.player.core.getCurrentPlayback(),
        Hls: Hls,
        data: {
          env_key: process.env.VUE_APP_MUX_TOKEN,
          // Metadata fields
          player_name: 'Clappr Player', // any arbitrary string you want to use to identify this player
          player_init_time: new Date(),
          video_title: this.video.title,
          video_id: this.video.uniqueKey,
          video_stream_type: this.isLiveActive ? 'live' : 'on-demand',
        },
      })
    },
    isIos() {
      // ignore iPad because it is compatible with P2P
      return ['iPhone Simulator', 'iPod Simulator', 'iPhone', 'iPod'].includes(
        navigator.platform,
      )
    },
  },
  watch: {
    $route(to, from) {
      if (to.query.t && to.query.t !== from.query.t) {
        this.player.seek(parseInt(to.query.t))
      }
    },
  },
  mounted() {
    if (!this.live || this.isLiveActive || this.finished) {
      this.initPlayer()
    } else {
      this.$emit('loading', false)
    }
    this.$root.$on('playerPlay', uniqueId => {
      if (this.player && this.player.uniqueId !== uniqueId) {
        this.pause()
      }
    })
  },
}
</script>

<style lang="scss" scoped>
.video-block {
  height: 100%;
  width: 100%;
}

.mobile-fab {
  background-color: #272727bf !important;
  position: absolute;
  top: 50%;
  z-index: 3;
  transform: translateY(-50%);
  &.rewind-btn {
    left: 10%;
  }
  &.fast-forward-btn {
    right: 10%;
  }
}

::v-deep {
  ul {
    padding-left: 0;
  }
  .play-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .poster-icon {
      max-height: 75px;
      border-radius: 50%;
      background-color: var(--v-primary-base);
      path {
        //fill: var(--v-primary-base) !important;
        //transform: scale(65%) translateX(38%) translateY(27%);
      }
    }
  }

  .media-control-background {
    margin-bottom: -1px;
  }

  .chromecast-button {
    line-height: 41px;
    svg {
      transform: scale(0.8);
    }
  }
  .drawer-container {
    margin-right: 0 !important;
    .volume-bar-hide {
      width: 0 !important;
    }
  }
  [aria-label='hd-indicator'] {
    display: none !important;
  }
  .cc-controls[data-cc-controls] > ul {
    right: 1px;
    border: none;
    background-color: rgba(28, 28, 28, 0.9);
    bottom: 100%;
    a {
      color: #eee;
      padding: 5px 18px;
      font-size: 12px;
    }
  }
}
.has-subtitles {
  ::v-deep {
    .cc-controls[data-cc-controls] {
      display: block;
    }
  }
}

@media (max-width: 480px) {
  ::v-deep {
    .media-control-indicator[data-duration] {
      display: none !important;
    }
    [data-level-selector-button] {
      max-width: 32px;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .has-subtitles {
    ::v-deep {
      .chromecast-button {
        display: none;
      }
    }
  }
}
</style>
