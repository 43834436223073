import i18n from '@/plugins/i18n'

const state = {
  stripe: null,
  stripeCard: null,
  stripeElementPaymentError: '',
  changePaymentCardDialog: false,
}

const actions = {
  setStripe({ commit }, stripe) {
    commit('setStripe', stripe)
  },
  setStripeCard({ commit }, stripeCard) {
    commit('setStripeCard', stripeCard)
  },
  setStripeElementPaymentError({ commit }, stripeElementPaymentError) {
    commit('setStripeElementPaymentError', stripeElementPaymentError)
  },
  stripeCreatePaymentMethod({ commit /* dispatch */ }, { email }) {
    if (!state.stripe || !state.stripeCard) {
      commit('setStripeElementPaymentError', 'Stripe not loaded')
      return Promise.reject('Stripe is not initialized.')
    }
    return new Promise((resolve, reject) => {
      state.stripe
        .createPaymentMethod({
          type: 'card',
          card: state.stripeCard,
          billing_details: {
            email: email,
          },
        })
        .then(result => {
          if (result.error) {
            commit('setStripeElementPaymentError', result.error.message)
            reject(result.error.message)
          } else {
            commit('setStripeElementPaymentError', '')
            // dispatch('account/savePaymentMethod', result.paymentMethod, {
            //   root: true,
            // })
            resolve(result)
          }
        })
        .catch(error => {
          // commit('setStripeElementPaymentError', error.message)
          // console.log(error.message)
          reject(i18n.tc('error.error-while-initializing-payment-card'))
        })
    })
  },
  setChangePaymentCardDialog({ commit }, changePaymentCardDialog) {
    commit('setChangePaymentCardDialog', changePaymentCardDialog)
  },
}

const mutations = {
  setStripe(state, stripe) {
    state.stripe = stripe
  },
  setStripeCard(state, stripeCard) {
    state.stripeCard = stripeCard
  },
  setStripeElementPaymentError(state, stripeElementPaymentError) {
    state.stripeElementPaymentError = stripeElementPaymentError
  },
  setChangePaymentCardDialog(state, changePaymentCardDialog) {
    state.changePaymentCardDialog = changePaymentCardDialog
  },
}

const getters = {
  stripe: state => state.stripe,
  stripeCard: state => state.stripeCard,
  stripeElementPaymentError: state => state.stripeElementPaymentError,
  changePaymentCardDialog: state => state.changePaymentCardDialog,
}

export const stripe = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
