<template>
  <div style="position:relative;">
    <div id="import-image" v-show="showDragAndDrop"></div>
    <div
      v-if="images.length > 0"
      class="mt-2 overflow-x-auto d-flex align-center images-wrapper"
    >
      <div
        v-for="image in images"
        :key="image.id"
        class="d-inline-flex mx-1 mt-4 mb-1 align-center"
        style="position: relative"
      >
        <div class="image-box">
          <v-img :src="image.preview" width="100%" height="100%">
            <v-row
              class="fill-height ma-0 image-overlay"
              align="center"
              justify="center"
              v-show="image.key === null"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </v-img>
          <v-progress-linear
            :active="image.progress < 100"
            height="5px"
            color="success"
            :value="image.progress"
            absolute
            bottom
          ></v-progress-linear>
        </div>

        <v-btn
          @click="removeImage(image)"
          fab
          absolute
          top
          x-small
          color="error"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-btn
        fab
        v-show="!showDragAndDrop && images.length < maxFiles"
        depressed
        color="primary"
        class="ml-2"
        @click="showDragAndDrop = true"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-btn
      @click="cancel"
      class="ml-6"
      color="primary darken-1"
      icon
      absolute
      top
      right
      outlined
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { uppyService } from '@/services/uppy.service'

export default {
  name: 'ImportImages',
  props: {
    files: { type: Array, default: () => [] },
    modifying: { type: Boolean, default: false },
  },
  data() {
    return {
      uppy: null,
      uploading: false,
      uploadProgress: 0,
      images: [],
      showDragAndDrop: true,
      maxFiles: 5,
    }
  },
  methods: {
    loadImages() {
      this.images = this.files
      this.showDragAndDrop = false
    },
    instantiateUppy() {
      this.uppy = uppyService.instantiate({
        id: 'images',
        note: 'Max. 5',
        useDashboard: false,
        useImageEditor: false,
        useDragDrop: true,
        allowedFileTypes: ['image/jpeg', 'image/png', 'image/gif'],
        maxFileSize: 19 * 1024 * 1024, // max 50 Mo
        minFileSize: 5000,
        target: '#import-image',
        autoProceed: true,
        maxNumberOfFiles: this.maxFiles,
        allowMultipleUploads: true,
      })
      if (this.modifying && this.files.length) {
        this.loadImages()
      }
      this.uppy.on('upload-success', file => {
        const image = this.images.find(item => item.id === file.id)
        if (file.s3Multipart) {
          image.key = file.s3Multipart.key
        } else {
          image.key = file.meta['key']
        }
      })
      this.uppy.on('file-added', file => {
        this.showDragAndDrop = false
        this.images.push({
          file: file,
          id: file.id,
          preview: URL.createObjectURL(file.data),
          progress: 0,
          key: null,
        })
      })
      // this.uppy.on('upload', file => {
      // })
      this.uppy.on('upload', () => {
        this.uploading = true
      })
      this.uppy.on('progress', data => {
        this.uploadProgress = data
      })
      this.uppy.on('upload-progress', (file, progress) => {
        const image = this.images.find(item => item.id === file.id)
        if (image) {
          image.progress = (progress.bytesUploaded / progress.bytesTotal) * 100
        }
      })
      this.uppy.on('error', error => {
        console.log('error', error)
        this.uploading = false
        this.uploadProgress = 0
      })
      this.uppy.on('upload-error', (file, response) => {
        this.uploading = false
        console.log('error with file:', file.id)
        const { error } = response
        console.log('error message:', error, response)
        if (error.detail) {
          this.$emit('error', error.detail)
        }
        this.uppy.reset()
      })
    },
    removeImage(image) {
      this.uppy.removeFile(image.id)
      const imageIndex = this.images.findIndex(item => item.id === image.id)
      this.images.splice(imageIndex, 1)
    },
    cancel() {
      if (this.showDragAndDrop && this.images.length) {
        this.showDragAndDrop = false
        return
      }
      this.$emit('cancelImport')
    },
  },
  watch: {
    images(images) {
      this.$emit('update:files', images)
    },
  },
  mounted() {
    this.instantiateUppy()
  },
}
</script>

<style scoped lang="scss">
#import-image {
  color: #adadad;
  ::v-deep {
    .uppy-DragDrop-container {
      max-height: 175px;
      background-color: var(--v-secondary-lighten2);
      font-family: 'Roboto', sans-serif;
      font-size: 17px;
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

.images-wrapper {
  border-right: 1px solid var(--v-secondary-lighten2);
  padding: 55px 3px 3px;
}

.image-box {
  width: 100px;
  height: 100px;
  border: 2px solid var(--v-primary-base);
  border-radius: 5px;
  position: relative;
  .image-overlay {
    background-color: #0b0b0b96;
  }
}
</style>
