
import { defineComponent } from 'vue'
import LiveChat from '@/components/LiveChat.vue'

export default defineComponent({
  name: 'LiveChatCard',
  components: { LiveChat },
  props: {
    post: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    inWindow: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    createLiveChatWindow() {
      const url =
        window.location.origin +
        this.$router.resolve({
          name: 'LiveChat',
          params: { slug: this.post.video.id },
        }).href
      // create a new window
      const newWindow = window.open(
        url,
        '_blank',
        'width=390,height=600,toolbar=0,location=0,menubar=0,status=0,titlebar=0',
      )
    },
  },
})
