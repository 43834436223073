<template>
  <div>
    <v-card v-if="!memberOrganization" color="background">
      <v-card-title class="secondary">
        <span class="text-truncate">{{ organization.name }}</span>
      </v-card-title>
      <v-card-title class="text-body-1 text-break">
        <span>{{ $tc('membership.join-this-organization') | capitalize }}</span>
      </v-card-title>
      <v-card-text v-if="!subscriptionFinished">
        <div>
          <h4>
            <span class="text-primary"
              >{{ $tc('membership.adhesion-level') | capitalize }} :</span
            >
            {{
              organization.membershipGroups[0].membershipLevel ??
                $tc('label.default') | capitalize
            }}
          </h4>
          <span class="text--primary"
            >{{ $tc('membership.adhesion-fees') }} € :</span
          >&nbsp;
          {{ organization.membershipGroups[0].membershipFeeCents / 100 }}€
          {{ $tc('donation.by-month') }}
        </div>
        <div>
          <span class="text-primary"
            >{{ $tc('membership.exclusive-advantages') }} :</span
          >&nbsp;
          <span
            v-for="(avantage, index) in organization.membershipGroups[0]
              .membershipAdvantages"
            :key="avantage"
          >
            {{ avantage }}
            {{
              index !==
              organization.membershipGroups[0].membershipAdvantages.length - 1
                ? ','
                : ''
            }}
          </span>
        </div>
        <div>
          <p class="mt-2 mb-0">
            <v-alert
              text
              dense
              color="teal"
              icon="mdi-checkbox-marked-circle-plus-outline"
              border="left"
              class="text-caption"
            >
              {{ $tc('membership.enter-infos-to-join') }}
            </v-alert>
          </p>
          <!-- show paymentMethod ui with for 4 last digits, expMonth, expYear -->
          <div>
            <!-- Credit card element ui (v-card) -->
            <credit-card-details
              :isConnect="true"
              :confirmCard="confirmCard"
            ></credit-card-details>
            <div v-if="subscriptionError" class="red--text">
              {{ subscriptionError }}
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-text v-else>
        <v-alert color="success">
          {{ $tc('membership.subscription-registered') }}
        </v-alert>
        <h3 class="primary--text text-center">
          {{ $tc('membership.thank-you') }} !
        </h3>
        <br />
        {{ $tc('membership.protection-thank-to-you') }} <br />
        <br />
        <p class="mt-3">
          {{ $tc('membership.modify-cancel-monthly-subscription') }}
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('cancel')" :disabled="isLoading">{{
          subscriptionFinished ? $tc('button.close') : $tc('button.cancel')
        }}</v-btn>
        <v-btn
          v-if="!subscriptionFinished"
          class="text-uppercase"
          color="success"
          @click="submitPayment()"
          :loading="isLoading"
          depressed
        >
          {{
            payment_intent_pending
              ? $tc('button.continue-to-pay')
              : $tc('button.join')
          }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-else>
      <v-card-title>
        <span class="headline">{{ $tc('membership.membership-info') }}</span>
      </v-card-title>
      <v-card-text>
        <div class="success--text font-weight-bold">
          <span>Abonnement</span>
          <span class="text-uppercase">
            {{ memberOrganization.organizationUid }}
          </span>
          <span> en cours.</span>
        </div>
        <div>
          <p class="mb-0">
            <span class="text-overline">Contribution</span> :
            {{ formatCurrency(memberOrganization.amountCents / 100, 2) }}
            <span> / Mois</span>
          </p>
          <p class="mb-0">
            <span class="text-overline">Date de début</span> :
            {{
              new Date(
                memberOrganization.subscriptionStartDate,
              ).toLocaleDateString()
            }}
          </p>
          <p v-if="memberOrganization.nextBillingDate" class="mb-0">
            <span class="text-overline">Prochaine date de facturation</span>
            :
            {{
              new Date(memberOrganization.nextBillingDate).toLocaleDateString()
            }}
          </p>
          <p v-else class="mb-0">
            <span class="text-overline">Date de fin</span> :
            {{
              new Date(
                memberOrganization.subscriptionEndDate,
              ).toLocaleDateString()
            }}
          </p>
          <div
            v-if="
              memberOrganization.status === 'past_due' &&
                ['requires_action', 'requires_payment_method'].includes(
                  memberOrganization.lastPaymentIntentStatus,
                )
            "
            class="orange--text"
          >
            <p class="text-subtitle-2 text-center mb-0">
              {{ $tc('error.a-platform-subscription-payment-failed') }}
            </p>
            <div
              v-if="
                memberOrganization.failureErrorCode ===
                  'authentication_required'
              "
              class="text-center"
            >
              <p class="text-body-2 mb-1">
                {{
                  memberOrganization.lastPaymentMethodId ===
                  paymentMethodInfos.id
                    ? $tc('label.payment-need-confirmation')
                    : ''
                }}
              </p>
              <v-btn
                color="info"
                outlined
                small
                @click="
                  memberOrganization.lastPaymentMethodId ===
                  paymentMethodInfos.id
                    ? confirmPayment()
                    : changePaymentMethodAndRetry()
                "
                :loading="isLoading"
              >
                {{
                  memberOrganization.lastPaymentMethodId ===
                  paymentMethodInfos.id
                    ? $tc('button.confirm-payment')
                    : $tc('button.retry-payment')
                }}
              </v-btn>
            </div>
            <div
              v-else-if="
                memberOrganization.failureErrorCode ===
                  'payment_intent_authentication_failure'
              "
              class="text-center"
            >
              <p
                v-if="
                  memberOrganization.lastPaymentMethodId ===
                    paymentMethodInfos.id
                "
                class="text-body-2 mb-1"
              >
                {{ $tc('label.card-authentication-failed') }}
              </p>
              <p class="text-body-2 mb-1">
                {{ $tc('label.change-card-and-retry') }}
              </p>
              <v-btn
                color="info"
                outlined
                small
                @click="changePaymentMethodAndRetry()"
                :loading="isLoading"
              >
                {{
                  memberOrganization.lastPaymentMethodId ===
                  paymentMethodInfos.id
                    ? $tc('button.change-card')
                    : $tc('button.retry-payment')
                }}
              </v-btn>
            </div>
            <div
              v-else-if="
                memberOrganization.failureErrorCode === 'card_declined'
              "
              class="text-center"
            >
              <p
                v-if="
                  memberOrganization.lastPaymentMethodId ===
                    paymentMethodInfos.id
                "
                class="text-body-2 mb-1"
              >
                {{ $tc('error.card-been-refused') }}
              </p>
              <p class="text-body-2 mb-1">
                {{ $tc('label.change-card-and-retry') }}
              </p>
              <v-btn
                color="info"
                outlined
                small
                @click="changePaymentMethodAndRetry()"
                :loading="isLoading"
              >
                {{
                  memberOrganization.lastPaymentMethodId ===
                  paymentMethodInfos.id
                    ? $tc('button.change-card')
                    : $tc('button.retry-payment')
                }}
              </v-btn>
            </div>
          </div>
          <!-- show paymentMethod ui with for 4 last digits, expMonth, expYear -->
          <div>
            <!-- Credit card element ui (v-card) -->
            <credit-card-details
              :isConnect="true"
              :showSmall="showSmall"
              :confirmCard="confirmCard"
            ></credit-card-details>
            <div v-if="subscriptionError" class="red--text">
              {{ subscriptionError }}
            </div>
          </div>
          <v-alert
            v-if="memberOrganization.status == 'pending'"
            dense
            text
            type="info"
            v-html="$tc('membership.subscription-pending-message')"
          >
          </v-alert>
          <v-alert
            v-if="memberOrganization.status == 'canceled'"
            dense
            text
            type="warning"
            v-html="$tc('membership.subscription-canceled-message')"
          ></v-alert>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="isLoading"
          color="primary"
          text
          @click="$emit('cancel')"
        >
          {{ $tc('button.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { requestService } from '@/services/request.service'
// import { utilsService } from '@/services/utils.service'
import { formatCurrency } from '../services/utils.service'
import CreditCardDetails from '@/components/CreditCardDetails'
export default {
  name: 'MembershipSubscription',
  components: {
    CreditCardDetails,
  },
  props: {
    organization: Object,
    isInfos: { type: Boolean, default: false },
  },
  data() {
    return {
      valid: false,
      subscriptionFinished: false,
      isLoading: false,
      subscriptionError: '',
      changeCardDialog: false,
      confirmCard: false,
      payment_intent_pending: false,
      showSmall: false,
    }
  },
  computed: {
    paymentMethodInfos() {
      return this.$store.state.account.paymentMethodConnect || null
    },
    stripeCard() {
      return this.$store.state.stripe.stripeCard
    },
    stripe() {
      return this.$store.state.stripe.stripe
    },
    memberOrganization() {
      return this.$store.state.account.memberOrganizations.find(
        elt => elt.organizationUid === this.organization.uid,
      )
    },
  },
  methods: {
    formatCurrency,
    // formatCurrency$(amount, decimal) {
    //   return utilsService.formatCurrency(amount, decimal)
    // },
    submitPayment() {
      if (this.paymentMethodInfos === null && !this.stripeCard._complete) {
        this.$store.dispatch(
          'stripe/setStripeElementPaymentError',
          this.$tc('error.payment-data-incomplete'),
        )
        return
      }

      this.isLoading = true
      if (this.paymentMethodInfos !== null) {
        if (this.payment_intent_pending) {
          this.retryInvoiceWithNewPaymentMethod({
            paymentMethodId: this.paymentMethodInfos.id,
            invoiceId: localStorage.getItem('membershipLatestInvoiceId'),
            membershipGroupId: this.organization.membershipGroups[0].id,
          })
        } else {
          this.createSubscription({
            paymentMethodId: this.paymentMethodInfos.id,
            membershipGroupId: this.organization.membershipGroups[0].id,
            email: this.$store.state.account.user.user.email,
          })
        }
        return
      }

      this.$store
        .dispatch('stripe/stripeCreatePaymentMethod', {
          email: this.$store.state.account.user.user.email,
        })
        .then(async result => {
          await this.$store.dispatch('account/savePaymentMethod', {
            paymentMethodId: result.paymentMethod.id,
            last4: result.paymentMethod.card.last4,
            brand: result.paymentMethod.card.brand,
            expMonth: result.paymentMethod.card.exp_month,
            expYear: result.paymentMethod.card.exp_year,
            country: result.paymentMethod.card.country,
            paymentMethodType: result.paymentMethod.type,
            isConnect: true,
          })
          this.createSubscription({
            paymentMethodId: result.paymentMethod.id,
            membershipGroupId: this.organization.membershipGroups[0].id,
            email: this.$store.state.account.user.user.email,
          })
        })
        .catch(err => {
          this.subscriptionError =
            typeof err == 'string' ? err : err.message || err.error
          this.isLoading = false
        })
    },

    createSubscription({ paymentMethodId, membershipGroupId, email }) {
      requestService
        .post(
          process.env.VUE_APP_API_BASE_URL +
            '/payment/start-membership-subscription',
          {
            paymentMethodId: paymentMethodId,
            membershipGroupId: membershipGroupId,
            email: email,
          },
        )
        .then(response => {
          this.subscriptionError = ''
          if (!response.success) {
            this.showSmall = false
            this.$store.dispatch(
              'stripe/setStripeElementPaymentError',
              response.message
                ? response.message
                : this.$tc('error.server-error-try-again'),
            )
            this.subscriptionError = response.message ?? ''
            if (
              response.code &&
              [
                'card_declined',
                'expired_card',
                'insufficient_funds',
                'processing_error',
              ].includes(response.code)
            ) {
              this.$store.dispatch('stripe/setChangePaymentCardDialog', true)
            }
            this.subscriptionFinished = false
            throw new Error(this.subscriptionError)
          }
          return {
            subscription: response.subscription,
            paymentMethodId: paymentMethodId,
          }
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(this.handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(this.handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(this.onSubscriptionComplete)
        .catch(err => {
          // console.log(err)
          if (!this.subscriptionError) {
            this.subscriptionError = this.$tc(
              'error.error-while-creating-subscription',
            )
          }
          this.isLoading = false
        })
    },
    async handlePaymentThatRequiresCustomerAction({
      subscription,
      paymentMethodId,
      isRetry,
    }) {
      const invoice = subscription.latest_invoice
      if (subscription && subscription.status === 'active') {
        // Subscription is active, no customer actions required.
        return { subscription, paymentMethodId, isRetry }
      }

      // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
      // If it's a retry, the payment intent will be on the invoice itself.
      const paymentIntent = invoice
        ? invoice.payment_intent
        : subscription.latest_invoice.payment_intent

      if (
        paymentIntent.status === 'requires_action' ||
        (isRetry === true && paymentIntent.status === 'requires_payment_method')
      ) {
        return await this.confirmCardHandler(
          subscription,
          paymentIntent,
          paymentMethodId,
          isRetry,
        )
      } else {
        // No customer action needed.
        return { subscription, paymentMethodId, isRetry }
      }
    },
    handleRequiresPaymentMethod({ subscription, paymentMethodId, isRetry }) {
      if (subscription.status === 'active') {
        // subscription is active, no customer actions required.
        return { success: true, subscription }
      } else if (
        subscription.latest_invoice.payment_intent.status ===
        'requires_payment_method'
      ) {
        // Using localStorage to manage the state of the retry here,
        // feel free to replace with what you prefer.
        // Store the latest invoice ID and status.
        localStorage.setItem(
          'membershipLatestInvoiceId',
          subscription.latest_invoice.id,
        )
        localStorage.setItem(
          'membershipLatestInvoicePaymentIntentStatus',
          subscription.latest_invoice.payment_intent.status,
        )
        this.payment_intent_pending = true
        this.subscriptionFinished = false
        this.showSmall = false
        this.subscriptionError = this.$tc('error.card-been-refused')
        this.$store.dispatch('stripe/setChangePaymentCardDialog', true)
        throw new Error(this.subscriptionError)
      } else {
        return { subscription, paymentMethodId }
      }
    },
    async onSubscriptionComplete() {
      if (this.confirmCard) {
        await this.$pause(5000)
      } else {
        await this.$pause(2000)
      }
      this.clean()
      this.subscriptionFinished = true
      this.subscriptionError = ''
      if (this.memberOrganization?.status === 'past_due') {
        this.$store.dispatch(
          'alert/success',
          this.$tc('membership.subscription-renewed'),
        )
        this.$emit('subscription-success')
      } else {
        this.$store.dispatch(
          'alert/success',
          this.$tc('membership.subscription-to-organization-registered'),
        )
        this.$emit('subscription-success')
      }
      // window.location.reload()
    },
    retryInvoiceWithNewPaymentMethod(
      { paymentMethodId, invoiceId, membershipGroupId },
      membershipId,
    ) {
      this.showSmall = true
      return (
        requestService
          .post(process.env.VUE_APP_API_BASE_URL + '/payment/startPayment', {
            connect: true,
            paymentMethodId: paymentMethodId,
            invoiceId: invoiceId,
            membershipGroupId: membershipGroupId,
            retry: true,
            ...(membershipId && { membershipId }),
          })
          // If the card is declined, display an error to the user.
          .then(response => {
            if (!response.success) {
              this.$store.dispatch(
                'stripe/setStripeElementPaymentError',
                response.message
                  ? response.message
                  : this.$tc('error.server-error-try-again'),
              )
              this.subscriptionError =
                response.message ?? this.$tc('error.server-error-try-again')
              if (
                response.code &&
                [
                  'card_declined',
                  'expired_card',
                  'insufficient_funds',
                ].includes(response.code)
              ) {
                this.$store.dispatch('stripe/setChangePaymentCardDialog', true)
              }
              this.clean()
              this.subscriptionFinished = false
              throw new Error(this.subscriptionError)
            }
            return {
              subscription: response.subscription,
              paymentMethodId: paymentMethodId,
              retry: true,
            }
          })
          // Normalize the result to contain the object returned by Stripe.
          // Add the additional details we need.
          // .then(result => {

          // })
          // Some payment methods require a customer to be on session
          // to complete the payment process. Check the status of the
          // payment intent to handle these actions.
          .then(this.handlePaymentThatRequiresCustomerAction)
          // No more actions required. Provision your service for the user.
          .then(this.handleRequiresPaymentMethod)
          .then(this.onSubscriptionComplete)
          .catch(error => {
            // An error has happened. Display the failure to the user here.
            // We utilize the HTML element we created.
            // console.log(error)
            this.subscriptionError =
              error.message ?? this.$tc('error.server-error-try-again')
            this.isLoading = false
          })
      )
    },
    clean() {
      localStorage.removeItem('membershipLatestInvoiceId')
      localStorage.removeItem('membershipLatestInvoicePaymentIntentStatus')
      this.payment_intent_pending = false
      this.confirmCard = false
    },

    confirmPayment() {
      this.isLoading = true
      this.showSmall = false
      requestService
        .post(
          process.env.VUE_APP_API_BASE_URL + `/payment/payment-intent-info`,
          {
            connect: true,
            membershipId: this.memberOrganization?.membershipId,
            paymentMethodId: this.memberOrganization?.lastPaymentMethodId,
          },
        )
        .then(async res => {
          if (!res.success) {
            this.subscriptionError = res.message
            throw new Error(res.message)
          }
          this.confirmCard = true
          await this.$pause(1000)
          this.stripe
            .confirmCardPayment(res.client_secret, {
              payment_method: this.memberOrganization?.lastPaymentMethodId,
            })
            .then(async result => {
              if (result.error) {
                // Start code flow to handle updating the payment details.
                // Display error message in your UI.
                // The card was declined (i.e. insufficient funds, card has expired, etc).
                this.subscriptionError = result.error.message
                throw new Error(this.subscriptionError)
                // this.isLoading = false
              } else {
                if (result.paymentIntent.status === 'succeeded') {
                  this.$store.dispatch(
                    'alert/success',
                    this.$tc('membership.subscription-renewed'),
                  )
                  this.subscriptionError = ''
                }
              }
            })
            .catch(async error => {
              // console.log(error)
              this.subscriptionError =
                error.message ??
                this.$tc('error.error-while-confirming-payment')
            })
            .finally(async () => {
              await this.$pause(5000)
              this.$emit('subscription-success')
              this.isLoading = false
              this.confirmCard = false
            })
        })
        .catch(error => {
          // console.log(error)
          this.subscriptionError =
            error.message ?? this.$tc('error.error-while-confirming-payment')
          this.$emit('subscription-success')
          this.isLoading = false
        })
    },
    changePaymentMethodAndRetry() {
      if (
        this.memberOrganization?.lastPaymentMethodId ===
        this.paymentMethodInfos.id
      ) {
        this.showSmall = false
        this.$store.dispatch('stripe/setChangePaymentCardDialog', true)
      } else {
        this.isLoading = true
        this.retryInvoiceWithNewPaymentMethod(
          {
            paymentMethodId: this.paymentMethodInfos.id,
            invoiceId: null,
            membershipGroupId: this.organization.membershipGroups[0].id,
          },
          this.memberOrganization?.membershipId,
        )
      }
    },
    async confirmCardHandler(
      subscription,
      paymentIntent,
      paymentMethodId,
      isRetry,
    ) {
      this.confirmCard = true
      await this.$pause(1000)
      // eslint-disable-next-line compat/compat
      return new Promise((resolve, reject) => {
        this.stripe
          .confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentMethodId,
          })
          .then(result => {
            if (result.error) {
              // Start code flow to handle updating the payment details.
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc).
              this.subscriptionError = result.error.message
              throw new Error(this.subscriptionError)
            } else {
              if (result.paymentIntent.status === 'succeeded') {
                // Show a success message to your customer.
                resolve({
                  subscription: subscription,
                  paymentMethodId: paymentMethodId,
                  isRetry,
                })
              }
            }
          })
          .catch(async err => {
            this.confirmCard = false
            this.subscriptionError =
              err.message ?? this.$tc('error.error-while-confirming-payment')
            await this.$pause(5000)
            this.$emit('subscription-success')
            reject(err)
          })
      })
    },
  },
  created() {
    this.payment_intent_pending =
      localStorage.getItem('membershipLatestInvoiceId') !== null &&
      localStorage.getItem('membershipLatestInvoicePaymentIntentStatus') ===
        'requires_payment_method'
  },
  watch: {
    memberOrganization: {
      handler() {
        this.isLoading = false
      },
      deep: true,
    },
  },
  beforeDestroy() {
    this.$store.dispatch('stripe/setStripeElementPaymentError', '')
  },
}
</script>
