<template>
  <v-row justify="center">
    <v-col cols="12" :class="colClass" style="max-width: 600px;">
      <!--      <dynamic-scroller
        :min-item-size="100"
        :items="formattedPosts"
        class="posts"
        key-field="uid"
        type-field="mediaType"
        page-mode
        :buffer="500"
      >
        <template v-slot="{ item: post, index, active }">
          <dynamic-scroller-item
            :item="post"
            :active="active"
            :data-active="active"
            :data-index="index"
            class="d-flex pb-3"
            style="min-height: 100px"
            :size-dependencies="[post.textContent, post.mediaType]"
          >-->
      <v-row
        class="justify-center"
        style="width: 100%"
        v-for="post in formattedPosts"
        :key="post.uid"
      >
        <v-col v-if="post.mediaType === 'channel'">
          <v-list-item
            :key="post.uid"
            :to="{
              name: 'Organization',
              params: { slug: post.uid },
            }"
            class="mb-1 py-2 secondary rounded"
            style="min-height: 40px"
          >
            <v-list-item-avatar :size="70" class="mr-2 my-0">
              <channel-avatar
                :thumbnails="post.thumbnailUrl"
                :channel-name="post.name"
                :size="70"
                :bg-color="post.avatarColor"
              ></channel-avatar>
            </v-list-item-avatar>
            <v-list-item-content class="align-content-start align-self-stretch">
              <v-list-item-subtitle class="d-flex"
                ><span
                  class="mr-1 text-overflow text-truncate white--text font-weight-medium"
                  >{{ post.name }}</span
                >
                <span class="">@{{ post.uid }}</span></v-list-item-subtitle
              >
              <v-list-item-subtitle>
                {{ post.subscribersCount }}
                {{ $tc('organization.subscriber', post.subscribersCount || 1) }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-text="post.textContent" />
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <post-item
          v-else
          cols="12"
          large
          :post="post"
          show-organization
          @deleted="$delete(posts, index)"
          :key="post.uid"
          :force-open-post="simplified && !playInline"
        ></post-item>
      </v-row>
      <!--          </dynamic-scroller-item>
        </template>
      </dynamic-scroller>-->

      <template v-if="dataLoading">
        <v-row>
          <post-item-loader
            v-for="item in 12"
            :key="item"
            cols="12"
            large
          ></post-item-loader>
        </v-row>
      </template>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          xl="3"
          v-intersect="{
            handler: onIntersectEnd,
            options: {
              rootMargin: '-50% 0px 500px 0px',
              threshold: [0, 1],
            },
          }"
          style="min-height: 50px"
        >
          <div style="min-height: 50px"></div>
        </v-col>
      </v-row>
      <v-row
        justify="center"
        v-if="!endReached && !hasError && formattedPosts.length"
      >
        <v-btn color="primary" :loading="dataLoading" @click="claimMorePosts"
          >{{ $tc('button.load-more') }}
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import PostItem from '../components/PostItem'
import PostItemLoader from '@/components/PostItemLoader'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import ChannelAvatar from '@/components/ChannelAvatar.vue'

export default {
  name: 'VerticalPostList',
  components: {
    ChannelAvatar,
    PostItemLoader,
    PostItem,
    DynamicScroller,
    DynamicScrollerItem,
  },
  props: {
    posts: {
      type: Array,
      default: () => [],
    },
    dataLoading: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    endReached: {
      type: Boolean,
      default: false,
    },
    simplified: {
      type: Boolean,
      default: false,
    },
    wrapped: {
      type: Boolean,
      default: false,
    },
    playInline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      queryLast: null,
    }
  },
  computed: {
    formattedPosts() {
      if (!this.simplified) {
        return this.posts
      }
      return this.posts.map(post => {
        const formattedPost = {
          ...post,
          uid: post.uid,
          publishedAt: (post.publishedAtTimestamp || 0) * 1000,
          channel: {
            organization: {
              name: post.channelName,
              thumbnails: post.channelThumbnailUrl,
              uid: post.channelUid,
            },
          },
        }
        if (post.title && post.mediaType !== 'channel') {
          formattedPost.video = {
            title: post.title,
            description: post.textContent,
            thumbnails: post.thumbnailUrl,
            duration: post.videoDuration,
          }
        }
        return formattedPost
      })
    },
    colClass() {
      return {
        'col-lg-6': !this.wrapped,
        'col-md-7': !this.wrapped,
        'col-sm-9': !this.wrapped,
      }
    },
  },
  methods: {
    onIntersectEnd() {
      if (this.dataLoading || this.endReached || this.hasError) {
        return
      }
      this.claimMorePosts()
    },
    claimMorePosts() {
      this.$emit('claim-more-posts')
    },
  },
}
</script>

<style scoped></style>
