
import { defineComponent } from 'vue'
import { formatNumber } from '@/services/utils.service'
import AppTooltip from '@/components/AppTooltip.vue'

export default defineComponent({
  name: 'TrustScore',
  components: { AppTooltip },
  props: {
    score: { type: Number, required: true },
    isUser: { type: Boolean, default: false },
  },
  computed: {
    iconColor() {
      const score = this.score
      if (this.isUser) {
        return score < 10 ? 'error' : score < 50 ? 'grey' : 'success'
      }
      return score < 10 ? 'grey' : 'success'
    },
  },
  methods: {
    formatNumber,
  },
})
