<template>
  <div>
    <template v-if="!createOrganizationDialog">
      <div class="text-h5 mb-2" v-if="!modal">
        {{ $tc('channel.customization') | capitalize }}
      </div>
      <v-card flat>
        <v-card-title v-if="modal" class="headline secondary lighten-2">{{
          $tc('channel.new-channel') | capitalize
        }}</v-card-title>
        <v-divider v-if="modal"></v-divider>
        <v-card-text>
          <div v-if="type === 'internal'">
            {{ $tc('channel.crowdbunker-channels-allows-direct-post') }}
          </div>
          <v-form>
            <text-field
              :label="$tc('channel.name-of-channel') | capitalize"
              :field="form.name"
              :counter="form.name.value.length > 40 ? 50 : undefined"
            ></text-field>
            <v-autocomplete
              :items="organizations"
              item-text="name"
              item-value="id"
              :label="$tc('channel.organization', 1) | capitalize"
              auto-select-first
              :filter="organizationsFilter"
              :no-data-text="$tc('channel.you-have-no-organization')"
              v-model="form.organization.value"
              append-outer-icon="mdi-plus"
              @click:append-outer="createOrganizationDialog = true"
              :error-messages="getErrors(form.organization.errors)"
            >
              <template #item="{item}">
                <div v-if="item.name">
                  <div>{{ item.name }}</div>
                  <div class="text-caption mt-n1">@{{ item.uid }}</div>
                </div>
              </template>
            </v-autocomplete>
            <div class="text-caption">
              {{ $tc('channel.profile-image') | capitalize }}
            </div>
            <v-hover v-slot="{ hover }">
              <channel-avatar
                :thumbnails="channelThumbnails"
                :size="100"
                :channel-name="form.name.value"
                :bg-color="
                  channelThumbnails.length ? 'primary' : 'secondary lighten-1'
                "
                class="my-2"
              >
                <v-overlay
                  absolute
                  :value="hover"
                  color="success darken-1"
                  opacity="0.8"
                >
                  <v-btn
                    icon
                    @click="uppyAvatar.getPlugin('Dashboard').openModal()"
                  >
                    <v-icon>mdi-cloud-upload-outline</v-icon>
                  </v-btn>
                </v-overlay>
              </channel-avatar>
            </v-hover>
            <div
              v-if="getErrors(form.thumbnailKey.errors).length"
              class="error text-caption"
            >
              {{ getErrors(form.thumbnailKey.errors)[0] }}
            </div>

            <div class="text-caption">
              {{ $tc('channel.cover-image') | capitalize }}
            </div>
            <v-hover v-slot="{ hover }">
              <v-img
                :src="bannerPreviewUrl"
                width="100%"
                :aspect-ratio="1903 / 315"
                class="my-2"
              >
                <v-overlay
                  absolute
                  :value="hover"
                  color="success darken-1"
                  opacity="0.8"
                >
                  <v-btn
                    text
                    @click="uppyBanner.getPlugin('Dashboard').openModal()"
                  >
                    <v-icon left>mdi-cloud-upload-outline</v-icon>
                    {{ $tc('button.import-an-image') }}
                  </v-btn>
                </v-overlay>
                <div
                  v-if="bannerPreviewUrl === null"
                  class="d-flex secondary lighten-1 fill-height justify-center align-center"
                  style="width: 100%"
                >
                  <span v-show="!hover">{{
                    ($tc('label.recommended') + ' : 1900 x 320 px') | capitalize
                  }}</span>
                </div>
              </v-img>
            </v-hover>
            <div
              v-if="getErrors(form.bannerKey.errors).length"
              class="error text-caption"
            >
              {{ getErrors(form.bannerKey.errors)[0] }}
            </div>

            <textarea-field
              :label="$tc('label.optional-description') | capitalize"
              :field="form.description"
              :counter="form.description.value.length > 900 ? 1000 : undefined"
              class="mt-3"
              rows="2"
            ></textarea-field>
            <v-switch
              v-if="type !== 'internal'"
              v-model="form.watched.value"
              :label="$tc('channel.enable-synchronization-with-youtube')"
              class="mt-0"
            ></v-switch>
            <template v-if="Object.keys(unhandledErrors).length">
              <div
                v-for="(categoryErrors, index) in unhandledErrors"
                :key="index"
              >
                <div
                  v-for="error in categoryErrors"
                  :key="error"
                  class="error--text"
                >
                  {{ error }}
                </div>
              </div>
            </template>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="modal"
            @click="$emit('input', false)"
            :disabled="submitLoading"
            depressed
            text
            >{{ $tc('button.cancel') }}</v-btn
          >
          <v-btn
            color="success"
            @click="submit"
            :loading="submitLoading"
            depressed
            >{{ $tc('button.validate') }}</v-btn
          >
        </v-card-actions>
      </v-card>
      <template v-if="!modal">
        <div class="text-h5 mb-2 mt-5">
          {{ $tc('channel.other-parameters') | capitalize }}
        </div>
        <v-card flat>
          <v-card-text>
            <div class="text-h6">{{ $tc('live.visibility') | capitalize }}</div>
            <div class="text-caption">
              {{ $tc('channel.chain-deactivated-content-hidden') }}
            </div>
            <v-btn
              class="mt-1"
              v-if="active"
              @click="updateStatus('disabled')"
              depressed
            >
              {{ $tc('button.deactivate-channel') }}
            </v-btn>
            <v-btn
              class="mt-1"
              v-else
              color="success"
              @click="updateStatus('active')"
              depressed
            >
              {{ $tc('button.activate-channel') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </template>
    </template>
    <template v-else>
      <edit-organization
        @edited="organizationEdited"
        @input="createOrganizationDialog = $event"
        modal
        embed
        :key="dialogKey"
      ></edit-organization>
    </template>
  </div>
</template>

<script>
import { requestService } from '@/services/request.service.js'
import { uppyService } from '@/services/uppy.service'
import ChannelAvatar from '../ChannelAvatar'
import Validation from '../mixins/Validation'
import TextField from '../form/TextField'
import TextareaField from '../form/TextareaField'
import EditOrganization from './EditOrganization'

// And their styles (for UI plugins)
// With webpack and `style-loader`, you can require them like this:
require('@uppy/core/dist/style.min.css')
require('@uppy/dashboard/dist/style.min.css')
require('@uppy/image-editor/dist/style.min.css')

export default {
  name: 'EditChannel',
  components: { EditOrganization, TextareaField, TextField, ChannelAvatar },
  mixins: [Validation],
  props: {
    value: { type: Boolean, default: false },
    modal: { type: Boolean, default: false },
    type: { type: String, default: 'internal' },
    channel: { type: Object, default: null },
  },
  data() {
    return {
      uppyAvatar: {},
      uppyBanner: {},
      storageBaseUrl: '',
      picturePath: null,
      form: {
        name: {
          value: '',
          required: true,
          minLength: 3,
          maxLength: 50,
          regex: [/^((?!Crowd.?Bunker|admin([^a-z]|$)).)*$/i, /^[^@].+$/i],
          regexMessage: [
            this.$options.filters.capitalize(
              this.$tc('organization.must-not-contain-words'),
            ),
            this.$options.filters.capitalize(
              this.$tc('organization.must-not-start-with-at'),
            ),
          ],
        },
        organization: {
          value: null,
          required: true,
        },
        description: {
          value: '',
          maxLength: 1000,
        },
        thumbnailKey: {
          value: '',
        },
        bannerKey: {
          value: '',
        },
        platform: {
          value: '',
        },
        watched: {
          value: true,
        },
      },
      picturePreviewUrl: null,
      bannerPreviewUrl: null,
      UIDAvailable: false,
      UIDCheckTimeout: null,
      UIDCheckLoading: false,
      UIDEdited: false,
      submitLoading: false,
      organizations: [],
      createOrganizationDialog: false,
      dialogKey: 0,
      organizationTemplate: null,
      organizationSelected: null,
    }
  },
  computed: {
    channelThumbnails() {
      if (this.picturePreviewUrl) {
        return [
          {
            url: this.picturePreviewUrl,
            name: 'medium',
          },
        ]
      } else if (this.channel !== null && this.channel.thumbnails) {
        return this.channel.thumbnails
      }
      return []
    },
    editing() {
      return this.channel !== null && this.channel.id
    },
    active() {
      return this.channel !== null && this.channel.status === 'active'
    },
  },
  methods: {
    submit() {
      this.formValidation(this.form, true)
      if (this.errorsNbr > 0) {
        return
      }
      this.submitLoading = true
      const url =
        '/channel/manage' + (this.editing ? `/${this.channel.id}` : '')
      requestService
        .post(url, this.formatDataToSend(this.form))
        .then(response => {
          if (response.success) {
            const message = this.editing
              ? this.$options.filters.capitalize(
                  this.$tc('channel.saved-modification'),
                )
              : this.$options.filters.capitalize(
                  this.$tc('channel.channel-created-successfully'),
                )
            this.$store.dispatch('alert/success', message)
            this.$emit('input', false)
            this.$emit('edited', response.channelUid)
            if (!this.editing) {
              this.$router.push({
                name: 'Channel',
                params: { slug: response.channelUid },
              })
            }
          }
        })
        .catch(response => {
          this.submitLoading = false
          this.handleServerErrors(response, this)
        })
        .finally(() => (this.submitLoading = false))
    },
    loadChannel() {
      if (this.channel !== null) {
        this.form.name.value = this.channel.name || ''
        this.form.description.value = this.channel.description || ''
        this.form.watched.value = this.channel.watched
        if (this.channel.organization) {
          this.form.organization.value = this.channel.organization.id
        }
        this.bannerPreviewUrl = this.channel.banner
          ? this.channel.banner.url
          : null
      }
    },
    updateStatus(status) {
      requestService
        .post(`/channel/manage/${this.channel.id}/update-status`, {
          status: status,
        })
        .then(response => {
          this.channel.status = response.status
          this.$store.dispatch(
            'alert/success',
            this.$options.filters.capitalize(
              this.$tc('channel.updated-channel'),
            ),
          )
        })
    },
    organizationEdited(organizationId) {
      this.dialogKey++
      this.loadOrganizations(() => {
        this.form.organization.value = organizationId
      })
    },
    instantiateUppyAvatar() {
      this.uppyAvatar = uppyService.instantiate({
        id: 'avatar',
        note: this.$tc('organization.jpg-png-or-gif') + ' : 800 x 800 px',
        minCanvasWidth: 200,
        aspectRatio: 1,
      })
      this.uppyAvatar.on('upload-success', file => {
        this.picturePath = file.meta['key']
        this.form.thumbnailKey.value = file.meta['key']
        this.picturePreviewUrl = file.preview
      })
    },
    instantiateUppyBanner() {
      this.uppyBanner = uppyService.instantiate({
        id: 'banner',
        note: this.$tc('organization.jpg-png-or-gif') + ' : 1900 x 320 px',
        minCanvasWidth: 200,
        aspectRatio: 1900 / 315,
        thumbnailWidth: 575,
      })
      this.uppyBanner.on('upload-success', file => {
        this.form.bannerKey.value = file.meta['key']
        this.bannerPreviewUrl = file.preview
      })
    },
    async loadOrganizations(callback) {
      requestService.get('/organization/mine').then(response => {
        this.organizations = response.items
        if (typeof callback === 'function') {
          callback()
        }
      })
    },
    organizationsFilter(item, queryText) {
      if (item.name === undefined) {
        return false
      }
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.uid.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },
  },
  watch: {
    channel() {
      this.loadChannel()
    },
    'form.organization.value'() {
      if (this.form.organization.value === undefined) {
        this.form.organization.value = ''
      }
    },
  },
  mounted() {
    this.instantiateUppyAvatar()
    this.instantiateUppyBanner()
    this.loadChannel()
    this.loadOrganizations()
    if (this.modal) {
      this.form.platform.value = 'internal'
    }
  },
}
</script>

<style scoped lang="scss">
//$blue: #ffffff;
//$highlight--dark: #42f202;
::v-deep {
  .v-counter {
    color: var(--v-success-base);
  }
}
.theme--dark.v-list {
  background-color: var(--v-secondary-lighten1);
}
</style>
