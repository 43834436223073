const state = {
  campaignStage: null,
  campaignStagePercent: null,
  campaignStartDate: new Date('2023-01-15T00:00:00+0100'),
}

const actions = {}

const mutations = {
  campaignStage(state, data) {
    state.campaignStage = data
  },
  campaignStagePercent(state, data) {
    state.campaignStagePercent = data
  },
}

export const realtime = {
  namespaced: true,
  state,
  actions,
  mutations,
}
