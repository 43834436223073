import { requestService } from '@/services/request.service.js'
import store from '@/store/index'
import router from '../router/index.ts'
import i18n from '@/plugins/i18n'

const checkIfLoggedIn = () => {
  if (!store.state.account.status.loggedIn) {
    router.push({ name: 'Login' })
    return false
  }
  return true
}

const actions = {
  fetchListsWithPost(action, { postUid, organizationUid }) {
    return new Promise((resolve, reject) => {
      if (!checkIfLoggedIn()) {
        return
      }
      requestService
        .get(`/list/by-post/${postUid}`, {
          params: {
            organization: organizationUid,
          },
        })
        .then(response => {
          if (response.lists) {
            // change name of this when isDefault is true
            response.lists.forEach(list => {
              if (list.isDefault) {
                list.name = i18n.t('list.watch-later')
                list.uid = 'watch-later'
              }
            })
          }
          resolve(response)
        })
        .catch(response => reject(response))
    })
  },
  addPostToList(action, { postUid, listUid }) {
    return new Promise((resolve, reject) => {
      if (!checkIfLoggedIn()) {
        return
      }
      requestService
        .post(`/list/${listUid}/add-post/${postUid}`)
        .then(response => resolve(response))
        .catch(response => reject(response))
    })
  },
  removePostFromList(action, { postUid, listUid }) {
    return new Promise((resolve, reject) => {
      if (!checkIfLoggedIn()) {
        return
      }
      requestService
        .post(`/list/${listUid}/remove-post/${postUid}`)
        .then(response => resolve(response))
        .catch(response => reject(response))
    })
  },
  createList(action, { name, visibility, organizationUid }) {
    return new Promise((resolve, reject) => {
      if (!checkIfLoggedIn()) {
        return
      }
      requestService
        .post('/list/', {
          name,
          visibility,
          organization: organizationUid,
        })
        .then(response => resolve(response))
        .catch(response => reject(response))
    })
  },
  updateList(action, { listUid, name, visibility, description }) {
    return new Promise((resolve, reject) => {
      if (!checkIfLoggedIn()) {
        return
      }
      requestService
        .post(`/list/${listUid}`, {
          name,
          visibility,
          description,
        })
        .then(response => resolve(response))
        .catch(response => reject(response))
    })
  },
  deleteList(action, { listUid }) {
    return new Promise((resolve, reject) => {
      if (!checkIfLoggedIn()) {
        return
      }
      requestService
        .delete(`/list/${listUid}`)
        .then(response => resolve(response))
        .catch(response => reject(response))
    })
  },
  fetchOrganizationLists(action, { organizationUid, offset }) {
    return new Promise((resolve, reject) => {
      requestService
        .get(`/list/organization/${organizationUid}`, {
          params: {
            offset,
          },
        })
        .then(response => resolve(response))
        .catch(response => reject(response))
    })
  },
  fetchLoggedInUserLists(action, { offset }) {
    return new Promise((resolve, reject) => {
      if (!checkIfLoggedIn()) {
        return
      }
      requestService
        .get('/list/', {
          params: {
            offset,
          },
        })
        .then(response => resolve(response))
        .catch(response => reject(response))
    })
  },
  fetchListItems(action, { listUid, offset }) {
    return new Promise((resolve, reject) => {
      requestService
        .get(`/list/${listUid}/items`, {
          params: {
            offset,
          },
        })
        .then(response => resolve(response))
        .catch(response => reject(response))
    })
  },
  fetchListItemsNear(action, { listUid, postUid, direction }) {
    return new Promise((resolve, reject) => {
      requestService
        .get(`/list/${listUid}/items/${postUid}`, {
          params: {
            next: direction === 'next' || undefined,
            previous: direction === 'previous' || undefined,
          },
        })
        .then(response => resolve(response))
        .catch(response => reject(response))
    })
  },
}

export const list = {
  namespaced: true,
  actions,
}
