var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"flex-md-nowrap",attrs:{"justify":"center"}},[_c('v-col',_vm._b({staticClass:"flex-fill"},'v-col',_vm.mainColProps,false),[_c('v-row',{staticClass:"flex-column",attrs:{"justify":"center","align":"center"}},[(
            !_vm.post.deletedAt &&
              !_vm.hasError &&
              !_vm.notFound &&
              _vm.bannedReason == null &&
              _vm.channelDeleted == null
          )?[_c('v-col',{attrs:{"cols":"12"}},[(_vm.post.parent)?_c('post-item',{attrs:{"cols":"12","large":"","post":_vm.post.parent,"show-organization":"","sub":""}}):_vm._e(),_vm._v(" "),(_vm.post.video || _vm.$route.name === 'VideoPost')?_c('video-with-meta',{attrs:{"post":_vm.post}}):_c('post-item',{staticClass:"pb-0 px-0",class:{ 'pa-0': !_vm.post.parent },attrs:{"large":"","cols":"12","post":_vm.post,"solo":"","show-organization":"","video-meta":""}})],1),_vm._v(" "),(
              !_vm.post.channel.organization.monetizationActive &&
                _vm.post.channel.organization.currentUserIsAdminOfThisOrganization
            )?_c('div',[_c('monetization-alert',{attrs:{"organization":_vm.post.channel.organization}})],1):_vm._e()]:_c('v-alert',{staticClass:"mt-5",attrs:{"type":"error"}},[(_vm.bannedReason !== null)?_c('span',[_vm._v(_vm._s(_vm.$tc('moderate-form.reason.' + _vm.bannedReason)))]):(_vm.notFound)?_c('span',[_vm._v(_vm._s(_vm.$tc('error.post-doesnt-exist-or-deleted')))]):(_vm.channelDeleted !== null)?_c('span',[_vm._v(_vm._s(_vm.$tc('error.post-unavailable-channel-deleted')))]):_c('span',[_vm._v(_vm._s(_vm.$tc('error.an-error-has-occurred')))])])],2),_vm._v(" "),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"px-sm-0 pt-0",attrs:{"cols":"12"}},[(_vm.isCommentAreaVisible && _vm.showCommentArea)?_c('comment-area',{attrs:{"parent-post":_vm.post}}):_vm._e()],1)],1)],1),_vm._v(" "),_c('v-col',{staticStyle:{"min-width":"370px"},attrs:{"cols":"12","md":"4","lg":"3"}},[(_vm.post.video?.isLiveType)?_c('live-chat-card',{attrs:{"post":_vm.post}}):_vm._e(),_vm._v(" "),(_vm.$route.query.list)?_c('list-card',{attrs:{"list-uid":_vm.$route.query.list}}):_vm._e(),_vm._v(" "),_c('related-card',{attrs:{"post":_vm.post}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }