
import { defineComponent } from 'vue'
import AppTooltip from '@/components/AppTooltip.vue'

export default defineComponent({
  name: 'VideoButton',
  components: { AppTooltip },
  props: {
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    btnClass: {
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
  },
  computed: {
    btnColor() {
      if (this.color) {
        return this.color
      }
      return !!this.text ? 'accent' : ''
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
  },
})
