<template>
  <div class="d-flex flex-nowrap mr-2">
    <template v-if="memberOrganization">
      <div class="d-flex flex-nowrap align-center">
        <app-tooltip :disabled="memberOrganization?.status == 'active'">
          <template v-slot:activator="{ on }">
            <v-btn
              color="danger"
              depressed
              plain
              raised
              small
              class="text-caption px-1"
              :loading="membershipLoading"
              @click="showDisangageConfirmDialog()"
              v-on="on"
              v-bind="$attrs"
            >
              {{ $tc('button.disangage') | capitalize }}
            </v-btn>
          </template>
          <span v-if="memberOrganization?.status === 'cancel'">
            {{ $tc('membership.effective-disangagement') }}
            {{ formatEndDate(memberOrganization?.subscriptionEndDate) }}
            <br />
            {{ $tc('membership.no-additional-invoice-will-be-issued') }}
          </span>
          <span v-if="memberOrganization?.status === 'past_due'">
            {{ $tc('error.a-platform-subscription-payment-failed') }}
            <br />
            {{ $tc('label.click-to-aside-icon-to-show-more') }}
          </span>
        </app-tooltip>
        <v-btn
          :color="memberOrganization?.status == 'past_due' ? 'warning' : 'info'"
          icon
          @click="showMembershipDialog()"
          :disabled="membershipLoading"
        >
          <v-icon>{{
            memberOrganization?.status == 'past_due'
              ? 'mdi-alert  '
              : 'mdi-information-variant-circle-outline'
          }}</v-icon>
        </v-btn>
      </div>
    </template>
    <template v-else>
      <v-btn
        color="default"
        outlined
        rounded
        class="mr-2 text-caption"
        :loading="membershipLoading"
        @click="membershipDialog = true"
      >
        {{ $tc('button.become-member') | capitalize }}
      </v-btn>
    </template>

    <v-dialog
      v-model="membershipDisangageConfirmDialog"
      v-if="membershipDisangageConfirmDialog"
      max-width="500"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ $tc('membership.disangage-confirmation') }}
          </span>
        </v-card-title>
        <v-card-text>
          <div>
            {{
              $tc('membership.disangage-confirmation-description') | capitalize
            }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="membershipDisangageConfirmDialog = false"
            :disabled="membershipLoading"
          >
            {{ $tc('button.cancel') }}
          </v-btn>
          <v-btn
            class="text-body-2"
            text
            @click="disangage()"
            :loading="membershipLoading"
          >
            {{ $tc('button.disangage') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="membershipDialog"
      v-if="membershipDialog"
      max-width="500"
      persistent
    >
      <membership-subscription
        :organization="organization"
        @cancel="cancelDialog"
        @subscription-success="reloadMemberships"
      ></membership-subscription>
    </v-dialog>
  </div>
</template>
<script>
import { requestService } from '@/services/request.service'
import { utilsService } from '@/services/utils.service'
import MembershipSubscription from '@/components/MembershipSubscription'
import AppTooltip from '@/components/AppTooltip.vue'

export default {
  name: 'MembershipButton',
  components: { MembershipSubscription, AppTooltip },
  props: {
    organization: { type: Object, required: true },
  },
  data() {
    return {
      membershipDialog: false,
      membershipDisangageConfirmDialog: false,
      membershipLoading: false,
      membershipError: null,
    }
  },
  computed: {
    paymentMethodInfos() {
      return this.$store.state.account.paymentMethodConnect
    },
    memberOrganization() {
      return this.$store.state.account.memberOrganizations.find(
        elt => elt.organizationUid === this.organization.uid,
      )
    },
  },
  methods: {
    async reloadMemberships() {
      // this.$store.dispatch('account/fetchMemberOrganizations')
      console.log('reloadMemberships')
      this.$store.dispatch('account/refreshToken')
    },

    cancelDialog() {
      this.membershipDialog = false
      this.$store.dispatch('account/refreshToken')
    },

    showMembershipDialog() {
      this.membershipDialog = true
      this.$store.dispatch('account/refreshToken')
    },

    formatEndDate(date) {
      return utilsService.formatDate(date)
    },

    showDisangageConfirmDialog() {
      if (this.memberOrganization.status == 'active') {
        this.membershipDisangageConfirmDialog = true
      }
    },

    async disangage() {
      this.membershipLoading = true
      try {
        const res = await requestService.post(
          `/payment/unsubscribe-membership/${this.memberOrganization.membershipGroup}`,
        )

        if (!res.success) {
          // console.log(res)
          this.$store.dispatch(
            'alert/error',
            res.message || this.$tc('error.an-error-has-occurred'),
          )
          return
        }
        this.$store.dispatch(
          'alert/success',
          this.$tc('membership.disangage-success'),
        )
        this.reloadMemberships()
      } catch (error) {
        this.membershipError = error
        this.$store.dispatch(
          'alert/error',
          this.$tc('error.an-error-has-occurred'),
        )
      } finally {
        this.membershipDisangageConfirmDialog = false
        this.membershipLoading = false
      }
    },
  },
}
</script>
