<template>
  <v-card>
    <v-card-title class="px-0 pt-0" primary-title>
      <v-toolbar dark color="info" flat dense>
        <v-toolbar-title>
          Edition de post/commentaire
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn :disabled="submitLoading" icon dark @click="cancel()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-card-text class="py-0">
      <v-row class="mt-1 mb-5">
        <v-col cols="12" class="py-0">
          <textarea-field
            solo-inverted
            flat
            :placeholder="$tc('login.express-yourself-freely')"
            background-color="secondary lighten-2"
            style="font-size: 20px"
            :hide-details="
              form.textContent.value.length < 4800 && !form.textContent.errors
            "
            :rows="
              Math.max(5, form.textContent.value.split(`\n`).length + 1 || 0)
            "
            :counter="form.textContent.value.length > 4800 ? 5000 : undefined"
            :field="form.textContent"
            autofocus
            no-resize
          >
          </textarea-field>
        </v-col>
        <!-- <v-col cols="12" sm="6" class="py-2">
          <v-select
            v-model="form.visibility.value"
            :items="visibilityChoices"
            :label="$tc('live.visibility') | capitalize"
          ></v-select>
        </v-col> -->
      </v-row>
    </v-card-text>
    <v-expand-transition leave-absolute>
      <template v-if="importImages">
        <v-card-text class="mt-3">
          <import-images
            @cancelImport="cancelUpload"
            :files.sync="images"
            :modifying="true"
          ></import-images>
        </v-card-text>
      </template>
      <!-- <template v-else-if="snippetData || snippetLoading">
        <v-card-text class="mt-3">
          <snippet
            :link-metadata="snippetData"
            horizontal
            preview
            @cancel="cancelSnippet"
          ></snippet>
        </v-card-text>
      </template> -->
    </v-expand-transition>
    <v-card-text v-if="error" class="error--text">{{ error }}</v-card-text>
    <v-card-text
      class="d-flex justify-end mt-1"
      v-if="!upload && !post.isComment"
    >
      <v-btn
        class="ml-3"
        color="primary"
        outlined
        icon
        @click="startImportImages"
      >
        <v-icon>mdi-image</v-icon>
      </v-btn>
    </v-card-text>
    <v-divider class="d-none d-sm-block"></v-divider>
    <v-card-actions v-if="$vuetify.breakpoint.smAndUp">
      <v-btn text class="ml-auto" @click="cancel" :disabled="submitLoading">{{
        $tc('button.cancel')
      }}</v-btn>
      <v-btn
        text
        color="success"
        :loading="submitLoading"
        :disabled="submitLoading"
        @click="submit()"
        >{{ $tc('button.publish') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import TextareaField from '@/components/form/TextareaField'
import Validation from '@/components/mixins/Validation'
import { requestService } from '../../services/request.service'
import ImportImages from '@/components/ImportImages'

const textContent = {
  value: '',
  maxLength: 5000,
  minLength: 3,
  required: true,
}
const visibility = {
  value: 'public',
}

export default {
  name: 'EditPost',
  components: { TextareaField, ImportImages },
  mixins: [Validation],
  props: {
    post: Object,
  },
  data() {
    return {
      importImages: false,
      upload: false,
      error: null,
      images: [],
      form: {
        textContent: { ...textContent, value: this.post.textContent ?? '' },
      },
      submitLoading: false,
    }
  },
  methods: {
    cancel() {
      this.$store.state.postEditionDialog = false
      this.form.textContent.value = ''
      this.form.channel.value = null
    },
    cancelUpload() {
      this.importImages = false
      this.upload = false
      this.images = []
      this.$root.$emit('cancelVideoUpload')
    },
    startImportImages() {
      this.upload = true
      this.importImages = true
    },
    async loadImage() {
      const defaultImage = this.post.images.filter(
        img => img.name === 'default',
      )
      for (const img of defaultImage) {
        const fileInfos = img.fileName.split('/')
        this.images.push({
          key: `${fileInfos[0]}/${fileInfos[2]}/${img.cid}`,
          preview: img.url,
          progress: 100,
          id: img.cid,
          file: null,
        })
      }
      this.importImages = true
    },
    submit() {
      this.error = null
      if (this.images.length) {
        this.form.images = {
          value: [],
        }
        for (const image of this.images) {
          this.form.images.value.push(image.key)
        }
        this.form.textContent.required = false
      } else {
        this.form.textContent.required = true
      }
      this.formValidation(this.form, true)
      if (this.errorsNbr > 0) {
        return
      }
      this.submitLoading = true
      const url = `/post/edit/${this.post.uid}`
      const formCopy = JSON.parse(JSON.stringify(this.form))
      requestService
        .post(url, this.formatDataToSend(formCopy))
        .then(response => {
          this.$store.dispatch(
            'alert/success',
            this.$options.filters.capitalize(
              this.$tc('channel.saved-modification'),
            ),
          )
          this.$store.state.postEdited = response
          this.cancel()
        })
        .catch(response => {
          this.submitLoading = false
          const { error, validationFailed } = response
          if (validationFailed) {
            this.handleServerErrors(error, this.form)
          }
        })
        .finally(() => (this.submitLoading = false))
    },
    loadPostChannel() {
      this.submitLoading = true
      requestService
        // .get(`/video/manage/${this.$route.params.uid}`)
        .get(`/channel/mine/internal`)
        .catch(response => {
          let error = this.$tc('error.an-error-has-occurred')
          if (response.error.status === 404) {
            error = this.$tc('error.this-video-does-not-exist')
          } else if (response.error.status === 403) {
            error = this.$tc('error.no-permission-view-video')
          }
          this.$store.dispatch('alert/error', error)
          // this.$router.push({ name: 'InternalVideos' })
        })
        .then(response => {
          const currentChannel = response.items.find(
            channel => channel.id === this.post.channel.id,
          )
          this.form = {
            ...this.form,
            channel: { value: currentChannel.uniqueKey },
          }
        })
        .finally(() => (this.submitLoading = false))
    },
  },
  mounted() {
    if (this.post && this.post.channel) {
      this.loadPostChannel()
    }
    if (this.post && this.post.images && this.post.images.length > 0) {
      this.loadImage()
    }
  },
  // watch: {
  //   post: function(newVal, oldVal) {
  //     console.log(newVal, oldVal)
  //     if (newVal) {
  //       this.form.textContent.value = this.post.textContent
  //       this.form.channel.value = this.post.channel.id
  //     }
  //   },
  // },
}
</script>
