<template>
  <div>
    <v-progress-circular
      v-if="loading"
      :size="size"
      indeterminate
    ></v-progress-circular>
    <v-avatar
      v-else
      :size="size"
      :color="color"
      v-bind="$attrs"
      :class="{ 'elevation-12': elevation }"
      class="avatar"
    >
      <v-img
        v-if="channelThumbnail && channelThumbnail.url !== null"
        :src="channelThumbnail.url"
        :alt="channelName"
        :lazy-src="tempChannelThumbnail && tempChannelThumbnail.url"
      >
        <slot></slot>
      </v-img>
      <template v-else>
        <slot></slot>
        <svg viewBox="0 -1 27 27" style="width: 100%">
          <text
            x="50%"
            y="50%"
            fill="white"
            dominant-baseline="middle"
            text-anchor="middle"
            v-text="channelName.slice(0, 1)"
          ></text>
        </svg>
      </template>
    </v-avatar>
  </div>
</template>

<script>
export default {
  name: 'ChannelAvatar',
  props: {
    thumbnails: [Array, String],
    channelName: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 100,
    },
    bgColor: {
      type: String,
      default: '',
    },
    object: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    elevation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tempChannelThumbnail() {
      if (typeof this.thumbnails === 'string') {
        return {
          url: this.thumbnails,
        }
      }
      return this.thumbnails
        ? this.thumbnails.find(thumbnail => thumbnail.name === 'default')
        : null
    },
    channelThumbnail() {
      if (typeof this.thumbnails === 'string' && this.thumbnails !== '') {
        return {
          url: this.thumbnails,
        }
      }
      return this.thumbnails
        ? this.thumbnails.find(thumbnail => thumbnail.name === 'medium')
        : null
    },
    color() {
      if (this.channelThumbnail && this.channelThumbnail.url) {
        return 'secondary'
      }
      if (this.object && this.object.avatarColor) {
        return this.object.avatarColor
      }
      if (this.bgColor) {
        return this.bgColor
      }
      return 'primary'
    },
  },
}
</script>

<style lang="scss" scoped></style>
