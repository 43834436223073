import Vue from 'vue'
// @ts-ignore
import Vuetify from 'vuetify/lib'
import fr from 'vuetify/src/locale/fr'
import de from 'vuetify/src/locale/de'
import en from 'vuetify/src/locale/en'
import es from 'vuetify/src/locale/es'
import it from 'vuetify/src/locale/it'
import i18n from '@/plugins/i18n'

Vue.use(Vuetify)

const themes = {
  light: {
    primary: '#B02550',
    secondary: '#002031',
    accent: '#353c49',
    error: '#b71c1c',
    success: '#009688',
    background: 'white',
  },
  dark: {
    primary: '#B02550',
    secondary: '#002031',
    accent: '#353c49',
    error: '#b71c1c',
    success: '#009688',
    background: '#001B29',
  },
}
// themes.light.background = themes.light.secondary
// themes.dark.background = themes.dark.secondary

export default new Vuetify({
  theme: {
    themes: themes,
    options: { customProperties: true },
    dark: true,
  },
  lang: {
    locales: { fr, en, de, es, it },
    current: i18n.locale,
  },
})
