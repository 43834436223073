<template>
  <v-textarea
    v-model="field.value"
    :error-messages="getErrors(field.errors)"
    @change="onFieldChange(field)"
    v-bind="$attrs"
    v-on="$listeners"
    ref="input"
  >
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-textarea>
</template>

<script>
import Validation from '../mixins/Validation'

export default {
  name: 'TextareaField',
  mixins: [Validation],
  props: {
    field: {
      type: Object,
    },
  },
}
</script>

<style lang="scss">
.theme--dark.v-text-field--solo-inverted.v-input--is-focused:not([background-color])
  textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}
.theme--dark.v-textarea.v-text-field--solo-inverted.v-input--is-focused textarea {
  color: #fff;
}
</style>
