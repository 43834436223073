var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',_vm._b({},'v-col',_vm.$attrs,false),[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'VideoPost', params: { slug: _vm.video.id } }}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{class:{ censored: _vm.video.censored },attrs:{"width":"100%","elevation":"0"}},[_c('video-thumbnail',{attrs:{"thumbnails":_vm.video.thumbnails,"in-second-duration":_vm.video.duration,"hover":hover,"censored":_vm.video.censored,"width":"100%","in-second-last-stop":_vm.video.lastStop,"video":_vm.video}}),_vm._v(" "),_c('v-card-title',{staticClass:"subtitle-2 text-break py-1"},[_c('v-list-item',{staticClass:"px-0 list-text-block",attrs:{"three-line":""}},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-subtitle',{attrs:{"title":_vm.video.title}},[_vm._v("\n                "+_vm._s(_vm.video.title)+"\n              ")]),_vm._v(" "),_c('v-list-item-subtitle',{staticClass:"font-weight-light align-self-end caption"},[_vm._v("\n                "+_vm._s(_vm._f("capitalize")(_vm.formatDateRelative(new Date(_vm.video.publishedAt), true)))+"\n              ")])],1)],1),_vm._v(" "),(!_vm.noChannel)?_c('v-list-item',{staticClass:"px-0 channel-block-list-item"},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-subtitle',[(_vm.usedChannel !== null)?_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":_vm.channelLink}},[_c('v-sheet',{staticClass:"d-flex align-center channel-block",attrs:{"rounded":""}},[_c('channel-avatar',{attrs:{"thumbnails":_vm.channelThumbnails,"channel-name":_vm.channelName,"size":30,"bg-color":"primary"}}),_vm._v(" "),_c('div',{staticClass:"ml-2 text-truncate",attrs:{"title":_vm.channelTooltip}},[_vm._v("\n                      "+_vm._s(_vm.channelName)+"\n                      "),(
                          !!_vm.$store.state.account.plateFormSubscription &&
                            _vm.$store.state.account.organizations.find(
                              elt => elt.uid === _vm.usedChannel.organization.uid,
                            )
                        )?_c('v-icon',{attrs:{"color":_vm.getColor(
                            _vm.$store.state.plateFormSubscription
                              .plateFormSubscription.planLevel,
                          )}},[_vm._v("mdi-seal")]):_vm._e()],1),_vm._v(" "),(_vm.getPlatformIcon(_vm.video))?_c('div',{staticClass:"ml-auto"},[_c('v-icon',{staticClass:"mx-1",attrs:{"small":"","color":_vm.getPlatformIcon(_vm.video).color},domProps:{"textContent":_vm._s(_vm.getPlatformIcon(_vm.video).iconName)}})],1):_vm._e()],1)],1):_vm._e()],1)],1)],1):_vm._e()],1)],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }