var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.memberOrganization)?_c('v-card',{attrs:{"color":"background"}},[_c('v-card-title',{staticClass:"secondary"},[_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(_vm.organization.name))])]),_vm._v(" "),_c('v-card-title',{staticClass:"text-body-1 text-break"},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('membership.join-this-organization'))))])]),_vm._v(" "),(!_vm.subscriptionFinished)?_c('v-card-text',[_c('div',[_c('h4',[_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('membership.adhesion-level')))+" :")]),_vm._v("\n          "+_vm._s(_vm._f("capitalize")(_vm.organization.membershipGroups[0].membershipLevel ??
              _vm.$tc('label.default')))+"\n        ")]),_vm._v(" "),_c('span',{staticClass:"text--primary"},[_vm._v(_vm._s(_vm.$tc('membership.adhesion-fees'))+" € :")]),_vm._v(" \n        "+_vm._s(_vm.organization.membershipGroups[0].membershipFeeCents / 100)+"€\n        "+_vm._s(_vm.$tc('donation.by-month'))+"\n      ")]),_vm._v(" "),_c('div',[_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.$tc('membership.exclusive-advantages'))+" :")]),_vm._v(" \n        "),_vm._l((_vm.organization.membershipGroups[0]
            .membershipAdvantages),function(avantage,index){return _c('span',{key:avantage},[_vm._v("\n          "+_vm._s(avantage)+"\n          "+_vm._s(index !==
            _vm.organization.membershipGroups[0].membershipAdvantages.length - 1
              ? ','
              : '')+"\n        ")])})],2),_vm._v(" "),_c('div',[_c('p',{staticClass:"mt-2 mb-0"},[_c('v-alert',{staticClass:"text-caption",attrs:{"text":"","dense":"","color":"teal","icon":"mdi-checkbox-marked-circle-plus-outline","border":"left"}},[_vm._v("\n            "+_vm._s(_vm.$tc('membership.enter-infos-to-join'))+"\n          ")])],1),_vm._v(" "),_c('div',[_c('credit-card-details',{attrs:{"isConnect":true,"confirmCard":_vm.confirmCard}}),_vm._v(" "),(_vm.subscriptionError)?_c('div',{staticClass:"red--text"},[_vm._v("\n            "+_vm._s(_vm.subscriptionError)+"\n          ")]):_vm._e()],1)])]):_c('v-card-text',[_c('v-alert',{attrs:{"color":"success"}},[_vm._v("\n        "+_vm._s(_vm.$tc('membership.subscription-registered'))+"\n      ")]),_vm._v(" "),_c('h3',{staticClass:"primary--text text-center"},[_vm._v("\n        "+_vm._s(_vm.$tc('membership.thank-you'))+" !\n      ")]),_vm._v(" "),_c('br'),_vm._v("\n      "+_vm._s(_vm.$tc('membership.protection-thank-to-you'))+" "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('p',{staticClass:"mt-3"},[_vm._v("\n        "+_vm._s(_vm.$tc('membership.modify-cancel-monthly-subscription'))+"\n      ")])],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"text":"","disabled":_vm.isLoading},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(_vm._s(_vm.subscriptionFinished ? _vm.$tc('button.close') : _vm.$tc('button.cancel')))]),_vm._v(" "),(!_vm.subscriptionFinished)?_c('v-btn',{staticClass:"text-uppercase",attrs:{"color":"success","loading":_vm.isLoading,"depressed":""},on:{"click":function($event){return _vm.submitPayment()}}},[_vm._v("\n        "+_vm._s(_vm.payment_intent_pending
            ? _vm.$tc('button.continue-to-pay')
            : _vm.$tc('button.join'))+"\n      ")]):_vm._e()],1)],1):_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$tc('membership.membership-info')))])]),_vm._v(" "),_c('v-card-text',[_c('div',{staticClass:"success--text font-weight-bold"},[_c('span',[_vm._v("Abonnement")]),_vm._v(" "),_c('span',{staticClass:"text-uppercase"},[_vm._v("\n          "+_vm._s(_vm.memberOrganization.organizationUid)+"\n        ")]),_vm._v(" "),_c('span',[_vm._v(" en cours.")])]),_vm._v(" "),_c('div',[_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"text-overline"},[_vm._v("Contribution")]),_vm._v(" :\n          "+_vm._s(_vm.formatCurrency(_vm.memberOrganization.amountCents / 100, 2))+"\n          "),_c('span',[_vm._v(" / Mois")])]),_vm._v(" "),_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"text-overline"},[_vm._v("Date de début")]),_vm._v(" :\n          "+_vm._s(new Date(
              _vm.memberOrganization.subscriptionStartDate,
            ).toLocaleDateString())+"\n        ")]),_vm._v(" "),(_vm.memberOrganization.nextBillingDate)?_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"text-overline"},[_vm._v("Prochaine date de facturation")]),_vm._v("\n          :\n          "+_vm._s(new Date(_vm.memberOrganization.nextBillingDate).toLocaleDateString())+"\n        ")]):_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"text-overline"},[_vm._v("Date de fin")]),_vm._v(" :\n          "+_vm._s(new Date(
              _vm.memberOrganization.subscriptionEndDate,
            ).toLocaleDateString())+"\n        ")]),_vm._v(" "),(
            _vm.memberOrganization.status === 'past_due' &&
              ['requires_action', 'requires_payment_method'].includes(
                _vm.memberOrganization.lastPaymentIntentStatus,
              )
          )?_c('div',{staticClass:"orange--text"},[_c('p',{staticClass:"text-subtitle-2 text-center mb-0"},[_vm._v("\n            "+_vm._s(_vm.$tc('error.a-platform-subscription-payment-failed'))+"\n          ")]),_vm._v(" "),(
              _vm.memberOrganization.failureErrorCode ===
                'authentication_required'
            )?_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"text-body-2 mb-1"},[_vm._v("\n              "+_vm._s(_vm.memberOrganization.lastPaymentMethodId ===
                _vm.paymentMethodInfos.id
                  ? _vm.$tc('label.payment-need-confirmation')
                  : '')+"\n            ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"info","outlined":"","small":"","loading":_vm.isLoading},on:{"click":function($event){_vm.memberOrganization.lastPaymentMethodId ===
                _vm.paymentMethodInfos.id
                  ? _vm.confirmPayment()
                  : _vm.changePaymentMethodAndRetry()}}},[_vm._v("\n              "+_vm._s(_vm.memberOrganization.lastPaymentMethodId ===
                _vm.paymentMethodInfos.id
                  ? _vm.$tc('button.confirm-payment')
                  : _vm.$tc('button.retry-payment'))+"\n            ")])],1):(
              _vm.memberOrganization.failureErrorCode ===
                'payment_intent_authentication_failure'
            )?_c('div',{staticClass:"text-center"},[(
                _vm.memberOrganization.lastPaymentMethodId ===
                  _vm.paymentMethodInfos.id
              )?_c('p',{staticClass:"text-body-2 mb-1"},[_vm._v("\n              "+_vm._s(_vm.$tc('label.card-authentication-failed'))+"\n            ")]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"text-body-2 mb-1"},[_vm._v("\n              "+_vm._s(_vm.$tc('label.change-card-and-retry'))+"\n            ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"info","outlined":"","small":"","loading":_vm.isLoading},on:{"click":function($event){return _vm.changePaymentMethodAndRetry()}}},[_vm._v("\n              "+_vm._s(_vm.memberOrganization.lastPaymentMethodId ===
                _vm.paymentMethodInfos.id
                  ? _vm.$tc('button.change-card')
                  : _vm.$tc('button.retry-payment'))+"\n            ")])],1):(
              _vm.memberOrganization.failureErrorCode === 'card_declined'
            )?_c('div',{staticClass:"text-center"},[(
                _vm.memberOrganization.lastPaymentMethodId ===
                  _vm.paymentMethodInfos.id
              )?_c('p',{staticClass:"text-body-2 mb-1"},[_vm._v("\n              "+_vm._s(_vm.$tc('error.card-been-refused'))+"\n            ")]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"text-body-2 mb-1"},[_vm._v("\n              "+_vm._s(_vm.$tc('label.change-card-and-retry'))+"\n            ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"info","outlined":"","small":"","loading":_vm.isLoading},on:{"click":function($event){return _vm.changePaymentMethodAndRetry()}}},[_vm._v("\n              "+_vm._s(_vm.memberOrganization.lastPaymentMethodId ===
                _vm.paymentMethodInfos.id
                  ? _vm.$tc('button.change-card')
                  : _vm.$tc('button.retry-payment'))+"\n            ")])],1):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',[_c('credit-card-details',{attrs:{"isConnect":true,"showSmall":_vm.showSmall,"confirmCard":_vm.confirmCard}}),_vm._v(" "),(_vm.subscriptionError)?_c('div',{staticClass:"red--text"},[_vm._v("\n            "+_vm._s(_vm.subscriptionError)+"\n          ")]):_vm._e()],1),_vm._v(" "),(_vm.memberOrganization.status == 'pending')?_c('v-alert',{attrs:{"dense":"","text":"","type":"info"},domProps:{"innerHTML":_vm._s(_vm.$tc('membership.subscription-pending-message'))}}):_vm._e(),_vm._v(" "),(_vm.memberOrganization.status == 'canceled')?_c('v-alert',{attrs:{"dense":"","text":"","type":"warning"},domProps:{"innerHTML":_vm._s(_vm.$tc('membership.subscription-canceled-message'))}}):_vm._e()],1)]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"loading":_vm.isLoading,"color":"primary","text":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v("\n        "+_vm._s(_vm.$tc('button.close'))+"\n      ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }