var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{class:_vm.colClass,staticStyle:{"max-width":"600px"},attrs:{"cols":"12"}},[_vm._l((_vm.formattedPosts),function(post){return _c('v-row',{key:post.uid,staticClass:"justify-center",staticStyle:{"width":"100%"}},[(post.mediaType === 'channel')?_c('v-col',[_c('v-list-item',{key:post.uid,staticClass:"mb-1 py-2 secondary rounded",staticStyle:{"min-height":"40px"},attrs:{"to":{
            name: 'Organization',
            params: { slug: post.uid },
          }}},[_c('v-list-item-avatar',{staticClass:"mr-2 my-0",attrs:{"size":70}},[_c('channel-avatar',{attrs:{"thumbnails":post.thumbnailUrl,"channel-name":post.name,"size":70,"bg-color":post.avatarColor}})],1),_vm._v(" "),_c('v-list-item-content',{staticClass:"align-content-start align-self-stretch"},[_c('v-list-item-subtitle',{staticClass:"d-flex"},[_c('span',{staticClass:"mr-1 text-overflow text-truncate white--text font-weight-medium"},[_vm._v(_vm._s(post.name))]),_vm._v(" "),_c('span',{},[_vm._v("@"+_vm._s(post.uid))])]),_vm._v(" "),_c('v-list-item-subtitle',[_vm._v("\n              "+_vm._s(post.subscribersCount)+"\n              "+_vm._s(_vm.$tc('organization.subscriber', post.subscribersCount || 1))+"\n            ")]),_vm._v(" "),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(post.textContent)}})],1)],1)],1):_c('post-item',{key:post.uid,attrs:{"cols":"12","large":"","post":post,"show-organization":"","force-open-post":_vm.simplified && !_vm.playInline},on:{"deleted":function($event){return _vm.$delete(_vm.posts, _vm.index)}}})],1)}),_vm._v(" "),(_vm.dataLoading)?[_c('v-row',_vm._l((12),function(item){return _c('post-item-loader',{key:item,attrs:{"cols":"12","large":""}})}),1)]:_vm._e(),_vm._v(" "),_c('v-row',[_c('v-col',{directives:[{name:"intersect",rawName:"v-intersect",value:({
          handler: _vm.onIntersectEnd,
          options: {
            rootMargin: '-50% 0px 500px 0px',
            threshold: [0, 1],
          },
        }),expression:"{\n          handler: onIntersectEnd,\n          options: {\n            rootMargin: '-50% 0px 500px 0px',\n            threshold: [0, 1],\n          },\n        }"}],staticStyle:{"min-height":"50px"},attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('div',{staticStyle:{"min-height":"50px"}})])],1),_vm._v(" "),(!_vm.endReached && !_vm.hasError && _vm.formattedPosts.length)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.dataLoading},on:{"click":_vm.claimMorePosts}},[_vm._v(_vm._s(_vm.$tc('button.load-more'))+"\n      ")])],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }