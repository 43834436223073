
import { defineComponent } from 'vue'
import AppTooltip from '@/components/AppTooltip.vue'

export default defineComponent({
  name: 'LabelChip',
  components: { AppTooltip },
  props: {
    textColor: {
      type: String,
      default: '#333333',
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
})
