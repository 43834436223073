var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"justify-center"},[_c('v-col',{staticClass:"px-sm-5",attrs:{"cols":"12"}},[(_vm.hasError && _vm.posts.length === 0 && !_vm.postsLoading)?_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-h5 text-center primary--text"},[_vm._v("\n          "+_vm._s(_vm.$tc('error.an-error-has-occurred'))+"\n        ")])])],1):_vm._e(),_vm._v(" "),(_vm.featuredPosts.length || _vm.featuredPostsLoading)?_c('v-row',[_c('v-col',[_c('div',{staticClass:"overline"},[_vm._v("\n          "+_vm._s(_vm.$tc('label.featured-posts'))+"\n        ")]),_vm._v(" "),_c('v-row',[_vm._l((_vm.featuredPosts),function(post){return _c('post-item',{key:post.uid,attrs:{"post":post,"cols":"12","sm":"6","md":"4","xl":"3","show-organization":""}})}),_vm._v(" "),(_vm.featuredPostsLoading)?_vm._l((2),function(item){return _c('post-item-loader',{key:item,attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}})}):_vm._e()],2)],1)],1):_vm._e(),_vm._v(" "),_c('v-row',[_c('v-col',[_c('v-row',{staticClass:"mb-1"},[(!_vm.hasError && _vm.$store.state.account.status.loggedIn)?_c('v-col',{staticClass:"pb-0 d-flex align-center",attrs:{"cols":"12"}},[_c('section-select',{model:{value:(_vm.sectionSelected),callback:function ($$v) {_vm.sectionSelected=$$v},expression:"sectionSelected"}}),_vm._v(" "),(_vm.sectionSelected !== 'discover')?_c('v-chip-group',{staticClass:"ml-1",attrs:{"active-class":"success--text"},model:{value:(_vm.tagSelected),callback:function ($$v) {_vm.tagSelected=$$v},expression:"tagSelected"}},[_c('v-chip',{staticClass:"ml-1",attrs:{"color":"accent","value":"most_recent"}},[_vm._v(_vm._s(_vm.$t('home.most-recent')))])],1):_vm._e()],1):_vm._e()],1),_vm._v(" "),_c('post-grid',{attrs:{"posts":_vm.posts,"loading":_vm.postsLoading,"simplified":""}}),_vm._v(" "),_c('v-col',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersectEnd,
            options: {
              rootMargin: '-50% 0px 500px 0px',
              threshold: [0, 1],
            },
          }),expression:"{\n            handler: onIntersectEnd,\n            options: {\n              rootMargin: '-50% 0px 500px 0px',\n              threshold: [0, 1],\n            },\n          }"}],staticStyle:{"min-height":"50px"},attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('div',{staticStyle:{"min-height":"50px"}})])],1)],1),_vm._v(" "),(!_vm.endReached && !_vm.hasError)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.postsLoading,"depressed":"","rounded":""},on:{"click":_vm.fetchPosts}},[_vm._v(_vm._s(_vm.$tc('button.see-more'))+"\n      ")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }