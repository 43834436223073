
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SectionSelect',
  props: {
    value: {
      type: String,
      default: 'for-you',
    },
  },
  data() {
    return {
      items: [
        { title: 'Pour vous', value: 'for-you' },
        { title: 'Populaires', value: 'popular' },
        { title: 'Découvrir', value: 'discover' },
      ],
      selectedItem: this.value,
      menu: false,
    }
  },
  computed: {
    selectedTitle() {
      // find item by value
      const item = this.items.find(item => item.value === this.selectedItem)
      return item ? this.$t(`home.${item.value}`) : ''
    },
  },
})
