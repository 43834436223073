<template>
  <div class="d-flex flex-nowrap">
    <template v-if="organization.subscription">
      <v-btn
        color="accent"
        depressed
        @click="unsubscribe"
        :loading="loading"
        rounded
        >{{ $tc('organization.subscriber') }}
      </v-btn>
      <v-menu :close-on-content-click="false" bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-on="on" v-bind="attrs">
            <v-icon>{{ muted ? 'mdi-bell-off' : 'mdi-bell-ring' }}</v-icon>
          </v-btn>
        </template>
        <v-card color="default" class="notifications-card">
          <v-card-text>
            <div class="text-caption">
              {{ $tc('notification.receive-notifications-for') + ' :' }}
            </div>
            <v-switch
              v-model="organization.subscription.notifications.newContent"
              :label="$tc('notification.new-publications')"
              hide-details
              @change="updateNotifications"
            ></v-switch>
            <v-switch
              v-model="organization.subscription.notifications.censorship"
              :label="$tc('censored.censored-content')"
              hide-details
              @change="updateNotifications"
            ></v-switch>
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
    <v-tooltip v-else bottom max-width="300">
      <template #activator="{on}">
        <v-btn
          v-on="on"
          color="primary"
          @click="subscribe"
          :loading="loading"
          depressed
          rounded
          >{{ $tc('button.subscribe') }}</v-btn
        >
      </template>
      {{ $tc('notification.notified-new-content-or-censored') }}
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'SubscriptionButton',
  data() {
    return {
      loading: false,
    }
  },
  props: {
    organization: { type: Object },
  },
  computed: {
    notifications() {
      return this.organization.subscription
        ? this.organization.subscription.notifications
        : {}
    },
    muted() {
      return !this.notifications.newContent && !this.notifications.censorship
    },
  },
  methods: {
    subscribe() {
      this.loading = true
      this.$store
        .dispatch('subscription/subscribe', this.organization.uid)
        .then(response => {
          this.organization.subscription = response
          this.$store.dispatch(
            'alert/success',
            this.$tc('subscribed.now-subscribed-organization'),
          )
          this.updateSubscriptions()
        })
        .finally(() => (this.loading = false))
    },
    unsubscribe() {
      this.$store
        .dispatch('subscription/unsubscribe', this.organization.uid)
        .then(this.updateSubscriptions)
      this.organization.subscription = null
      this.$store.dispatch(
        'alert/success',
        this.$tc('subscribed.no-longer-subscribe-organization'),
      )
    },
    updateNotifications() {
      this.loading = true
      this.$store
        .dispatch('subscription/updateNotifications', {
          organizationUid: this.organization.uid,
          notifications: this.notifications,
        })
        .then(response => {
          this.organization.subscription = response
          this.$store.dispatch(
            'alert/success',
            this.$tc('subscribed.subscription-updated'),
          )
        })
        .finally(() => (this.loading = false))
    },
    updateSubscriptions() {
      this.$root.$emit('subscribedOrganizationUpdated')
    },
  },
}
</script>

<style scoped lang="scss">
.notifications-card {
  background-color: map-get($material-dark, 'background');
}
</style>
