
import { defineComponent } from 'vue'
import PostMenu from '@/components/PostMenu.vue'
import PostPreview from '@/components/post/PostPreview.vue'
import { utilsService } from '@/services/utils.service'

export default defineComponent({
  name: 'DensePostItem',
  components: { PostPreview, PostMenu },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    getLink(item) {
      const routeName = item.mediaType === 'video' ? 'VideoPost' : 'Post'
      return {
        name: routeName,
        params: { slug: item.uid, post: item },
      }
    },
    getPublicationDate(post) {
      let date = null
      if (post.publishedAt) {
        date = new Date(post.publishedAt)
      } else if (post.publishedAtTimestamp) {
        date = new Date(post.publishedAtTimestamp * 1000)
      }
      return date ? utilsService.formatDateRelative(date) : null
    },
    getViewCount(post) {
      if (post.viewCount) {
        return post.viewCount
      }
      if (post.video && post.video.viewCount) {
        return post.video.viewCount
      }
      return null
    },
    getViewCountFormatted(post) {
      const viewCount = this.getViewCount(post)
      if (viewCount) {
        return `${utilsService.formatNumber(viewCount)} ${this.$tc(
          'video.view',
          viewCount,
        )}`
      }
      return null
    },
  },
})
