<template>
  <div
    class="d-flex justify-center fill-height mt-2"
    :class="[$vuetify.breakpoint.mdAndUp ? 'align-center' : 'align-start']"
    style="width: 100%"
  >
    <v-card width="400px" class="rounded-lg overflow-hidden" flat>
      <v-tabs
        background-color=""
        class="mb-3"
        center-active
        fixed-tabs
        v-model="tabs"
      >
        <v-tab :to="{ name: 'Register' }">{{ $tc('label.register') }}</v-tab>
        <v-tab :to="{ name: 'Login' }">{{ $tc('label.login') }}</v-tab>
      </v-tabs>

      <v-card-text>
        <v-tabs-items v-model="tabs">
          <v-tab-item value="/register">
            <p>
              {{ $tc('login.register-free-express-yourself') }}
            </p>
            <v-form>
              <v-text-field
                v-model.trim="registerForm.username"
                @input="delayTouch($v.registerForm.username)"
                :label="$tc('login.username') | capitalize"
                :hint="$tc('login.publicly-visible')"
                :error-messages="registerUsernameErrors"
              ></v-text-field>
              <v-text-field
                v-model.trim="registerForm.email"
                @input="delayTouch($v.registerForm.email)"
                :label="$tc('login.email')"
                type="email"
                :error-messages="emailErrors"
                persistent-hint
                :hint="$tc('login.orange-and-its-services')"
              ></v-text-field>
              <v-text-field
                v-model.trim="registerForm.password"
                @input="delayTouch($v.registerForm.password)"
                :label="$tc('login.password') | capitalize"
                type="password"
                :error-messages="registerPasswordErrors"
              ></v-text-field>
            </v-form>
          </v-tab-item>
          <v-tab-item value="/login">
            <v-form @submit="submit">
              <v-text-field
                v-model.trim="loginForm.username"
                @input="delayTouch($v.loginForm.username)"
                :label="$tc('login.username-or-email')"
                :error-messages="loginUsernameErrors"
                @keyup.enter="submit"
              ></v-text-field>
              <v-text-field
                v-model.trim="loginForm.password"
                @input="delayTouch($v.loginForm.password)"
                :label="$tc('login.password') | capitalize"
                type="password"
                :error-messages="loginPasswordErrors"
                @keyup.enter="submit"
              ></v-text-field>
            </v-form>
            <div v-if="loginError" class="error--text">{{ loginError }}</div>
            <div>
              <router-link :to="{ name: 'ForgetPassword' }">{{
                $tc('password.forgot-password')
              }}</router-link>
            </div>
            <div>
              <router-link :to="{ name: 'Register' }">{{
                $tc('label.register')
              }}</router-link>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :to="{ name: 'Home' }" color="grey" text exact>
          {{ $tc('button.return') }}
        </v-btn>
        <v-btn
          @click="submit"
          type="submit"
          :loading="loading"
          color="primary"
          text
        >
          {{ $tc('button.continue') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {
  email,
  minLength,
  required,
  helpers,
  maxLength,
} from 'vuelidate/lib/validators'
import axios from 'axios'
import Validation from '@/components/mixins/Validation'

const touchMap = new WeakMap()

const securePassword = helpers.regex(
  'alpha',
  /^((?=.*[a-z])(?=.*[A-Z]))(?=.*[0-9])(?=.{8,})/,
)

// const usernamePattern = helpers.regex('alpha', /^[a-z0-9_ -]+$/i)
const usernamePattern = helpers.regex(
  'alpha',
  /^[a-z0-9_-](?!.* {2})[ \w.-]{2,24}$/i,
)

export default {
  name: 'Login',
  mixins: [Validation],
  data() {
    return {
      tabs: 0,
      registerForm: {
        username: '',
        email: '',
        password: '',
      },
      loginForm: {
        username: '',
        password: '',
      },
      loading: false,
      unhandledErrors: {},
      loginError: null,
    }
  },
  validations: {
    registerForm: {
      username: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(25),
        pattern: usernamePattern,
      },
      email: {
        required,
        email,
      },
      password: { required, secure: securePassword },
    },
    loginForm: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    registerUsernameErrors() {
      if (
        this.unhandledErrors['username'] &&
        this.unhandledErrors['username'].length
      ) {
        return this.unhandledErrors['username']
      }
      const errors = []
      if (!this.$v.registerForm.username.$dirty) return errors
      !this.$v.registerForm.username.minLength &&
        errors.push(this.$tc('login.minimum-4-characters'))
      !this.$v.registerForm.username.maxLength &&
        errors.push(this.$tc('login.maximum-25-characters'))
      !this.$v.registerForm.username.required &&
        errors.push(this.$tc('login.required') + '.')
      !this.$v.registerForm.username.pattern &&
        errors.push(this.$tc('login.can-only-contain'))
      return errors
    },
    emailErrors() {
      if (
        this.unhandledErrors['email'] &&
        this.unhandledErrors['email'].length
      ) {
        return this.unhandledErrors['email']
      }
      const errors = []
      if (!this.$v.registerForm.email.$dirty) return errors
      !this.$v.registerForm.email.email &&
        errors.push(this.$tc('error.invalid-email-address'))
      !this.$v.registerForm.email.required &&
        errors.push(this.$tc('login.required') + '.')
      return errors
    },
    registerPasswordErrors() {
      if (
        this.unhandledErrors['password'] &&
        this.unhandledErrors['password'].length
      ) {
        return this.unhandledErrors['password']
      }
      const errors = []
      if (!this.$v.registerForm.password.$dirty) return errors
      !this.$v.registerForm.password.secure &&
        errors.push(this.$tc('login.password-must-have'))
      !this.$v.registerForm.password.required &&
        errors.push(this.$tc('login.required') + '.')
      return errors
    },
    loginPasswordErrors() {
      if (
        this.unhandledErrors['password'] &&
        this.unhandledErrors['password'].length
      ) {
        return this.unhandledErrors['password']
      }
      const errors = []
      if (!this.$v.loginForm.password.$dirty) return errors
      !this.$v.loginForm.password.required &&
        errors.push(this.$tc('login.required') + '.')
      return errors
    },
    loginUsernameErrors() {
      if (
        this.unhandledErrors['username'] &&
        this.unhandledErrors['username'].length
      ) {
        return this.unhandledErrors['username']
      }
      const errors = []
      if (!this.$v.loginForm.username.$dirty) return errors
      !this.$v.loginForm.username.required &&
        errors.push(this.$tc('login.required') + '.')
      return errors
    },
  },
  methods: {
    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 1000))
    },
    submit() {
      this.unhandledErrors = {}
      if (this.$route.name === 'Register') {
        this.submitRegistration()
      } else {
        this.submitLogin()
      }
    },
    submitRegistration() {
      this.$v.$touch()
      if (this.$v.registerForm.$invalid) {
        return
      }
      this.loading = true
      axios
        .post(
          process.env.VUE_APP_API_BASE_URL + '/auth/register',
          {
            username: this.registerForm.username,
            email: this.registerForm.email,
            password: this.registerForm.password,
          },
          { headers: { 'x-locale': this.$i18n.locale } },
        )
        .then(response => {
          if (!response.data.emailVerified) {
            return this.$router.push({
              name: 'ConfirmEmail',
              query: { email: response.data.email },
            })
          }
          this.$store.dispatch('account/loginSuccess', {
            username: this.registerForm.username,
            data: response.data,
          })
          this.$router.push({ name: 'MyChannels' })
        })
        .catch(error => {
          this.handleServerErrors(error.response.data, {})
        })
        .finally(() => {
          this.loading = false
        })
    },
    submitLogin() {
      this.$v.$touch()
      if (this.$v.loginForm.$invalid) {
        return
      }
      this.loading = true
      this.loginError = false
      axios
        .post(process.env.VUE_APP_API_BASE_URL + '/login-check', {
          username: this.loginForm.username.trim(),
          password: this.loginForm.password,
        })
        .then(response => {
          if (response.data.token) {
            this.$store.dispatch('account/loginSuccess', {
              username: this.loginForm.username,
              data: response.data,
            })

            if (this.$route.query.redirect) {
              this.$router.push(this.$route.query.redirect)
            } else {
              this.$router.push({ name: 'Home' })
            }
          } else if (response.data.emailNotVerified) {
            return this.$router.push({
              name: 'ConfirmEmail',
              query: { email: response.data.email, login: 1 },
            })
          } else if (response.data.message) {
            this.loginError = response.data.message
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.loginError = this.$tc('error.incorrect-credentials')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  beforeCreate() {
    if (this.$store.state.account.status.loggedIn) {
      this.$router.push({ name: 'Console' })
    }
  },
}
</script>

<style scoped></style>
