var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loggedIn)?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"pt-5",on:{"click":function($event){_vm.onBehalfMenu = true}}},[_c('v-menu',{attrs:{"offset-y":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"select-behalf"},'div',attrs,false),on),[_c('channel-avatar',{staticClass:"mr-3",attrs:{"thumbnails":_vm.onBehalf
                  ? _vm.onBehalf.thumbnails
                  : _vm.$store.state.account.user.user.thumbnails,"size":_vm.sub ? 30 : 45,"channel-name":_vm.onBehalfName,"bg-color":_vm.onBehalfAvatarColor || 'primary'}}),_vm._v(" "),_c('v-icon',{staticClass:"select-behalf-icon",attrs:{"small":""}},[_vm._v("mdi-chevron-down-circle\n            ")])],1)]}}],null,false,3251949747),model:{value:(_vm.onBehalfMenu),callback:function ($$v) {_vm.onBehalfMenu=$$v},expression:"onBehalfMenu"}},[_vm._v(" "),_c('v-card',[(_vm.organizations.length > 3)?_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm._f("capitalize")(_vm.$tc('label.search')),"flat":"","solo-inverted":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline","dense":"","autofocus":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}},model:{value:(_vm.onBehalfSearch),callback:function ($$v) {_vm.onBehalfSearch=$$v},expression:"onBehalfSearch"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"pa-2",staticStyle:{"max-height":"200px","overflow-y":"auto"}},[_c('v-treeview',{attrs:{"active":_vm.onBehalfSelect,"items":_vm.onBehalfList,"activatable":"","item-key":"id","open-on-click":"","open-all":"","dense":"","search":_vm.onBehalfSearch},on:{"update:active":function($event){_vm.onBehalfSelect=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function({ item }){return [(item.children === undefined)?_c('channel-avatar',{staticClass:"mr-3",attrs:{"thumbnails":item.thumbnails
                      ? item.thumbnails
                      : _vm.$store.state.account.user.user.thumbnails,"size":30,"channel-name":item.name,"bg-color":item && item.avatarColor
                      ? item.avatarColor
                      : _vm.onBehalfAvatarColor
                      ? _vm.onBehalfAvatarColor
                      : 'primary'}}):_vm._e()]}}],null,false,4191952674)})],1)],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"flex-grow-1 text-caption"},[_c('div',[_vm._v("\n        "+_vm._s(_vm._f("capitalize")(_vm.sub ? _vm.$tc('chat.answer') : _vm.$tc('chat.comment')))+_vm._s(' ' + _vm.$tc('chat.as'))+"\n        "),_c('b',[_vm._v(_vm._s(_vm.onBehalfName))])]),_vm._v(" "),_c('textarea-field',{attrs:{"solo-inverted":"","flat":"","placeholder":_vm.$tc('chat.comment-freely'),"background-color":"secondary lighten-2","hide-details":(_vm.form.textContent.value === null ||
            _vm.form.textContent.value.length < 1800) &&
            (!_vm.form.textContent.errors || _vm.form.textContent.value.length === 0),"auto-grow":"","counter":_vm.form.textContent.value !== null &&
          _vm.form.textContent.value.length > 1800
            ? 2000
            : undefined,"field":_vm.form.textContent,"rows":"1","dense":_vm.sub,"autofocus":_vm.sub},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if(!$event.ctrlKey)return null;return _vm.submit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"small":"","icon":"","loading":_vm.submitLoading,"disabled":_vm.form.textContent.value.length === 0},on:{"click":_vm.submit}},[_c('v-icon',{attrs:{"small":_vm.sub}},[_vm._v("mdi-send")])],1)]},proxy:true}],null,false,566150070)}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.unhandledErrors),expression:"unhandledErrors"}]},[_vm._l((_vm.unhandledErrors),function(errorField,index){return _vm._l((errorField),function(error,i){return _c('div',{key:index + i,staticClass:"error--text"},[_c('b',[_vm._v(_vm._s(error))])])})})],2)],1)]):_c('v-alert',{staticClass:"text-center mt-1",attrs:{"type":"info","color":"secondary"}},[_c('router-link',{attrs:{"to":{ name: 'Login' }}},[_vm._v(_vm._s(_vm.$tc('chat.connect')))]),_vm._v(_vm._s(' ' + _vm.$tc('label.or'))+"\n    "),_c('router-link',{attrs:{"to":{ name: 'Register' }}},[_vm._v(_vm._s(_vm.$tc('label.register-yourself')))]),_vm._v(_vm._s(' ' + _vm.$tc('chat.to-comment'))+"\n  ")],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }