<template>
  <v-progress-circular
    :rotate="-90"
    :size="200"
    :width="15"
    :value="$store.state.realtime.campaignStagePercent || 0"
    color="teal"
    class="body-1"
  >
    <div v-if="$store.state.realtime.campaignStagePercent !== null" class="text-center white--text" style="line-height: 22px">
      <span v-if="$store.state.realtime.campaignStage>1">Palier {{ $store.state.realtime.campaignStage }} :<br></span>
      <span class="" style="font-size: 2rem">{{ $store.state.realtime.campaignStagePercent + '%' }}</span>
    </div>

  </v-progress-circular>
</template>

<script>
import { createClient } from '@supabase/supabase-js'
export default {
  name: 'CampaignProgress',
  data() {
    return {
      totalAmount: 0,
    }
  },
}
</script>

<style scoped></style>
