<template>
  <v-row class="justify-center">
    <v-col cols="12" lg="11">
      <v-row v-if="hasError && posts.length === 0 && !postsLoading">
        <v-col>
          <div class="text-h5 text-center primary--text">
            {{ $tc('error.an-error-has-occurred') }}
          </div>
        </v-col>
      </v-row>
      <v-row v-if="featuredPosts.length || featuredPostsLoading">
        <v-col>
          <div class="overline">
            {{ $tc('label.featured-posts') }}
          </div>
          <v-row>
            <post-item
              v-for="post in featuredPosts"
              :key="post.uid"
              :post="post"
              cols="12"
              sm="6"
              md="4"
              xl="3"
              show-organization
            ></post-item>
            <template v-if="featuredPostsLoading">
              <post-item-loader
                v-for="item in 2"
                :key="item"
                cols="12"
                sm="6"
                md="4"
                xl="3"
              ></post-item-loader>
            </template>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-else-if="showCensoredVideos">
        <v-col>
          <div class="overline">
            {{ $tc('censored.recently-censored') }}
            <v-tooltip right max-width="500px" transition="fade-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small class="mb-1">
                  mdi-information
                </v-icon>
              </template>
              <div
                v-html="$tc('home.servers-automatically-detect-deleted-videos')"
              ></div>
            </v-tooltip>
          </div>
          <v-row>
            <video-item
              v-for="post in censoredVideos"
              :key="post.uid"
              :video="post.video"
              :channel="post.channel"
              cols="12"
              sm="4"
              md="3"
              xl="2"
              show-organization
            ></video-item>
            <template
              v-if="censoredVideosLoading && censoredVideos.length === 0"
            >
              <video-item-loader
                v-for="item in 4"
                :key="item"
                cols="12"
                sm="4"
                md="3"
                xl="2"
              ></video-item-loader>
            </template>
          </v-row>
          <div class="mt-1 d-flex">
            <v-btn class="ml-auto" text small :to="{ name: 'Censored' }">{{
              $tc('button.see-more')
            }}</v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="overline">{{ $tc('home.in-the-spotlight') }}</div>
          <v-row>
            <post-item
              v-for="post in posts"
              :key="post.uid"
              :post="post"
              cols="12"
              sm="6"
              md="4"
              xl="3"
              show-organization
            ></post-item>
            <template v-if="postsLoading">
              <post-item-loader
                v-for="item in 12"
                :key="item"
                cols="12"
                sm="6"
                md="4"
                xl="3"
              ></post-item-loader>
            </template>
            <v-col
              cols="12"
              sm="6"
              md="4"
              xl="3"
              v-intersect="{
                handler: onIntersectEnd,
                options: {
                  rootMargin: '-50% 0px 500px 0px',
                  threshold: [0, 1],
                },
              }"
              style="min-height: 50px"
            >
              <div style="min-height: 50px"></div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="!endReached && !hasError">
        <v-btn color="primary" :loading="postsLoading" @click="fetchPosts"
          >{{ $tc('button.see-more') }}
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue'
import { requestService } from '@/services/request.service.js'
import VideoItem from '@/components/VideoItem'
import VideoItemLoader from '@/components/VideoItemLoader'
import PostItem from '@/components/PostItem'
import PostItemLoader from '@/components/PostItemLoader'

export default Vue.extend({
  name: 'HomePreview',
  components: {
    PostItemLoader,
    PostItem,
    VideoItemLoader,
    VideoItem,
  },
  data() {
    return {
      hasError: false,
      posts: [],
      censoredVideos: [],
      postsLoading: false,
      censoredVideosLoading: false,
      queryLast: null,
      endReached: false,
      censoredLimitByBreakpoint: {
        xl: 6,
        lg: 4,
        md: 3,
        sm: 2,
        xs: 2,
      },
      featuredPostsLoading: false,
      featuredPosts: [],
      trendingPostsLoading: false,
      trendingPosts: [],
      showCensoredVideos: false,
      fundItems: [],
      fundItemsLoading: false,
    }
  },
  methods: {
    formatDate(date, hour = false) {
      return new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: hour ? 'numeric' : undefined,
        minute: hour ? 'numeric' : undefined,
      }).format(date)
    },
    async fetchPosts() {
      this.postsLoading = true
      this.hasError = false
      requestService
        .get(process.env.VUE_APP_API_BASE_URL + '/post/all-preview', {
          params: {
            after: this.queryLast,
          },
        })
        .then(response => {
          if (this.posts.length && this.queryLast !== null) {
            Array.prototype.push.apply(this.posts, response.posts)
          } else {
            this.posts = response.posts
          }

          if (response.last !== null) {
            this.queryLast = response.last
          } else {
            this.endReached = true
          }
          this.$store.state.lastVideos = this.posts
        })
        .catch(() => {
          this.hasError = true
        })
        .finally(() => {
          this.postsLoading = false
        })
    },
    async fetchCensoredVideos() {
      this.censoredVideosLoading = true
      // this.hasError = false
      let limit = this.censoredLimitByBreakpoint[this.$vuetify.breakpoint.name]
      limit = Math.min(limit, 6)
      requestService
        .get(process.env.VUE_APP_API_BASE_URL + '/post/censored', {
          params: {
            limit: limit,
          },
        })
        .then(response => {
          const posts = response.posts.slice(0, 6)
          this.censoredVideos = posts
          this.$store.state.lastCensoredVideos = posts
        })
        .catch(() => {
          this.hasError = true
        })
        .finally(() => {
          this.censoredVideosLoading = false
        })
    },
    async fetchFeaturedPosts() {
      this.featuredPostsLoading = true
      requestService
        .get(process.env.VUE_APP_API_BASE_URL + '/post/featured', {
          params: {
            limit: 4,
          },
        })
        .then(response => {
          const posts = response.posts.slice(0, 6)
          this.featuredPosts = posts
          this.$store.state.lastFeaturedPosts = posts
        })
        .catch(() => {
          this.hasError = true
        })
        .finally(() => {
          this.featuredPostsLoading = false
        })
    },
    async fetchTrendingPosts() {
      this.trendingPostsLoading = true
      requestService
        .get(process.env.VUE_APP_API_BASE_URL + '/post/trending', {
          params: {
            limit: 8,
          },
        })
        .then(response => {
          const posts = response.posts.slice(0, 8)
          this.trendingPosts = posts
          this.$store.state.lastTrendingPosts = posts
        })
        .catch(() => {
          this.hasError = true
        })
        .finally(() => {
          this.trendingPostsLoading = false
        })
    },
    async fetchFundItems() {
      this.fundItemsLoading = true
      requestService
        .get(process.env.VUE_APP_API_BASE_URL + '/fund/all', {
          params: {
            limit: 4,
          },
        })
        .then(response => {
          this.fundItems = response.funds.slice(0, 4)
        })
        .catch(() => {
          this.hasError = true
        })
        .finally(() => {
          this.fundItemsLoading = false
        })
    },
    onIntersectEnd() {
      if (this.postsLoading || this.endReached || this.hasError) {
        return
      }
      this.fetchPosts()
    },
    loadSavedVideos() {
      // setTimeout(() => {
      const savedVideosList = this.$store.state.lastVideos
      if (savedVideosList.length) {
        this.posts = savedVideosList
      }
      if (this.$store.state.lastVideos.length) {
        this.censoredVideos = this.$store.state.lastCensoredVideos
      }
      if (this.$store.state.lastTrendingPosts.length) {
        this.trendingPosts = this.$store.state.lastTrendingPosts
      }
      // }, 60)
    },
  },
  mounted() {
    this.$nextTick(this.loadSavedVideos)
  },
  created() {
    this.$nextTick(this.fetchPosts)
    if (this.showCensoredVideos) {
      this.$nextTick(this.fetchCensoredVideos)
    }
    this.$nextTick(this.fetchFeaturedPosts)
    this.$nextTick(this.fetchTrendingPosts)
  },
})
</script>

<style lang="scss" scoped>
.home {
  //position: absolute;
  width: 100%;
}

::v-deep {
  .v-list-item:hover::before {
    opacity: 0 !important;
  }

  .v-skeleton-loader__image {
    height: 100%;
  }

  .v-skeleton-loader__list-item-avatar {
    height: 40px;

    .v-skeleton-loader__avatar {
      height: 30px !important;
      width: 30px !important;
    }
  }
}
</style>
