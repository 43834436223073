<template>
  <vue-countdown-timer
    @start_callback="startCallBack()"
    @end_callback="endCallBack()"
    :start-time="startDate || new Date()"
    :end-time="endDate"
    :interval="1000"
    :start-label="''"
    label-position="begin"
    :end-text="'Cagnotte terminée !'"
    :day-txt="$tc('time.day', 2)"
    :hour-txt="$tc('time.hour', 2)"
    :minutes-txt="'min.'"
    :seconds-txt="'sec.'"
  >
    <template v-slot:countdown="scope">
      <div class="text-center d-flex" :class="{ small, 'x-small': xSmall }">
        <div>
          <div class="countdown-number">
            {{ scope.props.days }}
          </div>
          <div class="text-overline">
            {{ scope.props.dayTxt }}
          </div>
        </div>
        <div class="countdown-number countdown-separator">:</div>
        <div>
          <div class="countdown-number">
            {{ scope.props.hours }}
          </div>
          <div class="text-overline">
            {{ scope.props.hourTxt }}
          </div>
        </div>
        <div class="countdown-number countdown-separator">:</div>
        <div>
          <div class="countdown-number">
            {{ scope.props.minutes }}
          </div>
          <div class="text-overline">
            {{ scope.props.minutesTxt }}
          </div>
        </div>
        <div class="countdown-number countdown-separator">:</div>
        <div>
          <div class="countdown-number">
            {{ scope.props.seconds }}
          </div>
          <div class="text-overline">
            {{ scope.props.secondsTxt }}
          </div>
        </div>
      </div>
    </template>

    <template slot="end-text" slot-scope="scope">
      <span class="success--text">{{ scope.props.endText }}</span>
    </template>
  </vue-countdown-timer>
</template>

<script>
export default {
  name: 'CampaignCountdown',
  props: {
    startDate: {
      type: Date,
      required: false,
    },
    endDate: {
      type: Date,
      required: true,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    xSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    startCallBack() {
      this.$emit('start')
    },
    endCallBack() {
      this.$emit('end')
    },
  },
}
</script>

<style scoped lang="scss">
.countdown-number {
  font-size: 52px;
  line-height: 40px;
}
.countdown-separator {
  width: 20px;
  line-height: 40px;
}
.small .countdown-number {
  font-size: 32px;
  line-height: 25px;
}

.x-small {
  .countdown-number {
    font-size: 24px;
    line-height: 20px;
  }
  //.countdown-separator {
  //  width: 10px;
  //  line-height: 20px;
  //}
  .text-overline {
    font-size: 9px !important;
    line-height: 15px;
  }
}
</style>
