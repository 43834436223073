import { requestService } from '@/services/request.service.js'

const state = {
  organizations: {},
  users: {},
}

const mutations = {
  setDetails(state, { id, details, timestamp, type }) {
    state[type][id] = {
      details,
      timestamp,
    }
  },
}

const actions = {
  async getOrganization({ commit, state }, { uid }) {
    const currentTime = new Date().getTime()
    const cacheTime = 5 * 60 * 1000 // 5 minutes in milliseconds

    // Check if organization details are already cached
    if (state.organizations[uid]) {
      const { details, timestamp } = state.organizations[uid]
      if (currentTime - timestamp < cacheTime) {
        return Promise.resolve(details)
      }
    }

    try {
      const response = await requestService.get(`/organization/${uid}/details`)
      commit('setDetails', {
        id: uid,
        details: response,
        timestamp: currentTime,
        type: 'organizations',
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getUser({ commit, state }, { username }) {
    const currentTime = new Date().getTime()
    const cacheTime = 5 * 60 * 1000 // 5 minutes in milliseconds

    // Check if user details are already cached
    if (state.users[username]) {
      const { details, timestamp } = state.users[username]
      if (currentTime - timestamp < cacheTime) {
        return Promise.resolve(details)
      }
    }

    try {
      const response = await requestService.get(`/user/${username}`)
      commit('setDetails', {
        id: username,
        details: response,
        timestamp: currentTime,
        type: 'users',
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },
}

export const channel = {
  namespaced: true,
  state,
  mutations,
  actions,
}
