function extractServerErrors(errorsObj, parentKey = '') {
  let errorMessages = []

  if (errorsObj.errors) {
    errorMessages = errorsObj.errors.map(error => ({ field: parentKey, error }))
  }

  if (errorsObj.children) {
    for (const [key, value] of Object.entries(errorsObj.children)) {
      const fullKey = parentKey ? `${parentKey}.${key}` : key
      const childErrors = extractServerErrors(value, fullKey)

      errorMessages = [...errorMessages, ...childErrors]
    }
  }

  return errorMessages
}

/**
 * Extracts server errors in strings
 * @param errorsObj
 * @param directFields - fields that should be displayed directly without parent key
 * @param parentKey
 * @returns {unknown[]}
 */
function extractServerErrorsInStrings(
  errorsObj,
  directFields = [],
  parentKey = '',
) {
  let errorMessages = []

  if (errorsObj.errors) {
    errorMessages = [...errorsObj.errors]
  }

  if (errorsObj.children) {
    for (const [key, value] of Object.entries(errorsObj.children)) {
      const fullKey = parentKey ? `${parentKey}.${key}` : key
      const childErrors = extractServerErrorsInStrings(
        value,
        directFields,
        fullKey,
      )

      errorMessages = [...errorMessages, ...childErrors]
    }
  }

  return errorMessages.map(error => {
    if (directFields.includes(parentKey) || !parentKey) {
      return error
    }
    return `${parentKey}: ${error}`
  })
}

export const validationService = {
  extractServerErrors,
  extractServerErrorsInStrings,
}

export { extractServerErrors, extractServerErrorsInStrings }
