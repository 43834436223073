<template>
  <v-img
    :src="getVideoThumbnail().url"
    :lazy-src="getTempVideoThumbnail().url"
    cover
    :aspect-ratio="320 / 180"
    v-bind="$attrs"
    class="thumbnail-img rounded-lg overflow-hidden"
  >
    <!--    <div-->
    <!--      v-if="!getVideoThumbnail().url && (live || liveType)"-->
    <!--      class="thumbnail-replacement d-flex justify-center align-center"-->
    <!--    >-->
    <!--      <v-icon color="primary" large>mdi-access-point</v-icon>-->
    <!--    </div>-->
    <div
      v-if="!getVideoThumbnail().url"
      class="thumbnail-replacement justify-center align-center d-flex"
    >
      <!--      <div class="blur-area"></div>-->
      <channel-avatar
        :thumbnails="channelThumbnails"
        :channel-name="channelName"
        :size="130"
        bg-color="secondary"
        class="d-flex justify-center align-center"
        style="position:absolute;"
        elevation
      ></channel-avatar>
      <div class="blur-area-front"></div>
      <!--      <v-img max-width="30%" src="/favicon.png"></v-img>-->
    </div>
    <v-fade-transition>
      <div
        class="d-flex justify-center align-center fill-height transition-ease-in-out"
        style="z-index: 20"
      >
        <v-btn
          v-if="hover"
          fab
          dark
          color="primary"
          style="opacity: 0.8;"
          depressed
        >
          <v-icon large>mdi-play</v-icon>
        </v-btn>
      </div>
    </v-fade-transition>
    <div
      v-if="inSecondDuration && !live && inSecondDuration > 0"
      class="video-duration"
      :class="{ large: large }"
    >
      {{ formatDuration(inSecondDuration) }}
    </div>
    <v-progress-linear
      v-if="progress > 0"
      absolute
      :value="progress"
      bottom
      height="2px"
      background-opacity="0"
    ></v-progress-linear>
    <v-chip
      v-if="censored"
      :color="hover ? 'primary darken-1' : 'primary'"
      class="ml-2 thumbnail-censored text-uppercase"
      x-small
      label
      link
    >
      <v-icon left x-small>mdi-alert-octagon</v-icon>
      {{ $tc('censored.censored-fem') }}
    </v-chip>
    <v-chip
      v-if="live"
      :color="'error'"
      class="ml-1 thumbnail-live text-uppercase px-1 font-weight-bold"
      x-small
      label
      link
    >
      {{ $tc('label.on-the-air') }}
    </v-chip>
  </v-img>
</template>

<script>
import ChannelAvatar from '@/components/ChannelAvatar'
export default {
  name: 'VideoThumbnail',
  components: { ChannelAvatar },
  props: {
    thumbnails: {
      type: [String, Array],
      default: () => [],
    },
    inSecondDuration: Number,
    inSecondLastStop: { type: Number, default: 0 },
    hover: {
      type: Boolean,
      default: false,
    },
    censored: {
      type: Boolean,
      default: false,
    },
    live: {
      type: Boolean,
      default: false,
    },
    liveType: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    video: {
      type: Object,
      default: null,
    },
  },
  computed: {
    // seems unused
    randomGradiant() {
      const hexValues = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'a',
        'b',
        'c',
        'd',
        'e',
      ]

      function populate(a) {
        for (let i = 0; i < 6; i++) {
          const x = Math.round(Math.random() * 14)
          const y = hexValues[x]
          a += y
        }
        return a
      }

      const newColor1 = populate('#')
      const newColor2 = populate('#')
      const angle = Math.round(Math.random() * 360)

      return (
        'linear-gradient(' +
        angle +
        'deg, ' +
        newColor1 +
        ', ' +
        newColor2 +
        ')'
      )
    },
    channelThumbnails() {
      if (this.video === null || typeof this.video.channel !== 'object') {
        return []
      }
      if (this.video.channel && this.video.channel.organization) {
        return this.video.channel.organization.thumbnails
      }
      return this.video.channel.thumbnails || []
    },
    channelName() {
      if (this.video === null || typeof this.video.channel !== 'object') {
        return ''
      }
      if (this.video?.channel?.organization?.name) {
        return this.video.channel.organization.name
      }
      return this.video.channel.name
    },
    progress() {
      if (this.inSecondDuration === 0) {
        return 0
      }
      return (this.inSecondLastStop / this.inSecondDuration) * 100
    },
  },
  methods: {
    getVideoThumbnail() {
      if (typeof this.thumbnails === 'string') {
        return {
          url: this.thumbnails,
        }
      }
      const name = this.large ? 'standard' : 'high'
      return this.thumbnails.find(thumbnail => thumbnail.name === name) || {}
    },
    getTempVideoThumbnail() {
      if (typeof this.thumbnails === 'string') {
        return {
          url: this.thumbnails,
        }
      }
      return (
        this.thumbnails.find(thumbnail => thumbnail.name === 'default') || {}
      )
    },
    formatDuration(seconds) {
      return new Date(1000 * seconds)
        .toISOString()
        .substr(11, 8)
        .replace(/^0(0:)?0?/, '')
    },
  },
}
</script>

<style scoped lang="scss">
.video-duration {
  position: absolute;
  bottom: 3px;
  right: 3px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 1px 5px;
  line-height: 1rem;
  font-size: 0.9rem;
  &.large {
    line-height: 1.2rem;
    font-size: 1.1rem;
  }
}

.thumbnail-replacement {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: radial-gradient(#2c5172, #091e37);
  .blur-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.15);
  }
  .blur-area-front {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(4px);
  }
  ::v-deep {
    .avatar {
      max-width: 60%;
      min-width: 0 !important;
      height: auto !important;
    }
  }
}

.thumbnail-censored {
  position: absolute;
  top: 2px;
  right: 3px;
}

.thumbnail-live {
  position: absolute;
  top: 2px;
  left: 3px;
  line-height: 9px;
}

.thumbnail-img {
  cursor: pointer;
}
</style>
