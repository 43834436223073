var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.hasError)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"overflow-hidden pb-3",staticStyle:{"min-height":"100px"},attrs:{"loading":_vm.itemsLoading,"flat":""}},[(!_vm.itemsLoading || _vm.list.name)?[_c('v-card-title',[_c('h3',{staticClass:"text-h5"},[_c('router-link',{staticClass:"title-link enabled",attrs:{"to":{ name: 'List', params: { slug: _vm.list.uid } }}},[_vm._v("\n              "+_vm._s(_vm.list.name)+"\n            ")])],1)]),_vm._v(" "),_c('v-card-subtitle',[_c('div',{staticClass:"mt-1 font-weight-light d-flex"},[_c('div',{staticClass:"flex-grow-1 text-truncate"},[_c(_vm.list.isOwnedByAChannel ? 'router-link' : 'span',{tag:"component",staticClass:"title-link",class:{ enabled: _vm.list.isOwnedByAChannel },attrs:{"to":{
                  name: 'OrganizationLists',
                  params: { slug: _vm.list.authorUid },
                }}},[_vm._v("\n                "+_vm._s(_vm.list.authorName)+"\n              ")])],1),_vm._v(" "),_c('div',{staticClass:"text-no-wrap"},[_c('v-icon',{attrs:{"size":"20","title":_vm.getVisibilityText(_vm.list.visibility)},domProps:{"textContent":_vm._s(_vm.getVisibilityIcon(_vm.list.visibility))}}),_vm._v("\n              "+_vm._s(_vm.list.itemsCount)+"\n            ")],1)])]),_vm._v(" "),_c('v-card-text',{staticClass:"px-0 pb-0"},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-list',{staticClass:"overflow-y-auto",attrs:{"max-height":"400px"}},[_c('div',[(_vm.hasPrevious)?_c('v-btn',{directives:[{name:"intersect",rawName:"v-intersect",value:({
                      handler: () => _vm.fetchListItems('previous'),
                      rootMargin: '0px 0px 300px 0px',
                    }),expression:"{\n                      handler: () => fetchListItems('previous'),\n                      rootMargin: '0px 0px 300px 0px',\n                    }"}],attrs:{"text":"","block":"","small":"","color":"primary","loading":_vm.itemsLoading},on:{"click":function($event){return _vm.fetchListItems('previous')}}},[_vm._v("\n                    "+_vm._s(_vm.$t('button.see-more'))+"\n                  ")]):_vm._e()],1),_vm._v(" "),_vm._l((_vm.items),function(item){return _c('v-hover',{key:item.post.uid,scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',{staticClass:"px-1 py-1 list-item",attrs:{"to":_vm.getLink(item),"three-line":""}},[_c('div',{staticClass:"mr-2"},[_vm._v("\n                      "+_vm._s(_vm.getItemPosition(item))+"\n                    ")]),_vm._v(" "),_c('div',{staticClass:"rounded overflow-hidden",staticStyle:{"width":"120px"}},[_c('post-preview',{attrs:{"post":item.post,"show-duration":""}})],1),_vm._v(" "),_c('v-list-item-content',{staticClass:"pl-2 py-1 align-self-baseline"},[_c('v-list-item-subtitle',{staticClass:"white--text font-weight-bold"},[(item.post.video)?_c('span',{attrs:{"title":item.post.video.title}},[_vm._v(_vm._s(item.post.video.title))]):_c('span',[_vm._v("\n                          "+_vm._s(item.post.textContent)+"\n                        ")])]),_vm._v(" "),_c('div',{staticClass:"mt-1 font-weight-light text-truncate"},[_vm._v("\n                        "+_vm._s(item.post.authorName)+"\n                      ")])],1),_vm._v(" "),_c('v-list-item-action',{staticStyle:{"min-width":"28px"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}]},[_c('post-menu',{attrs:{"post":item.post,"in-list":_vm.list},on:{"itemRemovedFromList":_vm.itemRemovedFromList}})],1)])],1)]}}],null,true)})}),_vm._v(" "),_c('div',[(_vm.hasNext)?_c('v-btn',{directives:[{name:"intersect",rawName:"v-intersect",value:({
                      handler: () => _vm.fetchListItems('next'),
                      rootMargin: '300px 0px 0px 0px',
                    }),expression:"{\n                      handler: () => fetchListItems('next'),\n                      rootMargin: '300px 0px 0px 0px',\n                    }"}],attrs:{"text":"","block":"","small":"","color":"primary","loading":_vm.itemsLoading},on:{"click":function($event){return _vm.fetchListItems('next')}}},[_vm._v("\n                    "+_vm._s(_vm.$t('button.see-more'))+"\n                  ")]):_vm._e()],1)],2)],1)],1)],1)]:_vm._e()],2)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }