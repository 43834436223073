<template>
  <v-col cols="6" sm="3" v-bind="$attrs">
    <div class="secondary d-flex flex-column justify-center rounded pa-2">
      <v-icon>mdi-{{ icon }}</v-icon>
      <div class="text-center mt-2 caption">
        <slot></slot>
      </div>
    </div>
  </v-col>
</template>

<script>
export default {
  name: 'IconBlock',
  props: {
    icon: String,
    text: String,
  },
}
</script>

<style scoped lang="scss">
//.caption {
//  font-family: 'Montserrat', sans-serif !important;
//}
</style>
