<template>
  <div :class="{ large: large }">
    <component
      :is="component"
      :href="link"
      target="_blank"
      class="text-decoration-none d-block"
      @click="onClickComponent"
    >
      <div
        v-if="format === 'horizontal'"
        class="snippet-block overflow-hidden"
        :class="{
          preview: preview,
          'rounded-b-lg': !large,
          'rounded-lg': large,
        }"
      >
        <div class="d-flex flex-nowrap snippet-wrapper">
          <div class="flex-shrink-0" style="width: 120px">
            <v-img
              :src="imageUrl !== null ? imageUrl : 'https://aaa.aaa.commm/x'"
              :lazy-src="imageUrl ?? undefined"
              :aspect-ratio="1"
              width="100%"
              max-width="100%"
              style="position: relative;"
              :key="imageUrl"
              @error="imageError = true"
            >
              <template #placeholder>
                <div
                  v-if="linkMetadata && (imageUrl === null || imageError)"
                  class="d-flex justify-center align-center fill-height secondary lighten-1"
                >
                  <v-icon>mdi-link</v-icon>
                </div>
                <v-skeleton-loader
                  v-else-if="preview"
                  type="image"
                  height="100%"
                  width="100%"
                  style="position: absolute"
                ></v-skeleton-loader>
              </template>
            </v-img>
          </div>
          <div class="flex-grow-1 px-2 pt-1 text-truncate">
            <div class="d-flex" style="width: 100%;max-width: 100%">
              <div class="flex-grow-1 text-truncate">
                <div class="snippet-title">
                  <span v-if="linkMetadata">
                    {{ linkMetadata.title }}
                  </span>
                  <v-skeleton-loader v-else type="heading"></v-skeleton-loader>
                </div>
                <div class="description mt-1">
                  <span v-if="linkMetadata">
                    {{ linkMetadata.description }}
                  </span>
                  <v-skeleton-loader
                    v-else
                    type="text"
                    class="mt-1"
                  ></v-skeleton-loader>
                </div>
                <div class="mt-1 site-name">
                  <span v-if="linkMetadata" class="d-inline-flex align-center">
                    {{ linkMetadata.siteName }}
                    <site-badge v-if="siteBadge" :site-badge="siteBadge">
                    </site-badge>
                  </span>
                  <v-skeleton-loader
                    v-else
                    type="text"
                    width="50%"
                  ></v-skeleton-loader>
                </div>
              </div>
              <v-btn
                v-if="preview"
                class="flex-shrink-0 ml-3"
                color="primary"
                icon
                outlined
                @click="$emit('cancel')"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="snippet-block card-block rounded-lg overflow-hidden">
        <v-responsive
          v-if="loadEmbed && large && linkMetadata && linkMetadata.videoUrl"
          :aspect-ratio="16 / 9"
        >
          <iframe
            width="100%"
            height="100%"
            :src="formatVideoUrl(linkMetadata.videoUrl)"
            frameborder="0"
            allow="autoplay; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            scrolling="no"
          ></iframe>
        </v-responsive>

        <div v-else class="d-flex flex-column snippet-wrapper">
          <div class="flex-shrink-0 card-image-wrapper">
            <v-img
              :src="imageUrl !== null ? imageUrl : 'https://aaa.aaa.commm/x'"
              :lazy-src="imageUrl ?? undefined"
              :aspect-ratio="16 / 9"
              width="100%"
              max-width="100%"
              style="position: relative;"
              :key="imageUrl"
              @error="imageError = true"
              @click="onClickImage"
            >
              <template #placeholder>
                <div
                  v-if="linkMetadata && (imageUrl === null || imageError)"
                  class="d-flex justify-center align-center fill-height secondary lighten-1"
                >
                  <v-icon>mdi-link</v-icon>
                </div>
                <v-skeleton-loader
                  v-else-if="preview"
                  type="image"
                  height="100%"
                  width="100%"
                  style="position: absolute"
                ></v-skeleton-loader>
              </template>
              <template #default>
                <v-fade-transition>
                  <div
                    class="d-flex justify-center align-center fill-height transition-ease-in-out"
                    style="z-index: 20"
                  >
                    <v-btn
                      fab
                      dark
                      color="primary"
                      style="opacity: 0.8;"
                      depressed
                      class="play-btn"
                    >
                      <v-icon large>mdi-play</v-icon>
                    </v-btn>
                  </div>
                </v-fade-transition>
              </template>
            </v-img>
            <div class="video-duration" v-if="getDuration()">
              {{ getDuration() }}
            </div>
          </div>
          <div class="flex-grow-1 px-2 pt-1 text-truncate card-text-wrapper">
            <div class="d-flex" style="width: 100%;max-width: 100%">
              <div class="flex-grow-1 text-truncate">
                <div class="snippet-title">
                  <span v-if="linkMetadata">
                    {{ linkMetadata.title }}
                  </span>
                  <v-skeleton-loader v-else type="heading"></v-skeleton-loader>
                </div>
                <div class="description">
                  <span v-if="linkMetadata">
                    {{ linkMetadata.description }}
                  </span>
                  <v-skeleton-loader
                    v-else
                    type="text"
                    class="mt-1"
                  ></v-skeleton-loader>
                </div>
                <div class="site-name">
                  <span v-if="linkMetadata" class="d-inline-flex align-center">
                    {{ linkMetadata.siteName }}
                    <site-badge v-if="siteBadge" :site-badge="siteBadge">
                    </site-badge>
                  </span>
                  <v-skeleton-loader
                    v-else
                    type="text"
                    width="50%"
                  ></v-skeleton-loader>
                  <template v-if="linkMetadata">
                    <span v-if="linkMetadata.videoSeries">
                      {{ linkMetadata.videoSeries }}
                    </span>
                  </template>
                </div>
              </div>
              <v-btn
                v-if="preview"
                class="flex-shrink-0 ml-3"
                color="primary"
                icon
                outlined
                @click="$emit('cancel')"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </component>
  </div>
</template>

<script>
import { utilsService } from '@/services/utils.service'
import SiteBadge from '@/components/SiteBadge'

export default {
  name: 'Snippet',
  components: { SiteBadge },
  props: {
    linkMetadata: { type: Object, default: null },
    horizontal: { type: Boolean, default: true },
    preview: { type: Boolean },
    large: Boolean,
    post: { type: Object, default: null },
  },
  data() {
    return {
      imageError: false,
      loadEmbed: false,
    }
  },
  computed: {
    imageUrl() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.imageError = false
      if (this.linkMetadata && this.linkMetadata.thumbnailUrl) {
        return this.linkMetadata.thumbnailUrl
      }
      if (this.linkMetadata === null || !this.linkMetadata.images?.length) {
        return null
      }
      const imageName =
        this.large || this.linkMetadata.videoUrl !== null ? 'default' : 'tiny'
      const list = this.linkMetadata.images.filter(image => {
        return image.name === imageName
      })
      if (list.length > 0) {
        return list[0].url
      }
      return null
    },
    format() {
      if (this.preview) {
        return 'horizontal'
      }
      const isVideo =
        this.linkMetadata !== null && this.linkMetadata.videoUrl !== null
      if (isVideo && this.imageUrl !== null) {
        return 'card'
      }
      return 'horizontal'
    },
    link() {
      if (this.linkMetadata === null) {
        return null
      }
      // if (this.post && this.linkMetadata.videoUrl) {
      //   return (
      //     window.location.origin +
      //     this.$router.resolve({
      //       name: 'Post',
      //       params: { slug: this.post.uid, post: this.post },
      //     }).href
      //   )
      // }
      return `https://${this.linkMetadata.url}`
    },
    siteBadge() {
      switch (this.linkMetadata.host) {
        case 'crowdbunker.com':
          return {
            icon: 'mdi-shield-check',
            color: 'success',
          }
        case 'www.youtube.com':
        case 'twitter.com':
          return {
            text: '🤮',
          }
      }
      return null
    },
    component() {
      return this.preview ||
        (!this.large && this.linkMetadata && this.linkMetadata.videoUrl)
        ? 'div'
        : 'a'
    },
    linkTarget() {
      if (this.link === null) {
        return '_blank'
      }
      if (typeof this.link === 'object') {
        return '_self'
      }
      const url = new URL(this.link)
      if (url.hostname.match(/crowdbunker\.com$/)) {
        return '_self'
      }
      return '_blank'
    },
  },
  methods: {
    getDuration() {
      if (this.linkMetadata.videoDuration) {
        return utilsService.formatDuration(this.linkMetadata.videoDuration)
      }
      return null
    },
    onClickImage(event) {
      if (this.large && this.linkMetadata.videoUrl) {
        this.loadEmbed = true
        event.stopPropagation()
        event.preventDefault()
      }
    },
    onClickComponent(event) {
      if (this.component === 'a') {
        event.stopPropagation()
      }
    },
    formatVideoUrl(link) {
      const url = new URL(link)
      url.searchParams.set('autoplay', 1)
      return url.href
    },
  },
  watch: {
    format(value) {
      this.$emit('format', value)
    },
  },
  mounted() {
    this.$emit('format', this.format)
    if (this.$route.params.post) {
      this.loadEmbed = true
    }
  },
}
</script>

<style scoped lang="scss">
.snippet-block {
  position: relative;
  font-size: 14px;
}
.snippet-wrapper {
  background-color: var(--v-secondary-base);
}
.snippet-title {
  font-weight: bold;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.description {
  color: #fff;
  white-space: initial;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
.card-block {
  .description {
    -webkit-line-clamp: 1;
  }
}
.card-text-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: var(--v-secondary-base);
  opacity: 0.95;
}
.large {
  .description {
    -webkit-line-clamp: 3;
    margin-top: 3px;
  }
  .site-name {
    margin-top: 3px;
  }
  .card-text-wrapper {
    position: relative;
  }
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .description {
    -webkit-line-clamp: 3;
  }
  .preview {
    .description {
      -webkit-line-clamp: 1 !important;
    }
  }
}
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .card-image-wrapper {
    width: 100%;
    .play-btn {
      display: none;
    }
    &:hover {
      .play-btn {
        display: block;
      }
    }
  }
  .large {
    .card-block {
      padding-left: 30px;
      padding-right: 5px;
      &:after {
        content: '';
        position: absolute;
        left: 15px;
        top: 0;
        height: 100%;
        width: 3px;
        border-left: var(--v-primary-base) solid 3px;
        background-color: var(--v-primary-base);
      }
    }
  }
}
.site-name {
  color: #fff;
  opacity: 0.7;
  span:not(:first-child):not(.d-flex):before {
    content: ' \002022  ';
    margin-left: 2px;
  }
}
.video-duration {
  position: absolute;
  top: 3px;
  right: 3px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 1px 5px;
  line-height: 1rem;
  font-size: 0.9rem;
  color: white;
  &.large {
    line-height: 1.2rem;
    font-size: 1.1rem;
  }
}
</style>
