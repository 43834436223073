<template>
  <v-row v-if="fund.title" justify="center" class="pt-3 fund">
    <v-col style="max-width: 700px; min-width: 300px">
      <div class="fund-title font-weight-bold">
        {{ fund.title }}
        <v-chip class="ma-2" color="success" label text-color="white" x-small>
          <v-icon left x-small>
            mdi-check-decagram
          </v-icon>
          {{ $t('label.verified') }}
        </v-chip>
      </div>
      <v-img
        :src="imageUrl"
        :aspect-ratio="16 / 9"
        class="mt-2 rounded"
      ></v-img>
      <div
        class="mt-3"
        v-if="fund.descriptionHtml && $vuetify.breakpoint.smAndUp"
      >
        <fund-description
          :html-content="fund.descriptionHtml"
        ></fund-description>
      </div>
    </v-col>

    <v-col class="right-column">
      <div :class="{ sticky: !$vuetify.breakpoint.mobile }">
        <v-btn
          color="success"
          x-large
          :class="{ sticky: $vuetify.breakpoint.mobile }"
          style="width: 100%;z-index: 2;"
          @click="paymentDialog = true"
          :disabled="ended"
          rounded
          depressed
          >{{ $t('button.make-a-donation') }}</v-btn
        >
        <v-card class="mt-2" flat>
          <v-card-text>
            <div class="d-flex align-center white--text">
              <div class="text-h4 font-weight-bold">
                {{ formattedFund.currentAmountCents }}
              </div>
              <div class="ml-auto">
                {{ formattedFund.paymentCount }}
                {{ $tc('donation.participation', formattedFund.paymentCount) }}
              </div>
            </div>
            <template v-if="fund.goalAmountCents > 0">
              <div class="mt-1">
                {{ $tc('donation.goal') | capitalize }} :
                {{ formattedFund.goalAmountCents }}
              </div>
              <v-progress-linear
                :value="formattedFund.percentage"
                color="success"
                height="15"
                class="mt-2"
                >{{ formattedFund.percentage }}%</v-progress-linear
              >
            </template>

            <div class="mt-2 d-flex align-center">
              <div v-show="!ended">{{ $t('time.time-remaining') }} :</div>
              <div class="d-flex justify-end flex-grow-1 pt-1">
                <campaign-countdown
                  :end-date="formattedFund.endAt"
                  x-small
                  @end="countdownEnded = true"
                ></campaign-countdown>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <div
          class="mt-3"
          v-if="fund.descriptionHtml && $vuetify.breakpoint.xsOnly"
        >
          <fund-description
            :html-content="fund.descriptionHtml"
          ></fund-description>
        </div>
        <v-card class="mt-2" v-if="fundPayments.length" flat>
          <v-list two-line>
            <v-list-item v-for="(payment, i) in formattedFundPayments" :key="i">
              <v-list-item-avatar
                color="primary"
                size="45"
                class="justify-center"
              >
                <div class="avatar-amount-text">
                  {{ payment.amountInteger.replace(/ [€$]/g, '') }}
                </div>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{
                  $t('label.anonymous') | capitalize
                }}</v-list-item-title>

                <v-list-item-subtitle
                  >{{ payment.amount }} •
                  {{ payment.date }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </div>
    </v-col>
    <v-dialog v-model="paymentDialog" max-width="500px">
      <fund-payment :fund="fund" @close="paymentDialog = false"></fund-payment>
    </v-dialog>
  </v-row>
</template>

<script>
import CampaignCountdown from '@/components/CampaignCountdown.vue'
import { utilsService } from '@/services/utils.service'
import FundPayment from '@/components/fund/FundPayment.vue'
import { requestService } from '@/services/request.service'
import { createClient } from '@supabase/supabase-js'
import FundDescription from '@/components/fund/FundDescription.vue'

export default {
  name: 'FundPage',
  components: { FundDescription, FundPayment, CampaignCountdown },
  metaInfo() {
    const description = this.fund.description
      ? this.fund.description.substring(0, 160) + '...'
      : ''
    return {
      title: `${this.fund.title} - CrowdBunker`,
      meta: [
        {
          name: 'description',
          vmid: 'description',
          content: description,
        },
        {
          property: 'og:description',
          vmid: 'og:description',
          content: description,
        },
        {
          property: 'og:title',
          vmid: 'og:title',
          content: `${this.fund.title} - CrowdBunker`,
        },
        {
          property: 'twitter:title',
          vmid: 'twitter:title',
          content: `${this.fund.title} - CrowdBunker`,
        },
        {
          property: 'og:image',
          vmid: 'og:image',
          content: this.imageUrl,
        },
        {
          property: 'twitter:image',
          vmid: 'twitter:image',
          content: this.imageUrl,
        },
        {
          property: 'og:site_name',
          vmid: 'og:site_name',
          content: 'CrowdBunker',
        },
        { property: 'og:type', vmid: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    }
  },
  data() {
    return {
      paymentDialog: false,
      fund: {},
      fundPayments: [],
      iframeHeight: 0,
      countdownEnded: false,
    }
  },
  computed: {
    formattedFund() {
      return {
        ...this.fund,
        goalAmountCents: utilsService.formatCurrency(
          this.fund.goalAmountCents / 100,
        ),
        currentAmountCents: utilsService.formatCurrency(
          this.fund.currentAmountCents / 100,
        ),
        endAt: new Date(this.fund.endAt),
        percentage: (
          (this.fund.currentAmountCents / this.fund.goalAmountCents) *
          100
        ).toFixed(2),
      }
    },
    formattedFundPayments() {
      return this.fundPayments.map(fundPayment => ({
        ...fundPayment,
        date: utilsService.formatDateRelative(fundPayment.created_at),
        amount: utilsService.formatCurrency(fundPayment.amount_cents / 100, 2),
        amountInteger: utilsService.formatCurrency(
          Math.floor(fundPayment.amount_cents / 100),
        ),
      }))
    },
    imageUrl() {
      if (!this.fund.defaultThumbnail) {
        return 'https://crowdbunker.com/images/fund/liberons-nous.png'
      }
      return this.fund.defaultThumbnail.startsWith('http')
        ? this.fund.defaultThumbnail
        : window.location.origin + this.fund.defaultThumbnail
    },
    ended() {
      return this.countdownEnded || this.formattedFund.endAt < new Date()
    },
  },
  methods: {
    fetchFundData() {
      this.$store.state.loading = true
      requestService
        .get(`/fund/${this.$route.params.slug}`)
        .then(response => {
          this.fund = response
          this.initRealtime()
        })
        .catch(error => {
          console.log(error)
          if (error.status === 404) {
            this.$router.push({ name: 'PageNotFound' })
          }
        })
        .finally(() => {
          this.$store.state.loading = false
        })
    },
    async initRealtime() {
      const supabase = createClient(
        process.env.VUE_APP_SUPABASE_URL,
        process.env.VUE_APP_SUPABASE_KEY,
      )
      const channel = supabase
        .channel('table-db-changes')
        .on(
          'postgres_changes',
          {
            event: 'UPDATE',
            schema: 'public',
            table: 'Fund',
            filter: `id=eq.${this.fund.uniqueId}`,
          },
          this.handleFundUpdated,
        )
        .on(
          'postgres_changes',
          {
            event: 'INSERT',
            schema: 'public',
            table: 'FundPayment',
          },
          this.handleFundPaymentInserted,
        )

      channel.subscribe(async status => {
        // if (status === 'SUBSCRIBED') {
        //
        // }
      })

      const { data, error } = await supabase.from('fund_payments').select()

      this.fundPayments = data || []
    },
    handleFundUpdated(data) {
      const fund = data.new
      this.fund = {
        ...this.fund,
        currentAmountCents: fund.current_amount_cents,
        goalAmountCents: fund.goal_amount_cents,
        paymentCount: fund.payment_count,
        percentage: fund.percentage,
        endAt: fund.end_at,
      }
    },
    handleFundPaymentInserted(data) {
      const fundPayment = data.new
      if (Array.isArray(this.fundPayments)) {
        this.fundPayments.unshift(fundPayment)
        this.fundPayments = this.fundPayments.slice(0, 5)
      }
    },
  },
  created() {
    this.fetchFundData()
  },
}
</script>

<style scoped lang="scss">
.fund {
  .fund-title {
    font-size: 2rem;
    font-family: 'Montserrat', sans-serif;
    line-height: 3.25rem;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .right-column {
    max-width: 400px;
  }
}

.sticky {
  position: sticky;
  top: 3.5rem;
}

@mixin boxShadow($colour, $length) {
  $value: ();
  @for $i from 1 through $length {
    $px: $i + px;
    $shadow: $px $px $colour;
    $value: append($value, $shadow, comma);
  }
  text-shadow: $value;
}

.avatar-amount-text {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1rem;
  @include boxShadow(#7a1539, 25);
}
</style>
