<template>
  <div>
    <div v-if="loggedIn" class="d-flex">
      <div
        v-if="senderOrganization === null"
        class="pt-5"
        @click="onBehalfMenu = true"
      >
        <v-menu v-model="onBehalfMenu" offset-y bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="select-behalf">
              <channel-avatar
                :thumbnails="onBehalf ? onBehalf.thumbnails : []"
                :size="30"
                :channel-name="onBehalfName"
                :bg-color="
                  onBehalf && onBehalf.avatarColor
                    ? onBehalf.avatarColor
                    : 'primary'
                "
                class="mr-3"
              ></channel-avatar>
              <v-icon small class="select-behalf-icon"
                >mdi-chevron-down-circle
              </v-icon>
            </div>
          </template>
          <v-card>
            <v-card-text v-if="organizations.length > 3">
              <v-text-field
                v-model="onBehalfSearch"
                :label="$tc('label.search') | capitalize"
                flat
                solo-inverted
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
                @click.stop.prevent
                dense
                autofocus
              ></v-text-field>
            </v-card-text>
            <div style="max-height: 200px;overflow-y: auto" class="pa-2">
              <v-treeview
                :active.sync="onBehalfSelect"
                :items="onBehalfList"
                activatable
                item-key="id"
                open-on-click
                open-all
                dense
                :search="onBehalfSearch"
              >
                <template v-slot:prepend="{ item }">
                  <channel-avatar
                    v-if="item.children === undefined"
                    :thumbnails="item.thumbnails ? item.thumbnails : []"
                    :size="30"
                    :channel-name="item.name"
                    :bg-color="
                      item && item.avatarColor ? item.avatarColor : 'primary'
                    "
                    class="mr-3"
                  ></channel-avatar>
                </template>
              </v-treeview>
            </div>
          </v-card>
        </v-menu>
      </div>
      <div class="flex-grow-1 text-caption">
        <div>
          {{ $tc('chat.write-as') }}
          <b>{{ onBehalfName }}</b>
        </div>
        <textarea-field
          solo-inverted
          flat
          :placeholder="$tc('chat.comment-freely')"
          background-color="secondary lighten-2"
          auto-grow
          :counter="200"
          :field="form.textContent"
          rows="1"
          dense
          @keydown.enter.prevent.stop="submit"
          class="text-field"
          ref="textField"
        >
          <template #append>
            <v-btn
              small
              icon
              @click="submit"
              :loading="submitLoading"
              :disabled="form.textContent.value.length === 0"
            >
              <v-icon small>mdi-send</v-icon>
            </v-btn>
          </template>
          <template #append-outer> </template>
        </textarea-field>
        <v-menu
          offset-y
          top
          right
          nudge-top="40px"
          :close-on-content-click="false"
          @input="menuEmoji = $event"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="grey"
              icon
              v-bind="attrs"
              v-on="on"
              @click="keepFocus"
              class="mt-n10 mb-0"
            >
              <v-icon v-if="menuEmoji">mdi-emoticon-tongue</v-icon>
              <v-icon v-else>mdi-emoticon-excited</v-icon>
            </v-btn>
          </template>
          <EmojiPicker
            v-if="$refs.textField && $refs.textField.$refs.input"
            :text.sync="form.textContent.value"
            :field-node="$refs.textField.$refs.input.$refs.input"
          />
        </v-menu>
        <div v-show="unhandledErrors">
          <template v-for="(errorField, index) of unhandledErrors">
            <div
              v-for="(error, i) of errorField"
              :key="index + i"
              class="error--text"
            >
              {{ error }}
            </div>
          </template>
        </div>
      </div>
    </div>
    <div v-else class="text-center secondary lighten-1 mb-2">
      <router-link :to="{ name: 'Login' }">
        {{ $tc('chat.connect') }}
      </router-link>
      {{ ' ' + $tc('label.or') }}
      <router-link :to="{ name: 'Register' }">
        {{ $tc('label.register-yourself') }}
      </router-link>
      {{ ' ' + $tc('chat.to-comment') }}
    </div>
  </div>
</template>

<script>
import TextareaField from '@/components/form/TextareaField'
import { requestService } from '@/services/request.service'
import ChannelAvatar from '@/components/ChannelAvatar'
import Validation from '@/components/mixins/Validation'
import EmojiPicker from '@/components/form/EmojiPicker'

export default {
  name: 'ChatMessageForm',
  components: { EmojiPicker, ChannelAvatar, TextareaField },
  props: {
    video: { type: Object, required: true },
    senderOrganization: { type: Object, default: null },
  },
  mixins: [Validation],
  data() {
    return {
      form: {
        textContent: {
          value: '',
          maxLength: 200,
          // required: true,
        },
        organizationId: {
          value: null,
        },
        videoUniqueKey: {
          value: null,
          required: true,
        },
      },
      organizations: [],
      currentOrganization: null,
      onBehalfMenu: false,
      onBehalfSelect: [],
      onBehalfSearch: '',
      submitLoading: false,
      error: null,
      menuEmoji: false,
    }
  },
  computed: {
    onBehalfName() {
      if (this.onBehalf) {
        return this.onBehalf.name
      }
      return this.$store.state.account.user.user.username
    },
    onBehalfList() {
      const list = [
        {
          name: this.$store.state.account.user.user.username,
          id: null,
        },
      ]
      if (this.organizations.length) {
        list.push({
          name: 'Organisations',
          children: this.organizations,
        })
      }
      return list
    },
    onBehalf() {
      return this.organizations.find(
        organization => organization.id === this.form.organizationId.value,
      )
    },
    loggedIn() {
      return !!this.$store.state.account.status.loggedIn
    },
  },
  methods: {
    async fetchInternalChannels() {
      this.datasLoading = true
      requestService
        .get('/organization/mine')
        .then(response => {
          this.organizations = response.items
          this.$store.commit('account/organizations', response.items)
          this.autoSelectChannel()
        })
        .finally(() => (this.datasLoading = false))
    },
    autoSelectChannel() {
      if (this.senderOrganization) {
        this.form.organizationId.value = this.senderOrganization.id
        return
      }
      const postOnBehalf = localStorage.getItem('postOnBehalf')
      if (postOnBehalf && this.organizations.length) {
        const onBehalf = JSON.parse(postOnBehalf)
        const organization = this.organizations.find(
          organization => organization.id === onBehalf,
        )
        if (organization === undefined) {
          this.form.organizationId.value = null
        } else {
          this.form.organizationId.value = onBehalf
        }
        return
      }
      if (this.organizations.length) {
        this.form.organizationId.value = this.organizations[0].id
      }
    },
    submit() {
      this.error = null
      this.formValidation(this.form, true)
      if (this.errorsNbr > 0 || this.submitLoading) {
        return
      }
      this.submitLoading = true
      const url = '/chat/publish'

      const data = this.formatDataToSend(this.form)
      if (this.form.organizationId.value === null) {
        delete data.organizationId
      }
      requestService
        .post(url, data)
        .then(response => {
          if (response.success) {
            this.form.textContent.value = ''
            this.$emit('created', response)
          } else {
            // this.$set(this.unhandledErrors, 'error', [response.message])
            this.$set(this.form.textContent, 'errors', [response.message])
          }
        })
        .catch(response => {
          console.log('error response', response)
          this.submitLoading = false
          const { error, validationFailed } = response
          if (validationFailed) {
            this.handleServerErrors(error, this.form)
          } else if (error.detail) {
            this.error = error.detail
          }
        })
        .finally(() => (this.submitLoading = false))
    },
    keepFocus() {
      this.$nextTick(() => this.$refs.textField.$refs.input.focus())
    },
  },
  watch: {
    onBehalfSelect(value) {
      this.form.organizationId.value = value[0]
      localStorage.setItem('postOnBehalf', JSON.stringify(value[0]))
    },
    senderOrganization(value) {
      if (value) {
        this.form.organizationId.value = value.id
        this.currentOrganization = value
      }
    },
  },
  created() {
    this.form.videoUniqueKey.value = this.video.uniqueKey
  },
  mounted() {
    if (this.loggedIn) {
      const storedOrganizations = this.$store.state.account.organizations
      if (storedOrganizations) {
        this.organizations = storedOrganizations
      }
      this.autoSelectChannel()
      this.fetchInternalChannels()
      this.$root.$on('channelCreated', () => {
        this.$emit('rerender')
      })
    }
  },
}
</script>

<style scoped lang="scss">
.select-behalf {
  position: relative;
  cursor: pointer;

  .select-behalf-icon {
    position: absolute;
    bottom: 0;
    right: 8px;
    filter: url(#icon-outline);
  }
}

::v-deep {
  .v-treeview-node__prepend {
    min-width: 0;
  }
  .v-input__append-inner {
    margin-top: 6px !important;
  }

  .text-field {
    .v-input__append-outer {
      margin-top: 2px !important;
    }
    .v-messages__message {
      padding-left: 20px;
    }
  }
}
</style>
