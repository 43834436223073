<template>
  <div>
    <component
      :is="component"
      :aspect-ratio="fromList ? 16 / 9 : 320 / 215"
      max-height="100%"
      class="overflow-hidden align-center rounded-lg"
      :class="{ responsive: !large, 'secondary lighten-1': !large }"
      style="position:relative;"
    >
      <div
        class="pa-4 pt-0 d-flex justify-center text-pre-wrap"
        :class="{
          'align-center': !isBigTest && !noCenter,
          'text-center': !isBigTest,
          'text-wrapper': large,
        }"
        style="height:100%;"
        :style="{ fontSize: fontSize }"
      >
        <span
          v-html="formattedText"
          class="user-text"
          @click="textClick"
          :style="{
            width: isBigTest ? '100%' : 'none',
          }"
        ></span>
      </div>
      <a
        v-if="idState.textTooLong && large"
        class="read-more px-4 text-right white--text"
        @click.stop.prevent="readMore"
        href="#"
      >
        {{
          idState.truncate
            ? $tc('label.read-more')
            : $tc('label.reduce') | capitalize
        }}
      </a>
    </component>
  </div>
</template>

<script>
import { VResponsive } from 'vuetify/lib'
import { utilsService } from '@/services/utils.service'
import { IdState } from 'vue-virtual-scroller'

export default {
  name: 'TextPostInset',
  components: { VResponsive },
  mixins: [
    IdState({
      // You can customize this
      idProp: vm => vm.uid,
    }),
  ],
  props: {
    text: { type: String },
    large: {
      type: Boolean,
      default: false,
    },
    fromList: {
      type: Boolean,
      default: false,
    },
    noBigFont: {
      type: Boolean,
      default: false,
    },
    noCenter: {
      type: Boolean,
      default: false,
    },
    textLimit: { type: Number, default: 800 },
    uid: { type: String, required: true },
    forceOpenPost: {
      type: Boolean,
      default: false,
    },
  },
  idState() {
    return {
      truncate: true,
      textTooLong: false,
    }
  },
  // data() {
  //   return {
  //     truncate: true,
  //     textTooLong: false,
  //   }
  // },
  computed: {
    fontSize() {
      if (typeof this.text !== 'string') {
        return 'unset'
      }
      const linesCount = this.text.split(/\r\n|\r|\n/).length
      if (this.text.length < 60 && linesCount < 4) {
        return '30px'
      }
      if (this.text.length < 120) {
        return '20px'
      }
      return '15px'
    },
    isBigTest() {
      return this.noBigFont || (this.text && this.text.length >= 120)
    },
    formattedText() {
      if (this.text === null) {
        return ''
      }
      let text = this.text
        .replace(/[\u00A0-\u9999<>&]/g, i => {
          return '&#' + i.charCodeAt(0) + ';'
        })
        .replace(
          /(https?:\/\/\S+)/gim,
          '<a rel="nofollow" class="user-link" target="_blank" href="$1">$1</a>',
        )
        .replace(/(\r\n|\r|\n){3,}/gm, `\n`)
      const linesSplit = text.split(/\r\n|\r|\n/)
      const linesCount = linesSplit.length
      const maxLines = 7
      if (this.truncate && linesCount > maxLines) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.idState.textTooLong = true
        let maxChars = 0
        for (let i = 0; i < maxLines; i++) {
          maxChars += linesSplit[i].length
        }
        text = utilsService.truncate(text, maxChars + maxLines - 1)
      }
      if (this.idState.truncate && text.length > this.textLimit) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.idState.textTooLong = true
        text = utilsService.truncate(text, this.textLimit)
      }
      return text
    },
    component() {
      return this.large ? 'div' : VResponsive
    },
  },
  methods: {
    textClick(event) {
      if (event.target.classList.contains('user-link')) {
        event.stopPropagation()
      }
    },
    readMore() {
      if (this.forceOpenPost) {
        this.$router.push({ name: 'Post', params: { slug: this.uid } })
      }
      this.idState.truncate = !this.idState.truncate
    },
  },
}
</script>

<style scoped lang="scss">
.responsive {
  ::v-deep {
    .v-responsive__content {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
    }
  }

  &:after {
    content: '';
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 3.5em;
    background: linear-gradient(
      to bottom,
      rgba(#17303f, 0),
      var(--v-secondary-lighten1) 100%
    );
  }

  .read-more {
    position: absolute;
    bottom: 0;
    z-index: 3;
    right: 10px;
  }
}
.text-wrapper {
  max-height: 500px;
  overflow-y: auto;
}

.user-text {
  max-width: 100%;
}
</style>
