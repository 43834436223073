<template>
  <v-row justify="center">
    <v-col cols="12">
      <template v-if="hasError">
        <div class="text-center">
          {{ $tc('error.an-error-has-occurred-refresh') }}
        </div>
      </template>
      <template v-else>
        <v-row>
          <v-col>
            <div class="overline">
              {{ $tc('censored.content-censored-by-other-platforms') }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <post-grid
              :posts="censoredPosts"
              :loading="postsLoading"
            ></post-grid>
          </v-col>
          <!--          <post-item
            v-for="post in censoredPosts"
            :key="post.id"
            :post="post"
            cols="12"
            sm="6"
            md="4"
            xl="3"
            show-organization
          ></post-item>
          <template v-if="postsLoading">
            <post-item-loader
              v-for="item in 12"
              :key="item"
              cols="12"
              sm="6"
              md="4"
              xl="3"
            ></post-item-loader>
          </template>-->
          <v-col
            cols="12"
            sm="6"
            md="4"
            xl="3"
            v-intersect="{
              handler: onIntersectEnd,
              options: {
                rootMargin: '-50% 0px 500px 0px',
                threshold: [0, 1],
              },
            }"
            style="min-height: 50px"
          >
            <div style="min-height: 50px"></div>
          </v-col>
        </v-row>
        <v-row justify="center" v-if="!endReached && !hasError">
          <v-btn
            color="primary"
            :loading="postsLoading"
            @click="fetchCensoredPosts"
            >{{ $tc('button.load-more') }}
          </v-btn>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import PostItem from '../components/PostItem'
import { requestService } from '../services/request.service'
import PostItemLoader from '@/components/PostItemLoader'
import PostGrid from '@/components/post/PostGrid.vue'

export default {
  name: 'Censored',
  components: { PostGrid, PostItemLoader, PostItem },
  data() {
    return {
      postsLoading: false,
      hasError: false,
      queryLast: null,
      censoredPosts: [],
      endReached: false,
    }
  },
  methods: {
    async fetchCensoredPosts() {
      this.postsLoading = true
      this.hasError = false
      requestService
        .get(process.env.VUE_APP_API_BASE_URL + '/post/censored', {
          params: {
            after: this.queryLast,
          },
        })
        .then(response => {
          if (this.censoredPosts.length && this.queryLast !== null) {
            Array.prototype.push.apply(this.censoredPosts, response.posts)
          } else {
            this.censoredPosts = response.posts
          }

          if (response.last !== null) {
            this.queryLast = response.last
          } else {
            this.endReached = true
          }
        })
        .catch(() => {
          this.hasError = true
        })
        .finally(() => {
          this.postsLoading = false
        })
    },
    onIntersectEnd() {
      if (this.postsLoading || this.endReached || this.hasError) {
        return
      }
      this.fetchCensoredPosts()
    },
  },
  mounted() {
    this.fetchCensoredPosts()
  },
}
</script>

<style scoped></style>
