<template>
  <v-row class="justify-center">
    <v-col md="11" xl="8">
      <v-row>
        <v-col cols="12" md="4">
          <v-card v-if="list" flat>
            <v-card-title>
              <h3 class="text-h5">
                {{ list.name }}
              </h3>
            </v-card-title>
            <v-card-subtitle>
              <div class="mt-1 font-weight-light d-flex">
                <div class="flex-grow-1 text-truncate">
                  <component
                    :is="list.isOwnedByAChannel ? 'router-link' : 'span'"
                    :to="{
                      name: 'OrganizationLists',
                      params: { slug: list.authorUid },
                    }"
                    class="title-link"
                    :class="{ enabled: list.isOwnedByAChannel }"
                  >
                    {{ list.authorName }}
                  </component>
                </div>
                <div class="text-no-wrap">
                  <v-icon
                    size="20"
                    v-text="getVisibilityIcon(list.visibility)"
                    :title="getVisibilityText(list.visibility)"
                  ></v-icon>
                  {{ list.itemsCount }}
                </div>
              </div>
            </v-card-subtitle>
            <v-card-actions v-if="list.canEdit">
              <v-btn
                v-if="list.canEdit"
                small
                rounded
                depressed
                class="align-center"
                @click="editDialog = true"
              >
                <v-icon left small class="ml-0">mdi-pencil</v-icon>
                <span>{{ $t('label.modify') }}</span>
              </v-btn>
              <v-btn
                v-if="list.canEdit"
                small
                rounded
                depressed
                class="align-center"
                @click="deleteList"
              >
                <v-icon left small class="ml-0">mdi-trash-can-outline</v-icon>
                <span>{{ $t('label.delete') }}</span>
              </v-btn>
            </v-card-actions>
            <v-card-text
              v-if="list.description"
              class="text-pre-line overflow-y-auto"
              style="max-height: 150px"
            >
              {{ list.description }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="8">
          <v-card v-if="items.length" flat>
            <v-list class="rounded">
              <v-list-item
                v-for="item in items"
                :key="item.post.uid"
                :to="getLink(item)"
                class="px-1 px-md-3 py-1 list-item"
                three-line
              >
                <div class="mr-2">{{ getItemPosition(item) }}</div>
                <div style="width: 120px" class="rounded overflow-hidden">
                  <post-preview :post="item.post" show-duration></post-preview>
                </div>
                <v-list-item-content class="pl-2 py-1 align-self-center">
                  <v-list-item-subtitle class="white--text font-weight-bold">
                    <span
                      v-if="item.post.video"
                      :title="item.post.video.title"
                      >{{ item.post.video.title }}</span
                    >
                    <span v-else>
                      {{ item.post.textContent }}
                    </span>
                  </v-list-item-subtitle>
                  <div class="mt-1 font-weight-light text-truncate">
                    {{ item.post.authorName }}
                  </div>
                </v-list-item-content>
                <v-list-item-icon class="align-self-center">
                  <post-menu
                    :post="item.post"
                    :in-list="list"
                    @itemRemovedFromList="itemRemovedFromList"
                  ></post-menu>
                </v-list-item-icon>
              </v-list-item>
              <div class="text-center">
                <v-btn
                  v-if="hasMoreItems"
                  @click="fetchItems"
                  text
                  small
                  color="primary"
                  :loading="itemsLoading"
                  v-intersect="{
                    handler: () => fetchItems,
                    rootMargin: '500px 0px 0px 0px',
                  }"
                >
                  {{ $t('button.see-more') }}
                </v-btn>
              </div>
            </v-list>
          </v-card>
          <div v-else-if="!itemsLoading" class="text-center overline mt-3">
            {{ $t('list.empty-list') }}
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog max-width="400px" v-model="editDialog" persistent>
      <v-card>
        <v-card-text class="pt-6">
          <v-text-field
            v-model="list.name"
            :label="$options.filters.capitalize($t('label.name'))"
            outlined
            dense
            required
            :error-messages="editErrors"
          ></v-text-field>
          <v-textarea
            v-model="list.description"
            :label="$options.filters.capitalize($t('label.description'))"
            outlined
            dense
            rows="3"
            counter="500"
          ></v-textarea>
          <v-select
            v-model="list.visibility"
            :items="visibilityChoices()"
            :label="$options.filters.capitalize($t('live.visibility'))"
            outlined
            dense
            hide-details
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelEdit">
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn
            color="success"
            text
            @click="submitEdit"
            :loading="editLoading"
          >
            {{ $t('button.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import PostPreview from '@/components/post/PostPreview.vue'
import PostMenu from '@/components/PostMenu.vue'
import { extractServerErrorsInStrings } from '@/services/validation.service'

export default {
  name: 'List',
  components: { PostMenu, PostPreview },
  data() {
    return {
      items: [],
      list: null,
      itemsLoading: false,
      hasMoreItems: true,
      editDialog: false,
      originalListData: {
        name: '',
        description: '',
        visibility: '',
      },
      editErrors: [],
      editLoading: false,
    }
  },
  methods: {
    fetchItems() {
      if (this.itemsLoading) return
      this.itemsLoading = true
      this.$store
        .dispatch('list/fetchListItems', {
          listUid: this.$route.params.slug,
          offset: this.items.length,
        })
        .then(response => {
          this.items.push(...response.items)
          if (response.list.isDefault) {
            response.list.name = this.$t('list.watch-later')
            response.list.uid = 'watch-later'
          }
          this.list = response.list
          this.hasMoreItems = response.hasMore
          this.itemsLoading = false
          this.originalListData = {
            name: this.list.name,
            description: this.list.description,
            visibility: this.list.visibility,
          }
        })
        .catch(error => {
          this.itemsLoading = false
          if (error.status === 404) {
            this.$store.dispatch('alert/error', this.$t('error.list-not-found'))
          }
        })
        .finally(() => {
          this.itemsLoading = false
        })
    },
    cancelEdit() {
      this.list.name = this.originalListData.name
      this.list.description = this.originalListData.description
      this.list.visibility = this.originalListData.visibility
      this.editDialog = false
    },
    submitEdit() {
      this.editLoading = true
      this.editErrors = []
      this.$store
        .dispatch('list/updateList', {
          listUid: this.list.uid,
          name: this.list.name,
          description: this.list.description,
          visibility: this.list.visibility,
        })
        .then(response => {
          this.editDialog = false
          this.$store.dispatch('alert/success', this.$tc('label.changes-saved'))
          this.list = response
        })
        .catch(response => {
          if (response.validationFailed) {
            this.editErrors = extractServerErrorsInStrings(
              response.error.errors,
              ['name'],
            )
          } else {
            this.$store.dispatch(
              'alert/error',
              response.error.message || this.$t('error.an-error-has-occurred'),
            )
          }
        })
        .finally(() => {
          this.editLoading = false
        })
    },
    deleteList() {
      this.$root.$emit(
        'confirm',
        this.$t('list.confirm-delete-list', { name: this.list.name }),
        () => {
          this.$store
            .dispatch('list/deleteList', {
              listUid: this.list.uid,
            })
            .then(() => {
              if (this.list.isOwnedByAChannel) {
                this.$router.push({
                  name: 'OrganizationLists',
                  params: { slug: this.list.authorUid },
                })
              } else {
                this.$router.push({ name: 'Lists' })
              }
            })
        },
      )
    },
    getVisibilityIcon(visibility) {
      switch (visibility) {
        case 'unlisted':
          return 'mdi-eye-off'
        case 'private':
          return 'mdi-lock'
        default:
          return 'mdi-earth'
      }
    },
    getVisibilityText(visibility) {
      let list = this.$tc('list.list')
      list = list.charAt(0).toUpperCase() + list.slice(1) + ' '
      switch (visibility) {
        case 'unlisted':
          return list + this.$t('visibility.unlisted')
        case 'private':
          return list + this.$t('visibility.private')
        default:
          return list + this.$t('visibility.public')
      }
    },
    visibilityChoices() {
      const capitalizeFirstLetter = string => {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }

      const choices = [
        {
          text: capitalizeFirstLetter(this.$t('visibility.public')),
          value: 'public',
        },
      ]

      if (this.list.isOwnedByAChannel) {
        choices.push({
          text: capitalizeFirstLetter(this.$t('visibility.unlisted')),
          value: 'unlisted',
        })
      } else {
        choices.push({
          text: capitalizeFirstLetter(this.$t('visibility.private')),
          value: 'private',
        })
      }

      return choices
    },

    getLink(item) {
      const routeName = item.post.video ? 'VideoPost' : 'Post'
      return {
        name: routeName,
        params: { slug: item.post.uid, post: item.post },
        query: { list: this.list.uid },
      }
    },
    itemRemovedFromList(event) {
      if (event.list.uid === this.list.uid) {
        this.items.splice(
          this.items.findIndex(item => item.post.uid === event.postUid),
          1,
        )
      }
    },
    getItemPosition(item) {
      if (this.list.isDefault) {
        return this.list.itemsCount - item.position + 1
      }
      return item.position
    },
  },
  watch: {
    $route() {
      this.items = []
      this.fetchItems()
    },
    itemsLoading(value) {
      if (this.items.length) {
        this.$store.state.loading = false
        return
      }
      this.$store.state.loading = value
    },
  },
  created() {
    if (this.$route.params.list) {
      this.list = this.$route.params.list
    }
    this.fetchItems()
  },
}
</script>

<style lang="scss" scoped>
.list-item {
  min-height: 0 !important;
}
.title-link {
  color: inherit;
  text-decoration: none;
  &.enabled:hover {
    text-decoration: underline;
  }
}
</style>
