
import { defineComponent } from 'vue'
import ListSelector from '@/components/list/ListSelector.vue'
import VideoButton from '@/components/video/VideoButton.vue'

export default defineComponent({
  name: 'AddToList',
  components: { VideoButton, ListSelector },
  props: {
    post: {
      type: Object,
      required: true,
    },
    displayBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      listDialog: false,
      tab: 1,
      userLists: [],
      userListsSelected: [],
      organizationLists: [],
      organizationListsSelected: [],
      listsLoading: false,
    }
  },
  methods: {
    showListDialog() {
      this.listDialog = true
      if (this.post.canEdit) {
        this.tab = 0
      } else {
        this.fetchUserLists()
      }
    },
    fetchLists(params, target) {
      this.listsLoading = true
      this.$store
        .dispatch('list/fetchListsWithPost', params)
        .then(response => {
          target.splice(0, target.length, ...response.lists)
        })
        .finally(() => {
          this.listsLoading = false
        })
    },

    fetchUserLists() {
      const params = {
        postUid: this.post.uid,
      }
      this.fetchLists(params, this.userLists)
    },

    fetchOrganizationLists() {
      const params = {
        organizationUid: this.post.channel?.organization?.uid,
        postUid: this.post.uid,
      }
      this.fetchLists(params, this.organizationLists)
    },
  },
  watch: {
    tab(tab) {
      if (tab === 0) {
        this.fetchOrganizationLists()
      } else if (tab === 1) {
        this.fetchUserLists()
      }
    },
  },
})
