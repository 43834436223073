import router from '../router/index.ts'
import { requestService } from '@/services/request.service.js'
import axios from 'axios'
import i18n from '@/plugins/i18n'

const roleList = {
  sorter: 'ROLE_SORTER',
}

const user = JSON.parse(localStorage.getItem('user'))
const state = user
  ? {
      status: {
        loggedIn: true,
      },
      user: user,
      paymentMethod: null,
      paymentMethodConnect: null,
      internalChannels: [],
      organizations: [],
      memberOrganizations: [],
      memberOrganization: null,
      plateFormSubscription: null,
      lastDonationComment: null,
    }
  : {
      status: {},
      user: null,
    }
const actions = {
  login({ commit, dispatch }, { username, password }) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_API_BASE_URL + '/login-check', {
          username: username,
          password: password,
        })
        .then(response => {
          if (response.data.token) {
            commit('loginSuccess', { username, ...response.data })
            localStorage.setItem(
              'user',
              JSON.stringify({ username, ...response.data }),
            )
            resolve(response.data)
            router.push('/')
          }
        })
        .catch(error => {
          commit('loginFailure', error)
          reject(error)
        })
    })
  },
  loginSuccess({ commit }, { data }) {
    commit('loginSuccess', { ...data })
    localStorage.setItem('user', JSON.stringify({ ...data }))
  },
  logout({ commit }) {
    localStorage.removeItem('user')
    commit('logout')
    if (
      !router.currentRoute.meta.embed &&
      router.currentRoute.name !== 'Login'
    ) {
      router.push({ name: 'Login' })
    }
  },
  refreshToken({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_API_BASE_URL + '/login-refresh', {
          // eslint-disable-next-line @typescript-eslint/camelcase
          refresh_token: state.user.refresh_token,
        })
        .then(response => {
          if (response.data.token) {
            commit('loginSuccess', { ...response.data })
            localStorage.setItem('user', JSON.stringify(state.user))
            resolve(response.data)
          }
        })
        .catch(error => {
          commit('loginFailure', error)
          reject(error)
        })
    })
  },
  isGranted({ state }, roles) {
    if (!state.user || !state.user.roles) {
      return false
    }
    if (typeof roles === 'string') {
      return state.user.roles.includes(roleList[roles])
    }
    for (const role of roles) {
      if (state.user.roles.includes(roleList[role])) {
        return true
      }
    }
    return false
  },
  savePaymentMethod(
    { commit },
    {
      paymentMethodId,
      last4,
      brand,
      expMonth,
      expYear,
      country,
      paymentMethodType,
      isConnect,
    },
  ) {
    return new Promise((resolve, reject) => {
      requestService
        .post(
          process.env.VUE_APP_API_BASE_URL +
            '/account/change_user_payment_method',
          {
            paymentMethodId,
            last4,
            brand,
            country,
            expMonth,
            expYear,
            paymentMethodType,
            isConnect: isConnect ? isConnect : false,
          },
        )
        .then(response => {
          if (!response.success) {
            reject(
              response.message
                ? response.message
                : i18n.tc('error.server-error-try-again'),
            )
            return
          }
          if (response.success) {
            if (response.data.isConnect) {
              commit('paymentMethodConnect', response.data.paymentMethodInfos)
            } else {
              commit('paymentMethod', response.data.paymentMethodInfos)
            }
            resolve(response.data.paymentMethodInfos)
          }
        })
        .catch(response => {
          // console.log(response)
          reject(response.message ? response.message : response)
        })
    })
  },
  // fetchUserPaymentMethods({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     requestService
  //       .get(
  //         process.env.VUE_APP_API_BASE_URL +
  //           '/account/get-account-payment-methods',
  //       )
  //       .then(response => {
  //         if (!response.success) {
  //           reject(
  //             response.message
  //               ? response.message
  //               : i18n.tc('error.server-error-try-again'),
  //           )
  //           return
  //         }
  //         commit('paymentMethod', response.data.paymentMethodInfos)
  //         commit(
  //           'paymentMethodConnect',
  //           response.data.paymentMethodConnectInfos,
  //         )
  //         resolve([
  //           response.data.paymentMethodInfos,
  //           response.data.paymentMethodConnectInfos,
  //         ])
  //       })
  //       .catch(response => {
  //         // console.log(response)
  //         reject(response.message ? response.message : response)
  //       })
  //   })
  // },
  fetchPlateformSubscription({ commit }) {
    return new Promise((resolve, reject) => {
      requestService
        .get(
          process.env.VUE_APP_API_BASE_URL +
            '/account/get-active-platform-subscription',
        )
        .then(response => {
          if (!response.success) {
            reject(
              response.message
                ? response.message
                : i18n.tc('error.server-error-try-again'),
            )
            return
          }
          if (response.success && response.data) {
            commit('plateFormSubscription', response.data)
            resolve(response.data)
          }
        })
        .catch(response => {
          // console.log(response)
          reject(response.message ? response.message : response)
        })
    })
  },
  fetchMemberOrganizations({ commit }) {
    return new Promise((resolve, reject) => {
      requestService
        .get(
          process.env.VUE_APP_API_BASE_URL +
            '/account/get-active-organisation-memberships',
        )
        .then(response => {
          if (!response.success) {
            reject(
              response.message
                ? response.message
                : i18n.tc('error.server-error-try-again'),
            )
            return
          }
          if (response.success && response.data) {
            commit('memberOrganizations', response.data)
            resolve(response.data)
          }
        })
        .catch(response => {
          // console.log(response)
          reject(response.message ? response.message : response)
        })
    })
  },
}

const mutations = {
  loginRequest(state, user) {
    state.status = {
      loggingIn: true,
    }
    state.user = user
  },
  loginSuccess(state, user) {
    state.status = {
      loggedIn: true,
    }
    state.user = user
    state.paymentMethod = user.user.paymentMethodInfos
    state.paymentMethodConnect = user.user.paymentMethodConnectInfos
    state.memberOrganizations = user.user.organizationMemberships
    state.plateFormSubscription = user.user.platformSubscription
  },
  saveToken(state, token) {
    state.user.token = token
  },
  loginFailure(state) {
    state.status = {}
    state.user = null
    state.paymentMethod = null
    state.memberOrganization = null
    state.memberOrganizations = []
    state.plateFormSubscription = null
  },
  logout(state) {
    state.status = {}
    state.user = null
    state.paymentMethod = null
    state.memberOrganization = null
    state.memberOrganizations = []
    state.plateFormSubscription = null
  },
  internalChannels(state, internalChannels) {
    state.internalChannels = internalChannels
  },
  organizations(state, organizations) {
    state.organizations = organizations
  },
  memberOrganizations(state, memberOrganizations) {
    state.memberOrganizations = memberOrganizations
  },
  lastDonationComment(state, lastDonationComment) {
    state.lastDonationComment = lastDonationComment
  },
  paymentMethod(state, paymentMethod) {
    state.paymentMethod = paymentMethod
  },
  paymentMethodConnect(state, paymentMethodConnect) {
    state.paymentMethodConnect = paymentMethodConnect
  },
  plateFormSubscription(state, plateFormSubscription) {
    state.plateFormSubscription = plateFormSubscription
  },
}

const getters = {
  status: state => state.status,
}

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
