import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import GlobalSearch from '../views/GlobalSearch.vue'
import Video from '@/views/Video.vue'
import Channel from '@/views/Channel.vue'
import Organization from '@/views/Organization.vue'
import Login from '@/views/Login.vue'
import AccountSettings from '../views/AccountSettings.vue'
import PageNotFound from '@/views/PageNotFound.vue'
import Post from '@/views/Post.vue'
import Subscribed from '@/views/Subscribed.vue'
import WatchLater from '@/views/WatchLater.vue'
import Censored from '@/views/Censored.vue'
import EmbedVideo from '@/views/EmbedVideo.vue'
import DonationPage from '@/views/DonationPage.vue'
import PlateformSubscriptionInfos from '@/views/PlateformSubscriptionInfos.vue'
import PlateformSubscription from '@/views/PlateformSubscription.vue'
import CrowdBunkerCampaign from '@/views/CrowdBunkerCampaign.vue'
import FundCountdown from '@/views/FundCountdown.vue'
import HelpCampaign from '@/views/HelpCampaign.vue'
import store from '../store/index'
import i18n from '@/plugins/i18n'
import FundPage from '@/views/FundPage.vue'
import HomePreview from '@/views/HomePreview.vue'
import PostView from '@/views/PostView.vue'
import LiveChatWindow from '@/views/LiveChatWindow.vue'
import MyLists from '@/views/MyLists.vue'
import List from '@/views/List.vue'

Vue.use(VueRouter)

const fundComponent =
  new Date() < new Date('2022-10-24T21:00:00+0200')
    ? FundCountdown
    : CrowdBunkerCampaign

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      headerBanner: true,
      leftMenu: true,
    },
  },
  {
    path: '/home-preview',
    name: 'HomePreview',
    component: HomePreview,
    meta: {
      headerBanner: true,
      leftMenu: true,
    },
  },
  { path: '/videos', name: 'Videos', redirect: { name: 'Home' } },
  {
    path: '/search',
    name: 'Search',
    component: GlobalSearch,
    meta: {
      headerBanner: true,
    },
    props: route => ({ externalQuery: route.query.q }),
  },
  {
    path: '/about',
    name: 'About',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      headerBanner: false,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      layout: 'default',
      fillHeight: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Login,
    meta: {
      layout: 'default',
      fillHeight: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgetPassword',
    component: () =>
      import(
        /* webpackChunkName: "forget-password" */ '@/views/ForgetPassword.vue'
      ),
    meta: {
      layout: 'default',
      fillHeight: true,
    },
  },
  {
    path: '/link-connect/:key',
    name: 'LinkConnect',
    component: () =>
      import(/* webpackChunkName: "link-connect" */ '@/views/LinkConnect.vue'),
    meta: {
      layout: 'default',
      fillHeight: true,
    },
  },
  {
    path: '/confirm-email/:key?',
    name: 'ConfirmEmail',
    component: () =>
      import(
        /* webpackChunkName: "confirm-email" */ '@/views/ConfirmEmail.vue'
      ),
    meta: {
      layout: 'default',
      fillHeight: true,
    },
  },
  {
    path: '/confirm-team-member/:key?',
    name: 'ConfirmTeamMember',
    component: () =>
      import(
        /* webpackChunkName: "confirm-team-member" */ '@/views/ConfirmTeamMember.vue'
      ),
    meta: {
      layout: 'default',
      fillHeight: true,
    },
  },
  { path: '/console', name: 'Console', redirect: { name: 'InternalChannels' } },
  {
    path: '/console/account-settings',
    name: 'AccountSettings',
    component: AccountSettings,
    meta: {
      layout: 'default',
      fillHeight: false,
    },
  },
  {
    path: '/console/youtube/channels',
    name: 'YouTubeChannels',
    component: () =>
      import(/* webpackChunkName: "youtube" */ '@/views/console/YouTube.vue'),
    meta: {
      layout: 'console',
    },
  },
  {
    path: '/console/youtube/videos',
    name: 'YouTubeVideos',
    component: () =>
      import(/* webpackChunkName: "youtube" */ '@/views/console/YouTube.vue'),
    meta: {
      layout: 'console',
    },
  },
  {
    path: '/console/reset-password',
    name: 'ResetPassword',
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ '@/views/console/ResetPassword.vue'
      ),
    meta: {
      layout: 'console',
      fillHeight: true,
    },
  },
  {
    path: '/console/organizations',
    name: 'MyOrganizations',
    component: () =>
      import(
        /* webpackChunkName: "my-organizations" */ '@/views/console/MyOrganizations.vue'
      ),
    meta: {
      layout: 'console',
    },
  },
  {
    path: '/console/organizations/:id/general',
    name: 'ManageOrganization',
    component: () =>
      import(
        /* webpackChunkName: "manage-organization" */ '@/views/console/ManageOrganization.vue'
      ),
    meta: {
      layout: 'console',
    },
  },
  {
    path: '/console/organizations/:id/monetization',
    name: 'Monetization',
    component: () =>
      import(
        /* webpackChunkName: "manage-organization" */ '@/views/console/ManageOrganization.vue'
      ),
    meta: {
      layout: 'console',
    },
  },
  {
    path: '/console/organizations/:id/team',
    name: 'Team',
    component: () =>
      import(
        /* webpackChunkName: "manage-organization" */ '@/views/console/ManageOrganization.vue'
      ),
    meta: {
      layout: 'console',
    },
  },
  {
    path: '/console/organizations/:id/members',
    name: 'OrganizationMembers',
    component: () =>
      import(
        /* webpackChunkName: "manage-organization" */ '@/views/console/ManageOrganization.vue'
      ),
    meta: {
      layout: 'console',
    },
  },
  {
    path: '/console/organizations/:id/stats',
    name: 'OrganizationMonetizationStats',
    component: () =>
      import(
        /* webpackChunkName: "manage-organization" */ '@/views/console/ManageOrganization.vue'
      ),
    meta: {
      layout: 'console',
    },
  },
  {
    path: '/console/channels/:uid',
    name: 'ManageChannel',
    component: () =>
      import(
        /* webpackChunkName: "manage-channel" */ '@/views/console/ManageChannel.vue'
      ),
    meta: {
      layout: 'console',
    },
  },
  {
    path: '/console/videos/:uid',
    name: 'ManageVideo',
    component: () =>
      import(
        /* webpackChunkName: "manage-video" */ '@/views/console/ManageVideo.vue'
      ),
    meta: {
      layout: 'console',
    },
  },
  {
    path: '/console/post/:uid',
    name: 'ManagePost',
    component: () =>
      import(
        /* webpackChunkName: "manage-video" */ '@/views/console/ManageVideo.vue'
      ),
    meta: {
      layout: 'console',
    },
  },
  {
    path: '/console/crowdbunker/channels',
    name: 'InternalChannels',
    component: () =>
      import(
        /* webpackChunkName: "manage-internal" */ '@/views/console/Internal.vue'
      ),
    meta: {
      layout: 'console',
    },
  },
  {
    path: '/console/crowdbunker/videos',
    name: 'InternalVideos',
    component: () =>
      import(
        /* webpackChunkName: "manage-internal" */ '@/views/console/Internal.vue'
      ),
    meta: {
      layout: 'console',
    },
  },
  {
    path: '/channel/:slug',
    name: 'Channel',
    component: Channel,
    meta: {
      headerBanner: false,
      noPaddingTop: true,
      leftMenu: true,
    },
  },
  {
    path: '/post/:slug',
    name: 'Post',
    component: PostView,
    meta: {
      leftMenu: true,
    },
  },
  {
    path: '/live-chat/:slug',
    name: 'LiveChat',
    component: LiveChatWindow,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/me',
    name: 'Subscribed',
    component: Subscribed,
    meta: {
      leftMenu: true,
    },
  },
  {
    path: '/my-lists',
    name: 'UserLists',
    component: MyLists,
    meta: {
      leftMenu: true,
    },
  },
  {
    path: '/donation',
    name: 'Donation',
    component: DonationPage,
  },
  {
    path: '/plateforme-subscription-info',
    name: 'PlateformSubscriptionInfos',
    component: PlateformSubscriptionInfos,
  },
  {
    path: '/plateforme-subscription',
    name: 'PlateformSubscription',
    component: PlateformSubscription,
  },
  {
    path: '/fund',
    name: 'Fund',
    component: fundComponent,
    meta: {
      noPaddingTop: true,
    },
  },
  {
    path: '/fund/:slug',
    name: 'FundPage',
    component: FundPage,
    meta: {
      headerBanner: false,
    },
  },
  {
    path: '/funds',
    name: 'Funds',
    component: () =>
      import(/* webpackChunkName: "Funds" */ '@/views/Funds.vue'),
  },
  {
    path: '/fund-preview',
    name: 'FundPreview',
    component: CrowdBunkerCampaign,
    meta: {
      noPaddingTop: true,
    },
  },
  {
    path: '/lancement',
    name: 'FundCountdown',
    redirect: { name: 'Fund' },
  },
  {
    path: '/help-campaign',
    name: 'HelpCampaign',
    component: HelpCampaign,
    meta: {
      noPaddingTop: true,
    },
  },
  {
    path: '/censored',
    name: 'Censored',
    component: Censored,
    meta: {
      headerBanner: true,
      leftMenu: true,
    },
  },
  {
    path: '/cgu',
    name: 'CGU',
    component: () =>
      import(/* webpackChunkName: "CGU" */ '@/views/CGU/CGU.vue'),
    meta: {
      leftMenu: true,
    },
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicy',
    component: () =>
      import(
        /* webpackChunkName: "PrivacyPolicy" */ '@/views/CGU/PrivacyPolicy.vue'
      ),
    meta: {
      leftMenu: true,
    },
  },
  {
    path: '/dmca',
    name: 'DMCA',
    component: () =>
      import(/* webpackChunkName: "DMCA" */ '@/views/CGU/DMCA.vue'),
    meta: {
      leftMenu: true,
    },
  },
  {
    path: '/console/live-studio',
    name: 'LiveStudio',
    component: () =>
      import(/* webpackChunkName: "live-studio" */ '@/views/LiveStudio.vue'),
    meta: {
      layout: 'console',
      leftMenu: false,
    },
  },
  {
    path: '/@:slug',
    name: 'Organization',
    component: Organization,
    meta: {
      headerBanner: false,
      noPaddingTop: true,
      leftMenu: true,
    },
  },
  {
    path: '/embed/@:slug/live',
    name: 'OrganizationLive',
    component: EmbedVideo,
    meta: {
      layout: 'empty',
      embed: true,
    },
  },
  {
    path: '/@:slug/videos',
    name: 'OrganizationVideos',
    component: Organization,
    meta: {
      headerBanner: false,
      noPaddingTop: true,
    },
  },
  {
    path: '/@:slug/lists',
    name: 'OrganizationLists',
    component: Organization,
    meta: {
      headerBanner: false,
      noPaddingTop: true,
    },
  },
  {
    path: '/list/:slug',
    name: 'List',
    component: List,
    meta: {
      leftMenu: true,
    },
  },
  {
    path: '/v/:slug',
    name: 'VideoPost',
    component: PostView,
    meta: {
      headerBanner: true,
      isVideo: true,
    },
  },
  {
    path: '/embed/:slug',
    name: 'EmbedVideo',
    component: EmbedVideo,
    meta: {
      layout: 'empty',
      embed: true,
    },
  },
  {
    path: '/404',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: {
      layout: 'default',
      fillHeight: true,
    },
  },
  {
    path: '/:slug',
    name: 'Video',
    redirect: { name: 'Post' },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'PageNotFound' },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  store.state.loading = true
  const securedPages = /^\/console/
  const authRequired = securedPages.test(to.path)
  const loggedIn = localStorage.getItem('user')
  const currentUrl = from.fullPath

  if (authRequired && !loggedIn) {
    store.state.loading = false
    return next({ name: 'Login', query: { redirect: currentUrl } })
  }
  if (
    to.name === 'Login' &&
    from.name !== 'Login' &&
    to.query.redirect === undefined &&
    currentUrl !== '/'
  ) {
    return next({ name: 'Login', query: { redirect: currentUrl } })
  }

  document.title = 'CrowdBunker - ' + i18n.t('home.freedom-of-expression')

  next()
})

router.afterEach(() => {
  store.state.loading = false
})

export default router
