
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PostVotesPercentage',
  props: {
    upVotes: {
      type: Number,
      default: 0,
    },
    downVotes: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    percentage() {
      const total = this.upVotes + this.downVotes
      if (total === 0) {
        return 50
      }
      return Math.round((this.upVotes / total) * 100)
    },
  },
})
