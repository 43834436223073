<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="field.value"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="field.value"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="assign(attrs, $attrs)"
        v-on="assign(on, $listeners)"
      ></v-text-field>
    </template>
    <v-time-picker
      v-model="field.value"
      format="24hr"
      color="secondary"
      scrollable
      :max="max"
      :min="min"
    >
      <v-spacer></v-spacer>
      <v-btn v-if="field.value" text color="error" @click="field.value = null">
        {{ $t('label.clear') }}
      </v-btn>
      <v-btn text color="primary" @click="modal = false">
        {{ $t('button.cancel') }}
      </v-btn>
      <v-btn text color="primary" @click="$refs.dialog.save(field.value)">
        OK
      </v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
export default {
  name: 'TimePicker',
  props: {
    field: {
      type: Object,
    },
    max: { type: String },
    min: { type: String },
  },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    modal: false,
  }),
  methods: {
    assign(a, b) {
      return Object.assign(a, b)
    },
  },
  watch: {
    'field.value'(value) {
      this.$emit('change', value)
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .theme--dark.v-picker__body {
    background: var(--v-background-base);
  }
  .theme--dark.v-time-picker-clock {
    background: var(--v-secondary-lighten1);
  }
}
</style>
