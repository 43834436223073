<template>
  <VEmojiPicker
    emojisByRow="10"
    emojiSize="25"
    :showSearch="false"
    :dark="true"
    :i18n="i18n"
    v-bind="$attrs"
    v-on="$listeners"
    style="max-width: 100%;"
    @select="selectEmoji"
  />
</template>

<script>
import { VEmojiPicker } from 'v-emoji-picker'

export default {
  name: 'EmojiPicker',
  components: { VEmojiPicker },
  props: {
    fieldNode: { type: Element },
    text: { type: String },
  },
  data() {
    return {
      i18n: {
        search: this.$options.filters.capitalize(this.$tc('label.search')),
        categories: {
          Activity: this.$options.filters.capitalize(
            this.$tc('label.activity', 2),
          ),
          Flags: this.$options.filters.capitalize(this.$tc('label.flag', 2)),
          Foods: this.$options.filters.capitalize(this.$tc('label.food', 2)),
          Frequently: this.$options.filters.capitalize(
            this.$tc('label.frequently'),
          ),
          Objects: this.$options.filters.capitalize(
            this.$tc('label.object', 2),
          ),
          Nature: this.$options.filters.capitalize(this.$tc('label.nature')),
          Peoples: this.$options.filters.capitalize(
            this.$tc('label.people', 2),
          ),
          Symbols: this.$options.filters.capitalize(
            this.$tc('label.symbol', 2),
          ),
          Places: this.$options.filters.capitalize(this.$tc('label.place', 2)),
        },
      },
    }
  },
  methods: {
    selectEmoji(emoji) {
      const [start, end] = [
        this.fieldNode.selectionStart,
        this.fieldNode.selectionEnd,
      ]
      const newValue =
        this.text.substring(0, start) +
        emoji.data +
        this.text.substring(end, this.text.length)
      this.$emit('update:text', newValue)
      this.fieldNode.focus()
      this.$nextTick(() => this.fieldNode.setSelectionRange(start + 2, end + 2))
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .container-emoji {
    height: 290px !important;
  }
}
</style>
