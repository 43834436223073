
import { defineComponent } from 'vue'
import { extractServerErrorsInStrings } from '@/services/validation.service'

export default defineComponent({
  name: 'ListSelector',
  props: {
    lists: {
      type: Array,
      required: true,
    },
    post: {
      type: Object,
      required: true,
    },
    organization: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      newList: {
        name: '',
        visibility: 'public',
        creating: false,
        loading: false,
        errors: [],
      },
    }
  },
  computed: {
    selectedLists: {
      get() {
        return this.lists
          .filter(list => list.postIncluded)
          .map(list => list.uid)
      },
      set(val) {
        // this.$emit('update:selected-lists', val)
      },
    },
    visibilityChoices() {
      return this.organization ? ['public', 'unlisted'] : ['public', 'private']
    },
  },
  methods: {
    toggleList(list) {
      this.$set(list, 'loading', true)
      if (list.postIncluded) {
        this.$store
          .dispatch('list/removePostFromList', {
            listUid: list.uid,
            postUid: this.post.uid,
          })
          .then(() => {
            this.$store.dispatch(
              'alert/success',
              this.$t('list.post-removed-from-list', { name: list.name }),
            )
          })
          .finally(() => {
            this.$set(list, 'loading', false)
          })
      } else {
        this.$store
          .dispatch('list/addPostToList', {
            listUid: list.uid,
            postUid: this.post.uid,
          })
          .then(() => {
            this.$store.dispatch(
              'alert/success',
              this.$t('list.post-added-to-list', { name: list.name }),
            )
          })
          .finally(() => {
            this.$set(list, 'loading', false)
          })
      }
      list.postIncluded = !list.postIncluded
    },
    getVisibilityIcon(visibility) {
      switch (visibility) {
        case 'public':
          return 'mdi-earth'
        case 'unlisted':
          return 'mdi-eye-off'
        case 'private':
        default:
          return 'mdi-lock'
      }
    },
    showNewListForm() {
      this.newList.creating = true
      this.newList.visibility = this.organization ? 'public' : 'private'
    },
    createNewList() {
      this.newList.loading = true
      this.newList.errors = []
      this.$store
        .dispatch('list/createList', {
          name: this.newList.name,
          visibility: this.newList.visibility,
          organizationUid: this.organization?.uid,
        })
        .then(list => {
          this.toggleList(list)
          // add list to the top of lists
          this.lists.unshift(list)
          this.newList.name = ''
          this.newList.creating = false
        })
        .catch(response => {
          if (response.validationFailed) {
            this.newList.errors = extractServerErrorsInStrings(
              response.error.errors,
              ['name'],
            )
          } else {
            this.$store.dispatch(
              'alert/error',
              response.error.message || this.$t('error.an-error-has-occurred'),
            )
          }
        })
        .finally(() => {
          this.newList.loading = false
        })
    },
  },
})
