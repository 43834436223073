<template>
  <div class="d-flex align-center">
    <span class="mr-2 text-capitalize">{{ $tc('label.language-colon') }}</span>
    <v-select
      :items="$store.state.locales"
      v-model="$i18n.locale"
      single-line
      hide-details
      class="pt-0 caption select-language"
      @click.prevent.stop
      @change="onChange($event)"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: 'SelectLanguage',
  data() {
    return {}
  },
  methods: {
    onChange(value) {
      localStorage.setItem('locale', value)
      this.$vuetify.lang.current = value
    },
  },
}
</script>

<style scoped lang="scss">
.select-language {
  margin-top: 1px !important;
}
</style>
