<template>
  <!--  change to clipped-right to let the left menu on the top left -->
  <div>
    <v-app-bar
      :app="hasLeftMenu"
      :clipped-left="true"
      :hide-on-scroll="shouldHideOnScroll()"
      color="background"
      class="header-bar"
      dark
      dense
      flat
      v-bind="$attrs"
    >
      <v-app-bar-nav-icon
        v-if="
          (hasLeftMenu && $vuetify.breakpoint.smAndUp) ||
            $route.meta.layout === 'console'
        "
        @click.stop="switchLeftMenu"
      />
      <div
        v-if="$vuetify.breakpoint.smAndUp || !searchVisible"
        class="mr-1"
        style="min-width: 120px;max-width: 170px;"
      >
        <router-link
          :to="{ name: 'Home' }"
          @click.native="$root.$emit('refreshOrGoTop')"
          :title="$tc('menu.home-crowdbunker') | capitalize"
        >
          <!--          <v-img
            src="/images/logo-blanc.webp"
            alt="CrowdBunker"
            width="13rem"
            height="3rem"
            contain
            position="left center"
            eager
          />-->
          <picture class="d-flex" style="pointer-events: none;">
            <source
              srcset="/images/logo-crowdbunker.webp 400w"
              sizes="150px"
              type="image/webp"
            />
            <img
              src="/images/logo-crowdbunker.png"
              alt="CrowdBunker"
              style="width: 100%"
            />
          </picture>
        </router-link>
      </div>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
      <v-text-field
        v-model="searchText"
        @click:prepend="searchVisible = false"
        @click:append="submitSearch"
        @keyup.enter="submitSearch"
        @keyup="onSearchKeyUp"
        :prepend-icon="searchVisible ? 'mdi-close-circle' : ''"
        append-icon="mdi-magnify"
        v-if="$vuetify.breakpoint.mdAndUp || searchVisible"
        hide-details
        single-line
        solo-inverted
        dense
        flat
        rounded
        clearable
        :placeholder="$tc('label.search') | capitalize"
        class="mx-1"
        style="max-width: 400px"
        :style="{
          width: searchVisible ? '100%' : 'auto',
          'max-width': searchVisible ? '100%' : '400px',
        }"
      ></v-text-field>
      <app-tooltip
        v-if="
          $vuetify.breakpoint.mdAndUp && $store.state.account.status.loggedIn
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            class="ml-2"
            @click="addPost"
            fab
            small
            depressed
            v-on="on"
            v-bind="attrs"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $tc('button.publish') | capitalize }}</span>
      </app-tooltip>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
      <v-switch
        v-if="0"
        hide-details
        @change="$vuetify.theme.dark = !$event"
        value="!$vuetify.theme.dark"
        class="mr-3 d-none d-md-flex"
        prepend-icon="mdi-brightness-6"
      ></v-switch>
      <v-menu
        left
        bottom
        offset-y
        :min-width="200"
        z-index="450"
        max-width="280"
        content-class="elevation-1"
        v-if="
          $vuetify.breakpoint.mdAndUp && !$store.state.account.status.loggedIn
        "
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon>
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-translate</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                <select-language></select-language>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn
          v-if="!$store.state.account.status.loggedIn"
          :to="{ name: 'Login' }"
          class="mx-1"
          color="primary"
          exact
          rounded
          depressed
        >
          <v-icon left>mdi-account-circle</v-icon>
          <span>{{ $tc('label.login') }}</span>
        </v-btn>
      </template>
      <v-spacer
        v-if="$route.meta.layout === 'console' && $vuetify.breakpoint.smAndDown"
      ></v-spacer>
      <v-btn
        v-if="
          $vuetify.breakpoint.smAndDown &&
            !searchVisible &&
            $route.meta.layout !== 'console'
        "
        class="ml-auto"
        icon
        @click="searchVisible = true"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <!--      <template v-if="!$store.state.account.status.loggedIn">
        <v-btn
          color="primary"
          :to="{ name: 'Register' }"
          v-if="$vuetify.breakpoint.mdAndUp"
          depressed
          rounded
        >
          {{ $tc('label.register') }}
        </v-btn>
      </template>-->
      <!--      <template v-else>-->
      <template v-if="$store.state.account.status.loggedIn">
        <notification-list></notification-list>
        <v-menu
          left
          bottom
          offset-y
          :min-width="200"
          z-index="450"
          max-width="280"
        >
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon>
              <channel-avatar
                :thumbnails="$store.state.account.user.user.thumbnails"
                :size="35"
                :channel-name="$store.state.account.user.user.username"
                :bg-color="
                  $store.state.account.user.user.avatarColor || 'primary'
                "
              >
              </channel-avatar>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="flex-column py-1 align-center">
              <div class="text-truncate" style="max-width: 100%">
                <p class="my-auto">
                  {{ $store.state.account.user.user.username }}
                  <v-icon
                    v-if="!!$store.state.account.plateFormSubscription"
                    :color="
                      getColor(
                        $store.state.account.plateFormSubscription.planLevel,
                      )
                    "
                    >mdi-seal</v-icon
                  >
                </p>
              </div>
              <div
                class="caption text--secondary text-truncate"
                style="max-width: 100%"
              >
                {{ $store.state.account.user.user.email }}
              </div>
              <div>
                <trust-score
                  :score="$store.state.account.user.user.trustScore"
                  is-user
                ></trust-score>
              </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-list dense>
              <v-list-item :to="{ name: 'Console' }">
                <v-list-item-icon>
                  <v-icon>mdi-view-dashboard-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{
                  $tc('label.console')
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="$vuetify.breakpoint.smAndDown"
                @click="organizationsDialogVisible = true"
              >
                <v-list-item-icon>
                  <v-icon>mdi-format-list-text</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{
                  $tc('organization.my-organizations') | capitalize
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item link :to="{ name: 'AccountSettings' }">
                <v-list-item-icon>
                  <v-icon>mdi-account-wrench</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ $tc('account.account-settings') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-translate</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  <select-language></select-language>
                </v-list-item-title>
              </v-list-item>
              <v-list-item link @click="$store.dispatch('account/logout')">
                <v-list-item-icon>
                  <v-icon>mdi-exit-to-app</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{
                  $tc('label.sign-out')
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </template>
      <v-btn
        v-if="
          $vuetify.breakpoint.smAndDown && !$store.state.account.status.loggedIn
        "
        icon
        @click="$router.push({ name: 'Login' })"
      >
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
      <v-progress-linear
        :active="$store.state.loading"
        :indeterminate="$store.state.loading"
        absolute
        bottom
        color="primary"
        v-show="$store.state.loading"
        height="3px"
      ></v-progress-linear>
    </v-app-bar>
    <v-navigation-drawer
      v-model="rightMenu"
      app
      right
      temporary
      color="secondary"
    >
      <v-list>
        <v-list-item-group class="text-uppercase">
          <v-list-item :to="{ name: 'Videos' }">
            <v-list-item-title>
              {{ $tc('label.video', 2) | capitalize }}
            </v-list-item-title>
          </v-list-item>
          <template v-if="!$store.state.account.status.loggedIn">
            <v-list-item :to="{ name: 'Register' }">
              <v-list-item-title class="primary--text">
                {{ $tc('label.register') }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item
            :to="{ name: 'Console' }"
            v-if="!$store.state.account.status.loggedIn"
          >
            <v-list-item-title>
              {{ $tc('label.login') }}
            </v-list-item-title>
          </v-list-item>
          <!--          <v-list-item :to="{ name: 'About' }">-->
          <!--            <v-list-item-title>{{ $tc('label.about') }}</v-list-item-title>-->
          <!--          </v-list-item>-->
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-dialog
      max-width="300px"
      :value="organizationsDialogVisible"
      scrollable
      fullscreen
    >
      <v-card flat>
        <v-card-title>
          <span class="headline">{{
            $tc('organization.my-organizations') | capitalize
          }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="organizationsDialogVisible = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-0">
          <v-list
            dense
            style="overflow: auto"
            class="pt-0 mt-0"
            rounded
            max-height="100%"
          >
            <v-item-group
              v-for="organization in $store.state.account.organizations"
              :key="organization.uid"
            >
              <v-list-item
                :to="{
                  name: 'Organization',
                  params: {
                    slug: organization.uid,
                    noBanner: organization.banner === null,
                  },
                }"
                :title="organization.name"
                @click="organizationsDialogVisible = false"
              >
                <v-list-item-icon class="mr-5">
                  <channel-avatar
                    :thumbnails="organization.thumbnails"
                    :size="26"
                    :channel-name="organization.name"
                    :bg-color="organization.avatarColor || 'primary'"
                  >
                  </channel-avatar>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ organization.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-item-group>
          </v-list>
          <p
            class="px-4 text-caption text-center mt-2"
            v-if="!$store.state.account?.organizations?.length"
          >
            {{
              $tc('organization.organization-group-several-channels')
                | capitalize
            }}
            <br />
            <router-link :to="{ name: 'MyOrganizations' }"
              >{{ $tc('organization.create-first-organization') | capitalize }}
            </router-link>
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { requestService } from '@/services/request.service'
import NotificationList from '@/components/NotificationList'
import SelectLanguage from '@/components/SelectLanguage'
import ChannelAvatar from '@/components/ChannelAvatar'
import { useDebounceFn } from '@vueuse/core'
import { subscriptionService } from '@/services/subscription.service'
import TrustScore from '@/components/TrustScore.vue'
import AppTooltip from '@/components/AppTooltip.vue'

const updateSearchQueryUrl = useDebounceFn((query, router) => {
  router.replace({ name: 'Search', query: { q: query } })
}, 500)

export default {
  name: 'HeaderBar',
  components: {
    AppTooltip,
    TrustScore,
    SelectLanguage,
    NotificationList,
    ChannelAvatar,
  },
  props: {
    hasLeftMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHiddenActionsVisible: false,
      loginDialog: true,
      rightMenu: false,
      searchVisible: false,
      searchText: '',
      organizationsDialogVisible: false,
    }
  },
  methods: {
    getColor(level) {
      return subscriptionService.subscriptionLevelColor(level)
    },
    onSearchKeyUp() {
      // trim the search text
      const query = this.searchText.trim()
      if (
        this.$route.name !== 'Search' ||
        this.$store.state.globalSearchQuery === query ||
        !this.searchText
      ) {
        return
      }
      this.$store.state.globalSearchQuery = query
      updateSearchQueryUrl(query, this.$router)
    },
    submitSearch() {
      if (this.$route.name === 'Search' || !this.searchText) {
        return
      }
      this.$router.push({ name: 'Search', query: { q: this.searchText } })
    },
    switchLeftMenu() {
      this.$emit('switch-left-menu')
      this.$store.state.leftMenuManuallySet = true
    },
    addPost() {
      if (this.$store.state.account.status.loggedIn) {
        this.$root.$emit('createPost')
      } else {
        this.$router.push({ name: 'Login' })
      }
    },
    shouldHideOnScroll() {
      return false
      return (
        this.$vuetify.breakpoint.smAndDown &&
        !['Video', 'VideoPost', 'Post'].includes(this.$route.name) &&
        this.$route.meta.layout !== 'console'
      )
    },
  },
  mounted() {
    if (this.$route.query.q) {
      this.searchText = this.$route.query.q
    }
  },
}
</script>

<style scoped lang="scss">
.header-bar {
  z-index: 100;
  top: 0;
}
</style>
