import { render, staticRenderFns } from "./PageListHeader.vue?vue&type=template&id=508e2f96&scoped=true&"
import script from "./PageListHeader.vue?vue&type=script&lang=js&"
export * from "./PageListHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "508e2f96",
  null
  
)

export default component.exports