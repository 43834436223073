<template>
  <div style="width: 100%;height: 100%">
    <v-sheet height="100%" class="black">
      <div v-if="loading" style="height: 100%;width: 100%">
        <v-progress-linear absolute indeterminate active></v-progress-linear>
      </div>
      <v-alert
        color="red"
        type="info"
        class="mx-3 my-1"
        style="position: absolute"
        dismissible
        v-if="$store.state.remoteConfig.globalErrorMessage.length > 0"
        >{{ $store.state.remoteConfig.globalErrorMessage }}</v-alert
      >
      <div
        v-if="video.deleted"
        class="d-flex justify-center align-center fill-height flex-column text-center"
      >
        <div class="text-sm-h4 text-h5 font-weight-bold">
          {{ $tc('error.this-video-has-been-deleted') }}
        </div>
        <div class="text-sm-h5 text-h6" v-if="video.deletedReason">
          {{ video.deletedReason }}
        </div>
      </div>
      <div
        v-else-if="!inIframe()"
        class="d-flex justify-center align-center fill-height flex-column text-center"
      >
        <div class="text-sm-h4 text-h5 font-weight-bold">
          {{ $tc('error.must-be-in-iframe') }}
        </div>
        <div class="text-sm-h5 text-h6" v-if="video.visibility === 'public'">
          <v-btn
            text
            :to="{ name: 'VideoPost', params: { slug: video.id } }"
            target="_blank"
            color="primary"
          >
            {{ $t('video.view-video') }}
            <v-icon right>mdi-open-in-new</v-icon>
          </v-btn>
        </div>
      </div>
      <div
        v-else-if="channel.status && channel.status !== 'active'"
        class="d-flex justify-center align-center fill-height flex-column text-center"
      >
        <div
          class="text-sm-h4 text-h5 font-weight-bold"
          v-if="channel.status === 'disabled'"
        >
          {{ $tc('error.this-channel-is-disabled') }}
        </div>
        <div
          class="text-sm-h4 text-h5 font-weight-bold"
          v-else-if="channel.status === 'deleted'"
        >
          {{ $tc('error.this-channel-has-been-deleted') }}
        </div>
        <div class="text-sm-h5 text-h6" v-if="channel.statusReason">
          {{ channel.statusReason }}
        </div>
      </div>
      <div
        v-else-if="videoError"
        class="d-flex justify-center align-center fill-height flex-column"
      >
        <div class="text-sm-h4 text-h5 font-weight-bold">
          {{ $tc('error.an-error-has-occurred') }}
        </div>
        <div class="text-sm-h5 text-h6" v-if="videoErrorMessage">
          {{ videoErrorMessage }}
        </div>
      </div>
      <player-wrapper
        v-else-if="videoAvailable"
        :video.sync="videoPlayed"
        :post="post"
        :live="video.isLiveType"
        embed
        :key="playerKey"
        :forceVideoLoad="forceVideoLoad"
        :autoplay="video.isLiveType"
        @embedError="handleEmbedError"
        @videoError="handleVideoError"
        @reload="reloadPlayer"
        style="height: 100vh;"
      ></player-wrapper>
      <div
        v-else-if="['processing', 'created'].includes(video.status)"
        class="d-flex justify-center align-center fill-height flex-column"
      >
        <div class="text-sm-h4 text-h5 font-weight-bold">
          {{ $tc('video.this-video-is-being') }}
          {{
            video.platform === 'internal'
              ? $tc('video.transcoded')
              : $tc('video.imported')
          }}...
        </div>
        <div class="text-sm-h5 text-h6">
          {{ $tc('video.will-be-available-shortly') }}
        </div>
        <v-progress-circular
          class="mt-3"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div
        v-else
        class="d-flex justify-center align-center fill-height flex-column text-center"
      >
        <div class="text-sm-h4 text-h5 font-weight-bold">
          {{ $tc('error.this-video-does-not-exist') }}
        </div>
        <div
          class="text-sm-h5 text-h6"
          v-html="$tc('video.publish-or-synchronise-video-free')"
        ></div>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import axios from 'axios'
import { requestService } from '@/services/request.service'
import { utilsService } from '@/services/utils.service'
import PlayerWrapper from '@/components/PlayerWrapper'

export default {
  name: 'EmbedVideo',
  components: {
    PlayerWrapper,
  },
  data() {
    return {
      loading: true,
      video: {},
      descriptionCondensed: true,
      descriptionOverflowScroll: false,
      channelThumbnailError: false,
      externalPlayability: {}, // updated when error is detected during loading
      videoError: false,
      videoErrorMessage: null,
      post: null,
      playerKey: 0,
      forceVideoLoad: false,
      videoPlayed: {},
    }
  },
  computed: {
    thumbnail() {
      const thumbnail = this.video.thumbnails.slice().sort((a, b) => {
        return b.height - a.height
      })[0]
      // const thumbnail = this.video.thumbnails.find(
      //   thumbnail => thumbnail.name === 'maxres',
      // )
      return thumbnail ? thumbnail.url : ''
    },
    channelThumbnail() {
      return this.channel.thumbnails.find(
        thumbnail => thumbnail.name === 'default',
      )
    },
    description() {
      if (this.video.description === null) {
        return ''
      }
      return this.video.description
        .replace(/[\u00A0-\u9999<>&]/g, i => {
          return '&#' + i.charCodeAt(0) + ';'
        })
        .replace(
          /(https?:\/\/\S+)/gim,
          '<a rel="nofollow" target="_blank" href="$1">$1</a>',
        )
    },
    videoAvailable() {
      return !!(
        this.video.externallyPlayable ||
        this.video.dashManifest ||
        this.video.hlsManifest
      )
    },
    isCensored() {
      return (
        !this.video.externallyPlayable && this.video.externalStatus === 'ERROR'
      )
    },
    publishedAt() {
      return new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }).format(new Date(this.video.publishedAt))
    },
    channel() {
      return this.video.channel || {}
    },
    active() {
      return this.channel.status === 'active'
    },
    channelLink() {
      if (this.video.channel.organization) {
        return {
          name: 'Organization',
          params: {
            slug: this.video.channel.organization.uid,
            noBanner: !this.video.channel.organization.hasBanner,
          },
        }
      }
      return {
        name: 'Channel',
        params: {
          slug: this.video.channel.id,
          noBanner: !this.video.channel.hasBanner,
        },
      }
    },
  },
  methods: {
    getVideo() {
      const ancestor =
        document.location.ancestorOrigins &&
        document.location.ancestorOrigins.length
          ? document.location.ancestorOrigins[0]
          : null
      let url
      if (this.$route.name === 'OrganizationLive') {
        url = `/organization/${this.$route.params.slug}/current-live?ancestor=${ancestor}`
      } else {
        url = `/post/${this.$route.params.slug}/details?ancestor=${ancestor}`
      }
      requestService
        .get(url)
        .then(response => {
          if (response.success === false && response.message) {
            this.videoError = true
            this.videoErrorMessage = response.message
            return
          }

          this.post = response
          this.video = response.video
          this.videoPlayed = this.video
          this.video.channel = response.channel

          this.$nextTick(() => {
            if (
              !this.videoAvailable &&
              ['processing', 'created'].includes(this.video.status)
            ) {
              setTimeout(this.getVideo, 10000)
            }
          })
          if (response.title) {
            this.$store.dispatch('updateTitle', response.title)
          }
        })
        .catch(() => {
          // 404 ?
        })
        .finally(() => {
          this.loading = false
        })
    },
    toggleDescription() {
      this.descriptionCondensed = !this.descriptionCondensed

      if (!this.descriptionCondensed) {
        setTimeout(
          () =>
            (this.descriptionOverflowScroll = !this.descriptionOverflowScroll),
          520,
        )
      } else {
        this.descriptionOverflowScroll = !this.descriptionOverflowScroll
      }
    },
    handleEmbedError() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/post/${this.video.id}/check-visibility`,
        )
        .then(response => {
          this.externalPlayability = response.data
        })
        .catch(error => {
          console.log('error catch handleEmbedError', error)
        })
    },
    handleVideoError(message) {
      this.videoError = true
      this.videoErrorMessage = message
    },
    formatDate(date) {
      return utilsService.formatDate(date, true)
    },
    formatDateRelative(date) {
      return utilsService.formatDateRelative(date)
    },
    formatPlatformName(name) {
      return utilsService.formatPlatform(name)['text']
    },
    reloadPlayer() {
      this.forceVideoLoad = true
      this.playerKey++
    },
    inIframe() {
      try {
        return window.self !== window.top
      } catch (e) {
        return true
      }
    },
  },
  mounted() {
    this.getVideo()
    this.$vuetify.goTo(0)
    document.documentElement.style.overflow = 'hidden'
  },
  beforeRouteUpdate(to, from, next) {
    this.$root.$emit('reload')
    next()
  },
}
</script>

<style lang="scss" scoped>
.description {
  white-space: pre-wrap;
  word-wrap: break-word;
  max-height: 800px;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;

  &.description-condensed {
    max-height: 100px;
  }

  &.overflow-scroll {
    overflow: auto;
  }
}

.channel-block {
  cursor: pointer;

  &:hover {
    background-color: var(--v-background-lighten1);
  }

  .channel-name {
    font-size: 1rem;
    line-height: 0.9rem;
  }
}

.theme--dark {
  .description {
    color: #fff;
  }
}

.subtitle-details {
  span:not(:last-child):after {
    content: ' \002022 ';
    margin-right: 3px;
  }
}

::v-deep {
  .v-skeleton-loader__image {
    height: 100%;
  }
}
</style>
