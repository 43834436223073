<template>
  <v-container class="px-0">
    <!-- <v-sheet
      outlined
      rounded
      height="56px"
      class="d-flex align-center px-2 card-input-outline"
      style="position:relative;"
    >
      <v-progress-linear
        indeterminate
        :active="initLoading"
        absolute
        top
      ></v-progress-linear>
      <div ref="cardElement" id="card-element" style="width: 100%"></div>
    </v-sheet>
    <div
      class="error--text mt-1"
      v-if="$store.state.stripe.stripeElementPaymentError.length"
    >
      {{ $store.state.stripe.stripeElementPaymentError }}
    </div>
    <div class="mt-3 grey--text text--darken-1">
      <v-icon small color="success" class="mb-1">mdi-shield-check</v-icon>
      {{ $tc('donation.secure-payment-via-stripe') }}
    </div> -->
    <div class="success text-center">
      Credit Card Confirm Payment
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'CreditCardConfirmPayment',
  props: {
    isConnect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initLoading: true,
      stripeElements: null,
    }
  },
  computed: {
    stripe() {
      return this.$store.state.stripe.stripe
    },
  },
  methods: {
    init() {
      if (this.stripe !== null) {
        return
      }

      const tmpStripe = window.Stripe(
        this.$store.state.account.status.loggedIn && this.isConnect
          ? process.env.VUE_APP_STRIPE_CONNECT_PUBLIC_KEY
          : process.env.VUE_APP_STRIPE_PUBLIC_KEY,
        {
          ...(!this.$store.state.account.status.loggedIn && {
            betas: ['payment_intent_beta_3'],
          }),
        },
      )

      this.$store.dispatch('stripe/setStripe', tmpStripe)
    },
    create() {
      try {
        this.init()
      } catch (e) {
        if (this.rebuild < 10) {
          this.rebuild++
          setTimeout(this.create, 500)
        }
      }
    },
    importStripe: () => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.onload = () => {
          resolve()
        }
        script.async = true
        script.src = 'https://js.stripe.com/v3/'
        document.head.appendChild(script)
      })
    },
  },
  mounted() {
    this.importStripe().then(this.create)
  },

  beforeDestroy() {
    this.$store.dispatch('stripe/setStripe', null)
  },
}
</script>

<style scoped></style>
