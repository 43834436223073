<template>
  <v-app>
    <v-main>
      <div class="fill-height align-start">
        <slot />
      </div>
      <slot name="outside"></slot>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'EmptyLayout',
}
</script>

<style scoped></style>
