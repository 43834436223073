<template>
  <v-card-title class="subtitle-2 text-break py-1">
    <v-list-item v-if="post" three-line class="px-0 list-text-block">
      <v-list-item-content class="py-0">
        <v-list-item-subtitle class="text body-2">
          {{ post.textContent }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card-title>
</template>

<script>
export default {
  name: 'TextPostPreview',
  props: {
    post: Object,
  },
}
</script>

<style scoped lang="scss">
.list-text-block {
  min-height: 32px !important;
  height: 32px;
  .text {
    color: white !important;
  }
}
</style>
