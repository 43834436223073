
import { defineComponent } from 'vue'
import VideoThumbnail from '@/components/VideoThumbnail.vue'
import ImagesInset from '@/components/ImagesInset.vue'

export default defineComponent({
  name: 'PostPreview',
  components: { VideoThumbnail, ImagesInset },
  props: {
    post: {
      type: Object,
      required: true,
    },
    showDuration: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    effectiveThumbnails() {
      if (this.post.thumbnailUrl) {
        return this.post.thumbnailUrl
      }
      if (this.post.video && this.post.video.thumbnails) {
        return this.post.video.thumbnails
      }
      return []
    },
    effectiveDuration() {
      if (!this.showDuration) {
        return 0
      }
      if (this.post.videoDuration) {
        return this.post.videoDuration
      }
      if (this.post.video && this.post.video.duration) {
        return this.post.video.duration
      }
    },
  },
})
